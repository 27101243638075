import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { CartWritePermission } from '@obeta/models/lib/models/Users/UserV2'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { RadioButtonLine } from '../form/FormComponents'
import { NotifyAutoComplete } from '../form/NotifyAutoComplete'
import { OrderPermissionGroupOption } from './OrderPermissionGroup'
import styles from './OrderPermissionRadioNotify.module.scss'

type Props = {
  usersToNotify: string[]
  setUsersToNotify: (value: string[]) => void
}

export const OrderPermissionRadioNotify: FC<OrderPermissionGroupOption & Props> = ({
  allowedSubmitAction,
  usersToNotify,
  setUsersToNotify,
}) => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()

  const isSelected = allowedSubmitAction === CartWritePermission.Notify

  return (
    <>
      <RadioButtonLine
        value={CartWritePermission.Notify}
        label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.CART_NOTIFY')}
        tooltip={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.CART_NOTIFY')}
      />
      {isSelected && (
        <div className={clsx(styles.notificationMailInput, mobile && styles.mobileInput)}>
          <NotifyAutoComplete usersToNotify={usersToNotify} setUsersToNotify={setUsersToNotify} />
        </div>
      )}
    </>
  )
}
