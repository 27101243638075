import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Mutation, MutationDeleteProjectArgs } from '@obeta/schema'
import { responseMessageToNotification } from '@obeta/utils/lib/responseMessageToNotification'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'

type DeleteProjectMutation = {
  __typename?: 'Mutation'
  deleteProject: Mutation['deleteProject']
}

const mutation = gql`
  mutation deleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      message
      type
    }
  }
`
const NOTIFICATION_ID_DELETE_PROJECT = 'delete-project'

export const useDeleteProject = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const deleteProject = useCallback(
    async (id: string) => {
      let success = false
      try {
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.Pending,
          id: NOTIFICATION_ID_DELETE_PROJECT,
          options: {
            message: t('PROJECTS.DELETE_PENDING'),
          },
        })
        const response = await client.mutate<DeleteProjectMutation, MutationDeleteProjectArgs>({
          mutation,
          variables: {
            input: { id },
          },
        })
        if (response.data?.deleteProject) {
          if (response.data?.deleteProject.type === 'Success') success = true
          responseMessageToNotification(response.data.deleteProject, {
            notificationIdToRemoveOnRender: NOTIFICATION_ID_DELETE_PROJECT,
          })
        }
      } catch (e) {
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.Error,
          id: 'project-mutation-went-wrong',
          options: {
            message: t('PROJECTS.DELETE_WENT_WRONG'),
            duration: 8000,
            notificationIdToRemoveOnRender: NOTIFICATION_ID_DELETE_PROJECT,
          },
        })
      }
      return success
    },
    [client, t]
  )

  return deleteProject
}
