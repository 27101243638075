import { DropdownOption } from '@obeta/models/lib/models/UI'
import { FC, KeyboardEvent, useCallback } from 'react'
import styles from './SearchFieldWithOptions.module.scss'
import { SearchField } from '../search-field/SearchField'
import { BaseOption, Select } from '../select/Select'
import { ReactComponent as SearchIcon } from 'assets/icon/designsystem/search.svg'
import { Button } from '@mui/material'

type SearchFieldWithOptionsProps = {
  placeholder: string
  options: DropdownOption[]
  optionValue: number | string | undefined
  searchValue: string
  onChangeOption: (option: number | string | undefined) => void
  onChangeSearchValue: (searchValue: string) => void
  onSearch: () => void
}

export const SearchFieldWithOptions: FC<SearchFieldWithOptionsProps> = (props) => {
  const {
    placeholder,
    options,
    optionValue,
    searchValue,
    onChangeOption,
    onChangeSearchValue,
    onSearch,
  } = props

  const getSelectedOption = useCallback((): BaseOption => {
    const selectedOption = options.find((option) => option.value === optionValue)
    return {
      id: selectedOption?.value ?? '',
      title: selectedOption?.label ?? '',
    }
  }, [options, optionValue])

  const mapOptions = useCallback((): BaseOption[] => {
    return options.map((option) => ({
      id: option.value ?? '',
      title: option.label,
    }))
  }, [options])

  const onSearchOptionChange = useCallback(
    (selectedOption: BaseOption) => {
      if (selectedOption.id !== optionValue) {
        onChangeOption(selectedOption.id)
      }
    },
    [optionValue, onChangeOption]
  )

  const onSearchFieldKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        onSearch()
      }
    },
    [onSearch]
  )

  const onSearchFieldReset = useCallback(() => {
    onChangeSearchValue('')
  }, [onChangeSearchValue])

  return (
    <div className={styles.searchFieldWithOptions}>
      <SearchField
        inputClassName={styles.inputContainer}
        placeholder={placeholder}
        startAdornment={
          <Select
            borderless
            options={mapOptions()}
            paperClassname={styles.dropdownPaper}
            selectedOption={getSelectedOption()}
            onOptionSelected={onSearchOptionChange}
          />
        }
        value={searchValue}
        onChange={onChangeSearchValue}
        onKeyUp={onSearchFieldKeyUp}
        onReset={onSearchFieldReset}
      />
      <Button className={styles.button} onClick={onSearch}>
        <SearchIcon />
      </Button>
    </div>
  )
}
