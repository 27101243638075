import { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { Query, QueryGetProjectArgs, ShippingType } from '@obeta/schema'
import { ProjectDetailsProject } from '@obeta/models/lib/schema-models/project'

type GetProjectQuery = {
  __typename?: 'Query'
  getProject: Query['getProject']
}

const query = gql`
  query getProject($id: String!) {
    getProject(id: $id) {
      project {
        id
        companyId
        customerId
        name
        description
        remark
        commission
        notes
        offerId
        phone
        shippingData {
          deliveryAddress {
            name1
            name2
            name3
            name4
            city
            country
            zipCode
            street
            houseNumber
          }
          storeId
          shippingType
          shippingDate
          addressId
        }
      }
      message
    }
  }
`

export const useProject = (id: string) => {
  const apolloClient = useApolloClient()
  const [project, setProject] = useState<ProjectDetailsProject>()
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    const fetchProject = async () => {
      const response = await apolloClient.query<GetProjectQuery, QueryGetProjectArgs>({
        query,
        variables: { id },
      })
      const fixedProject: ProjectDetailsProject = {
        ...response.data.getProject.project,
        shippingData: response.data.getProject.project.shippingData
          ? {
              ...response.data.getProject.project.shippingData,
              shippingType: response.data.getProject.project.shippingData
                .shippingType as ShippingType,
            }
          : undefined,
      }
      setProject(fixedProject)
      setInitialLoading(false)
    }
    if (id?.length > 0) {
      fetchProject()
    }
  }, [apolloClient, id])

  return { project, initialLoading }
}
