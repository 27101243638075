import React, { ComponentProps, Dispatch, FC, SetStateAction } from 'react'
import clsx from 'clsx'
import {
  CartOrderableStatus,
  CartPermissionStatus,
} from '@obeta/utils/lib/user-management/cart-status'
import { OrderPermissionCartNotification } from './OrderPermissionCartNotification'
import { OrderPermissionOrderableCartGroup } from './OrderPermissionOrderableCartGroup'
import { OrderPermissionCartGroup } from './OrderPermissionCartGroup'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import styles from './UserOrderPermission.module.scss'
import stylesMyProfile from '../MyProfilePage.module.scss'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { OrderPermissionGroup } from './OrderPermissionGroup'
import { CartWritePermission } from '@obeta/models/lib/models/Users/UserV2'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

export const UserOrderPermission: FC<
  {
    currentUser?: UserSubuser
    updateUser?: (value: UserSubuser) => void
    cartPermissionStatus: CartPermissionStatus
    setCartPermissionStatus: Dispatch<SetStateAction<CartPermissionStatus>>
    cartOrderableStatus?: CartOrderableStatus
    setCartOrderableStatus: Dispatch<SetStateAction<CartOrderableStatus | undefined>>
    allowedToOrder: boolean
    setAllowedToOrder: Dispatch<SetStateAction<boolean | undefined>>
    maxValueFullRange?: number | null
    setMaxValueFullRange: Dispatch<SetStateAction<number | null | undefined>>
    maxValueFullRangeActive: boolean
    setMaxValueFullRangeActive: Dispatch<SetStateAction<boolean>>
    maxValueOrders?: number | null
    setMaxValueOrders: Dispatch<SetStateAction<number | null | undefined>>
    maxValueOrdersActive: boolean
    setMaxValueOrdersActive: Dispatch<SetStateAction<boolean>>
    compact?: boolean
    projectCartIds: string[]
    setProjectCartIds: Dispatch<SetStateAction<string[]>>
    allowedSubmitAction: CartWritePermission
    setAllowedSubmitAction: Dispatch<SetStateAction<CartWritePermission>>
    handleMaxValueChange: (value: number | null | undefined) => void
  } & ComponentProps<typeof OrderPermissionCartNotification>
> = ({
  currentUser, // TODO check if this can be replaced by `user`
  updateUser,
  usersToNotify,
  setUsersToNotify,
  cartOrderableStatus,
  cartPermissionStatus,
  setCartPermissionStatus,
  setCartOrderableStatus,
  allowedToOrder,
  setAllowedToOrder,
  maxValueFullRange,
  setMaxValueFullRange,
  maxValueFullRangeActive,
  setMaxValueFullRangeActive,
  maxValueOrders,
  setMaxValueOrders,
  maxValueOrdersActive,
  setMaxValueOrdersActive,
  compact,
  projectCartIds,
  setProjectCartIds,
  allowedSubmitAction,
  setAllowedSubmitAction,
  handleMaxValueChange,
}) => {
  const user = useUserDataV2()
  const isUpdatedUserManagementFormEnabled = useFeatureToggle('UseUserManagementUpdatedForm')

  return (
    <div
      className={clsx(
        styles.container,
        compact && styles.compact,
        !user?.permissions?.Global_canSeeUserManagement && stylesMyProfile.disabled
      )}
    >
      <OrderPermissionCartGroup
        status={cartPermissionStatus}
        setStatus={setCartPermissionStatus}
        projectCartIds={projectCartIds}
        setProjectCartIds={setProjectCartIds}
      />
      {!isUpdatedUserManagementFormEnabled && (
        <>
          <OrderPermissionOrderableCartGroup
            currentUser={currentUser}
            updateUser={updateUser}
            status={cartOrderableStatus}
            setStatus={setCartOrderableStatus}
            allowedToOrder={allowedToOrder}
            setAllowedToOrder={setAllowedToOrder}
            maxValueFullRange={maxValueFullRange}
            setMaxValueFullRange={setMaxValueFullRange}
            maxValueFullRangeActive={maxValueFullRangeActive}
            setMaxValueFullRangeActive={setMaxValueFullRangeActive}
            maxValueOrders={maxValueOrders}
            setMaxValueOrders={setMaxValueOrders}
            maxValueOrdersActive={maxValueOrdersActive}
            setMaxValueOrdersActive={setMaxValueOrdersActive}
            handleMaxValueChange={handleMaxValueChange}
          />
          <OrderPermissionCartNotification
            usersToNotify={usersToNotify}
            setUsersToNotify={setUsersToNotify}
          />
        </>
      )}
      {isUpdatedUserManagementFormEnabled && (
        <OrderPermissionGroup
          allowedSubmitAction={allowedSubmitAction}
          setAllowedSubmitAction={setAllowedSubmitAction}
          usersToNotify={usersToNotify}
          setUsersToNotify={setUsersToNotify}
          maxValueFullRange={maxValueFullRange}
          setMaxValueFullRange={setMaxValueFullRange}
          maxValueFullRangeActive={maxValueFullRangeActive}
          setMaxValueFullRangeActive={setMaxValueFullRangeActive}
          currentUser={currentUser}
          updateUser={updateUser}
          handleMaxValueChange={handleMaxValueChange}
        />
      )}
    </div>
  )
}
