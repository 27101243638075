import {
  CartVerificationErrorCode,
  MoveCartItemsOfferIdUpdateEnum,
  ShoppingCartActionTypeEnum,
  ShoppingCartContextEnum,
  ShoppingCartDetailsState,
  ShoppingCartFilterEnum,
  ShoppingCartForDropdown,
  ShoppingCartItem,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart'
import {
  OptimisticPricesCartSummary,
  ShoppingCartSummary,
} from '@obeta/components/lib/shoppingcart/ShoppingCartSummary'
import { ShoppingCartDetailsHeader } from '@obeta/components/lib/shoppingcart/ShoppingCartDetailsHeader'
import { ShoppingCartDetailsSelectionBarEdit } from '@obeta/components/lib/shoppingcart/ShoppingCartDetailsSelectionBarEdit'
import { AppBar, Card, Divider, Stack, SvgIcon, Toolbar, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as PrintIcon } from 'assets/icon/designsystem/print.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/icon/designsystem/keyboard-arrow-right.svg'
import { useTranslation } from 'react-i18next'
import { OfferV2 } from '@obeta/models/lib/models/Offer/OfferV2'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useEntityMetaData } from '@obeta/data/lib/hooks/useEntityMetaData'
import { CartTemplateItemInput } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { useDispatch } from 'react-redux'
import {
  AddCartContextEnum,
  addProductToCartGraphQL,
  CartsActionTypes,
  changeProductAmountGraphQL,
  deleteCartItemsGraphQL,
  DeleteCartItemsGraphQLResultAction,
  emptyShoppingCartGraphQL,
  geCustomProductsGraphQL,
  getShoppingCartPrices,
  moveCartItemsGraphQL,
  MoveCartItemsGraphQLResultAction,
  resetShoppingCartGraphQL,
  updateCartGraphQL,
  verifyCartGraphQL,
  VerifyCartGraphQLResultAction,
} from '@obeta/data/lib/actions/cart-actions'
import { GenericControlStickyBox } from '@obeta/components/lib/shoppingcart/ShoppingCartControlStickyBox'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { interFrameCommunication } from '@obeta/utils/lib/interFrameCommunication'
import {
  ArticleActionTypes,
  getStock,
  getStockAvailabilityEstimates,
} from '@obeta/data/lib/actions/article-actions'
import { printCart } from '@obeta/utils/lib/printPdf'
import {
  PrintCartViewTypes,
  ScannerListType,
} from '@obeta/models/lib/models/PrintService/PrintInput'
import { CartItem } from '@obeta/components/lib/product-card'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { useHistory, useLocation, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import PromotionCheckoutComponent from './promotionCheckout'
import { useDispatchPoll } from '@obeta/data/lib/hooks/useDispatchPoll'
import styles from './ShoppingCartDetails.module.scss'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'
import { useCustomItemTitles } from '@obeta/utils/lib/cart/useCustomItemTitles'
import { CartWritePermission, PricePermissions } from '@obeta/models/lib/models/Users/UserV2'
import {
  TertiaryButton,
  TertiaryIconButton,
} from '@obeta/components/lib/custom-button/CustomButton'
import { getCollectionSync } from '@obeta/app-bootstrap'
import { useVoltimumPoints } from '@obeta/data/lib/hooks/useVoltimumPoints'
import { usePvProducts } from '@obeta/data/lib/hooks/usePvProducts'
import {
  displayCreditLimitExceeded,
  displayOrderBlockIsActivated,
  displayShippingBlockIsActivated,
} from '@obeta/utils/lib/displayOrderFailureNotifications'
import { ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { useLoadShopConfig } from '@obeta/data/lib/hooks/useLoadShopConfig'
import { DeadProductCard } from '@obeta/components/lib/dead-products/DeadProductCard'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import clsx from 'clsx'
import {
  getTotalAmountInStock,
  isEnoughAmountInStock,
} from '@obeta/data/lib/hooks/useReplacementDialog'
import { DropdownMenu } from '@obeta/components/lib/header/components/dropdown'
import { MenuItemColor } from '@obeta/components/lib/header/components/dropdown/types'
import { IListOption } from '@obeta/components/lib/header/types'
import { ScannerList } from '@obeta/components/lib/scannerlist/ScannerList'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { useIdsCartTransfer } from '@obeta/data/lib/hooks/ids/useIdsCartTransfer'
import { useOciCartTransfer } from '@obeta/data/lib/hooks/oci/useOciCartTransfer'
import { isSingleCartRemoveInProgress } from '@obeta/data/lib/epics'
import { useWarehouseContext } from '@obeta/data/lib/stores/useWarehouseContext'

export const ShoppingCartDetailsPage: React.FC = () => {
  useAuthenticatedRoute()
  const params = useParams()
  const shoppingCarts = useCartsv2WithPricesAndStock()
  const offers = useEntities<OfferV2>('offersv2')
  const { selectedStore } = useUserSelectedStore()
  const { state } = useLocation()

  useEffect(() => {
    interFrameCommunication.setIFrameHeight()
  })

  if (!selectedStore || shoppingCarts.length === 0) {
    return null
  }

  return (
    <ShoppingCartDetailsContent
      cartId={params.cartId}
      state={state as ShoppingCartDetailsState | undefined}
      offers={offers}
      shoppingCarts={shoppingCarts}
      selectedStore={selectedStore}
    />
  )
}

export async function getStaticProps() {
  return {
    props: {},
  }
}

const STOCKS_POLL_TIME = 10 * 1000 * 60 // ~ 10 minutes
const STOCK_AVAILABILITY_ESTIMATES_POLL_TIME = 24 * 60 * 60 * 1000 // ~ 24 hours

const ShoppingCartDetailsContent: React.FC<{
  cartId: string
  state?: ShoppingCartDetailsState
  offers: OfferV2[]
  shoppingCarts: ShoppingCartV2[]
  selectedStore: StoreV2
}> = (props) => {
  const domain = useAppDomain()
  const dispatch = useDispatch()
  const history = useHistory()
  const shopConfigData = useLoadShopConfig()
  const [isScannerlistOpen, setScannerListOpen] = useState(false)

  const { isLoggedIn, user: userV2, tokens } = useUserDataV2()

  // Keeping the original implementation using the React state for the time being
  // It will be removed once the queue system has been completely tested from within the old shop
  const { mobile, tabletAll, desktop } = useBreakpoints()
  const { cartId, offers, state, selectedStore, shoppingCarts } = props

  const cart = shoppingCarts.find((sc) => sc.id === cartId)

  const [shoppingCartItemsOptimisticCalculation, setShoppingCartItemsOptimisticCalculation] =
    useState<ShoppingCartItem[]>(cart?.items ? cart.items : [])

  useEffect(() => {
    const isDeleteSingleItemsInProgress = isSingleCartRemoveInProgress()

    if (cart?.items && !isDeleteSingleItemsInProgress) {
      setShoppingCartItemsOptimisticCalculation(cart.items)
    }
  }, [cart?.items])

  const [optimisticPricesCartSummary, setOptimisticPricesCartSummary] =
    useState<OptimisticPricesCartSummary>({})

  const [utilizeOptimisticPrices, setUtilizeOptimisticPrices] = useState(false)

  const { voltimumPoints, isLoadingVoltimumPoints } = useVoltimumPoints(
    cart?.items?.map((item) => ({ sapId: item.sapId, amount: item.amount })) ?? []
  )

  const { startPolling, stopPolling, pollingStartedRef } = useDispatchPoll({
    resultAction: ArticleActionTypes.GetStockResult,
    interval: STOCKS_POLL_TIME,
  })

  const {
    startPolling: startAvailabilityPolling,
    stopPolling: stopAvailabilityPolling,
    pollingStartedRef: availabilityPollingStartedRef,
  } = useDispatchPoll({
    resultAction: ArticleActionTypes.GetStockAvailabilityEstimatesResult,
    interval: STOCK_AVAILABILITY_ESTIMATES_POLL_TIME,
  })

  const hasPvProductsInCart = usePvProducts(cart?.items ?? [])
  const { warehouseId } = useWarehouseContext()

  useEffect(() => {
    trackCustom('cartsv2-resync-cartdetailspage')
    getCollectionSync('cartsv2')?.reSync()
  }, [cartId])

  useEffect(() => {
    stopPolling()
  }, [cartId, stopPolling])

  useEffect(() => {
    if (!userV2 || !cart || pollingStartedRef.current || !warehouseId) {
      return
    }
    dispatch(getShoppingCartPrices(cartId, verifyCart))

    startPolling(
      getStock(
        cart.items.map((itm) => itm.sapId),
        [warehouseId, userV2?.settings?.defaultStoreId].filter(Boolean) as string[]
      )
    )

    return () => {
      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, pollingStartedRef, startPolling, stopPolling, userV2, warehouseId])

  useEffect(() => {
    if (!userV2 || !cart || availabilityPollingStartedRef.current) {
      return
    }
    dispatch(getStockAvailabilityEstimates(cart.items.map((itm) => itm.sapId)))

    return () => {
      stopAvailabilityPolling()
    }
  }, [
    cart,
    availabilityPollingStartedRef,
    startAvailabilityPolling,
    stopAvailabilityPolling,
    userV2,
    dispatch,
  ])

  useEffect(() => {
    return () => {
      displayCreditLimitExceeded({ cartId, removeNotification: true })
      displayOrderBlockIsActivated({ cartId, removeNotification: true })
    }
  }, [cartId])
  const userCanSubmitCart =
    userV2?.permissions?.Cart_allowedSubmitAction === CartWritePermission.Submit

  const transferIdsCartAndSubmit = useIdsCartTransfer({
    withRedirect: true,
    cleanupCart: true,
    userCanSubmitCart,
  })
  const transferOciCartAndSubmit = useOciCartTransfer()

  let offerName = ''
  if (cart && cart.offerId !== '') {
    const offer = offers.find((offer) => offer.id === cart.offerId)
    offerName = offer?.offerName ?? ''
  }

  const shoppingCartPrices = cart?.shoppingCartPrices
  const shoppingCartsToShowDetailsFor: ShoppingCartForDropdown[] = []
  const shoppingCartsToMoveCartItemsTo: ShoppingCartForDropdown[] = []
  shoppingCarts.forEach((sc) => {
    let offerName = ''
    if (sc.offerId !== '') {
      offerName = offers.find((offer) => offer.id === sc.offerId)?.offerName ?? ''
    }
    const shoppingCartForDropdown: ShoppingCartForDropdown = {
      id: sc.id,
      name: sc.name,
      articleCount: sc.items.length,
      offerId: sc.offerId,
      offerName: offerName,
      count: sc.items.length,
    }
    if (sc.id !== cartId) {
      shoppingCartsToMoveCartItemsTo.push(shoppingCartForDropdown)
    }
    if (sc.items.length > 0) {
      shoppingCartsToShowDetailsFor.push(shoppingCartForDropdown)
    }
  })

  const updateCartIsCompleteDelivery = (checked: boolean) => {
    if (cart) {
      cart.shippingData.isCompleteDelivery = checked
      dispatch(updateCartGraphQL(cart))
    }
  }

  const updateCartOffer = (offerId: string) => {
    if (cart) {
      cart.offerId = offerId
      dispatch(updateCartGraphQL(cart))
    }
  }

  const unselectAllArticles = () => {
    setSelectedArticleIds([])
    setSelectedArticleCount(0)
  }

  const updateArticleSelection = (articleIds: string[]) => {
    articleIds.forEach((articleId) => {
      const index = selectedArticleIds.indexOf(articleId)
      if (index > -1) {
        selectedArticleIds.splice(index, 1)
      }
    })
    setSelectedArticleIds(selectedArticleIds)
    setSelectedArticleCount(selectedArticleIds.length)
  }

  const waitForDeleteResultAction = useActionNotification(
    CartsActionTypes.DeleteCartItemsGraphQLResult
  )
  const waitForMoveCartItemsResultAction = useActionNotification(
    CartsActionTypes.MoveCartItemsGraphQLResult
  )

  const deleteSingleCartItem = useCallback(
    (shoppingCartItem: ShoppingCartItem, imminentAddToCartWillFollow?: boolean) => {
      if (cart) {
        setShoppingCartItemsOptimisticCalculation(
          shoppingCartItemsOptimisticCalculation.filter(
            (cartItem) => cartItem.id !== shoppingCartItem.id
          )
        )
        //Note that deleteCartItems might need to be added to the array of dependencies of useCallback if it ever stops being re-generated upon every call
        deleteCartItems([shoppingCartItem.id], cart, true, imminentAddToCartWillFollow)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, cart?.items, shoppingCartItemsOptimisticCalculation]
  )

  const deleteMultipleCartItemsViaEditingMode = () => {
    setShoppingCartItemsOptimisticCalculation(
      shoppingCartItemsOptimisticCalculation.filter(
        (cartItem) => !selectedArticleIds.includes(cartItem.id)
      )
    )
    if (cart) {
      deleteCartItems(selectedArticleIds, cart, false)
    }
  }
  const deleteCartItems = (
    ids: string[],
    cart: ShoppingCartV2,
    deleteSingle,
    imminentAddToCartWillFollow?: boolean
  ) => {
    dispatch(
      deleteCartItemsGraphQL(cart, ids, deleteSingle, imminentAddToCartWillFollow as boolean)
    )

    waitForDeleteResultAction((action: DeleteCartItemsGraphQLResultAction) => {
      // deletion via editing mode
      if (!deleteSingle) {
        // set all items to be unselected and reset count shown within ShoppingCartEditingStickyBox
        if (action.success) {
          unselectAllArticles()
        }
        // set successfully deleted items to be unselected and update count shown within ShoppingCartEditingStickyBox
        else {
          updateArticleSelection(action.deletedItemIds)
        }
      }
      // re-route to list page if last item(s) of cart deleted
      if (action.cartEmpty) {
        history.push('/shopping-cart-list')
      }
      // Uncheck filter checkboxes, as they could now become disabled, if no items match criteria
      toggleFilteredNonSendableArticles(false)
      toggleFilteredNonExpressArticles(false)
    }, -1)
  }

  const handleMoveItems = (targetCartId, MoveCartItemsOfferIdUpdateEnum, offerId?) => {
    if (cart) {
      moveCartItems(selectedArticleIds, cart, targetCartId, MoveCartItemsOfferIdUpdateEnum, offerId)
    }
  }

  const moveCartItems = (
    itemIds: string[],
    sourceCart: ShoppingCartV2,
    targetCartId: string,
    MoveCartItemsOfferIdUpdateEnum: MoveCartItemsOfferIdUpdateEnum,
    offerId?: string
  ) => {
    dispatch(
      moveCartItemsGraphQL(
        itemIds,
        sourceCart,
        targetCartId,
        MoveCartItemsOfferIdUpdateEnum,
        offerId
      )
    )
    waitForMoveCartItemsResultAction((action: MoveCartItemsGraphQLResultAction) => {
      if (action.success) {
        unselectAllArticles()
      }
      // set successfully moved items to be unselected and update count shown within ShoppingCartEditingStickyBox
      else {
        updateArticleSelection(action.movedItemIds)
      }
      // re-route to list page if last item(s) of cart deleted
      if (action.cartEmpty) {
        history.push('/shopping-cart-list')
      }
      // Uncheck filter checkboxes, as they could now become disabled, if no items match criteria
      toggleFilteredNonSendableArticles(false)
      toggleFilteredNonExpressArticles(false)
    })
  }

  const changeProductAmount = useCallback(
    (itemId: string, amount: number, sapId?: string) => {
      if (cart) {
        const items = shoppingCartItemsOptimisticCalculation.map((productItem) =>
          productItem.id === itemId ? { ...productItem, amount } : productItem
        )

        setShoppingCartItemsOptimisticCalculation(items)
        setUtilizeOptimisticPrices(true)
        dispatch(
          changeProductAmountGraphQL(cart.id, itemId, amount, () =>
            dispatch(getShoppingCartPrices(cartId))
          )
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartId, shoppingCartItemsOptimisticCalculation, dispatch]
  )

  const { t } = useTranslation()
  const [context, setContext] = useState(state?.context ?? ShoppingCartContextEnum.DETAILS)
  const [nonExpressFilterActive, setNonExpressFilterActive] = useState(
    state?.filter === ShoppingCartFilterEnum.XPRESS
  )
  const [nonSendableFilterActive, setNonSendableFilterActive] = useState(
    state?.filter === ShoppingCartFilterEnum.NON_SENDABLE
  )
  const [selectedArticleCount, setSelectedArticleCount] = useState(0)
  const [selectedArticleIds, setSelectedArticleIds] = useState<string[]>([])
  const [includeXpressArticles, setIncludeXpressArticles] = useState(
    state?.filter !== ShoppingCartFilterEnum.XPRESS
  )
  const [includeSendableArticles, setIncludeSendableArticles] = useState(
    state?.filter !== ShoppingCartFilterEnum.NON_SENDABLE
  )
  const [actionType, setActionType] = useState(ShoppingCartActionTypeEnum.NO_ACTION)
  const [showEmptyCartConfirm, setShowEmptyCartConfirm] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [itemOfChoiceShouldBeDeleted, setItemOfChoiceShouldBeDeleted] = useState<
    ShoppingCartItem | undefined
  >(undefined)

  const [indexToShowForDiscontinuedItem, setIndexToShowForDiscontinuedItem] = useState(-1)

  const [itemsDiscontinuedWithPartialStock, setItemsDiscontinuedWithPartialStock] = useState<
    ShoppingCartItem[]
  >([])
  const [markedItemsOfChoiceForDeletion, setMarkedItemsOfChoiceForDeletion] = useState<
    ShoppingCartItem[]
  >([])
  const [
    markedItemsOfChoiceToBeAddedWithAdaptedAmount,
    setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount,
  ] = useState<ShoppingCartItem[]>([])
  const [replacementItemsToBeAddedToCart, setReplacementItemsToBeAddedToCart] = useState<
    ShoppingCartItem[]
  >([])

  useEffect(() => {
    return () => {
      interFrameCommunication.removeNotificationButton()
    }
  }, [])

  const enableEditingMode = () => {
    interFrameCommunication.setNotificationButton()
    setContext(ShoppingCartContextEnum.DETAILS_EDITING)
  }

  const disableEditingMode = () => {
    interFrameCommunication.removeNotificationButton()
    setContext(ShoppingCartContextEnum.DETAILS)
    setSelectedArticleIds([])
    setSelectedArticleCount(0)
    setIncludeSendableArticles(true)
    setIncludeXpressArticles(true)
    setActionType(ShoppingCartActionTypeEnum.NO_ACTION)
  }

  //The undoHandler function is declared here because this component has access to all necessary data needed to handle the various cases
  const undoHandler = () => {
    if (actionType === ShoppingCartActionTypeEnum.ADD_TO_TEMPLATE) {
      //TODO: the logic to reverse adding for the product last added to a given template
    }

    if (actionType === ShoppingCartActionTypeEnum.MOVE_TO_ANOTHER_CART) {
      //TODO: the logic to reverse moving for the product last moved to a given shopping cart
    }
  }

  const hasNoStock = (cartItem: ShoppingCartItem): boolean => {
    return (
      cartItem.product.stock?.filter((stockData) => {
        return stockData.amount > 0
      }).length === 0
    )
  }

  let {
    deadItemsBecauseDiscontinuedAndNoStock,
    deadItemsBecauseNotForSale,
    promotionItemsForDisplay,
    normalItemsForDisplay,
    specialItemsForDisplay,
  } = shoppingCartItemsOptimisticCalculation.reduce<{
    deadItemsBecauseDiscontinuedAndNoStock: ShoppingCartItem[]
    deadItemsBecauseNotForSale: ShoppingCartItem[]
    promotionItemsForDisplay: ShoppingCartItem[]
    normalItemsForDisplay: ShoppingCartItem[]
    specialItemsForDisplay: ShoppingCartItem[]
  }>(
    (acc, article) => {
      if (
        article.product.isDiscontinued &&
        hasNoStock(article) &&
        !article.product.replacementArticleSapId
      ) {
        acc.deadItemsBecauseDiscontinuedAndNoStock.push(article)
        return acc
      }
      if (
        article.product.isDiscontinued &&
        hasNoStock(article) &&
        article.product.replacementArticleSapId
      ) {
        acc.deadItemsBecauseNotForSale.push(article)
        return acc
      }
      if (article.product.isForSale === false) {
        acc.deadItemsBecauseNotForSale.push(article)
        return acc
      }

      if (article.isPromotionItem) {
        acc.promotionItemsForDisplay.push(article)
        return acc
      }
      if (article.product.type === ProductType.normal) {
        acc.normalItemsForDisplay.push(article)
        return acc
      }
      if (
        article.product.type === ProductType.special ||
        article.product.type === ProductType.custom ||
        article.product.type === ProductType.oversize
      ) {
        acc.specialItemsForDisplay.push(article)
        return acc
      }

      return acc
    },
    {
      deadItemsBecauseDiscontinuedAndNoStock: [],
      deadItemsBecauseNotForSale: [],
      promotionItemsForDisplay: [],
      normalItemsForDisplay: [],
      specialItemsForDisplay: [],
    }
  )

  const calculatePriceDataAllDisplayedProducts = () => {
    if (cart) {
      const allDisplayedItems = [
        ...normalItemsForDisplay,
        ...specialItemsForDisplay,
        ...promotionItemsForDisplay,
      ]
      let netPriceAllItems = 0
      let metalValueAllItems = 0
      allDisplayedItems.forEach((item) => {
        const netPricePerUnit = (item.prices?.netPrice || 0) / item.product?.priceDimension
        // const netPriceAllItems = netPricePerUnit * item.amount
        netPriceAllItems = netPriceAllItems + netPricePerUnit * item.amount
        const metalNeAdditionPerUnit =
          (item.prices?.metalNeAddition ?? 0) / item.product?.priceDimension
        metalValueAllItems = metalValueAllItems + metalNeAdditionPerUnit * item.amount
      })

      // For some yet unknown reason, shipping costs of 0 does not trigger a re-rendering of the corresponding component in the cart summary.
      // Hence, for now this marginal value is being used which is rounded up to zero successfully.
      let shippingCosts = 0.001
      if (!userV2?.hasFreeShipping) {
        if (
          cart.shippingData.shippingType === ShippingType.DefaultParcel &&
          netPriceAllItems < shopConfigData.shippingCostDefaultParcelFreeDeliveryThreshold
        ) {
          shippingCosts = shopConfigData.shippingCostDefaultParcelTotal
        } else if (
          cart.shippingData.shippingType === ShippingType.FastParcel &&
          netPriceAllItems < shopConfigData.shippingCostFastParcelFreeDeliveryThreshold
        ) {
          shippingCosts = shopConfigData.shippingCostFastParcelTotal
        }
      }

      const netSum = netPriceAllItems + metalValueAllItems + shippingCosts
      const vat = netSum * shopConfigData.valueAddedTax

      setOptimisticPricesCartSummary({
        net: netPriceAllItems,
        metal: metalValueAllItems,
        shipping: shippingCosts,
        netSum: netSum,
        vat: vat,
        totalSum: netSum + vat,
      })
    }
  }

  useEffect(() => {
    calculatePriceDataAllDisplayedProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.id, shoppingCartItemsOptimisticCalculation])

  useCustomItemTitles({ action: geCustomProductsGraphQL, cart, items: specialItemsForDisplay })

  // only non-sendable articles are shown
  if (!includeSendableArticles) {
    const filterNonSendable = (shoppingCartItem) => !shoppingCartItem.product.isSendable
    promotionItemsForDisplay = promotionItemsForDisplay.filter(filterNonSendable)
    normalItemsForDisplay = normalItemsForDisplay.filter(filterNonSendable)
    specialItemsForDisplay = specialItemsForDisplay.filter(filterNonSendable)
    deadItemsBecauseNotForSale = deadItemsBecauseNotForSale.filter(Boolean)
    deadItemsBecauseDiscontinuedAndNoStock = deadItemsBecauseDiscontinuedAndNoStock.filter(Boolean)
  }

  const filterForNonXpressItems = (items: ShoppingCartItem[]) => {
    return items.filter(
      (shoppingCartItem) =>
        // no stock data provided
        !shoppingCartItem.product.stock ||
        // insufficient stock data provided
        shoppingCartItem.product.stock.length === 0 ||
        // only online stock data provided
        (shoppingCartItem.product.stock.length === 1 &&
          shoppingCartItem.product.stock[0].warehouseId === '10') ||
        // item only partially available in store
        shoppingCartItem.product.stock?.find(
          (stockObject) =>
            stockObject.warehouseId !== '10' && stockObject.amount < shoppingCartItem.amount
        )
    )
  }

  // only non-xpress articles are shown
  if (!includeXpressArticles) {
    normalItemsForDisplay = filterForNonXpressItems(normalItemsForDisplay)
    specialItemsForDisplay = filterForNonXpressItems(specialItemsForDisplay)
  }

  const nonSendableItems = shoppingCartItemsOptimisticCalculation.filter(
    (article) => !article.product.isSendable
  )

  const nonExpressItems = filterForNonXpressItems(shoppingCartItemsOptimisticCalculation)
  const metaData = useEntityMetaData('cartsv2')
  const isUpdating = metaData.isUpdating

  const updateIndividualSelectedArticles = useCallback(
    (articleId: string, checked: boolean) => {
      const index = selectedArticleIds.indexOf(articleId)
      const newSelectedArticleIds = [...selectedArticleIds]

      if (checked) {
        if (index === -1) {
          newSelectedArticleIds.push(articleId)
        }
      } else if (index > -1) {
        newSelectedArticleIds.splice(index, 1)
      }

      setSelectedArticleIds(newSelectedArticleIds)
      setSelectedArticleCount(newSelectedArticleIds.length)
    },
    [selectedArticleIds]
  )

  const toggleFilteredNonSendableArticles = (checked: boolean) => {
    setNonSendableFilterActive(checked)
    normalItemsForDisplay = []
    specialItemsForDisplay = []
    setIncludeSendableArticles(!checked)
  }

  const toggleFilteredNonExpressArticles = (checked: boolean) => {
    setNonExpressFilterActive(checked)
    normalItemsForDisplay = []
    specialItemsForDisplay = []
    setIncludeXpressArticles(!checked)
  }

  const updateAllSelectedArticles = (checked: boolean) => {
    if (checked) {
      // Note: Use normalItemsForDisplay, specialItemsForDisplay, and promotionItemsForDisplay rather than
      // shoppingCartItems, as only with the former the filter logic relevant for the editing mode is applied
      const newSelectedArticleIds = selectedArticleIds
      normalItemsForDisplay.forEach((article) => {
        const index = newSelectedArticleIds.indexOf(article.id)
        if (index === -1) {
          newSelectedArticleIds.push(article.id)
        }
      })
      specialItemsForDisplay.forEach((article) => {
        const index = newSelectedArticleIds.indexOf(article.id)
        if (index === -1) {
          newSelectedArticleIds.push(article.id)
        }
      })
      promotionItemsForDisplay.forEach((article) => {
        const index = newSelectedArticleIds.indexOf(article.id)
        if (index === -1) {
          newSelectedArticleIds.push(article.id)
        }
      })
      setSelectedArticleIds(newSelectedArticleIds)
      setSelectedArticleCount(newSelectedArticleIds.length)
    } else {
      setSelectedArticleIds([])
      setSelectedArticleCount(0)
    }
  }

  const creditLimitExceeded = useRef(false)
  const orderBlockIsActivated = useRef(false)
  const shippingBlockIsActivated = useRef(false)

  const prevCreditLimitExceeded = useRef(false)
  useEffect(() => {
    prevCreditLimitExceeded.current = creditLimitExceeded.current
  })

  const waitForResultAction = useActionNotification(CartsActionTypes.VerifyCartGraphQLResult)
  const waitForEmptyShoppingCartAction = useActionNotification(
    CartsActionTypes.EmptyShoppingCartGraphQLResult
  )
  const waitForResetShoppingCartAction = useActionNotification(
    CartsActionTypes.ResetShoppingCartGraphQLResult
  )
  const waitForDeleteItemsResultAction = useActionNotification(
    CartsActionTypes.DeleteCartItemsGraphQLResult
  )

  useEffect(() => {
    //avoid running excessively as long as there is no stock data on product
    if (!cart?.items[0]?.product.stock) return

    const itemsDiscontinuedWithPartialStock = shoppingCartItemsOptimisticCalculation.filter(
      (item) => {
        if (item.product.isDiscontinued) {
          const totalAmountInStock = getTotalAmountInStock(item)
          return !isEnoughAmountInStock(item.amount, totalAmountInStock)
        } else {
          return false
        }
      }
    )
    setItemsDiscontinuedWithPartialStock(itemsDiscontinuedWithPartialStock)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.items, cart?.items[0]?.product.stock, shoppingCartItemsOptimisticCalculation])

  //This should be the correct condition to perform WK-related actions for discontinued items with partial stock. At this point, all dialogs should already be shown
  useEffect(() => {
    if (cart) {
      if (indexToShowForDiscontinuedItem === -1) return
      if (!itemsDiscontinuedWithPartialStock[indexToShowForDiscontinuedItem]) {
        const replacementItemsToAddToCart = replacementItemsToBeAddedToCart.map((item) => {
          return {
            sapId: item.product.sapId,
            amount: item.amount,
            title: item.product.title,
          }
        })

        dispatch(
          addProductToCartGraphQL({
            cartId: cart.id,
            items: replacementItemsToAddToCart,
            context: AddCartContextEnum.Generic,
          })
        )

        const itemIds = markedItemsOfChoiceForDeletion.map((item) => item.id)
        if (itemIds.length > 0) deleteCartItems(itemIds, cart, false, true)

        waitForDeleteItemsResultAction((action: DeleteCartItemsGraphQLResultAction) => {
          if (action.deletedItemIds === itemIds) {
            const itemsToAddToCart = markedItemsOfChoiceToBeAddedWithAdaptedAmount.map((item) => {
              return {
                sapId: item.product.sapId,
                amount: item.amount,
                title: item.product.title,
              }
            })
            dispatch(
              addProductToCartGraphQL({
                cartId: cart.id,
                items: itemsToAddToCart,
                context: AddCartContextEnum.Generic,
              })
            )
          }
        })

        //This is to prevent permanent loop here.
        setIndexToShowForDiscontinuedItem(-1)

        setMarkedItemsOfChoiceForDeletion([])
        let shouldReloadPage = false
        setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount((oldValue) => {
          if (oldValue.length > 0) shouldReloadPage = true
          return []
        })
        setReplacementItemsToBeAddedToCart((oldValue) => {
          if (oldValue.length > 0) shouldReloadPage = true
          return []
        })

        setTimeout(() => {
          if (shouldReloadPage) window.location.reload()
        }, 500) //tardy execution to make sure the reload won't happen before possible items of choice are added to the cart.
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexToShowForDiscontinuedItem, cart?.items, itemsDiscontinuedWithPartialStock.length])

  // TODO: consolidate refs and useEffect, since its almost identical with code in ShoppingCartCheckoutPage
  // check credit limit, orderBlock, shippingBlock - violates DRY
  const verifyCart = () => {
    dispatch(verifyCartGraphQL(cartId, 5 * 1000 * 60))
    waitForResultAction((action: VerifyCartGraphQLResultAction) => {
      const exceeded = action.results.find((error) => {
        return error.errorCode === CartVerificationErrorCode.CreditLimitExceeded
      })
      const orderBlock = action.results.find((error) => {
        return error.errorCode === CartVerificationErrorCode.OrderingBlocked
      })
      const shippingBlock = action.results.find((error) => {
        return error.errorCode === CartVerificationErrorCode.ShippingBlocked
      })

      creditLimitExceeded.current = exceeded !== undefined
      orderBlockIsActivated.current = orderBlock !== undefined
      shippingBlockIsActivated.current = shippingBlock !== undefined

      if (creditLimitExceeded.current) {
        displayCreditLimitExceeded({ cartId })
      } else if (!creditLimitExceeded.current && prevCreditLimitExceeded.current) {
        displayCreditLimitExceeded({ cartId, removeNotification: true })
      } else if (orderBlockIsActivated.current) {
        displayOrderBlockIsActivated({ cartId })
      } else if (shippingBlockIsActivated.current) {
        displayShippingBlockIsActivated(cartId)
      }
    })
  }

  const renderCartItem = (shoppingCartItem: ShoppingCartItem) => {
    return (
      <CartItem
        shoppingCartItem={shoppingCartItem}
        withCheckbox={context === ShoppingCartContextEnum.DETAILS_EDITING}
        changeProductAmount={changeProductAmount}
        onDeleteClicked={deleteSingleCartItem}
        selectedStore={selectedStore}
        selected={selectedArticleIds.indexOf(shoppingCartItem.id) > -1}
        onChangeHandler={updateIndividualSelectedArticles}
        loadingPrices={false}
        loadingStocks={false}
        user={userV2}
        isLoggedIn={isLoggedIn}
        mobile={mobile}
        tablet={tabletAll}
        productId={shoppingCartItem.product.sapId}
        url={
          shoppingCartItem.product.images && shoppingCartItem.product.images?.length > 0
            ? shoppingCartItem.product.images[0].url
            : ''
        }
      />
    )
  }

  const onPrintCart = (view: PrintCartViewTypes) => {
    printCart({
      view,
      cartId,
      accessToken: tokens?.accessToken,
      cartName: cart?.name,
      domain,
    })
  }
  const emptyAndResetShoppingCart = () => {
    if (cart) {
      dispatch(emptyShoppingCartGraphQL(cart))
      waitForEmptyShoppingCartAction(() => {
        dispatch(resetShoppingCartGraphQL(cart))
        waitForResetShoppingCartAction(() => {
          history.push('/shopping-cart-list')
        })
      })
    }
  }

  const onEmptyCartClick = () => {
    setShowEmptyCartConfirm(true)
  }

  const handleConfirmEmptyCart = () => {
    emptyAndResetShoppingCart()
    setShowEmptyCartConfirm(false)
  }

  const handleCancelEmptyCart = () => {
    setShowEmptyCartConfirm(false)
  }

  const isDeadProductsInCart =
    deadItemsBecauseDiscontinuedAndNoStock.length > 0 || deadItemsBecauseNotForSale.length > 0

  const maxCartValueExceeded =
    typeof userV2?.permissions?.Cart_maxCartValue === 'number' &&
    shoppingCartPrices?.netSum !== undefined &&
    shoppingCartPrices?.netSum > userV2?.permissions.Cart_maxCartValue

  return (
    <>
      <div className={styles.background} />
      <div className={styles.shoppingcartdetails}>
        <Grid container direction={'column'} className={styles.pageGrid}>
          <Grid>
            <AppBar
              color={'transparent'}
              elevation={0}
              position={'static'}
              className={styles.header}
            >
              <Toolbar variant={'dense'} className={styles.toolbar} disableGutters={true}>
                {context === ShoppingCartContextEnum.DETAILS && (
                  <TertiaryButton
                    size={desktop ? 'small' : 'large'}
                    leftIcon={<SvgIcon component={ArrowBackIcon} />}
                    onClick={() => history.push('/shopping-cart-list')}
                  >
                    {(desktop || tabletAll) && t('SHOPPING_CART.NAVIGATION.BACK_TO_PAGE_0')}
                    {mobile && t('SHOPPING_CART.NAVIGATION.BACK')}
                  </TertiaryButton>
                )}
                {context === ShoppingCartContextEnum.DETAILS_EDITING && (
                  <TertiaryButton
                    leftIcon={<SvgIcon component={CloseIcon} />}
                    onClick={disableEditingMode}
                  >
                    {t('SHOPPING_CART.COMPLETE_CART_EDIT')}
                  </TertiaryButton>
                )}
                {(desktop || context !== ShoppingCartContextEnum.DETAILS_EDITING) && (
                  <DropdownMenu
                    classes={{ titleContainerClassName: styles.titleContainerClassName }}
                    title={
                      !mobile ? (
                        <TertiaryButton
                          size={desktop ? 'small' : 'large'}
                          leftIcon={<SvgIcon component={PrintIcon} />}
                        >
                          {t('BUTTON.PRINT')}
                        </TertiaryButton>
                      ) : (
                        <TertiaryIconButton size={'large'} icon={<PrintIcon />} />
                      )
                    }
                    options={[
                      {
                        name: t('SHOPPING_CART.SHOPPING_CART_TABULAR'),
                        id: `print_${PrintCartViewTypes.Tabular}`,
                      },
                      {
                        name: t('SHOPPING_CART.SHOPPING_CART_DETAIL'),
                        id: `print_${PrintCartViewTypes.Detailed}`,
                      },
                      {
                        name: t('TEMPLATES_CONTEXT_MENU.SCANNERLIST'),
                        id: 'scannerlist',
                        isDisabled: !cart?.items?.length,
                      },
                    ]}
                    color={MenuItemColor.Black}
                    withBorder={false}
                    onMenuItemClick={(data: IListOption) => {
                      if (data.id === `print_${PrintCartViewTypes.Detailed}`)
                        onPrintCart(PrintCartViewTypes.Detailed)

                      if (data.id === `print_${PrintCartViewTypes.Tabular}`)
                        onPrintCart(PrintCartViewTypes.Tabular)

                      if (data.id === 'scannerlist') setScannerListOpen(true)
                    }}
                    keepDefaultLeftPosition={true}
                    topSpacingToAnchorEl={'0.625rem'}
                  />
                )}
                {isScannerlistOpen && (
                  <ScannerList
                    type={ScannerListType.ShopingCart}
                    parentId={cartId}
                    isOpen={isScannerlistOpen}
                    onClose={() => setScannerListOpen(false)}
                  />
                )}
              </Toolbar>
            </AppBar>
          </Grid>
          <Divider />
          <Grid className={styles.content} container columnSpacing={1.5} rowSpacing={1}>
            <Grid size={{ xs: 12, lg: 8 }}>
              <Grid container rowSpacing={1}>
                <Grid key="details-header" size={{ xs: 12 }}>
                  <ShoppingCartDetailsHeader
                    activeCartId={cartId}
                    articleCount={normalItemsForDisplay.length + specialItemsForDisplay.length}
                    cartData={shoppingCartsToShowDetailsFor}
                    context={context}
                    enableEditingMode={enableEditingMode}
                    nonSendableArticleCount={nonSendableItems.length}
                    nonExpressArticleCount={nonExpressItems.length}
                    nonExpressFilterActive={nonExpressFilterActive}
                    nonSendableFilterActive={nonSendableFilterActive}
                    selectedAll={
                      selectedArticleIds.length ===
                      normalItemsForDisplay.length + specialItemsForDisplay.length
                    }
                    selectedCount={selectedArticleIds.length}
                    toggleFilteredNonSendableArticles={toggleFilteredNonSendableArticles}
                    updateAllSelectedArticles={updateAllSelectedArticles}
                    toggleFilteredNonExpressArticles={toggleFilteredNonExpressArticles}
                    onEmptyCartClick={onEmptyCartClick}
                  />
                </Grid>

                {maxCartValueExceeded && (
                  <Grid size={{ xs: 12 }}>
                    <Card className={styles.alertBox}>
                      <Stack className={styles.alertBoxHead}>
                        <Stack>
                          <Typography variant={'bodyBold'}>
                            {t('SHOPPING_CART.ALERT_BOX.ORDER_LIMIT_EXCEEDED_HEAD')}
                          </Typography>
                        </Stack>
                        <Stack>
                          <TertiaryButton
                            rightIcon={<ArrowRightIcon />}
                            onClick={() => {
                              history.push(`/user-management/${userV2.customerNumber}?tab=2`)
                            }}
                          >
                            {t('SHOPPING_CART.ALERT_BOX.TO_THE_RIGHTS')}
                          </TertiaryButton>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography variant={'body2'}>
                          {t('SHOPPING_CART.ALERT_BOX.ORDER_LIMIT_EXCEEDED_TEXT')}
                        </Typography>
                      </Stack>
                    </Card>
                  </Grid>
                )}

                {isDeadProductsInCart && (
                  <Grid className={clsx(styles.marginBottomOneRem, styles.paddingTopOneAndHalfRem)}>
                    <Typography variant={'bodyBold'}>
                      {t('SHOPPING_CART.DEAD_PRODUCTS_HEADER')}
                    </Typography>
                  </Grid>
                )}
                {deadItemsBecauseDiscontinuedAndNoStock.length > 0 &&
                  deadItemsBecauseDiscontinuedAndNoStock.map((cartItem) => {
                    return (
                      <div className={styles.wrapperDeadProductCard}>
                        <DeadProductCard
                          cartItem={cartItem}
                          userV2={userV2}
                          key={cartItem.sapId}
                          onDeleteClicked={deleteSingleCartItem}
                        />
                      </div>
                    )
                  })}

                {cart &&
                  deadItemsBecauseNotForSale.length > 0 &&
                  deadItemsBecauseNotForSale.map((cartItem) => {
                    return (
                      <div className={styles.wrapperDeadProductCard}>
                        <DeadProductCard
                          cartItem={cartItem}
                          userV2={userV2}
                          showReplacementSection={true}
                          key={cartItem.sapId}
                          onDeleteClicked={deleteSingleCartItem}
                          activeCart={cart}
                        />
                      </div>
                    )
                  })}

                {!!cart?.promotionId && (
                  <>
                    <PromotionCheckoutComponent
                      promotionId={cart.promotionId}
                      promotionItems={promotionItemsForDisplay}
                    />
                    {promotionItemsForDisplay.map((shoppingCartItem) => {
                      return (
                        <Grid key={shoppingCartItem.product.sapId} size={{ xs: 12 }}>
                          {renderCartItem(shoppingCartItem)}
                        </Grid>
                      )
                    })}
                    <Grid className={styles.mt_1}>
                      <Typography variant={'bodyBold'}>
                        Artikel, die nicht zur Aktion gehören
                      </Typography>
                    </Grid>
                  </>
                )}

                {normalItemsForDisplay.length > 0 && (
                  <Grid className={styles.mt_1}>
                    <Typography variant={'bodyBold'}>
                      {t('SHOPPING_CART.CHECK_ARTICLES.STOCK_ARTICLES')}
                    </Typography>
                  </Grid>
                )}
                {normalItemsForDisplay?.map((shoppingCartItem) => {
                  return (
                    <Grid key={shoppingCartItem.product.sapId} size={{ xs: 12 }}>
                      {renderCartItem(shoppingCartItem)}
                    </Grid>
                  )
                })}
                {specialItemsForDisplay.length > 0 && (
                  <Grid className={styles.mt_1}>
                    <Typography variant={'bodyBold'}>Sonderbestellung</Typography>
                  </Grid>
                )}
                {specialItemsForDisplay?.map((shoppingCartItem) => {
                  return (
                    <Grid key={shoppingCartItem.id} size={{ xs: 12 }}>
                      {renderCartItem(shoppingCartItem)}
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid size={{ xs: 12, lg: 4 }}>
              {cart && (
                <ShoppingCartSummary
                  shoppingCartPrices={shoppingCartPrices}
                  offers={offers}
                  context={context}
                  shippingData={cart.shippingData}
                  updateCartIsCompleteDelivery={updateCartIsCompleteDelivery}
                  updateCartOffer={updateCartOffer}
                  cartOfferId={cart.offerId}
                  selectedCartId={cartId}
                  checkBoxIsCompleteDelivery={cart.shippingData.isCompleteDelivery}
                  user={userV2}
                  loadingPrices={isUpdating || !shoppingCartPrices}
                  specialOrCustomProductsInCart={
                    specialItemsForDisplay && specialItemsForDisplay.length > 0
                  }
                  voltimumPoints={voltimumPoints}
                  isLoadingVoltimumPoints={isLoadingVoltimumPoints}
                  hasPvProductsInCart={hasPvProductsInCart}
                  isEmptyCart={cart.items.length === 0}
                  optimisticPricesCartSummary={optimisticPricesCartSummary}
                  setUtilizeOptimisticPrices={setUtilizeOptimisticPrices}
                  utilizeOptimisticPrices={utilizeOptimisticPrices}
                  deadProductsInCart={isDeadProductsInCart}
                  itemsDiscontinuedWithPartialStock={itemsDiscontinuedWithPartialStock}
                  setItemsDiscontinuedWithPartialStock={setItemsDiscontinuedWithPartialStock}
                  setItemOfChoiceShouldBeDeleted={setItemOfChoiceShouldBeDeleted}
                  indexToShowForDiscontinuedItem={indexToShowForDiscontinuedItem as number}
                  setIndexToShowForDiscontinuedItem={setIndexToShowForDiscontinuedItem}
                  setMarkedItemsOfChoiceForDeletion={setMarkedItemsOfChoiceForDeletion}
                  setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount={
                    setMarkedItemsOfChoiceToBeAddedWithAdaptedAmount
                  }
                  setReplacementItemsToBeAddedToCart={setReplacementItemsToBeAddedToCart}
                  activeCart={cart}
                  markedItemsOfChoiceForDeletion={markedItemsOfChoiceForDeletion}
                  transferOciCartAndSubmit={() => transferOciCartAndSubmit(cart)}
                  transferIdsCartAndSubmit={() => transferIdsCartAndSubmit(cart)}
                />
              )}
            </Grid>
          </Grid>
          {cart && context === ShoppingCartContextEnum.DETAILS_EDITING && (
            <ShoppingCartDetailsSelectionBarEdit
              productsToAdd={() => {
                const itemsToAdd: CartTemplateItemInput[] = []
                selectedArticleIds.map((articleId) =>
                  itemsToAdd.push({
                    productId:
                      cart?.items?.find((item) => item.id === articleId)?.product.sapId || '',
                    amount: cart?.items?.find((item) => item.id === articleId)?.amount || 0,
                  })
                )
                return itemsToAdd
              }}
              cartData={shoppingCartsToMoveCartItemsTo}
              deleteCartItems={deleteMultipleCartItemsViaEditingMode}
              disableEditingMode={disableEditingMode}
              handleMoveItems={handleMoveItems}
              currentCartOfferId={cart.offerId}
              currentCartOfferName={offerName}
              selectedArticleCount={selectedArticleCount}
              setActionType={setActionType}
              undoHandler={undoHandler}
            />
          )}
          {context === ShoppingCartContextEnum.DETAILS && shoppingCartPrices && (
            <GenericControlStickyBox
              cartId={cartId}
              context={context}
              enableEditingMode={enableEditingMode}
              closeNotificationBox={() => setActionType(ShoppingCartActionTypeEnum.NO_ACTION)}
              userCanReadPrices={
                userV2?.permissions?.Global_canReadPrices === PricePermissions.PurchasePrice
              }
              shoppingCartPrices={shoppingCartPrices}
              deadProductsInCart={isDeadProductsInCart}
              optimisticNetSum={
                optimisticPricesCartSummary?.netSum && optimisticPricesCartSummary.netSum > 0.001
                  ? optimisticPricesCartSummary.netSum
                  : undefined
              }
              utilizeOptimisticPrices={utilizeOptimisticPrices}
              setUtilizeOptimisticPrices={setUtilizeOptimisticPrices}
              itemsDiscontinuedWithPartialStock={itemsDiscontinuedWithPartialStock}
              transferOciCartAndSubmit={() => transferOciCartAndSubmit(cart)}
              transferIdsCartAndSubmit={() => transferIdsCartAndSubmit(cart)}
            />
          )}
        </Grid>
        <Confirm
          heading={t('SHOPPING_CART.EMPTY_CART')}
          body={t('SHOPPING_CART.EMPTY_CART_CONFIRM')}
          handleConfirm={handleConfirmEmptyCart}
          handleCancel={handleCancelEmptyCart}
          openConfirmDialog={showEmptyCartConfirm}
          changesInCart={false}
        />
      </div>
    </>
  )
}
