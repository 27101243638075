import React, { FC, ReactElement } from 'react'
import Grid from '@mui/material/Grid2'
import { Card, CardContent, Typography } from '@mui/material'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import styles from './CardLayout.module.scss'
import { ShopLink } from '../link/ShopLink'
import { CardLayoutIconHeader } from './CardLayoutIconHeader'
import clsx from 'clsx'

type Props = {
  className?: string
  title?: string
  href?: string
  mainLineElement?: JSX.Element // pass this if you don't want to use the standard title / linked title
  bottomLineElement?: JSX.Element
  headerElement: ReactElement<typeof CardLayoutIconHeader> // if further types are supported extend this type
}

const Title = ({ href, title }) => {
  if (href) {
    return (
      <ShopLink href={href}>
        <Typography variant="boldText">{title}</Typography>
      </ShopLink>
    )
  }
  return <Typography variant="boldText">{title}</Typography>
}

// Layout component for cards like cart templates or projects
// no state handling, no logic
// takes the full width / width needs to be adjusted by parent
export const CardLayout: FC<Props> = ({
  className,
  title,
  href,
  mainLineElement,
  bottomLineElement,
  headerElement,
}) => {
  return (
    <Card elevation={1} className={clsx(styles.card, className)}>
      <CardContent className={styles.content}>
        <Grid container direction={'column'}>
          {headerElement}
          <LightGrayDivider />
          <Grid className={styles.name}>
            {mainLineElement}
            {!mainLineElement && <Title title={title} href={href} />}
          </Grid>
          {bottomLineElement && <Grid className={styles.bottom}>{bottomLineElement}</Grid>}
        </Grid>
      </CardContent>
    </Card>
  )
}
