import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { OrderShippingType, OrdersFacetV2 } from '@obeta/schema'
import { useOrderListContext } from '@obeta/data/lib/stores/useOrderListContext'
import styles from './OrderListFilters.module.scss'
import { CheckboxWithAmountItem } from '../checbox-radio-item/CheckboxRadioItem'
import {
  getOrderListFilterToggleState,
  sortFacetByCustomOrder,
  toggleOrderListFilter,
} from '@obeta/utils/lib/orders-helpers'
import { getTranslationString } from '@obeta/utils/lib/translation-helpers'
import {
  FACETS_SHIPPING_TYPE_ORDER,
  ORDER_SHIPPING_TYPE_TRANSLATIONS,
} from '@obeta/models/lib/models/Orders'

export const OrderListFiltersShippingType: FC = () => {
  const history = useHistory()
  const { facets, isLoading, resetOrders, setIsLoading } = useOrderListContext()
  const initiateRefresh = useCallback(
    (shippingTypeValue) => {
      // Reset order list
      resetOrders()
      setIsLoading(true)
      toggleOrderListFilter(history, 'shippingType', shippingTypeValue)

      return true
    },
    [history, resetOrders, setIsLoading]
  )
  const { t } = useTranslation()

  return (
    <div className={styles.content}>
      {sortFacetByCustomOrder(
        facets?.shippingType as OrdersFacetV2[],
        FACETS_SHIPPING_TYPE_ORDER
      ).map((shippingType) => {
        const shippingTypeValue = shippingType.name as OrderShippingType
        const addition = shippingType.isAddition ? '+' : ''
        const disabled = shippingType.count === 0
        const rightAdornment: JSX.Element = (
          <Typography
            variant="bodyBold"
            className={clsx(styles.checkboxWithAmount, disabled && styles.disabled)}
          >{`(${addition}${shippingType.count})`}</Typography>
        )
        return (
          <CheckboxWithAmountItem
            disabled={disabled}
            rightAdornment={rightAdornment}
            onChange={() => !isLoading && initiateRefresh(shippingTypeValue)}
            key={shippingTypeValue}
            id={shippingTypeValue}
            label={t(
              getTranslationString<OrderShippingType>(
                ORDER_SHIPPING_TYPE_TRANSLATIONS,
                shippingTypeValue
              )
            )}
            value={shippingTypeValue}
            checked={getOrderListFilterToggleState(
              history.location.search,
              'shippingType',
              shippingTypeValue
            )}
            datatestid="shippingTypes"
          />
        )
      })}
    </div>
  )
}
