import { gql, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'
import {
  DispatchConstructionDocumentationPdfQuery,
  DispatchConstructionDocumentationPdfQueryVariables,
  DispatchLargeConstructionDocumentationZipQuery,
  DispatchLargeConstructionDocumentationZipQueryVariables,
  OrdersInput,
  LargeConstructionDocumentationInput,
} from '@obeta/schema'

export const dispatchSmallConstructionDocumentationPdfQuery = gql`
  query dispatchConstructionDocumentationPdf($input: OrdersInput!) {
    dispatchConstructionDocumentationPdf(input: $input)
  }
`
export const dispatchLargeConstructionDocumentationZipQuery = gql`
  query dispatchLargeConstructionDocumentationZip($input: LargeConstructionDocumentationInput!) {
    dispatchLargeConstructionDocumentationZip(input: $input)
  }
`

function showPendingMessage(message: string, isSmall: boolean) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: NotificationType.ConstructionDocumentationPending,
    id: isSmall
      ? 'small-construction-documentation-pending'
      : 'large-construction-documentation-pending',
    options: {
      message,
      isSmall,
    },
  })
}

export const useDispatchConstructionDocumentation = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const dispatchSmallConstructionDocumentationPdf = useCallback(
    async (input: OrdersInput) => {
      showPendingMessage(t('MY_ACCOUNTING.PENDING_COPIES'), true)

      await client.query<
        DispatchConstructionDocumentationPdfQuery,
        DispatchConstructionDocumentationPdfQueryVariables
      >({
        query: dispatchSmallConstructionDocumentationPdfQuery,
        variables: { input: input },
      })
    },
    [client, t]
  )

  const dispatchLargeConstructionDocumentationZip = useCallback(
    async (input: LargeConstructionDocumentationInput) => {
      showPendingMessage(t('MY_ACCOUNTING.PENDING_COPIES'), false)

      await client.query<
        DispatchLargeConstructionDocumentationZipQuery,
        DispatchLargeConstructionDocumentationZipQueryVariables
      >({
        query: dispatchLargeConstructionDocumentationZipQuery,
        variables: { input: input },
      })
    },
    [client, t]
  )

  return { dispatchSmallConstructionDocumentationPdf, dispatchLargeConstructionDocumentationZip }
}
