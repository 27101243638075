import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import styles from './offerDetailsPage.module.scss'
import { OfferDetailHeader } from '@obeta/components/lib/offers/OfferDetailHeader'
import { OfferDetailProductList } from '@obeta/components/lib/offers/OfferDetailProductList'
import { OfferDetailsSelectionBar } from '@obeta/components/lib/offers/OfferDetailsSelectionBar'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useGetOfferById } from '@obeta/data/lib/hooks/useGetOfferById'
import { useHistory, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useEntities } from '@obeta/data/lib/hooks'
import { useOfferItemsSearch } from '@obeta/data/lib/hooks/useOfferItemsSearch'
import { MultiSelectionNumber } from '@obeta/models/lib/models/Meta/Meta'
import { OfferItemsInput, OfferOrderDir } from '@obeta/schema'
import {
  INITIAL_OFFER_DETAILS_FILTERS,
  INITIAL_OFFER_DETAILS_QUERY_PARAMS,
  OfferDetailsFilter,
  OfferDetailsURLSearchParams,
  OfferV2,
  ShoppingCartForDropdown,
} from '@obeta/models/lib/models'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'
import { useOfferDetailsContext } from '@obeta/data/lib/stores/useOfferDetailsContext'

// Utils
import { trackClick } from '@obeta/utils/lib/tracking'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { createOfferItemsInput, parseSearchOfferItemsFilters } from '@obeta/utils/lib/offers'
import {
  getURLSearchParamsByLocationSearch,
  getURLSearchParamsValue,
} from '@obeta/utils/lib/virtualized-list'
import {
  AcceptedOfferCancellationReasonStrings,
  ConfirmWithOfferCancellationReasonSelect,
} from '@obeta/components/lib/alert-and-confirm/ConfirmWithOfferCancellationReasonSelect'
import { useRequestOfferCancellation } from '@obeta/data/lib/hooks/useRequestOfferCancellation'
import { useWarehouseContext } from '@obeta/data/lib/stores/useWarehouseContext'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'

const INITIAL_SELECTED_OFFER_ITEMS: MultiSelectionNumber = {
  selectAll: false,
  include: [],
  exclude: [],
  search: {
    searchTerm: '',
    filter: '',
    orderBy: '',
    orderDir: '',
  },
}

const OfferDetailsPage: FC = () => {
  useAuthenticatedRoute()
  const { mobile } = useBreakpoints()
  const cartsV2 = useCartsv2WithPricesAndStock()
  const history = useHistory()
  const { getOfferById, offer } = useGetOfferById()
  const params = useParams()
  const { getFacets, searchOfferItems } = useOfferItemsSearch()
  const { t } = useTranslation()
  const { user } = useUserDataV2()
  const { warehouseId } = useWarehouseContext()

  const { cancelOffer } = useRequestOfferCancellation()

  // Offer details store
  const { offerItems, setQueryParams } = useOfferDetailsContext()

  // Component state
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true)
  const [orderDir, setOrderDir] = useState<OfferOrderDir>('ASC')
  const [selectedOfferItems, setSelectedOfferItems] = useState<MultiSelectionNumber>({
    ...INITIAL_SELECTED_OFFER_ITEMS,
  })
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showOfferCancellationDialog, setShowOfferCancellationDialog] = useState<boolean>(false)

  const offersV2 = useEntities<OfferV2>('offersv2')
  const shoppingCartsToMoveCartItemsTo: ShoppingCartForDropdown[] = []
  cartsV2.forEach((cartV2) => {
    let offerName = ''
    if (cartV2.offerId !== '') {
      offerName = offersV2.find((offer) => offer.id === cartV2.offerId)?.offerName ?? ''
    }
    const shoppingCartForDropdown: ShoppingCartForDropdown = {
      id: cartV2.id,
      name: cartV2.name,
      articleCount: cartV2.items.length,
      offerId: cartV2.offerId,
      offerName: offerName,
      count: cartV2.items.length,
    }
    shoppingCartsToMoveCartItemsTo.push(shoppingCartForDropdown)
  })
  const [modifiedOfferItemAmounts, setModifiedOfferItemAmounts] = useState<
    { offerItemPosition: number; amount: number }[]
  >([])

  const TITLE = t('OFFERS.OFFERS')

  // Get offer and facets on mount by id
  useEffect(() => {
    getOfferById({ offerId: params.id }).then(() => setIsInitialLoading(false))
    if (params.id) {
      getFacets(params.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  // Create search input if history location search changes
  useDebouncedEffect(
    () => {
      // Create input object for graphql endpoint
      const urlSearchParams = getURLSearchParamsByLocationSearch<OfferDetailsURLSearchParams>(
        history.location.search
      )
      const input = createOfferItemsInput(
        params.id,
        INITIAL_OFFER_DETAILS_QUERY_PARAMS,
        urlSearchParams
      )
      // Reset state
      setQueryParams(INITIAL_OFFER_DETAILS_QUERY_PARAMS)
      setSelectedOfferItems({ ...INITIAL_SELECTED_OFFER_ITEMS })
      // Search offer items by input body
      searchOfferItems(input, user?.settings?.defaultStoreId ?? '')
    },
    [history.location.search, user?.settings?.defaultStoreId, warehouseId],
    250
  )

  const handleSortByPositionNumber = useCallback((orderDir: OfferOrderDir) => {
    // Note: This only works in combination with orderBy, which must be 'sortItem' (its initial value)
    setOrderDir(orderDir)
  }, [])

  /**
   * Reset selected offer items.
   * @returns Initial MultiSelection object.
   */
  const resetSelectedOfferItems = (): MultiSelectionNumber => {
    setSelectedOfferItems({ ...INITIAL_SELECTED_OFFER_ITEMS })

    return { ...INITIAL_SELECTED_OFFER_ITEMS }
  }

  /**
   * Select all offer items.
   * @returns MultiSelection object with selectAll set to true.
   */
  const selectAllOfferItems = (): MultiSelectionNumber => {
    trackClick('select-all-offer-items', { offerId: params.id })

    const isActive =
      getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
        'isActive',
        history.location.search,
        INITIAL_OFFER_DETAILS_FILTERS
      ) === 'true'
    const allSelectedOfferItems: MultiSelectionNumber = {
      selectAll: true,
      include: [],
      exclude: [],
      search: {
        searchTerm:
          getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
            'searchTerm',
            history.location.search,
            INITIAL_OFFER_DETAILS_FILTERS
          ) ?? '',
        filter: parseSearchOfferItemsFilters(
          isActive,
          getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
            'supplierId',
            history.location.search,
            INITIAL_OFFER_DETAILS_FILTERS
          ) ?? ''
        ),
        orderBy: 'sortItem',
        orderDir: orderDir ?? 'ASC',
      },
    }

    setSelectedOfferItems(allSelectedOfferItems)

    return allSelectedOfferItems
  }

  /**
   * Handler to load more offer items while endless scrolling.
   * @param queryParams QueryParams
   */
  const onLoadMoreOfferItems = useCallback(
    (queryParams: QueryParams) => {
      const urlSearchParams = getURLSearchParamsByLocationSearch<OfferDetailsURLSearchParams>(
        history.location.search
      )
      const input: OfferItemsInput = createOfferItemsInput(params.id, queryParams, urlSearchParams)
      // Search offer items by input body
      searchOfferItems(input, user?.settings?.defaultStoreId ?? '')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.location.search, offerItems, params.id, user?.settings?.defaultStoreId]
  )

  const onOfferItemAmountChanged = (offerItemPosition: number, amount: number) => {
    trackClick('on-offer-item-amount-changed', {
      offerId: params.id,
      offerItemPosition,
      amount,
    })
    const updatedModifiedOfferItemAmounts = [...modifiedOfferItemAmounts]
    const idx = updatedModifiedOfferItemAmounts.findIndex(
      (el) => el.offerItemPosition === offerItemPosition
    )

    if (idx >= 0) {
      updatedModifiedOfferItemAmounts[idx] = {
        offerItemPosition: offerItemPosition,
        amount: amount,
      }
    } else {
      updatedModifiedOfferItemAmounts.push({
        offerItemPosition: offerItemPosition,
        amount: amount,
      })
    }
    setModifiedOfferItemAmounts(updatedModifiedOfferItemAmounts)
  }

  const onSelectOfferItem = (offerItemPosition: number) => {
    selectOfferItem(
      offer?.offerId,
      offerItemPosition,
      offerItems.length,
      selectedOfferItems,
      setSelectedOfferItems,
      selectAllOfferItems,
      resetSelectedOfferItems
    )
  }

  if (!isInitialLoading && (offer === null || offer?.isActive === false)) {
    history.push(ShopRoutes.OfferList)
    return null
  }

  const onCancelOffer = (reason: AcceptedOfferCancellationReasonStrings) => {
    cancelOffer({ offerId: offer?.offerId ?? '', reason: reason })
  }

  const onHandleClose = () => {
    setShowOfferCancellationDialog(false)
    history.push(isUiTarget('app') ? '/offers' : '/offer-list')
  }

  return (
    <>
      <div className={styles.offerDetails}>
        <Grid container direction={'column'}>
          {isInitialLoading || !offer ? (
            <Typography className={styles.title} variant={'h3'} color={'text.primary'}>
              {TITLE}
            </Typography>
          ) : (
            <Grid container direction="column" className={styles.appBarWrapper}>
              <OfferDetailHeader
                offer={offer}
                showDetails={showDetails}
                onSortByPositionNumber={handleSortByPositionNumber}
                onSelectAll={() => {
                  selectedOfferItems.selectAll ? resetSelectedOfferItems() : selectAllOfferItems()
                }}
                onSetShowDetails={setShowDetails}
                selectedOfferItems={selectedOfferItems}
                setShowOfferCancellationDialog={setShowOfferCancellationDialog}
              />
              {((mobile && !showDetails) || !mobile) && (
                <OfferDetailProductList
                  id={params.id}
                  dropDownCarts={shoppingCartsToMoveCartItemsTo}
                  modifiedOfferItemAmounts={modifiedOfferItemAmounts}
                  offer={offer}
                  selectedOfferItems={selectedOfferItems}
                  totalOfferItemsCount={offer.itemCount}
                  onLoadMoreOfferItems={onLoadMoreOfferItems}
                  onOfferItemAmountChanged={onOfferItemAmountChanged}
                  onSelectOfferItem={onSelectOfferItem}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
      <OfferDetailsSelectionBar
        dropDownCarts={shoppingCartsToMoveCartItemsTo}
        modifiedOfferItemAmounts={modifiedOfferItemAmounts}
        offer={offer}
        resetSelectedOffers={resetSelectedOfferItems}
        selectedOfferItems={selectedOfferItems}
      />
      <ConfirmWithOfferCancellationReasonSelect
        handleConfirm={onCancelOffer}
        handleClose={onHandleClose}
        handleAbort={() => setShowOfferCancellationDialog(false)}
        openDialog={showOfferCancellationDialog}
      ></ConfirmWithOfferCancellationReasonSelect>
    </>
  )
}

/**
 * Handler to select or deselect an offer item.
 * @param offerId Offer id
 * @param offerItemPosition Offer item position
 * @param offerItemsLength Offer items length
 * @param selectedOfferItems Selected offer items
 * @param setSelectedOfferItems
 * @param selectAllOfferItems
 * @param resetSelectedOfferItems
 */
export const selectOfferItem = (
  offerId: string | undefined,
  offerItemPosition: number,
  offerItemsLength: number,
  selectedOfferItems: MultiSelectionNumber,
  setSelectedOfferItems: React.Dispatch<React.SetStateAction<MultiSelectionNumber>>,
  selectAllOfferItems: () => MultiSelectionNumber,
  resetSelectedOfferItems: () => MultiSelectionNumber
) => {
  // Create updated selected offer items MultiSelection object for DataDog tracking
  let updatedSelectedOfferItems: MultiSelectionNumber | undefined = undefined

  // Note: We are ignoring the selectedOfferItems.search object since we are basing the logic on the offerItemsLength

  // 1) Nur einzelne oder keine Artikel ausgewählt
  if (!selectedOfferItems.selectAll) {
    if (selectedOfferItems.include.includes(offerItemPosition)) {
      // 1a auf INCLUDE list --> entfernen
      updatedSelectedOfferItems = {
        ...selectedOfferItems,
        include: [...selectedOfferItems.include.filter((el) => el !== offerItemPosition)],
      }
    } else {
      // 1b einziges element nicht auf include list --> toggle und ALLE auswählen
      if ([...selectedOfferItems.include, offerItemPosition].length === offerItemsLength) {
        updatedSelectedOfferItems = selectAllOfferItems()
      } else {
        // 1c nicht auf INCLUDE list --> hinzufügen
        updatedSelectedOfferItems = {
          ...selectedOfferItems,
          include: [...selectedOfferItems.include, offerItemPosition],
        }
      }
    }
  }
  // 2) Alle ausgewählt --> Add to EXCLUDE list
  else if (selectedOfferItems.selectAll && selectedOfferItems.exclude.length === 0) {
    if (offerItemsLength === 1) {
      updatedSelectedOfferItems = resetSelectedOfferItems()
    } else {
      updatedSelectedOfferItems = {
        ...selectedOfferItems,
        exclude: [...selectedOfferItems.exclude, offerItemPosition],
      }
    }
  }
  // 3) Alle ausgewählt und exclude Liste existiert
  // 3a) auf exclude list --> entnehmen
  else if (selectedOfferItems.selectAll && selectedOfferItems.exclude.includes(offerItemPosition)) {
    updatedSelectedOfferItems = {
      ...selectedOfferItems,
      exclude: [...selectedOfferItems.exclude.filter((el) => el !== offerItemPosition)],
    }
  }
  // 3b) nicht in exclude liste --> in exclude liste übernehmen
  else if (
    selectedOfferItems.selectAll &&
    !selectedOfferItems.exclude.includes(offerItemPosition)
  ) {
    if (
      selectedOfferItems.selectAll &&
      [...selectedOfferItems.exclude, offerItemPosition].length === offerItemsLength
    ) {
      updatedSelectedOfferItems = resetSelectedOfferItems()
    } else {
      updatedSelectedOfferItems = {
        ...selectedOfferItems,
        exclude: [...selectedOfferItems.exclude, offerItemPosition],
      }
    }
  }

  if (updatedSelectedOfferItems) {
    setSelectedOfferItems(updatedSelectedOfferItems)
  }

  trackClick('select-offer-item', {
    request: {
      offerId,
      offerItemPosition,
    },
    response: updatedSelectedOfferItems,
  })
}

export default OfferDetailsPage
