import React from 'react'
import { SearchResultItemBase } from '@obeta/components/lib/product-card'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import styles from './promotionContent.module.scss'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useSelectedCart } from '@obeta/data/lib/hooks/useSelectedCart'
import { MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models'
import { useProductImages } from '@obeta/data/lib/hooks/useProductImages'

interface IShortListProps {
  articleData: MaybeCompleteSearchProduct[]
  onAddToCart: (cart: ShoppingCartV2) => void
}

export const PromotionContentShortList: React.FC<IShortListProps> = (props) => {
  const { articleData, onAddToCart } = props
  const { mobile, tablet } = useBreakpoints()
  const { selectedStore, user } = useUserSelectedStore()
  const { isLoggedIn } = useUserDataV2()

  const history = useHistory()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const selectedCart = useSelectedCart()
  const { images: productImages } = useProductImages(
    articleData.filter(Boolean).map((product) => product.sapId)
  )

  return (
    <>
      {articleData.map((article) => {
        const goToDetailsPage = () =>
          history.push(`/article-detail/${article.sapId}?amount=${article.minimumAmount}`)

        const productImageArray = productImages?.find((pi) => article.sapId === pi.sapId)?.images

        return (
          <div className={styles['promotion-page-product']} key={`${article.sapId}`}>
            <SearchResultItemBase
              user={user}
              carts={carts}
              selectedCart={selectedCart}
              mobile={mobile}
              tablet={tablet}
              key={`${article.sapId}`}
              selected={false}
              onChange={() => {
                //
              }}
              selectedStore={selectedStore}
              product={article}
              onProductImageClicked={goToDetailsPage}
              onTitleClick={goToDetailsPage}
              isLoggedIn={isLoggedIn}
              onAddToCart={onAddToCart}
              productId={article.sapId}
              url={
                productImageArray && productImageArray.length > 0 ? productImageArray[0].url : ''
              }
            />
          </div>
        )
      })}
    </>
  )
}
