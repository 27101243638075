import { Box } from '@mui/material'
import { useEffect } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InfoIcon } from '@obeta/assets/icon/designsystem/info.svg'
import { Tooltip } from '@obeta/components/lib/tooltip/Tooltip'
import {
  useBreakpoints,
  useGetDataSheetCustomerCompanyData,
  useSaveDataSheetCustomerCompanyData,
} from '@obeta/data/lib/hooks'
import { CompanySection } from './CompanySection'
import { ContactSection } from './ContactSection'
import { FormActionFooter } from './FormActionFooter'
import { OpeningHoursSection } from './OpeningHoursSection'
import { SettingsFormWrapper } from './SettingsFormWrapper'
import { UploadLogoSection } from './UploadLogoSection'
import { useLogoUpload } from './useLogoUpload'
import {
  convertInitialFirmDataSettings,
  convertFirmDataSettingsToSubmit,
} from '@obeta/utils/lib/settingsConverters'
import { IFirmData } from '@obeta/models/lib/models/Settings/Settings'
import styles from './SettingsPage.module.scss'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'

const FirmDataSettings = () => {
  const { t } = useTranslation()
  const { data: dataSheetCustomerCompanyData } = useGetDataSheetCustomerCompanyData()
  const updateDataSheetCustomerCompanyData = useSaveDataSheetCustomerCompanyData()
  const { onLogoSubmit, onLogoDelete } = useLogoUpload()
  const { desktop } = useBreakpoints()

  const { reset, handleSubmit, setValue, clearErrors } = useFormContext<IFirmData>()

  useEffect(() => {
    if (!dataSheetCustomerCompanyData) return

    const firmDataValues = convertInitialFirmDataSettings(dataSheetCustomerCompanyData)
    reset(firmDataValues)
  }, [reset, dataSheetCustomerCompanyData])

  const onFirmDataSettingsSubmit = async (data: IFirmData) => {
    try {
      const firmDataSettingsToSubmit = convertFirmDataSettingsToSubmit(data)
      const response = await updateDataSheetCustomerCompanyData(firmDataSettingsToSubmit)

      getEventSubscription().next({
        type: EventType.Toast,
        notificationType: NotificationType.Success,
        id: `onFirmDataSettingsSubmitSuccess--${new Date().getTime()}`,
        options: { heading: t('SETTINGS.COMPANY_DATA_SAVE_SUCCESS'), body: '' },
      })

      if (!data.companyLogo && dataSheetCustomerCompanyData?.companyLogo) {
        const res = await onLogoDelete(dataSheetCustomerCompanyData.companyLogo)

        if (res.errors) {
          getEventSubscription().next({
            type: EventType.Toast,
            notificationType: NotificationType.Error,
            id: `onFirmImageRemovalError--${new Date().getTime()}`,
            options: { message: t('SETTINGS.COMPANY_DATA_SAVE_ERROR') },
          })
        }
      } else {
        await onLogoSubmit()
      }

      if (response) reset(data)
    } catch (_err) {
      getEventSubscription().next({
        type: EventType.Toast,
        notificationType: NotificationType.Error,
        id: `onFirmDataSettingsSubmitError--${new Date().getTime()}`,
        options: { message: t('SETTINGS.COMPANY_DATA_SAVE_ERROR') },
      })
    }
  }

  const onReset = () => {
    setValue('companyLogo', null)
    clearErrors('companyLogo')
    if (dataSheetCustomerCompanyData) {
      reset(convertInitialFirmDataSettings(dataSheetCustomerCompanyData))
    } else {
      reset()
    }
  }

  const uploadLogoSectionTitle = (
    <Box className={styles.uploadLogoSectionTitle}>
      {t('SETTINGS.FIRM_DATA_SECTION.UPLOAD_LOGO')}
      {desktop && (
        <Tooltip
          arrow
          placement="right"
          title={t('SETTINGS.FIRM_DATA_SECTION.UPLOAD_LOGO_MAX_SIZE')}
        >
          <InfoIcon className={styles.uploadLogoSectionIcon} />
        </Tooltip>
      )}
    </Box>
  )

  return (
    <div className={styles.content}>
      <SettingsFormWrapper
        title={t('SETTINGS.FIRM_DATA_SECTION.COMPANY')}
        wrapperStyles={styles.sectionFormWrapperStyles}
      >
        <CompanySection />
      </SettingsFormWrapper>
      <SettingsFormWrapper
        title={t('SETTINGS.FIRM_DATA_SECTION.CONTACT')}
        wrapperStyles={styles.sectionFormWrapperStyles}
      >
        <ContactSection />
      </SettingsFormWrapper>
      <SettingsFormWrapper
        title={t('SETTINGS.FIRM_DATA_SECTION.OPENING_HOURS')}
        wrapperStyles={styles.sectionFormWrapperStyles}
      >
        <OpeningHoursSection />
      </SettingsFormWrapper>
      <SettingsFormWrapper
        title={uploadLogoSectionTitle}
        wrapperStyles={styles.sectionFormWrapperStyles}
      >
        <UploadLogoSection />
      </SettingsFormWrapper>
      <FormActionFooter onSubmit={handleSubmit(onFirmDataSettingsSubmit)} onReset={onReset} />
    </div>
  )
}

export const FirmDataSettingsForm = () => {
  const methods = useForm<IFirmData>({ mode: 'onChange' })

  return (
    <FormProvider {...methods}>
      <FirmDataSettings />
    </FormProvider>
  )
}
