import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, Typography } from '@mui/material'
import { useBreakpoints } from '@obeta/data/lib/hooks'
import clsx from 'clsx'
import { ReactComponent as MailIcon } from 'assets/icon/designsystem/mail_fixed_color.svg'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import formStyles from './form.module.scss'
import styles from './NotifySubForm.module.scss'
import { NotifyAutoComplete } from './NotifyAutoComplete'

type Props = {
  usersToNotify: string[]
  setUsersToNotify: (value: string[]) => void
}

export const NotifySubForm: FC<Props> = ({ usersToNotify, setUsersToNotify }) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()

  return (
    <div className={clsx(styles.notifySubFormContainer, !desktop && styles.touchContainer)}>
      <div className={styles.notifySubFormTitle}>
        <MailIcon />
        <Typography variant="bodyBold">
          {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.CART_NOTIFY')}
        </Typography>
        {desktop && (
          <Tooltip
            title={t(
              'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.CART_NOTIFICATION'
            )}
          >
            <InfoIcon className={formStyles.tooltipIcon} color={scssVariables.$darkGray} />
          </Tooltip>
        )}
      </div>
      <NotifyAutoComplete usersToNotify={usersToNotify} setUsersToNotify={setUsersToNotify} />
      <Typography variant="body" className={styles.notifySubFormInfo}>
        {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.NESTED_NOTIFICATION_MAIL.INFO')}
      </Typography>
    </div>
  )
}
