import { gql } from '@apollo/client'

export const gqlUserSubusers = gql`
  query getUserSubusers {
    getUserSubusers {
      customerNumber
      companyId
      type
      isTecselectUser
      tecselectLoginUrl
      algoliaUserToken
      hasFreeShipping
      isActive
      email
      emailData {
        email
        isConfirmed
      }
      notificationEmail
      orderConfirmationEmail
      permissions {
        Global_canReadPrices
        Address_canWrite
        Cart_canWriteAddress
        Cart_canWriteCommission
        Cart_canWriteAdditionalText
        Cart_requiredCommission
        Cart_allowedSubmitAction
        Cart_canChangePayment
        Offers_canRead
        Orders_canRead
        Global_canReadAccountInfo
        Global_canUseInterfaces
        Global_canSeeUserManagement
        Global_canUseCustomDataSheet
        Global_canChangeDataSheetPrice
        Global_showCreditWarning
        Projects_canManage
        Cart_maxCartValue
        Cart_canSee
        Cart_canSeeProjectIds
      }
      settings {
        name
      }
    }
  }
`

export const gqlUserToUpdate = gql`
  query getUserToUpdate {
    getUser {
      email
      customerNumber
      companyId
      isActive
      notificationEmail
      orderConfirmationEmail
      permissions {
        Global_canReadPrices
        Address_canWrite
        Cart_canWriteAddress
        Cart_canWriteCommission
        Cart_canWriteAdditionalText
        Cart_requiredCommission
        Cart_allowedSubmitAction
        Cart_canChangePayment
        Offers_canRead
        Orders_canRead
        Global_canReadAccountInfo
        Global_canUseInterfaces
        Global_canSeeUserManagement
        Global_canUseCustomDataSheet
        Global_canChangeDataSheetPrice
        Global_showCreditWarning
        Projects_canManage
        Cart_maxCartValue
        Cart_canSee
        Cart_canSeeProjectIds
      }
      settings {
        name
      }
    }
  }
`
