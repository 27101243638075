import { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { getCompanyFilters } from '../../startPage/hooks/useNews'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'

// This is our custom resolver, so if the filter logic needs to be extended, it needs to be done on the Strapi side
const metaNewsQuery = gql`
  query obetaNewsTotal($filters: FiltersInput) {
    obetaNewsTotals(filters: $filters) {
      Produktnews
      All
      Branchennews
      Onlinewelt
      Extras
    }
  }
`

export type NewsMeta = {
  All: number
  Produktnews: number
  Branchennews: number
  Onlinewelt: number
  Extras: number
}

export const useNewsTotalsMeta = (searchFilter: string, shouldRefetch: number) => {
  const apolloClient = useStrapiGql()
  const currentDate = new Date()
  const [meta, setMeta] = useState<NewsMeta>({
    All: 0,
    Produktnews: 0,
    Branchennews: 0,
    Onlinewelt: 0,
    Extras: 0,
  })

  const domain = useAppDomain()

  const client = useApolloClient(apolloClient)

  useEffect(() => {
    const getNews = async () => {
      const response = await client.query({
        query: metaNewsQuery,
        variables: {
          filters: {
            endDate: { gte: currentDate },
            startDate: { lte: currentDate },
            and: [
              { or: getCompanyFilters({ domain }) },
              {
                or: [
                  { title: { contains: searchFilter } },
                  { teaserText: { contains: searchFilter } },
                ],
              },
            ],
          },
        },
      })

      setMeta(response.data.obetaNewsTotals)
    }

    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch, searchFilter, domain])

  return meta
}
