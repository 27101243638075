import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import {
  CartsActionTypes,
  emptyShoppingCartGraphQL,
  getIdsFormFields,
  GetIdsFormFieldsGraphQLResultAction,
  resetShoppingCartGraphQL,
  updateCartGraphQL,
  UpdateCartGraphQLResultAction,
} from '../../actions'
import { useActionNotification } from '../useActionNotification'
import { useUserDataV2 } from '../useUserDataV2'
import { useSubmitIdsForm } from './useSubmitIdsForm'

type TransferIdsCartAndSubmit = (cart?: ShoppingCartV2) => void
type UseIdsCartTransfer = (options?: {
  withRedirect?: boolean
  cleanupCart?: boolean
  userCanSubmitCart?: boolean
  updateCartBefore?: boolean
  commission?: string
  phone?: string
  remark?: string
}) => TransferIdsCartAndSubmit

export const useIdsCartTransfer: UseIdsCartTransfer = (options) => {
  const dispatch = useDispatch()
  const submitIdsForm = useSubmitIdsForm()
  const waitForIdsFormFieldsResultAction = useActionNotification(
    CartsActionTypes.GetIdsFormFieldsGraphQLResult
  )
  const waitForUpdateCartResultAction = useActionNotification(
    CartsActionTypes.UpdateCartGraphQLResult
  )
  const waitForEmptyShoppingCartAction = useActionNotification(
    CartsActionTypes.EmptyShoppingCartGraphQLResult
  )
  const waitForResetShoppingCartAction = useActionNotification(
    CartsActionTypes.ResetShoppingCartGraphQLResult
  )
  const { userId, companyId } = useUserDataV2()

  const validUserConditions = userId && companyId && userId?.length > 0 && companyId?.length > 0

  return useCallback(
    async (cart?: ShoppingCartV2) => {
      const sessionContext = getSessionContext()
      if (cart && sessionContext?.hookUrl && validUserConditions) {
        if (options?.updateCartBefore) {
          cart.commission = options?.commission ?? ''
          cart.phone = options?.phone ?? ''
          cart.remark = options?.remark ?? ''
          dispatch(updateCartGraphQL(cart))
          waitForUpdateCartResultAction((action: UpdateCartGraphQLResultAction) => {
            dispatch(getIdsFormFields(cart.id))
          })
        } else {
          dispatch(getIdsFormFields(cart.id))
        }
        waitForIdsFormFieldsResultAction((action: GetIdsFormFieldsGraphQLResultAction) => {
          if (action.idsFormFieldsResult?.success && action.idsFormFieldsResult?.idsFormFields) {
            if (options?.cleanupCart) {
              dispatch(emptyShoppingCartGraphQL(cart))
              waitForEmptyShoppingCartAction(() => {
                dispatch(resetShoppingCartGraphQL(cart))
                waitForResetShoppingCartAction(() => {
                  submitIdsForm({
                    cart,
                    userCanSubmitCart: options?.userCanSubmitCart,
                    idsFormFields: action.idsFormFieldsResult?.idsFormFields,
                    withRedirect: options?.withRedirect,
                  })
                })
              })
            } else {
              submitIdsForm({
                cart,
                userCanSubmitCart: options?.userCanSubmitCart,
                idsFormFields: action.idsFormFieldsResult?.idsFormFields,
                withRedirect: options?.withRedirect,
              })
            }
          }
        })
      }
    },
    [
      validUserConditions,
      options?.updateCartBefore,
      options?.commission,
      options?.phone,
      options?.remark,
      options?.cleanupCart,
      options?.userCanSubmitCart,
      options?.withRedirect,
      waitForIdsFormFieldsResultAction,
      dispatch,
      waitForUpdateCartResultAction,
      waitForEmptyShoppingCartAction,
      waitForResetShoppingCartAction,
      submitIdsForm,
    ]
  )
}
