import React, { FC } from 'react'
import styles from './ProgressBar.module.scss'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface ProgressBarProps {
  title: string
  onChange: (value: string) => void
  className: string
  active: string
  isActive: boolean
}

export const ProgressBar: FC<ProgressBarProps> = ({
  title,
  className,
  active,
  isActive: isAktive,
}) => {
  const { mobile } = useBreakpoints()

  const header = (title: string) => {
    if (!mobile) {
      return title
    }
    if (mobile && isAktive) {
      return title
    }
    return null
  }

  return (
    <div className={`${className}`}>
      <div className={!mobile ? clsx(styles.Line) : clsx(styles.Line, active)}></div>
      <div className={!mobile ? clsx(styles.BarState) : clsx(active)}>
        <div className={`${className}`}>
          <div className={styles.title}>{header(title)}</div>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
