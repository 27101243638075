import {
  ShippingOptions,
  ShippingType,
} from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'

export const defaultShippingOptionsDelivery = [
  {
    paymentMethods: [],
    isEnabled: true,
    isDefault: true,
    storeId: '',
    shippingType: ShippingType.Cargo,
    deliveryAddress: {
      name1: 'Testkonto Codi',
      name2: 'NICHT ÄNDERN',
      name3: '',
      name4: '',
      city: 'Berlin',
      zipCode: '12681',
      street: 'Zur Alten Börse',
      houseNumber: '79',
      country: undefined,
    },
    shippingCosts: {
      freeDeliveryThreshold: 0,
      total: 0,
      currency: '€',
    },
    shippingDates: [],
  },
  {
    paymentMethods: [],
    isEnabled: true,
    isDefault: false,
    storeId: '',
    shippingType: ShippingType.DefaultParcel,
    deliveryAddress: undefined,
    shippingCosts: {
      freeDeliveryThreshold: 59,
      total: 0,
      currency: '€',
    },
    shippingDates: [],
  },
  {
    paymentMethods: [],
    isEnabled: true,
    isDefault: false,
    storeId: '',
    shippingType: ShippingType.FastParcel,
    deliveryAddress: {
      name1: 'Testkonto Codi',
      name2: 'NICHT ÄNDERN',
      name3: '',
      name4: '',
      city: 'Berlin',
      zipCode: '12681',
      street: 'Zur Alten Börse',
      houseNumber: '79',
      country: undefined,
    },
    shippingCosts: {
      freeDeliveryThreshold: 59,
      total: 0,
      currency: '€',
    },
    shippingDates: [],
  },
] as ShippingOptions[]

export const defaultShippingOptionsPickup = [
  {
    paymentMethods: ['CreditCard', 'PayPal', 'Cash'],
    isEnabled: true,
    isDefault: true,
    storeId: '22',
    shippingType: ShippingType.DefaultPickup,
    deliveryAddress: {
      name1: '',
      name2: '',
      name3: '',
      name4: '',
      city: '',
      zipCode: '',
      street: '',
      houseNumber: '',
      country: '',
    },
    shippingCosts: {
      total: 0,
      currency: '€',
    },
    shippingDates: [],
  },
  {
    paymentMethods: ['Cash'],
    isEnabled: true,
    isDefault: false,
    storeId: '22',
    shippingType: ShippingType.ExpressPickup,
    deliveryAddress: {
      name1: '',
      name2: '',
      name3: '',
      name4: '',
      city: '',
      zipCode: '',
      street: '',
      houseNumber: '',
      country: '',
    },
    shippingCosts: {
      total: 0,
      currency: '€',
    },
    shippingDates: [],
  },
] as ShippingOptions[]
