import { gql, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'
import { GetOfferExportQuery, GetOfferExportQueryVariables, OfferItemsInput } from '@obeta/schema'

export const getOfferExportQuery = gql`
  query getOfferExport($input: OfferItemsInput!) {
    getOfferItemsExport(input: $input) {
      success
      errorCode
      errorMessage
    }
  }
`

function showPendingMessage(message: string) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: NotificationType.OfferExportPending,
    id: 'offer-export-pending',
    options: {
      message,
    },
  })
}

export const useGetOfferExport = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const getOfferExport = useCallback(
    async (input: OfferItemsInput) => {
      showPendingMessage(t('OFFERS.NOTIFICATIONS.PENDING_OFFER_EXPORT'))

      await client.query<GetOfferExportQuery, GetOfferExportQueryVariables>({
        query: getOfferExportQuery,
        variables: {
          input,
        },
      })
    },
    [client, t]
  )

  return { getOfferExport }
}
