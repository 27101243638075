import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionHeader } from '@obeta/components/index'
import { NavMenu } from './NavMenu'
import { TSettingsView } from './types'

export interface IHeader {
  activeView: TSettingsView
  setActiveView: Dispatch<SetStateAction<TSettingsView>>
}

export const Header: FC<IHeader> = ({ activeView, setActiveView }) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionHeader marginBottom="md">{t('SETTINGS.TITLE')}</SectionHeader>
      <NavMenu setActiveView={setActiveView} activeView={activeView} />
    </>
  )
}
