import { FC, useRef, useState } from 'react'
import { ReactComponent as EditIcon } from '@obeta/assets/icon/designsystem/edit.svg'
import { ClickAwayListener, TextField } from '@mui/material'
import { Controller, FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import clsx from 'clsx'
import { FormHelperText } from '../form-helper-text/FormHelperText'
import { useIconSize } from '@obeta/data/lib/hooks/useIconSize'
import { TypographyEllipsis } from '../typography'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import styles from './RenameField.module.scss'

type Props = {
  errorText?: string
  name: string
  onClose: () => void
  onSubmit: (fieldValues: FieldValues) => Promise<void>
  placeholder?: string
  previewLabel: string
  showEditIcon: boolean
}

export const RenameField = (props) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: props.name,
    },
  })

  return <ControlledRenameField form={form} {...props} />
}

export const ControlledRenameField: FC<Props & { form: UseFormReturn }> = ({
  errorText,
  form,
  name,
  onClose,
  onSubmit,
  placeholder,
  previewLabel,
  showEditIcon,
}) => {
  const [isRenaming, setIsRenaming] = useState<boolean>(false)
  const iconSize = useIconSize()
  const inputRef = useRef<HTMLInputElement>(null)
  const textFieldRef = useRef<HTMLInputElement>(null)

  return (
    <div style={{ display: 'flex' }}>
      {!isRenaming && (
        <div className={styles.templateName}>
          <TypographyEllipsis variant="h3">{previewLabel}</TypographyEllipsis>
          <div className={styles.editButton}>
            {showEditIcon && (
              <TertiaryIconButton
                size={iconSize}
                onClick={() => setIsRenaming(true)}
                icon={<EditIcon />}
              />
            )}
          </div>
        </div>
      )}
      {isRenaming && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => {
            setIsRenaming(false)
            onClose()
          }}
        >
          <form
            aria-label="form"
            className="w-96"
            onSubmit={form.handleSubmit(async (fieldValues) => {
              await onSubmit(fieldValues)
              setIsRenaming(false)
            })}
          >
            <Controller
              control={form.control}
              defaultValue={name}
              rules={{
                required: true,
                maxLength: 20,
              }}
              name="name"
              render={({ field: { onChange, onBlur } }) => (
                <>
                  <TextField
                    id="name"
                    autoFocus={true}
                    className={clsx(
                      styles.textFieldInput,
                      form.formState.errors.name && styles.textFieldInputError
                    )}
                    defaultValue={name}
                    inputProps={{
                      ref: textFieldRef,
                      id: 'name-input',
                    }}
                    inputRef={inputRef}
                    placeholder={placeholder ?? ''}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                  {form.formState.errors.name && <FormHelperText errorText={errorText ?? ''} />}
                </>
              )}
            />
          </form>
        </ClickAwayListener>
      )}
    </div>
  )
}
