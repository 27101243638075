export interface ICalcTotalParams {
  netPrice: number
  bulkPrice: number
  bulkPriceAmount: number
  priceDimension: number
  metalNeAddition: number
  amount: number
  strikeThroughPrice?: number
}

export interface ITotal {
  totalStrikeThrough?: number
  total: number
  totalMetalAdition: number
  subTotal: number
}

/**
 *
 * @param {number} params.netPrice - price for 1 item
 * @param {number} params.bulkPrice - price for "bulk"
 * @param {number} params.bulkPriceAmount - how many items "bulk" consists of
 * @param {number} params.priceDimension
 * @param {number} params.metalNeAddition. - float
 * @param {number} params.amount - integer. number of items * multipleOf
 * @returns {number}
 */
export const calcProductTotalPrice = (params: ICalcTotalParams): ITotal => {
  const {
    bulkPrice,
    bulkPriceAmount,
    amount,
    priceDimension,
    netPrice,
    metalNeAddition,
    strikeThroughPrice,
  } = params

  let base = netPrice

  if (bulkPriceAmount > 0 && bulkPrice > 0 && amount >= bulkPriceAmount) {
    base = bulkPrice
  }

  const totalPrice = (base / priceDimension) * amount
  const totalMetalAdition = (metalNeAddition / priceDimension) * amount

  const totalStrikeThrough = strikeThroughPrice
    ? (strikeThroughPrice / priceDimension) * amount + totalMetalAdition
    : undefined

  return {
    subTotal: totalPrice,
    totalMetalAdition,
    total: totalPrice + totalMetalAdition,
    totalStrikeThrough,
  }
}
