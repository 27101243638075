import { Reducer, useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'
import {
  ProjectDetailsProject,
  ProjectDetailsShippingData,
} from '@obeta/models/lib/schema-models/project'
import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { DeliveryAddressV2 } from '@obeta/models/lib/models/ShoppingCart/DeliveryAddressV2'
import { ShippingGroup, ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { useProjectFormContext } from '../../stores/useProjectFormContext'
import { useDefaultStoreId } from '../useDefaultStoreId'
import { useDefaultUserAddress } from '../useDefaultUserAddress'
import { useEntities } from '../useEntities'
import { useMutateProject } from './useMutateProject'
import { useCreateProject } from './useCreateProject'

export type ProjectDetailsShippingDataPickup = Omit<
  ProjectDetailsShippingData,
  'addressId' | 'isCompleteDelivery' | 'isCompleteDeliveryPossible'
>

type ProjectDefaults = {
  defaultStoreId: string | undefined
  defaultAddress: UserAddressV2 | undefined
}

type ReducerState = ProjectDetailsProject & {
  defaults: ProjectDefaults
  shippingGroup: ShippingGroup | null
}

type FormState = Omit<ReducerState, 'defaults'>

export type ProjectDetailsPage = {
  project: ProjectDetailsProject
  shippingGroup: ShippingGroup | null
  updateOfferId: (value: string) => void
  updateShippingGroup: (value: ShippingGroup | null) => void
  updateShippingDataPickup: (value: ProjectDetailsShippingDataPickup) => void
  updateShippingType: (value: ShippingType) => void
  updateStoreId: (value: string) => void
  updateDeliveryAddress: (deliveryAddress: DeliveryAddressV2, addressId?: string) => void
  updateAddressId: (addressId: string) => void
  restoreOriginalSettings: () => void
  mutateProject: () => Promise<boolean>
  createProject: () => Promise<boolean>
  mutateProjectName: (name: string) => Promise<boolean>
  hasChanges: boolean
  updateLocalProjectName: (name: string) => void
  pendingCreate: boolean
  pendingMutate: boolean
}

type ReducerAction =
  | { type: 'INIT_DEFAULT_ADDRESS'; payload: UserAddressV2 }
  | { type: 'INIT_DEFAULT_STORE_ID'; payload: string }
  | { type: 'UPDATE_NAME'; payload: string }
  | { type: 'UPDATE_OFFER_ID'; payload: string }
  | { type: 'UPDATE_SHIPPING_GROUP'; payload: ShippingGroup | null }
  | { type: 'UPDATE_SHIPPING_DATA_PICKUP'; payload: ProjectDetailsShippingDataPickup }
  | { type: 'UPDATE_SHIPPING_TYPE'; payload: ShippingType }
  | { type: 'UPDATE_STORE_ID'; payload: string }
  | {
      type: 'UPDATE_DELIVERY_ADDRESS'
      payload: { deliveryAddress: DeliveryAddressV2; addressId?: string }
    }
  | {
      type: 'UPDATE_ADDRESS_ID'
      payload: { addressId?: string }
    }
  | {
      type: 'COMPLETE_UPDATE'
      payload: FormState
    }

const comparableProject = ({ defaults, ...rest }: { defaults?: unknown }) => rest

const emptyShippingData = {
  shippingType: '',
  storeId: '',
  addressId: '',
  shippingDate: '',
  deliveryAddress: {
    name1: '',
    name2: '',
    zipCode: '',
    street: '',
    houseNumber: '',
    city: '',
  },
}

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case 'INIT_DEFAULT_ADDRESS':
      return { ...state, defaults: { ...state.defaults, defaultAddress: action.payload } }
    case 'INIT_DEFAULT_STORE_ID':
      return { ...state, defaults: { ...state.defaults, defaultStoreId: action.payload } }
    case 'UPDATE_NAME':
      return { ...state, name: action.payload }
    case 'UPDATE_OFFER_ID':
      return { ...state, offerId: action.payload }
    case 'UPDATE_STORE_ID':
      return {
        ...state,
        shippingData: { ...state.shippingData, storeId: action.payload },
      }
    case 'UPDATE_SHIPPING_GROUP': {
      const shippingGroup = action.payload
      let shippingData
      if (shippingGroup === ShippingGroup.Pickup) {
        shippingData = {
          ...emptyShippingData,
          shippingType: ShippingType.DefaultPickup,
          storeId: state.defaults.defaultStoreId,
          addressId: state.defaults.defaultAddress?.addressId ?? '',
        }
      } else if (shippingGroup === ShippingGroup.Delivery) {
        shippingData = {
          ...emptyShippingData,
          shippingType: ShippingType.DefaultParcel,
          deliveryAddress: state.defaults.defaultAddress?.address ?? {},
        }
      } else {
        const { shippingData, ...stateWithoutShippingData } = state
        return { ...stateWithoutShippingData, shippingGroup }
      }

      return {
        ...state,
        shippingGroup,
        shippingData,
      }
    }
    case 'UPDATE_SHIPPING_DATA_PICKUP':
      return { ...state, shippingData: action.payload }
    case 'UPDATE_SHIPPING_TYPE':
      return { ...state, shippingData: { ...state.shippingData, shippingType: action.payload } }
    case 'UPDATE_DELIVERY_ADDRESS':
      return {
        ...state,
        shippingData: {
          ...state.shippingData,
          deliveryAddress: action.payload.deliveryAddress,
          addressId: action.payload.addressId,
        },
      }
    case 'UPDATE_ADDRESS_ID':
      return {
        ...state,
        shippingData: {
          ...state.shippingData,
          addressId: action.payload.addressId,
        },
      }
    case 'COMPLETE_UPDATE': {
      return { ...state, ...action.payload, defaults: state.defaults }
    }
    default:
      return state
  }
}

const getShippingGroupFromProject = (project: ProjectDetailsProject): ShippingGroup | null => {
  const shippingType = project.shippingData?.shippingType
  if (!shippingType) return null

  const isDelivery = ['DefaultParcel', 'FastParcel', 'Cargo'].includes(shippingType)
  const isPickup = ['DefaultPickup', 'ExpressPickup', 'PickupRenzbox'].includes(shippingType)

  if (isDelivery) return ShippingGroup.Delivery
  if (isPickup) return ShippingGroup.Pickup
  return null
}

export const useProjectDetailsPageState = (initialProject: ProjectDetailsProject) => {
  const defaultStoreId = useDefaultStoreId()
  const defaultAddress = useDefaultUserAddress()
  const userAddresses = useEntities<UserAddressV2>('useraddressesv2')
  const mutate = useMutateProject()
  const create = useCreateProject()
  const { form } = useProjectFormContext()
  const initialShippingGroup = getShippingGroupFromProject(initialProject)
  const [pendingCreate, setPendingCreate] = useState(false)
  const [pendingMutate, setPendingMutate] = useState(false)

  const [project, dispatch] = useReducer<Reducer<ReducerState, ReducerAction>>(reducer, {
    ...initialProject,
    shippingGroup: initialShippingGroup,
    defaults: {
      defaultStoreId,
      defaultAddress,
    },
  })
  const [shippingGroup, setShippingGroup] = useState(initialShippingGroup)
  const watchPhone = useWatch({ control: form.control, name: 'phone' }) ?? project.phone
  const watchCommission =
    useWatch({ control: form.control, name: 'commission' }) ?? project.commission
  const watchAdditionalText =
    useWatch({ control: form.control, name: 'additionalText' }) ?? project.remark

  // states & utils to restore original data
  const [hasChanges, setHasChanges] = useState(false)
  const originalProject = useRef(JSON.stringify(comparableProject(project)))
  const originalShippingGroup = useRef(JSON.stringify(shippingGroup))
  const originalFormValues = useRef(
    JSON.stringify({
      phone: project.phone,
      commission: project.commission,
      additionalText: project.remark,
    })
  )
  useEffect(() => {
    setHasChanges(
      originalProject.current !== JSON.stringify(comparableProject(project)) ||
        originalShippingGroup.current !== JSON.stringify(shippingGroup) ||
        originalFormValues.current !==
          JSON.stringify({
            phone: watchPhone,
            commission: watchCommission,
            additionalText: watchAdditionalText,
          })
    )
  }, [project, shippingGroup, watchPhone, watchCommission, watchAdditionalText])

  useEffect(() => {
    if (!project.defaults.defaultAddress && defaultAddress) {
      dispatch({ type: 'INIT_DEFAULT_ADDRESS', payload: defaultAddress })
    }
  }, [defaultAddress, project.defaults.defaultAddress])

  useEffect(() => {
    if (!project.defaults.defaultStoreId && defaultStoreId) {
      dispatch({ type: 'INIT_DEFAULT_STORE_ID', payload: defaultStoreId })
    }
  }, [defaultStoreId, project.defaults.defaultStoreId])

  const updateOfferId = useCallback(
    (payload: string) => dispatch({ type: 'UPDATE_OFFER_ID', payload }),
    []
  )
  const updateShippingGroup = useCallback((payload: ShippingGroup | null) => {
    setShippingGroup(payload)
    dispatch({ type: 'UPDATE_SHIPPING_GROUP', payload })
  }, [])
  const updateShippingDataPickup = useCallback(
    (payload: ProjectDetailsShippingDataPickup) =>
      dispatch({ type: 'UPDATE_SHIPPING_DATA_PICKUP', payload }),
    []
  )
  const updateShippingType = useCallback(
    (payload: ShippingType) => dispatch({ type: 'UPDATE_SHIPPING_TYPE', payload }),
    []
  )
  const updateStoreId = useCallback(
    (payload: ShippingType) => dispatch({ type: 'UPDATE_STORE_ID', payload }),
    []
  )
  const updateDeliveryAddress = useCallback(
    (deliveryAddress: DeliveryAddressV2, addressId?: string) =>
      dispatch({ type: 'UPDATE_DELIVERY_ADDRESS', payload: { deliveryAddress, addressId } }),
    []
  )
  const updateAddressId = useCallback(
    (addressId: string) => {
      const deliveryAddress = userAddresses.find(
        (userAddress) => userAddress.addressId === addressId
      )
      if (deliveryAddress) {
        updateDeliveryAddress(deliveryAddress.address, addressId)
      }
    },
    [updateDeliveryAddress, userAddresses]
  )

  const restoreOriginalSettings = useCallback(() => {
    const restoredFormValues = JSON.parse(originalFormValues.current)
    form.setValue('phone', restoredFormValues.phone)
    form.setValue('commission', restoredFormValues.commission)
    form.setValue('additionalText', restoredFormValues.additionalText)

    const restoredShippingGroup = JSON.parse(originalShippingGroup.current)
    setShippingGroup(restoredShippingGroup)
    const restoredProject = JSON.parse(originalProject.current)
    dispatch({
      type: 'COMPLETE_UPDATE',
      payload: { ...restoredProject, shippingGroup: restoredShippingGroup },
    })
  }, [form])

  const mutateWithAggregatedValues = useCallback(
    async (
      project: ReducerState,
      mutationExecutor: (project: ProjectDetailsProject) => Promise<boolean>
    ) => {
      const formValues = form.getValues()
      const projectFormValues = {
        phone: formValues.phone,
        commission: formValues.commission,
        remark: formValues.additionalText,
      }
      const { defaults, shippingGroup, ...projectToMutate } = project

      const success = mutationExecutor({ ...projectToMutate, ...projectFormValues })
      originalProject.current = JSON.stringify(comparableProject(project))
      originalFormValues.current = JSON.stringify({
        phone: formValues.phone,
        commission: formValues.commission,
        additionalText: formValues.additionalText,
      })
      originalShippingGroup.current = JSON.stringify(shippingGroup)

      if (await success) setHasChanges(false)
      return success
    },
    [form]
  )

  const mutateProject = useCallback(
    () => mutateWithAggregatedValues(project, mutate),
    [mutate, mutateWithAggregatedValues, project]
  )

  const mutateProjectName = useCallback(
    async (name: string) => {
      setPendingMutate(true)
      const success = await mutateWithAggregatedValues({ ...project, name }, mutate)
      setPendingMutate(false)
      if (success) {
        dispatch({ type: 'UPDATE_NAME', payload: name })
        return true
      }
      return false
    },
    [mutate, mutateWithAggregatedValues, project]
  )

  const createProject = useCallback(async () => {
    setPendingCreate(true)
    const success = await mutateWithAggregatedValues(project, create)
    if (!success) {
      // we only need to reset the pending state if the creation failed since the user will be redirected on success
      setPendingCreate(false)
    }
    return success
  }, [create, mutateWithAggregatedValues, project])

  const updateLocalProjectName = useCallback(
    (payload: string) => dispatch({ type: 'UPDATE_NAME', payload }),
    []
  )

  return {
    project,
    shippingGroup,
    updateOfferId,
    updateShippingGroup,
    updateShippingDataPickup,
    updateShippingType,
    updateStoreId,
    updateDeliveryAddress,
    updateAddressId,
    hasChanges,
    restoreOriginalSettings,
    mutateProject,
    mutateProjectName,
    createProject,
    updateLocalProjectName,
    pendingCreate,
    pendingMutate,
  }
}
