import React, { FC, useState } from 'react'
import { withProvider } from '@obeta/data/lib/providers/withProvider'
import {
  ProjectOverviewProvider,
  useProjectOverviewContext,
} from '@obeta/data/lib/stores/useProjectOverviewContext'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as AddIcon } from 'assets/icon/designsystem/add.svg'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { OverviewPageHeader } from '@obeta/components/lib/overview-page-header/OverviewPageHeader'
import { ProjectCard } from '@obeta/components/lib/project-card/ProjectCard'
import { CardGrid } from '@obeta/components/lib/card-grid/CardGrid'
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { ProjectOverviewEmpty } from './ProjectOverviewEmpty'
import { Pagination } from '@obeta/components/lib/pagination/Pagination'
import styles from './ProjectOverviewPage.module.scss'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { ShopLink } from '@obeta/components/lib/link/ShopLink'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { ProjectOverviewLoading } from './ProjectOverviewLoading'
import { ProjectOverviewNoPermission } from './ProjectOverviewNoPermission'

const ProjectOverviewPage: FC = withProvider(() => {
  useAuthenticatedRoute()
  const { searchTerm, setSearchTerm, page, setPage, projects, maxPage, count, initialLoading } =
    useProjectOverviewContext()
  const [searchInputValue, setSearchInputValue] = useState<string>(searchTerm)
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const history = useHistory()
  const user = useUserDataV2()

  useDebouncedEffect(
    () => {
      if (searchTerm !== searchInputValue) setSearchTerm(searchInputValue)
    },
    [searchInputValue],
    400
  )

  if (!user.permissions?.Projects_canManage) return <ProjectOverviewNoPermission />
  if (initialLoading) return <ProjectOverviewLoading />
  if (count === 0) return <ProjectOverviewEmpty />

  return (
    <>
      <OverviewPageHeader
        title={t('PROJECTS.TITLE', { count })}
        titleLineActions={[
          <ShopLink
            href={ShopRoutes.ProjectCreateNew}
            style={{ textDecoration: 'none' }}
            key="create-project"
          >
            <TertiaryButton
              leftIcon={<AddIcon />}
              onClick={() => history.push(ShopRoutes.ProjectCreateNew)}
              size={desktop ? 'small' : 'large'}
            >
              {t('PROJECTS.CREATE_PROJECT_BUTTON_LABEL')}
            </TertiaryButton>
          </ShopLink>,
        ]}
        searchPlaceHolder={t('PROJECTS.SEARCH_PLACEHOLDER')}
        searchTerm={searchInputValue}
        setSearchTerm={setSearchInputValue}
      />
      <CardGrid>
        {projects.map((project) => (
          <ProjectCard id={project.id} name={project.name} offerId={project.offerId} />
        ))}
      </CardGrid>
      <div className={styles.bottom}>
        <Pagination
          maxPage={maxPage}
          currentPage={page}
          onPageSelected={(p) => {
            setPage(p)
            window.scrollTo(0, 0)
          }}
        />
      </div>
    </>
  )
}, ProjectOverviewProvider)

export default ProjectOverviewPage
