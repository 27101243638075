import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { TimeField } from '@mui/x-date-pickers-pro'
import { weekdays } from '@obeta/models/lib/models'
import { validateTimeRange } from '@obeta/utils/lib/settingsConverters'
import styles from './SettingsPage.module.scss'

const weekDays = {
  0: 'Sonntag ',
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag',
}

interface TimeFormField {
  dayIndex: weekdays
}

export const TimeFormField: FC<TimeFormField> = ({ dayIndex }) => {
  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext()
  const timeFrom = watch(`openingHours.${dayIndex}.from`)
  const timeTo = watch(`openingHours.${dayIndex}.to`)

  return (
    <Box className={styles.timeFormField}>
      <Typography>{weekDays[dayIndex]}</Typography>
      <Controller
        control={control}
        name={`openingHours.${dayIndex}.from`}
        defaultValue={null}
        rules={{
          validate: (newValueFrom) => validateTimeRange(newValueFrom, timeTo),
          required: !!timeTo,
        }}
        render={({ field: { value, onChange, onBlur } }) => (
          <TimeField
            fullWidth
            value={value}
            onChange={onChange}
            onBlur={() => {
              trigger(`openingHours.${dayIndex}.to`)
              onBlur()
            }}
            format="HH:mm"
            slotProps={{ textField: { error: !!errors.openingHours?.[dayIndex]?.from } }}
          />
        )}
      />
      <Typography>bis</Typography>
      <Controller
        control={control}
        name={`openingHours.${dayIndex}.to`}
        defaultValue={null}
        rules={{
          validate: (newValueTo) => validateTimeRange(timeFrom, newValueTo),
          required: !!timeFrom,
        }}
        render={({ field: { value, onChange, onBlur } }) => (
          <TimeField
            fullWidth
            value={value}
            onChange={onChange}
            onBlur={() => {
              trigger(`openingHours.${dayIndex}.from`)
              onBlur()
            }}
            format="HH:mm"
            slotProps={{ textField: { error: !!errors.openingHours?.[dayIndex]?.to } }}
          />
        )}
      />
    </Box>
  )
}
