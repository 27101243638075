import { Typography, Box } from '@mui/material'
import { IColumn, StripedTable } from '@obeta/components/lib/striped-table/StripedTable'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { ReactComponent as CompareArrowsIcon } from '@obeta/assets/icon/compare_arrows.svg'
import styles from './TechnicalSpecifications.module.scss'
import clsx from 'clsx'
import { ToggleMore } from '@obeta/components/lib/toggle-more/ToggleMore'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import { IArticle } from './types'
import { useArticleSearchFilter } from '@obeta/data/lib/hooks/useArticleSearchFilter'
import { EtimData, ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { SectionWrapper } from './SectionProvider'
import { mapEtim } from '@obeta/utils/lib/mapEtims'
import { SectionHeader } from './SectionHeader'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import { useTranslateValues } from '@obeta/data/lib/hooks/useTranslateValues'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SecondaryLightButton } from '@obeta/components/lib/custom-button/CustomButton'

// UI
import { Checkbox } from '@obeta/components/lib/checkbox/Checkbox'
import { getTechInfoValue } from '@obeta/components/lib/pdf/PdfArticleDataSheet/pdfArticleDataSheetHelpers/configs'
import { OverflowTooltipTypography } from '@obeta/components/lib/typography'
import { TypographyLineClamp } from '@obeta/components/lib/typography'

type ITechnicalSpecification = EtimData
type ITechnicalSpecificationColumns = ITechnicalSpecification & { selected?: boolean }

const DESKTOP_MAX_ITEMS_TO_SHOW = 16
const MAX_ITEMS_TO_SHOW = 10
const DESKTOP_MIN_ITEMS_TO_SHOW = 8

const idWithPrefix = (id: string) => {
  return `tech-data-${id}`
}

interface ITechnicalSpecificationProps {
  article: IArticle | undefined
  initialShowAll?: boolean
  withToggleButton?: boolean
}

interface ITechnicalSpecificationBaseProps extends ITechnicalSpecificationProps {
  onEtimsUpdate?: (selectedEtim: EtimData, selectedEtims: Set<EtimData>) => void
  withSelection?: boolean
}

const defaultEtimData: ITechnicalSpecification[] = []

export const transformStrippedTableTechInfo = (
  etimData: ProductAggregate['etimData'],
  t: TFunction
) => {
  if (!etimData) return []

  return etimData.reduce((acc, etimDataItem) => {
    return [
      ...acc,
      {
        ...etimDataItem,
        value1: getTechInfoValue(etimDataItem, t),
      },
    ]
  }, [] as ProductAggregate['etimData'])
}

// TODO: I think it can be reused for ArticleEtimFilter
const generateSearchParams = (
  selectedEtims: Set<ITechnicalSpecification>,
  selectedEtim: EtimData
): ArticleSearchParams => {
  const selectedEtimEntries = [...selectedEtims].map(mapEtim).flat()
  return {
    selectedEtim: selectedEtim
      ? {
          id: selectedEtim?.classId,
          name: selectedEtim?.classText,
        }
      : undefined,
    etim: selectedEtimEntries,
  }
}

export const TechnicalSpecificationsBase: React.FC<ITechnicalSpecificationBaseProps> = (props) => {
  const {
    article,
    withToggleButton = true,
    initialShowAll = false,
    onEtimsUpdate,
    withSelection = true,
  } = props
  const { tabletWide, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const etimData = article?.etimData || defaultEtimData
  // TODO: this should be tmp. We need to figure out the logic behind selectedEtim in gql api
  const selectedEtim = etimData[0]
  const [selectedEtims, setSelectedEtims] = useState(new Set<ITechnicalSpecification>())
  const { tValue } = useTranslateValues()
  const { mobile } = useBreakpoints()

  useEffect(() => {
    setSelectedEtims(new Set())
  }, [etimData])

  useEffect(() => {
    onEtimsUpdate?.(selectedEtim, selectedEtims)
  }, [onEtimsUpdate, selectedEtim, selectedEtims])

  const [showAllItems, setShowAllItems] = useState<boolean>(initialShowAll)

  const columns = useMemo(() => {
    const cols: IColumn<keyof ITechnicalSpecificationColumns, ITechnicalSpecificationColumns>[] = []

    const getFormattedValue = (value1: string, value2?: string) =>
      value2 ? `${tValue(value1)} - ${tValue(value2)}` : tValue(value1)

    const renderFeatureWithValue = (name: string, data: ITechnicalSpecificationColumns) => {
      return (
        <Box className={styles.featureTextWithValueWrapper}>
          <TypographyLineClamp lines={3} variant="body" className={styles.title}>
            {name}
          </TypographyLineClamp>
          <TypographyLineClamp lines={3} variant="bodyBold" className={styles.value}>
            {getFormattedValue(data.value1, data.value2)}
          </TypographyLineClamp>
        </Box>
      )
    }

    if (mobile) {
      cols.push({
        accessor: 'featureText',
        minWidth: 0,
        flex: 46,
        format: renderFeatureWithValue,
      })
    } else {
      cols.push(
        {
          accessor: 'featureText',
          minWidth: 0,
          flex: 46,
          format: (name: string) => (
            <OverflowTooltipTypography
              text={name}
              overflowVariantProps={{
                component: desktop ? 'label' : 'span',
                htmlFor: desktop ? idWithPrefix(name) : undefined,
              }}
              hoverVariantProps={{
                component: 'label',
                htmlFor: idWithPrefix(name),
              }}
            />
          ),
        },
        {
          accessor: 'value1',
          flex: 46,
          alignment: withSelection ? 'left' : 'right',
          format: (_, data) => (
            <Typography noWrap variant="bodyBold">
              {getFormattedValue(data.value1, data.value2)}
            </Typography>
          ),
        }
      )
    }

    if (withSelection) {
      cols.push({
        accessor: 'selected',
        className: styles.techDataSelectedCheckbox,
        alignment: 'right',
        flex: 8,
        format: (_, data) => {
          const { featureText: name, featureId: currentFeatureId } = data

          const isChecked = Array.from(selectedEtims).some(
            ({ featureId }) => featureId === currentFeatureId
          )

          return (
            <Checkbox
              id={idWithPrefix(name)}
              name={name}
              checked={isChecked}
              onChange={(e) => {
                const checked = e.target.checked
                setSelectedEtims((prevEtims) => {
                  const newSet = new Set(prevEtims)
                  if (checked) {
                    newSet.add(data)
                  } else {
                    newSet.forEach((etim) => {
                      if (etim.featureId === currentFeatureId) {
                        newSet.delete(etim)
                      }
                    })
                  }
                  return newSet
                })
              }}
              className={styles.checkbox}
            />
          )
        },
      })
    }

    return cols
  }, [mobile, desktop, selectedEtims, tValue, withSelection])

  const available = etimData.length > 0
  const [canToggle, columnData] = useMemo<[boolean, ITechnicalSpecification[]]>(() => {
    if (!available) {
      return [false, etimData]
    }

    let itemsLength = etimData.length
    if (desktop || tabletWide) {
      if (itemsLength <= DESKTOP_MIN_ITEMS_TO_SHOW) {
        return [false, etimData]
      }

      const canToggle = itemsLength > DESKTOP_MAX_ITEMS_TO_SHOW

      if (!showAllItems && canToggle) {
        itemsLength = DESKTOP_MAX_ITEMS_TO_SHOW
      }

      return [canToggle, etimData.slice(0, itemsLength)]
    }

    if (itemsLength > MAX_ITEMS_TO_SHOW) {
      if (!showAllItems) {
        itemsLength = MAX_ITEMS_TO_SHOW
      }

      return [true, etimData.slice(0, itemsLength)]
    } else {
      return [false, etimData]
    }
  }, [available, desktop, tabletWide, etimData, showAllItems])

  const preparedTableData = useMemo(() => {
    return transformStrippedTableTechInfo(columnData, t)
  }, [columnData, t])

  return (
    <SectionWrapper
      available={available}
      id={ArticleDetailsSections.TechnicalSpecification}
      className={styles.root}
    >
      <SectionHeader>{t('ARTICLE_DETAIL.TECHNICAL_SPECIFICATIONS')}</SectionHeader>
      <div className={clsx(styles.data)}>
        <StripedTable
          columns={columns}
          data={preparedTableData}
          cellClassName={styles.techDataCellClassName}
        />
      </div>
      {/** Use SvgIcon to set correct dimensions (20x20 instead of 24x24) */}
      {withToggleButton && (
        <ToggleMore
          canToggle={canToggle}
          showMore={showAllItems}
          toggleMore={() => {
            setShowAllItems((baseState) => !baseState)
          }}
        />
      )}
      {props.children}
    </SectionWrapper>
  )
}

export const TechnicalSpecifications: React.FC<ITechnicalSpecificationProps> = (props) => {
  const changeSearchParams = useChangeSearchParams()
  const { t } = useTranslation()
  const [localSearchParams, setLocalSearchParams] = useState<ArticleSearchParams>({})

  const onEtimsUpdateRef = useRef<ITechnicalSpecificationBaseProps['onEtimsUpdate']>(
    (selectedEtim, selectedEtims) => {
      setLocalSearchParams(generateSearchParams(selectedEtims, selectedEtim))
    }
  )

  const { searchFilter: allFilterResults } = useArticleSearchFilter(localSearchParams)

  return (
    // Need to fix this page styles problem
    <TechnicalSpecificationsBase {...props} onEtimsUpdate={onEtimsUpdateRef.current}>
      <div className={styles['btn-wrapper']}>
        <SecondaryLightButton
          leftIcon={<CompareArrowsIcon />}
          disabled={!allFilterResults.hitCount}
          onClick={() => {
            changeSearchParams({
              searchParams: localSearchParams,
              route: 'push',
            })
          }}
        >
          {`${t<string>('ARTICLE_DETAIL.COMPARABLE_ITEMS')} (${allFilterResults.hitCount})`}
        </SecondaryLightButton>
      </div>
    </TechnicalSpecificationsBase>
  )
}
