import React from 'react'
import { FirmDataSettingsForm } from '../../FirmDataSettings'
import { PriceSettings } from '../../PriceSettings'
import { useTranslation } from 'react-i18next'
import { ShowMoreContentCollapse } from '../../components/ShowMoreContentCollapse'
import styles from './IndividualDatasheetView.module.scss'

export const IndividualDatasheetView = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div className={styles.firmDataSection}>
        <ShowMoreContentCollapse
          headerTitle={t('SETTINGS.SUBTITLE.FIRM_DATA')}
          headerSubtitle={t('SETTINGS.DESCRIPTION.FIRM_DATA')}
        >
          <FirmDataSettingsForm />
        </ShowMoreContentCollapse>
      </div>
      <div className={styles.priceSection}>
        <ShowMoreContentCollapse
          headerTitle={t('SETTINGS.SUBTITLE.PRICE_DATA')}
          headerSubtitle={t('SETTINGS.DESCRIPTION.PRICE_DATA')}
        >
          <PriceSettings />
        </ShowMoreContentCollapse>
      </div>
    </div>
  )
}
