import { INotificationData, printArticleDataSheet } from '@obeta/utils/lib/printPdf'
import { useTranslation } from 'react-i18next'
import { useAppDomain } from './useAppDomain'
import { useUserDataV2 } from './useUserDataV2'

type PrintOptions = {
  sapId: string
  dehaNr: string
  requestParamsUrl?: string
}

export const usePrintArticleDataSheet = () => {
  const { tokens } = useUserDataV2()
  const domain = useAppDomain()
  const { t } = useTranslation()

  const print = (options: PrintOptions) => {
    const { sapId, dehaNr, requestParamsUrl } = options

    const notificationData: INotificationData = {
      id: t('PRINT.DATASHEET_EVENT_ID', { sapId }),
      heading: t('PRINT.DATASHEET_PDF_HEADER'),
      body: ({ filename }) => t('PRINT.NOTIFICATION_BODY', { filename }),
    }
    return printArticleDataSheet({
      sapId,
      dehaNr: dehaNr,
      accessToken: tokens?.accessToken,
      domain,
      notificationData,
      requestParamsUrl,
    })
  }

  return { print }
}
