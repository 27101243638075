import { gql, useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import {
  OrderItemsInArrearsInput,
  SearchOrderItemsInArrearsQuery,
  SearchOrderItemsInArrearsQueryVariables,
} from '@obeta/schema'
import { useOrderDetailsInArrearsContext } from '../stores/useOrderDetailsInArrearsContext'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import { useUserDataV2 } from './useUserDataV2'

const SEARCH_QUERY = gql`
  query searchOrderItemsInArrears($input: OrderItemsInArrearsInput!, $warehouseIds: [String!]!) {
    searchOrderItemsInArrears(input: $input) {
      success
      errorCode
      errorMessage
      totalItemsCount
      items {
        offerId
        commissionText
        orderName
        articleDescription
        createdAt
        dehaId
        deliverySlipId
        id
        isSapPromotionalProduct
        lastUpdated
        metalAddition
        netPrice
        obetaId
        orderAmount
        orderId
        supplierFilterName
        product {
          sapId
          articleDescription
          dehaId
          isCurrentlyNotAvailable
          isTopseller
          isSendable
          isCutProduct
          minimumAmount
          oxomiId
          priceDimension
          imageData {
            sapId
            images {
              large
            }
          }
          images {
            url
          }
          oxomiId
          supplierId
          prices {
            currency
            listPrice
            netPrice
            strikeThroughPrice
            tecSelect
          }
          stock(warehouseIds: $warehouseIds) {
            amount
            sapId
            unit
            warehouseId
          }
          stockAvailabilityEstimate {
            sapId
            minValue
            maxValue
            error
            unit
          }
          supplierId
          supplierImageData {
            large
            sapId
          }
          title
          type
          unit
        }
        sapId
        shippingAmount
        shippingValue
        shippingValueState
        state {
          type
          count
        }
        supplierArticleId
        supplierArticleId2
        supplierId
        supplierImageData {
          sapId
          large
        }
        supplierName
        totalPrice
        unit
      }
    }
  }
`
export const SEARCH_ORDER_ITEMS_LIMIT = '20'

export const INITIAL_ORDER_ITEMS_SEARCH_INPUT: OrderItemsInArrearsInput = {
  limit: SEARCH_ORDER_ITEMS_LIMIT,
  offset: '0',
  searchTerm: '',
}

export const useOrderItemWithOrderDataSearch = () => {
  const { orderItems, setOrderItems, totalItemsCount, setTotalItemsCount } =
    useOrderDetailsInArrearsContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchOrderItemsInput, setSearchOrderItemsInput] = useState<OrderItemsInArrearsInput>(
    INITIAL_ORDER_ITEMS_SEARCH_INPUT
  )

  const { warehouseId } = useWarehouseContext()
  const { user } = useUserDataV2()
  const client = useApolloClient()

  const searchOrderItems = useCallback(
    async (input: OrderItemsInArrearsInput) => {
      setIsLoading(true)
      const defaultStoreId = user?.settings?.defaultStoreId ?? ''
      const warehouseIds = [defaultStoreId, warehouseId].filter(Boolean) as string[]

      const response = await client.query<
        SearchOrderItemsInArrearsQuery,
        SearchOrderItemsInArrearsQueryVariables
      >({
        query: SEARCH_QUERY,
        variables: {
          input: {
            limit: input.limit,
            offset: input.offset,
            searchTerm: input.searchTerm,
          },
          warehouseIds: warehouseIds,
        },
        fetchPolicy: 'no-cache',
      })

      setIsLoading(false)
      const orderItemsResponse = response.data?.searchOrderItemsInArrears
      if (orderItemsResponse.success) {
        if (orderItemsResponse.totalItemsCount !== totalItemsCount) {
          setTotalItemsCount(orderItemsResponse.totalItemsCount)
        }

        if (input.offset === '0') {
          // Replace order items on search change
          setOrderItems([...orderItemsResponse.items])
        } else if (orderItemsResponse.items.length > 0) {
          // Add orders on scroll
          setOrderItems([...orderItems, ...orderItemsResponse.items])
        }
      }
      setIsLoading(false)
    },
    // eslint-disable-next-line
    [isLoading, orderItems, user?.settings?.defaultStoreId]
  )

  return {
    isLoading,
    searchOrderItemsInput,
    searchOrderItems,
    setIsLoading,
    setSearchOrderItemsInput,
  }
}
