import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import {
  ArticleSearchArea,
  ArticleSearchAreaLabelsByKey,
  ArticleSearchParamsWithId,
} from '@obeta/models/lib/models/Search'
import { isEmpty } from '@obeta/utils/lib/isEmpty'
import { validateContextValue } from '@obeta/utils/lib/validateContextValue'
import React, { Reducer, useCallback, useContext, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { DispatchFiltersAction, FiltersActions, filtersReducer, IState } from '../reducer'
import { formatListPriceForFilterAsNumber } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { ArticleFilterProvider } from '@obeta/data/lib/hooks/useArticleSearchFilter'

interface IFilterReducerProps {
  withoutRedirect?: boolean
}

const buildSearchParams = (
  searchParams: ArticleSearchParamsWithId | null | undefined,
  forceImmediatelyAvailable: boolean
): ArticleSearchParamsWithId => {
  return {
    id: '',
    articleSearchArea: forceImmediatelyAvailable
      ? ArticleSearchArea.IMMEDIATELY_AVAILABLE
      : ArticleSearchArea.ALL,
    ...searchParams,
  }
}

export const useFiltersReducer = (withoutRedirect = false) => {
  const { searchParams } = useArticlesSearchProvider()
  const changeSearchParams = useChangeSearchParams()

  const [filtersState, dispatchFiltersAction] = useReducer<Reducer<IState, FiltersActions>>(
    filtersReducer,
    {
      searchParams: buildSearchParams(searchParams, true),
      activeEtimFeatureName: null,
      selectedEtimValueByFeatureName: {},
      filters: {
        data: {},
        list: [],
      },
    }
  )

  const applyFilters = useCallback(() => {
    changeSearchParams({
      searchParams: filtersState.searchParams,
      route: withoutRedirect ? 'none' : 'replace',
    })
  }, [changeSearchParams, filtersState.searchParams, withoutRedirect])

  // TODO:
  // 1) Do not store whole searchParams inside filtersState. Only parts thats are needed: selected ids (strings)

  // sync GLOBAL state to LOCAL
  useEffect(() => {
    // compare md5
    if (!searchParams || filtersState.searchParams.id === searchParams?.id) {
      return
    }

    dispatchFiltersAction({
      type: 'setSearchParams',
      payload: {
        searchParams: buildSearchParams(searchParams, true),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]) // should be executed only when GLOBAL change

  // sync LOCAL state to GLOBAL
  useEffect(() => {
    // default search params
    if (!filtersState.searchParams.id) {
      return
    }

    // compare md5
    if (filtersState.searchParams.id === searchParams?.id) {
      return
    }

    changeSearchParams({
      searchParams: filtersState.searchParams,
      route: withoutRedirect ? 'none' : 'replace',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState.searchParams]) // should be executed only when LOCAL change

  return { filtersState, dispatchFiltersAction: dispatchFiltersAction, applyFilters }
}

export interface Filter {
  id: string
  value: string
  remove: () => void
}

const FiltersStateContext = React.createContext<{
  dispatchFiltersAction: DispatchFiltersAction
  filtersState: IState
  applyFilters: () => void
  activeFilters: Filter[]
} | null>(null)

export const FiltersStateProvider: React.FC<IFilterReducerProps> = (props) => {
  const { filtersState, dispatchFiltersAction, applyFilters } = useFiltersReducer(
    props.withoutRedirect
  )

  const { t } = useTranslation()

  /**
   * TODO: I think these filters must me stored inside filtersState
   * And we must rethink how these filters are stored.
   * It should be convenient to map/remove them.
   */
  const activeFilters = (() => {
    const filters: Filter[] = []

    // search area filter
    if (
      filtersState.searchParams.articleSearchArea &&
      filtersState.searchParams.articleSearchArea !== ArticleSearchArea.ALL
    ) {
      filters.push({
        id: 'search-area',
        value: t(
          `SEARCH.ARTICLELIST.FILTER.SEARCH_AREA.${
            ArticleSearchAreaLabelsByKey[filtersState.searchParams.articleSearchArea]
          }`
        ),
        remove: () => {
          dispatchFiltersAction({
            type: 'selectSearchArea',
            payload: {
              searchArea: null,
            },
          })
        },
      })
    }

    // fundings
    if (filtersState.searchParams.fundings) {
      filters.push({
        id: 'suppliers',
        value: filtersState.searchParams.fundings.toString(),
        remove: () => {
          dispatchFiltersAction({ type: 'setFundings', payload: { fundings: null } })
        },
      })
    }

    // suppliers
    if (filtersState.searchParams.suppliers && !isEmpty(filtersState.searchParams.suppliers)) {
      const supplierFilters: Filter[] = Object.keys(filtersState.searchParams.suppliers).map(
        (key) => {
          const supplierName = filtersState.searchParams.suppliers?.[key].name ?? ''
          return {
            id: supplierName,
            value: supplierName,
            remove: () => {
              dispatchFiltersAction({
                type: 'resetSupplier',
                payload: {
                  supplier: supplierName,
                },
              })
            },
          }
        }
      )
      filters.push(...supplierFilters)
    }

    // category
    if (filtersState.searchParams.obetaCategory) {
      filters.push({
        id: 'category',
        value: filtersState.searchParams.obetaCategory.name,
        remove: () => {
          dispatchFiltersAction({
            type: 'setChosenCategory',
            payload: {
              category: null,
              type: 'obetaCategory',
            },
          })
        },
      })
    }

    // online category
    if (filtersState.searchParams.dehaCategory) {
      filters.push({
        id: 'onlin-category',
        value: filtersState.searchParams.dehaCategory.name,
        remove: () => {
          dispatchFiltersAction({
            type: 'setChosenCategory',
            payload: {
              category: null,
              type: 'dehaCategory',
            },
          })
        },
      })
    }

    // sendable
    if (filtersState.searchParams.sendable !== undefined) {
      filters.push({
        id: 'packaging',
        value: filtersState.searchParams.sendable
          ? t('SEARCH.PACKAGEABLE')
          : t('SEARCH.NOT_PACKAGEABLE'),
        remove: () => {
          dispatchFiltersAction({
            type: 'setPackagingFilter',
            payload: {
              sendable: undefined,
            },
          })
        },
      })
    }

    // list price range
    if (
      filtersState.searchParams.minListPrice !== undefined &&
      filtersState.searchParams.maxListPrice !== undefined
    ) {
      filters.push({
        id: 'list-price-range',
        value: `${formatListPriceForFilterAsNumber(
          filtersState.searchParams.minListPrice
        )} - ${formatListPriceForFilterAsNumber(filtersState.searchParams.maxListPrice)}`,
        remove: () => {
          dispatchFiltersAction({
            type: 'resetListPriceRange',
          })
        },
      })
    }
    return filters
  })()

  return (
    <FiltersStateContext.Provider
      value={{ filtersState, dispatchFiltersAction, applyFilters, activeFilters }}
    >
      <ArticleFilterProvider searchParams={filtersState.searchParams}>
        {props.children}
      </ArticleFilterProvider>
    </FiltersStateContext.Provider>
  )
}

export const useFiltersStateContext = () => {
  return validateContextValue(
    useContext(FiltersStateContext),
    'FiltersStateContext',
    'useFiltersStateContext'
  )
}
