import { useCallback, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import {
  GetOfferDetailsFacetsQuery,
  GetOfferDetailsFacetsQueryVariables,
  OfferItemsInput,
  SearchOfferItemsQuery,
  SearchOfferItemsQueryVariables,
} from '@obeta/schema'
import { useUserSelectedStore } from './useUserSelectedStore'
import { useWarehouseContext } from '../stores/useWarehouseContext'

// Stores
import { useOfferDetailsContext } from '../stores/useOfferDetailsContext'

// Utils
import { trackCustom } from '@obeta/utils/lib/tracking'
import { useUserDataV2 } from '../hooks/useUserDataV2'

// Constants
const FACETS_QUERY = gql`
  query getOfferDetailsFacets($input: OfferItemsInput!) {
    searchOfferItems(input: $input) {
      success
      errorCode
      errorMessage
      facets {
        supplier {
          id
          name
          count
        }
      }
    }
  }
`

const SEARCH_QUERY = gql`
  query searchOfferItems($input: OfferItemsInput!, $warehouseIds: [String!]!) {
    searchOfferItems(input: $input) {
      success
      errorCode
      errorMessage
      totalItemCount
      notCancelledItemCount
      facets {
        supplier {
          id
          name
          count
        }
      }
      items {
        sapId
        articleDescription
        amount
        netPrice
        offerItemPosition
        productType
        readingDirectoryNumber
        product {
          sapId
          articleDescription
          dehaId
          isCurrentlyNotAvailable
          isTopseller
          isSendable
          isCutProduct
          minimumAmount
          oxomiId
          priceDimension
          images {
            url
            width
          }
          imageData {
            sapId
            images {
              large
            }
          }
          oxomiId
          supplierId
          prices {
            currency
            listPrice
            netPrice
            strikeThroughPrice
            tecSelect
          }
          stock(warehouseIds: $warehouseIds) {
            amount
            sapId
            unit
            warehouseId
          }
          stockAvailabilityEstimate {
            sapId
            minValue
            maxValue
            error
            unit
          }
          supplierId
          supplierImageData {
            large
            sapId
          }
          title
          type
          unit
        }
        supplierImageData {
          sapId
          large
        }
        isCancelled
      }
    }
  }
`

export const useOfferItemsSearch = () => {
  const selectedStore = useUserSelectedStore()
  const { warehouseId } = useWarehouseContext()
  const { user } = useUserDataV2()

  // Offer details state
  const { offerItems, setFacets, setNotCancelledItemCount, setOfferItems, setTotalItemCount } =
    useOfferDetailsContext()

  // Component state
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const client = useApolloClient()

  const getFacets = useCallback(
    async (offerId: string) => {
      const response = await client.query<
        GetOfferDetailsFacetsQuery,
        GetOfferDetailsFacetsQueryVariables
      >({
        query: FACETS_QUERY,
        variables: {
          input: {
            offerId,
            filterV2: '',
            limit: '1',
            offset: '0',
            orderDir: 'ASC',
            searchTerm: '',
          },
        },
        fetchPolicy: 'no-cache',
      })
      if (response.data.searchOfferItems.success && response.data.searchOfferItems.facets) {
        setFacets(response.data.searchOfferItems.facets)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStore]
  )

  const searchOfferItems = useCallback(
    // Providing input as well as default store id to ensure the query is resend if either of the values change.
    // The defaultStoreId is likely to be undefined initially, but required to correctly display the stock information.

    async (input: OfferItemsInput, defaultStoreId) => {
      const wareHouseIds = [defaultStoreId, warehouseId].filter(Boolean) as string[]

      const response = await client.query<SearchOfferItemsQuery, SearchOfferItemsQueryVariables>({
        query: SEARCH_QUERY,
        variables: {
          input: {
            filterV2: input.filterV2,
            offerId: input.offerId,
            limit: input.limit,
            offset: input.offset,
            searchTerm: input.searchTerm,
            orderBy: input.orderBy,
            orderDir: input.orderDir,
          },
          warehouseIds: wareHouseIds,
        },
        fetchPolicy: 'no-cache',
      })

      setNotCancelledItemCount(response.data.searchOfferItems.notCancelledItemCount ?? 0)
      setTotalItemCount(response.data.searchOfferItems.totalItemCount ?? 0)

      const offerItemsResponse = response.data.searchOfferItems.items

      if (offerItemsResponse) {
        trackCustom('search-offer-items', {
          request: input,
          response: offerItemsResponse,
        })
        if (input.offset === '0') {
          // Replace offer items on search change
          setOfferItems([...offerItemsResponse])
        } else if (offerItemsResponse.length > 0) {
          // Add offers on scroll
          setOfferItems([...offerItems, ...offerItemsResponse])
        }
      } else {
        trackCustom('search-offer-items-error', response.data)
      }

      setIsLoading(false)
    },
    // eslint-disable-next-line
    [setFacets, isLoading, offerItems, user?.settings?.defaultStoreId, warehouseId]
  )

  return {
    isLoading,
    getFacets,
    searchOfferItems,
    setIsLoading,
  }
}
