import { FC, useCallback, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'

// Hooks
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

// Models
import { OrdersFacetV2 } from '@obeta/schema'

// Stores
import { useOrderListContext } from '@obeta/data/lib/stores/useOrderListContext'

// Styles
import styles from './OrderListFilters.module.scss'

// UI
import { CheckboxWithAmountItem } from '../checbox-radio-item/CheckboxRadioItem'
import { SearchField } from '../search-field/SearchField'

// Utils
import {
  filterBySearch,
  getOrderListFilterToggleState,
  sortFacetByName,
  toggleOrderListFilter,
} from '@obeta/utils/lib/orders-helpers'

type Props = {
  id: string
}

export const OrderListFiltersItem: FC<Props> = (props) => {
  const { id } = props

  const history = useHistory()

  const { facets, isLoading, resetOrders, setIsLoading } = useOrderListContext()
  const initiateRefresh = useCallback(
    (value) => {
      // Reset order list
      resetOrders()
      setIsLoading(true)
      toggleOrderListFilter(history, id, value)

      return true
    },
    [history, id, resetOrders, setIsLoading]
  )

  // Component state
  const [filteredFacet, setFilteredFacet] = useState<OrdersFacetV2[]>([])
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    if (!facets) {
      return
    }
    setFilteredFacet(facets[id])
  }, [facets, id])

  /**
   * Search facet item.
   * @param search Search string
   */
  const onSearch = (search: string) => {
    if (!facets) {
      return
    }
    setSearch(search)
    setFilteredFacet(search.length > 0 ? filterBySearch(facets[id], search) : facets[id])
  }

  return (
    <div className={styles.search}>
      <SearchField value={search} onChange={onSearch} onReset={() => onSearch('')} />
      <div className={clsx(styles.content, styles.maxHeight)}>
        {sortFacetByName(filteredFacet).map((item) => {
          const name = item.name
          const value = item.search

          const addition = item.isAddition ? '+' : ''
          const disabled = item.count === 0
          const rightAdornment: JSX.Element = (
            <Typography
              variant="bodyBold"
              className={clsx(styles.checkboxWithAmount, disabled && styles.disabled)}
            >{`(${addition}${item.count})`}</Typography>
          )
          return (
            <CheckboxWithAmountItem
              disabled={disabled}
              rightAdornment={rightAdornment}
              onChange={() => !isLoading && initiateRefresh(value)}
              key={value}
              id={value}
              label={name}
              value={value}
              checked={getOrderListFilterToggleState(history.location.search, id, value)}
              datatestid={id}
            />
          )
        })}
      </div>
    </div>
  )
}
