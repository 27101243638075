import { CartWritePermission } from '@obeta/models/lib/models/Users/UserV2'

type CartWritePermissionArgs = {
  submit: boolean
  notificationEmail?: string
}

type CartWritePermissionResolver = (args: CartWritePermissionArgs) => CartWritePermission

export const determineCartWritePermission: CartWritePermissionResolver = ({
  submit,
  notificationEmail,
}) => {
  if (submit) return CartWritePermission.Submit
  if (notificationEmail && notificationEmail.length > 0) return CartWritePermission.Notify
  return CartWritePermission.None
}
