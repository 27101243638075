import React, { memo, useRef, useState } from 'react'
import {
  Card,
  CardContent,
  ClickAwayListener,
  ImageList,
  ImageListItem,
  Link,
  TextField,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import styles from './TemplateListItemV2.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { TemplateListItemHeader } from './TemplateListItemHeader'
import { CartTemplate, CartTemplateItemInput } from '@obeta/schema'
import { TextSkeleton } from '../text-skeleton/TextSkeleton'
import { ProductOxomiImage } from '../product-images/ProductOxomiImage'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { AddCartTemplateItemsToCartButton } from './AddCartTemplateItemsToCartButton'
import { useTranslation } from 'react-i18next'
import { useStateWithCallback } from '@obeta/data/lib/hooks/useStateWithCallback'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { Controller, useForm } from 'react-hook-form'
import { ReactComponent as MoreHorizIcon } from 'assets/icon/designsystem/more_horiz.svg'
import { FormHelperText } from '../form-helper-text/FormHelperText'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import {
  TemplateActionTypes,
  UpdateCartTemplateGraphQLResultAction,
} from '@obeta/data/lib/actions/template-actions'
import { ImgProxyImage } from '../img-proxy-image/ImgProxyImage'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'
import { TypographyEllipsis } from '../typography'

interface Props {
  carts: ShoppingCartV2[]
  isOnlyTemplate: boolean
  template: CartTemplate
  onDeleteTemplate: (template: CartTemplate) => void
  onDuplicateTemplate: (template: CartTemplate) => void
  onFavoriteToggled: (template: CartTemplate, isFavorite: boolean) => void
  onRenameTemplate: (template: CartTemplate, newName: string) => void
  onVisibilityToggled: (template: CartTemplate, isShared: boolean) => void
  cartTemplatesCount: number
  isLoading: boolean
}
export const TemplateListItemV2 = memo<Props>(function TemplateListItemV2(props) {
  const {
    carts,
    isOnlyTemplate,
    template,
    onDeleteTemplate,
    onDuplicateTemplate,
    onFavoriteToggled,
    onRenameTemplate,
    onVisibilityToggled,
    cartTemplatesCount,
    isLoading,
  } = props

  const waitForUpdateCartTemplateAction = useActionNotification(
    TemplateActionTypes.UpdateCartTemplateGraphQLResult
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: template.name,
    },
  })

  const { desktop } = useBreakpoints()
  const { t } = useTranslation()
  const history = useHistory()
  // TODO: check if this is needed without setting the state
  const [updateWaitingAddItems] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [showTextFieldForRenaming, setShowTextFieldForRenaming] = useStateWithCallback(
    false,
    () => {
      if (showTextFieldForRenaming && inputRef.current) inputRef.current.focus()
    }
  )

  const imageListSize = 7

  const textFieldRef = useRef<HTMLInputElement>(null)

  const onShowTextFieldForRenaming = () => {
    setShowTextFieldForRenaming(true)
  }

  const handleEnterKeyPressInTextField = () => {
    const newName = textFieldRef.current?.value
    onRenameTemplate(template, newName ? newName : '')

    waitForUpdateCartTemplateAction((action: UpdateCartTemplateGraphQLResultAction) => {
      if (action.success) {
        setShowTextFieldForRenaming(false)
      } else if (action.errorMessage === 'Template name already in use.') {
        setError('name', { type: 'taken' })
      } else {
        setError('name', { type: 'unknown' })
      }
    })
  }

  const onDeleteTemplateWithParam = () => {
    onDeleteTemplate(template)
  }
  const onDuplicateTemplateWithParam = () => {
    onDuplicateTemplate(template)
  }

  const onFavoriteToggledWithParam = (isFavorite: boolean) => {
    onFavoriteToggled(template, isFavorite)
  }

  const onVisibilityToggledWithParam = (isShared: boolean) => {
    onVisibilityToggled(template, isShared)
  }

  const onClickAway = () => {
    if (showTextFieldForRenaming) {
      setShowTextFieldForRenaming(false)
      clearErrors('name')
    }
  }

  const itemsToAdd: CartTemplateItemInput[] =
    template.cartTemplateItems?.items?.map((cartTemplateItem) => ({
      productId: cartTemplateItem.productId,
      amount: cartTemplateItem.amount,
    })) ?? []

  const gridHoldingTemplateNameAndItemCount: JSX.Element = (
    <Grid className={styles.marginItem}>
      <TypographyEllipsis variant="boldText">
        {`${template.name} (${template.itemCount})`}
      </TypographyEllipsis>
    </Grid>
  )

  let errorTextTemplateName = ''
  if (errors.name) {
    if (errors.name.type === 'required') {
      errorTextTemplateName = t('TEMPLATES.TEMPLATE_NAME_ERROR_REQUIRED')
    } else if (errors.name.type === 'maxLength') {
      errorTextTemplateName = t('TEMPLATES.TEMPLATE_NAME_ERROR_MAX_LENGTH')
    } else if (errors.name.type === 'taken') {
      errorTextTemplateName = t('TEMPLATES.TEMPLATE_NAME_ERROR_TAKEN')
    } else if (errors.name.type === 'unknown') {
      errorTextTemplateName = t('TEMPLATES.TEMPLATE_NAME_ERROR_UNKNOWN')
    }
  }

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClickAway}>
      <div>
        <Card elevation={1} className={clsx(desktop && styles.reducedPaddingBottom)}>
          <CardContent>
            <Grid container direction={'column'}>
              <TemplateListItemHeader
                cartTemplate={template}
                isOnlyTemplate={isOnlyTemplate}
                onDeleteTemplate={() => onDeleteTemplateWithParam()}
                onDuplicateTemplate={() => onDuplicateTemplateWithParam()}
                onFavoriteToggled={onFavoriteToggledWithParam}
                onRenameTemplate={onShowTextFieldForRenaming}
                onVisibilityToggled={onVisibilityToggledWithParam}
                cartTemplatesCount={cartTemplatesCount}
                productsToAdd={itemsToAdd}
              />
              <LightGrayDivider />
              <Grid className={styles.name}>
                {showTextFieldForRenaming ? (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={handleEnterKeyPressInTextField}
                  >
                    <form
                      className="w-full"
                      onSubmit={handleSubmit(handleEnterKeyPressInTextField)}
                    >
                      <Controller
                        control={control}
                        defaultValue={template.name}
                        rules={{
                          required: true,
                          maxLength: 20,
                        }}
                        name="name"
                        render={({ field: { onChange, onBlur } }) => (
                          <>
                            <TextField
                              id="name"
                              autoFocus
                              className={clsx(
                                styles.textFieldInput,
                                errors.name && styles.textFieldInputError
                              )}
                              defaultValue={template.name}
                              inputProps={{
                                ref: textFieldRef,
                              }}
                              inputRef={inputRef}
                              placeholder={t('TEMPLATES.TEMPLATE_NAME')}
                              onBlur={onBlur}
                              onChange={onChange}
                            />
                            {errors.name && <FormHelperText errorText={errorTextTemplateName} />}
                          </>
                        )}
                      />
                    </form>
                  </ClickAwayListener>
                ) : template.itemCount > 0 ? (
                  <Link
                    href={`/cart-template-details/${template.id}`}
                    color={'inherit'}
                    underline={'none'}
                    maxWidth={'100%'}
                    onClick={(event) => {
                      event.preventDefault()
                      history.push(`/cart-template-details/${template.id}`)
                    }}
                  >
                    {gridHoldingTemplateNameAndItemCount}
                  </Link>
                ) : (
                  gridHoldingTemplateNameAndItemCount
                )}
              </Grid>
              <Grid
                className={clsx(
                  styles.marginItem,
                  !isLoading && styles.imageGridForProducts,
                  isLoading && styles.imageGridForSkeletons,
                  errors.name && styles.minimisedTopMargin
                )}
              >
                {!isLoading && (
                  <ImageList
                    cols={imageListSize + 1}
                    rowHeight={24}
                    gap={8}
                    className={clsx(styles.imageList, styles.fitWidth)}
                  >
                    {template.cartTemplateItems?.items?.slice(0, 6)?.map((cartTemplateItem) => {
                      if (
                        cartTemplateItem.product?.images &&
                        cartTemplateItem.product?.imageData &&
                        cartTemplateItem.product?.oxomiId &&
                        cartTemplateItem.product?.supplierId
                      ) {
                        const validImgProxyUrl = ensureValidImgProxyUrl(
                          cartTemplateItem?.product?.images?.[0]?.url
                        )
                        return (
                          <ImageListItem key={cartTemplateItem.id} sx={{ flexDirection: 'row' }}>
                            {validImgProxyUrl ? (
                              <ImgProxyImage
                                variant="product"
                                className={styles.previewImage}
                                url={validImgProxyUrl}
                                title={cartTemplateItem.product.title}
                                widthInPx={cartTemplateItem.product.images[0].width ?? undefined}
                                mobileWidth={16}
                                tabletWidth={16}
                                tabletWideWidth={16}
                                desktopWidth={16}
                              />
                            ) : (
                              <ProductOxomiImage
                                alt={template.id + '-' + cartTemplateItem.id}
                                src={cartTemplateItem.product.imageData.images[0]?.large}
                                className={styles.previewImage}
                                oxomiId={cartTemplateItem.product.oxomiId}
                                supplierId={cartTemplateItem.product.supplierId}
                              />
                            )}
                          </ImageListItem>
                        )
                      }
                      return null
                    })}
                    {template.itemCount > imageListSize ? (
                      <ImageListItem>
                        <MoreHorizIcon className={styles.moreIcon} />
                      </ImageListItem>
                    ) : (
                      ''
                    )}
                  </ImageList>
                )}
                {isLoading && <TextSkeleton />}
              </Grid>
              <Grid className={styles.marginItem}>
                <AddCartTemplateItemsToCartButton
                  key="add-items-to-cart-button"
                  carts={carts}
                  cartTemplateName={template.name}
                  mobile={false}
                  input={[template.id]}
                  size={!desktop ? 'large' : 'small'}
                  updateWaitingAddItems={updateWaitingAddItems}
                  disabled={!template.cartTemplateItems?.items?.length}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </ClickAwayListener>
  )
})

export const TemplateListItemV2Skeleton: React.FC = () => {
  const { mobile, tablet, tabletWide, desktop } = useBreakpoints()

  return (
    <Card
      className={clsx(
        styles.skeletonWrapper,
        !mobile && styles.reducedPaddingBottom,
        desktop && styles.desktop,
        tabletWide && styles.landscape,
        tablet && styles.tablet,
        mobile && styles.desktop
      )}
    >
      <Grid className={styles.skeletonHeader} />
      <Grid className={styles.skeletonItemTall}>
        <TextSkeleton />
      </Grid>
      <Grid className={styles.skeletonItemShort}>
        <TextSkeleton />
      </Grid>
      <Grid className={styles.skeletonItemTall}>
        <ImageList
          className={clsx(styles.imageList, styles.fitWidth)}
          cols={3}
          rowHeight={24}
          gap={8}
        >
          {new Array(3).fill(null).map((_, idx) => {
            return <TextSkeleton key={idx} width={24} height={24} />
          })}
        </ImageList>
      </Grid>
    </Card>
  )
}
