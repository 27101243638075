import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { PrintOptionIndividualForm } from '@obeta/components/index'
import { useGetDataSheetPriceInfo, useSaveDataSheetPriceInfo } from '@obeta/data/lib/hooks'
import { FormActionFooter } from './FormActionFooter'
import {
  convertPriceSettingsToSubmit,
  convertInitialPriceSettings,
} from '@obeta/utils/lib/settingsConverters'
import { TPriceData } from '@obeta/models/lib/models/Settings/Settings'
import styles from './SettingsPage.module.scss'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'

export const PriceSettings = () => {
  const { t } = useTranslation()
  const dataSheetPriceInfo = useGetDataSheetPriceInfo()
  const updateDataSheetPriceInfo = useSaveDataSheetPriceInfo()

  const methods = useForm<TPriceData>({ mode: 'onBlur' })
  const { reset, handleSubmit } = methods

  useEffect(() => {
    if (!dataSheetPriceInfo) return

    const initialPriceSettings = convertInitialPriceSettings(dataSheetPriceInfo)
    reset(initialPriceSettings)
  }, [reset, dataSheetPriceInfo])

  const onReset = () => {
    if (!dataSheetPriceInfo) {
      reset()
    } else {
      const initialPriceSettings = convertInitialPriceSettings(dataSheetPriceInfo)
      reset(initialPriceSettings)
    }
  }

  const onSubmit = async (data: TPriceData) => {
    try {
      const priceSettingsToSubmit = convertPriceSettingsToSubmit(data)

      const response = await updateDataSheetPriceInfo(priceSettingsToSubmit)

      getEventSubscription().next({
        type: EventType.Toast,
        notificationType: NotificationType.Success,
        id: `onFirmDataSettingsSubmitSuccess--${new Date().getTime()}`,
        options: { heading: t('SETTINGS.PRICE_INFORMATION_SAVE_SUCCESS'), body: '' },
      })

      if (response) reset(data)
    } catch (_err) {
      getEventSubscription().next({
        type: EventType.Toast,
        notificationType: NotificationType.Error,
        id: `onFirmDataSettingsSubmitSuccess--${new Date().getTime()}`,
        options: { heading: t('SETTINGS.PRICE_INFORMATION_SAVE_ERROR'), body: '' },
      })
    }
  }

  return (
    <div className={styles.content}>
      <Typography variant="bodyBold" mt={{ lg: 0.5 }} mb={{ lg: 0.5 }}>
        {t('SETTINGS.PRICE_DATA_SECTION.HEADER')}
      </Typography>
      <FormProvider {...methods}>
        <PrintOptionIndividualForm isUsedOnSettingsPage={true} />
        <FormActionFooter onSubmit={handleSubmit(onSubmit)} onReset={onReset} />
      </FormProvider>
    </div>
  )
}
