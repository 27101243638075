import React, { FC } from 'react'
import Grid from '@mui/material/Grid2'

type Props = {
  children: JSX.Element[]
  size?: { xs?: number; sm?: number; md?: number; lg?: number }
}
const SPACING = { xs: 1, sm: 1, md: 1.25, lg: 1 }
const SIZE = { xs: 12, sm: 4, md: 3, lg: 3 }

export const CardGrid: FC<Props> = ({ children, size }) => (
  <Grid container columnSpacing={SPACING} rowSpacing={SPACING}>
    {children.map((item) => {
      return <Grid size={{ ...SIZE, ...size }}>{item}</Grid>
    })}
  </Grid>
)
