import dayjs, { Dayjs } from 'dayjs'
import { TPriceData, IFirmData } from '@obeta/models/lib/models/Settings/Settings'
import {
  CustomerCompanyDataModel,
  CustomerCompanyDataModelInput,
  DatasheetPriceModeEnum,
  DatasheetPriceModel,
  DatasheetPriceTypeEnum,
} from 'graphql-codegen'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import {
  OfferPriceOptions,
  PercentagePriceBaseValueOption,
  ShowPricesWithVAT,
} from '@obeta/models/lib/models'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const convertStringToTime = (timeString?: string) => {
  return timeString ? dayjs(timeString, 'HH:mm') : null
}

const convertTimeToString = (time: Dayjs | null) => {
  return time ? time.format('HH:mm') : ''
}

export const convertInitialFirmDataSettings = (data: CustomerCompanyDataModel): IFirmData => {
  const openingTimes = Array.from({ length: 7 }).map((_, dayIndex) => {
    const openingHoursForCurrentDay = data.openingHours.find((day) => day.weekday === dayIndex)

    return {
      weekday: dayIndex,
      from: convertStringToTime(openingHoursForCurrentDay?.from),
      to: convertStringToTime(openingHoursForCurrentDay?.to),
    }
  })

  return {
    ...data,
    openingHours: openingTimes,
    /* TODO: API does not return the name of the previously saved file, only the link; therefore, the company name is used instead */
    companyLogo: data.companyLogo ? data.companyName : null,
  }
}

export const convertFirmDataSettingsToSubmit = (data: IFirmData): CustomerCompanyDataModelInput => {
  const { companyLogo, openingHours, ...rest } = data
  const openingHoursToSubmit = openingHours.map(({ weekday, from, to }) => ({
    weekday,
    from: convertTimeToString(from),
    to: convertTimeToString(to),
  }))

  return { ...rest, openingHours: openingHoursToSubmit }
}

export const validateTimeRange = (valueFrom: Dayjs | null, valueTo: Dayjs | null): boolean => {
  if (!valueFrom || !valueTo) return true
  if (valueFrom.isSameOrAfter(valueTo)) return false

  return true
}

const priceModeMap: Record<DatasheetPriceModeEnum | 'fixed', OfferPriceOptions> = {
  None: OfferPriceOptions.NoSumPrice,
  Price: OfferPriceOptions.Price,
  PercentBasedAddition: OfferPriceOptions.Percentage,
  fixed: OfferPriceOptions.Fixed,
}

const priceOptionMap: Omit<
  Record<OfferPriceOptions, DatasheetPriceModeEnum>,
  OfferPriceOptions.Fixed
> = {
  [OfferPriceOptions.NoSumPrice]: 'None',
  [OfferPriceOptions.Price]: 'Price',
  [OfferPriceOptions.Percentage]: 'PercentBasedAddition',
}

const percentBasedPriceTypeMap: Record<DatasheetPriceTypeEnum, PercentagePriceBaseValueOption> = {
  Price: PercentagePriceBaseValueOption.Net,
  ListPrice: PercentagePriceBaseValueOption.List,
  CatalogPrice: PercentagePriceBaseValueOption.Catalog,
}

const percentageBaseMap: Record<PercentagePriceBaseValueOption, DatasheetPriceTypeEnum> = {
  [PercentagePriceBaseValueOption.Net]: 'Price',
  [PercentagePriceBaseValueOption.List]: 'ListPrice',
  [PercentagePriceBaseValueOption.Catalog]: 'CatalogPrice',
}

export const convertPriceMode = (priceMode: DatasheetPriceModeEnum): OfferPriceOptions => {
  return priceModeMap[priceMode]
}

export const convertPriceOption = (priceOption: OfferPriceOptions): DatasheetPriceModeEnum => {
  return priceOptionMap[priceOption]
}

export const convertPercentBasedPriceType = (
  type: DatasheetPriceTypeEnum
): PercentagePriceBaseValueOption => {
  return percentBasedPriceTypeMap[type]
}

export const convertPercentageBaseOption = (
  percentageBaseOption?: PercentagePriceBaseValueOption
): DatasheetPriceTypeEnum => {
  return percentageBaseMap[percentageBaseOption as PercentagePriceBaseValueOption]
}

export const convertInitialPriceSettings = (data: DatasheetPriceModel): TPriceData => {
  const { includeVAT, pricePart, offerPrice } = data
  const convertedData = {
    showPricesWithVAT: includeVAT ? ShowPricesWithVAT.Inclusive : ShowPricesWithVAT.Exclusive,
    priceType: {
      listPrice: pricePart.showListPrice,
      catalogPrice: pricePart.showCatalogPrice,
      metalSurcharge: pricePart.showMetalAddition,
    },
    offerPrice: {
      option: convertPriceMode(offerPrice.priceMode),
      fixedPriceValue: '',
      percentagePriceBaseValue: convertPercentBasedPriceType(offerPrice.percentBasedPrice.type),
      priceMarkup: offerPrice.percentBasedPrice.percent,
    },
  }

  return convertedData
}

export const convertPriceSettingsToSubmit = (data: TPriceData): DatasheetPriceModel => {
  const { showPricesWithVAT, priceType, offerPrice } = data
  const convertedData = {
    includeVAT: showPricesWithVAT === ShowPricesWithVAT.Inclusive,
    pricePart: {
      showListPrice: !!priceType.listPrice,
      showCatalogPrice: !!priceType.catalogPrice,
      showMetalAddition: !!priceType.metalSurcharge,
    },
    offerPrice: {
      priceMode: convertPriceOption(offerPrice?.option || OfferPriceOptions.Price),
      percentBasedPrice: {
        type: convertPercentageBaseOption(offerPrice?.percentagePriceBaseValue),
        percent: offerPrice?.priceMarkup ? +offerPrice?.priceMarkup : 0,
      },
    },
  }

  return convertedData
}
