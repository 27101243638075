import { ProjectDetailsProject } from '@obeta/models/lib/schema-models/project'
import { removeTypename } from '@obeta/utils/lib/removeTypename'

export const mapProjectToInput = (project: ProjectDetailsProject) => {
  const { companyId, customerId, ...projectWithoutOutputFields } = project
  return removeTypename({
    ...projectWithoutOutputFields,
    shippingData: projectWithoutOutputFields.shippingData
      ? {
          ...projectWithoutOutputFields.shippingData,
          addressId: projectWithoutOutputFields.shippingData.addressId ?? '',
          shippingType: projectWithoutOutputFields.shippingData.shippingType as string,
          storeId: projectWithoutOutputFields.shippingData.storeId ?? '',
          shippingDate: '',
          isCompleteDelivery: false,
        }
      : undefined,
  })
}
