import checkoutSuccessStyles from '../shoppingcart/CheckoutOrderSuccess.module.scss'
import orderPendingStyles from '../shoppingcart/CheckoutOrderPending.module.scss'
import styles from './UserRegistrationDialog.module.scss'
import { DarkButton } from '../custom-button/CustomButton'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ShopNameByDomain } from '@obeta/utils/lib/shopInfoByDomain'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'

export const UserRegistrationSuccess = ({ domain }) => {
  const { redirectToStartPage } = useRedirect()
  const { t } = useTranslation()

  const getButtonText = () => {
    return t('SIGNUP.SUCCESS_MODAL.EXPLORE_DOMAIN', { domain: ShopNameByDomain[domain] })
  }
  return (
    <div className={styles.root}>
      <div className={orderPendingStyles.animationMainContainer}>
        <div className={checkoutSuccessStyles.circleLoader}>
          <div className={checkoutSuccessStyles.checkMark} />
        </div>
      </div>
      <div className={styles.textBlock}>
        <div className={styles.headlines}>
          <Typography id="modal-heading" variant={'headline2Bold'}>
            {t('SIGNUP.SUCCESS_MODAL.CONGRATS')}
          </Typography>
          <Typography id="modal-description" variant={'h3'}>
            {t('SIGNUP.SUCCESS_MODAL.DOCUMENTS_WILL_BE_CHECKED')}
          </Typography>
        </div>
        <div className={styles.body}>
          <Typography variant={'body'}>
            {t('SIGNUP.SUCCESS_MODAL.MYRIAD_FUNCTIONS_AND_SERVICES')}
          </Typography>
          <Typography variant={'body'}>{t('SIGNUP.SUCCESS_MODAL.ENJOY_YOUR_TIME')}</Typography>
          <Typography variant={'body'}>
            {t('SIGNUP.SUCCESS_MODAL.THANKS_FOR_YOUR_TRUST')}
          </Typography>
        </div>
      </div>
      <DarkButton type="submit" fullWidth={false} onClick={redirectToStartPage}>
        {getButtonText()}
      </DarkButton>
    </div>
  )
}
