import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import {
  createUserAddressGraphQL,
  CreateUserAddressGraphQLResultAction,
  deleteUserAddressGraphQL,
  updateUserAddressGraphQL,
  UpdateUserAddressGraphQLResultAction,
  UserActionTypes,
} from '../../actions'
import { useActionNotification } from '../useActionNotification'

export const useAddressCRUDActions = () => {
  const dispatch = useDispatch()

  const waitForCreateUserAddressResultAction = useActionNotification(
    UserActionTypes.CreateUserAddressGraphQLResult
  )
  const waitForUpdateUserAddressResultAction = useActionNotification(
    UserActionTypes.UpdateUserAddressGraphQLResult
  )
  const createUserAddress = useCallback(
    (address: AddressV2, customName: string, onSuccess: (addressId: string) => void) => {
      dispatch(createUserAddressGraphQL(address, customName))
      waitForCreateUserAddressResultAction((action: CreateUserAddressGraphQLResultAction) => {
        if (action.success && action.addressId) {
          onSuccess(action.addressId)
        }
      })
    },
    [dispatch, waitForCreateUserAddressResultAction]
  )

  const deleteUserAddress = useCallback(
    (addressId: string) => {
      dispatch(deleteUserAddressGraphQL(addressId))
    },
    [dispatch]
  )

  const updateUserAddress = useCallback(
    (
      addressId: string,
      address: AddressV2,
      customName: string,
      onSuccess: (addressId: string) => void
    ) => {
      dispatch(updateUserAddressGraphQL(addressId, address, customName))
      waitForUpdateUserAddressResultAction((action: UpdateUserAddressGraphQLResultAction) => {
        if (action.success) {
          onSuccess(addressId)
        }
      })
    },
    [dispatch, waitForUpdateUserAddressResultAction]
  )
  return {
    createUserAddress,
    updateUserAddress,
    deleteUserAddress,
  }
}
