import { useCallback } from 'react'
import {
  getURLSearchParamsByLocationSearch,
  updateURLSearchParams,
} from '@obeta/utils/lib/virtualized-list'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { safeParseInt } from '@obeta/utils/lib/safeParseInt'
import { useHistory } from '../useHistoryApi'

const URL_QUERY_PARAMETERS = ['orderDir', 'orderBy', 'searchTerm', 'page'] as const

type ProjectOverviewURLSearchParams = {
  [K in typeof URL_QUERY_PARAMETERS[number]]?: string
}

export const useProjectOverviewUrlHandling = () => {
  const history = useHistory()
  const { searchTerm, page } = getURLSearchParamsByLocationSearch<ProjectOverviewURLSearchParams>(
    history.location.search
  )

  const setUrl = useCallback(
    (params) => {
      const urlQueryParameters = getURLSearchParamsByLocationSearch<ProjectOverviewURLSearchParams>(
        history.location.search,
        URL_QUERY_PARAMETERS
      )
      updateURLSearchParams(
        { ...urlQueryParameters, ...params },
        history,
        ShopRoutes.ProjectOverview
      )
    },
    [history]
  )
  const pageFromUrl = useCallback(() => {
    const parsedPage = safeParseInt(page)
    return parsedPage === 0 ? 1 : parsedPage
  }, [page])
  const setSearchTerm = useCallback(
    (value: string) => setUrl({ searchTerm: value, page: 1 }),
    [setUrl]
  )
  const setPage = useCallback((value: number) => setUrl({ page: value }), [setUrl])

  return {
    page: pageFromUrl(),
    setPage,
    searchTerm: searchTerm ?? '',
    setSearchTerm,
  }
}
