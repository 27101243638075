import { ErrorCode, FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Typography } from '@mui/material'
import { FileUpload } from '@obeta/components/lib/file-upload/FileUpload'
import { useBreakpoints } from '@obeta/data/lib/hooks'
import { useLogoUpload } from './useLogoUpload'
import { IFirmData } from '@obeta/models/lib/models/Settings/Settings'

const MAX_FILE_SIZE = 1 * 1024 * 1024 // 1 MB

enum EFileErrors {
  FILE_NAME_TOO_LONG = 'FileNameTooLong',
  FILE_INVALID_TYPE = 'FileInvalidType',
  FILE_TOO_LARGE = 'FileTooLarge',
  FILE_ERROR = 'FileError',
}

export const UploadLogoSection = () => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<IFirmData>()
  const { fileIsUploading } = useLogoUpload()
  const companyLogo = watch('companyLogo')

  const uploadedFilesToDisplay = companyLogo
    ? [companyLogo instanceof File ? companyLogo : new File([], `${companyLogo}.jpg`)]
    : []

  const onDeleteFile = () => {
    setValue('companyLogo', null, { shouldDirty: true })
  }

  const handleFileAccepted = async (acceptedFiles: File[], onChange: (file?: File) => void) => {
    const acceptedLogo = acceptedFiles[0]
    if (acceptedLogo.name.length > 20) {
      setError('companyLogo', {
        type: EFileErrors.FILE_NAME_TOO_LONG,
        message: t('SETTINGS.FIRM_DATA_SECTION.ERRORS.FILE_NAME_TOO_LONG'),
      })
      onChange(undefined)
    } else {
      clearErrors('companyLogo')
      onChange(acceptedLogo)
    }
  }

  const onDropRejected = (fileRejections: FileRejection[]) => {
    setValue('companyLogo', null)
    const { message, code } = fileRejections[0].errors[0]

    switch (code) {
      case ErrorCode.FileInvalidType:
        setError('companyLogo', {
          type: EFileErrors.FILE_INVALID_TYPE,
          message: t('SETTINGS.FIRM_DATA_SECTION.ERRORS.FILE_INVALID_TYPE'),
        })
        break

      case ErrorCode.FileTooLarge:
        setError('companyLogo', {
          type: EFileErrors.FILE_TOO_LARGE,
          message: t('SETTINGS.FIRM_DATA_SECTION.ERRORS.FILE_TOO_LARGE'),
        })
        break

      default:
        setError('companyLogo', {
          type: EFileErrors.FILE_ERROR,
          message: t('SETTINGS.FIRM_DATA_SECTION.ERRORS.FILE_ERROR'),
        })
        console.error(message)
        break
    }
  }

  return (
    <>
      <Typography paragraph variant="smallText" mb={0.75}>
        {t('SETTINGS.FIRM_DATA_SECTION.UPLOAD_LOGO_DESCRIPTION')}{' '}
        {!desktop && t('SETTINGS.FIRM_DATA_SECTION.UPLOAD_LOGO_MAX_SIZE')}
      </Typography>
      <Controller
        control={control}
        name="companyLogo"
        defaultValue={null}
        render={({ field: { onChange } }) => (
          <FileUpload
            accept={{
              'image/jpeg': ['.jpeg', '.jpg'],
              'image/png': ['.png'],
            }}
            buttonText={companyLogo ? t('SETTINGS.FIRM_DATA_SECTION.REPLACE_LOGO') : undefined}
            files={uploadedFilesToDisplay}
            disabled={fileIsUploading}
            disableWhiteContainer
            maxSize={MAX_FILE_SIZE}
            error={errors?.companyLogo?.message as string}
            onDeleteFile={onDeleteFile}
            onDropAccepted={(acceptedFiles: File[]) => handleFileAccepted(acceptedFiles, onChange)}
            onDropRejected={onDropRejected}
          />
        )}
      />
    </>
  )
}
