import React, { useState } from 'react'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { Header } from './Header'
import { TSettingsView, ESettingsSection } from './types'
import { Iframe } from '@obeta/components/lib/legacy-shop/Iframe'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { IndividualDatasheetView } from './settingsPageViews/IndividualDatasheetView/IndividualDatasheetView'
import { OrderConfirmationSettings } from './OrderConfirmationSettings'

export const SettingsPage: React.FC = () => {
  useAuthenticatedRoute()

  const isNewCompanySettings = useFeatureToggle('UseNewCompanySettings')
  const legacyRoute = 'benutzer/einstellungen'

  return isNewCompanySettings ? <SettingsTabPage /> : <Iframe legacyShopPageUrl={legacyRoute} />
}

export const SettingsTabPage = () => {
  const [activeView, setActiveView] = useState<TSettingsView>(ESettingsSection.DATA_FOR_PRINT)

  const getPageContent = () => {
    switch (activeView) {
      case ESettingsSection.DATA_FOR_PRINT:
        return <IndividualDatasheetView />
      case ESettingsSection.ORDER_NOTIFICATION:
        return <OrderConfirmationSettings />
      default:
        return null
    }
  }

  return (
    <>
      <Header activeView={activeView} setActiveView={setActiveView} />
      {getPageContent()}
    </>
  )
}
