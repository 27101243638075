/**
 * We need symbol observable polyfill to make all "rxjs" versions compatible.
 * TODO: Looks like we have several "rxjs" packages included in bundle.
 * One of the "rxjs" is imported before 'symbol-ovservable'.
 * And other after. Because of this they stop being comatible.
 * ('symbol-observable' is used to validate observables)
 * the fact that we have several "rxjs" in bundle is BUG.
 * We need to analyze bundle and find soltion
 */
import 'symbol-observable'
import 'intersection-observer'
import ReactDOM from 'react-dom'
import { App } from './App'
import { AppLogoutWrapper } from './common/wrappers/AppLogoutWrapper'
import * as serviceWorker from './serviceWorker'
import { createStore, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { Provider } from 'react-redux'
import { getCollectionSync } from '@obeta/app-bootstrap/lib/bootstrap'
import { bootstrapDatabase, syncDataWithDatabase } from '@obeta/app-bootstrap/lib/bootstrap'
import { boostrapRootEpic } from '@obeta/app-bootstrap/lib/bootstrapEpics'
import { Provider as RxDBProvider } from 'rxdb-hooks'
import { AppActions, Tokens } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { IDbUtils } from '@obeta/models/lib/models'
import { MuiThemeProvider } from '@obeta/components/lib/mui-theme-provider-5'
import { SecureStoragePlugin } from '@atroo/capacitor-secure-storage-plugin'
import { ApolloProvider } from '@apollo/client'
import { initAppActions } from '@obeta/app-bootstrap/lib/bootstrapAppActions'
import { initApollo } from '@obeta/app-bootstrap/lib/apolloClient'
import { AppActionsContext } from '@obeta/data/lib/hooks/useAppActions'
import { setMobiscrollOptions } from '@obeta/utils/lib/setMobiscrollOptions'
import { RxError } from 'rxdb'
import { isAuthenticationErrorSync } from '@obeta/utils/lib/isAuthenticationError'
import { createEmotionCache } from '@obeta/utils/lib/createEmotionCache'
import { CacheProvider } from '@emotion/react'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { GraphQLError } from 'graphql'
import { datadogRum } from '@datadog/browser-rum'
import { DbUtilsContext } from '@obeta/data/lib/hooks/useDbUtils'
import { ErrorBoundary } from '@obeta/components/lib/error-boundary/ErrorBoundary'
import { StartPageDataProvider } from '@obeta/data/lib/hooks/useStartPageDataProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { bootstrapBusinessLogic, BusinessLayerContext } from '@obeta/app-bootstrap'

if (process.env.REACT_APP_DATADOG_APPLICATION_ID && process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
    version: process.env.REACT_APP_DATADOG_VERSION,
    site: 'datadoghq.eu',
    service: 'obeta-shop-frontends:app',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowFallbackToLocalStorage: true,
    defaultPrivacyLevel: 'allow',
    enableExperimentalFeatures: ['clickmap', 'feature_flags'],
    allowedTracingUrls: [
      {
        match: process.env.REACT_APP_PUBLIC_TRACING_REGEX
          ? new RegExp(process.env.REACT_APP_PUBLIC_TRACING_REGEX)
          : '',
        propagatorTypes: ['tracecontext'],
      },
    ],
  })

  datadogRum.startSessionReplayRecording()
}

let appActions: AppActions

const epicMiddleware = createEpicMiddleware()
const store = createStore(() => {
  // We use the store only to adhere to the dispatch approach (redux-observable) when working with rxJS at the moment.
}, applyMiddleware(epicMiddleware))

Promise.all([
  bootstrapDatabase(isPlatform('web'), false),
  SecureStoragePlugin.get({ key: 'auth' })
    .catch((err) => {
      return Promise.resolve({ value: null })
    })
    .then(({ value }) => {
      let authObj: Tokens | null = null
      if (value) {
        authObj = JSON.parse(value)
      }
      return authObj
    }),
])
  .then(async ([database, tokens]) => {
    appActions = initAppActions(database, tokens, store)
    const apolloClient = initApollo(
      process.env.REACT_APP_NEXT_PUBLIC_GRAPHQL_HOST as string,
      appActions,
      false,
      database
    )

    const errorHandler = (err: RxError) => {
      if (!Array.isArray(err.parameters?.errors)) {
        datadogRum.addError(err)
        return
      }

      const accessDeniedError = err.parameters.errors.find((errObj) => {
        /** errObj can actually be a GraphQLError */
        return isAuthenticationErrorSync(errObj as unknown as GraphQLError)
      })

      accessDeniedError ? appActions.requestNewToken() : datadogRum.addError(err)
    }

    const BusinessLayer = await bootstrapBusinessLogic(appActions, database, apolloClient, {
      baseUrl: process.env.REACT_APP_NEXT_PUBLIC_GRAPHQL_HOST as string,
      token: tokens?.accessToken,
      entities: [
        'message',
        'carttemplates',
        'storesv2',
        'offersv2',
        'cartsv2',
        'useraddressesv2',
        'openpositions',
      ],
      errorHandler,
    })

    const { rootEpic, epic$ } = boostrapRootEpic(database, apolloClient, getCollectionSync)
    const dbUtils: IDbUtils = {
      epicInit: epic$,
      syncDataWithDatabase,
      getCollectionSync,
    }

    epicMiddleware.run(rootEpic)

    appActions.tokens$.subscribe((tokens) => {
      BusinessLayer.startSync()
    })

    setMobiscrollOptions()
    const cache = createEmotionCache()

    ReactDOM.render(
      <ErrorBoundary>
        <CacheProvider value={cache}>
          <MuiThemeProvider>
            <StartPageDataProvider>
              <BusinessLayerContext.Provider value={BusinessLayer}>
                <RxDBProvider db={database}>
                  <DbUtilsContext.Provider value={dbUtils}>
                    <AppActionsContext.Provider value={appActions}>
                      <Provider store={store}>
                        <ApolloProvider client={apolloClient}>
                          <AppLogoutWrapper>
                            <>
                              <CssBaseline />
                              <App />
                            </>
                          </AppLogoutWrapper>
                        </ApolloProvider>
                      </Provider>
                    </AppActionsContext.Provider>
                  </DbUtilsContext.Provider>
                </RxDBProvider>
              </BusinessLayerContext.Provider>
            </StartPageDataProvider>
          </MuiThemeProvider>
        </CacheProvider>
      </ErrorBoundary>,
      document.getElementById('root')
    )
  })
  .catch((e: Error) => {
    datadogRum.addError(e)
  })

serviceWorker.unregister()
