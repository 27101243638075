import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './OrderConfirmationSettings.module.scss'
import { TextField, Typography } from '@mui/material'
import { FormActionFooterLine } from './FormActionFooter'
import clsx from 'clsx'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { isValidEmail } from '@obeta/utils/lib/isValidEmail'
import { useUserToUpdate } from '@obeta/data/lib/hooks/useUserToUpdate'
import { removeTypename } from '@obeta/utils/lib/removeTypename'
import { UserInputV2 } from '@obeta/schema'
import { buildUserId } from '@obeta/utils/lib/buildUserId'
import CircularProgress from '@mui/material/CircularProgress'

export const OrderConfirmationSettings: FC = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  // TODO: check why subUserId is used here but not in UserInputV2 schema anymore
  const [completeUser, setCompleteUser] = useState<(UserInputV2 & { subUserId: string }) | null>(
    null
  )
  const [isDirty, setIsDirty] = useState(false)
  const prevSettings = useRef<string | null>(null)
  const { getUserToUpdate, updateUserComplete } = useUserToUpdate()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUserToUpdate()
      const name = user.settings.name
      const companyId = user.companyId
      const userId = user.customerNumber
      // @ts-expect-error - field not allowed on UserInput object
      delete user.customerNumber
      // @ts-expect-error - field not allowed on UserInput object
      delete user.companyId
      // @ts-expect-error - field not allowed on UserInput object
      delete user.settings
      setCompleteUser({
        ...user,
        subUserId: buildUserId({
          userId,
          companyId,
          includeSubuser: true,
        }),
        name,
      })
      setEmail(user.orderConfirmationEmail)
      setLoading(false)
      prevSettings.current = user.orderConfirmationEmail
    }
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (prevSettings.current !== null) {
      setIsDirty(email !== prevSettings.current)
    }
  }, [email])

  const onSubmit = useCallback(() => {
    if (!isValidEmail(email) && email.length > 0) {
      getEventSubscription().next({
        type: EventType.Alert,
        notificationType: NotificationType.Error,
        id: 'invalid-email',
        options: {
          message: t('SETTINGS.ORDER_CONFIRMATION_SECTION.INVALID_EMAIL_ERROR'),
          id: 'invalid-email',
          duration: 8000,
        },
      })
      return
    }
    if (completeUser !== null) {
      const value = removeTypename({ ...completeUser, orderConfirmationEmail: email })
      updateUserComplete(value, () => {
        prevSettings.current = email
        setIsDirty(false)
      })
    }
  }, [email, t, updateUserComplete, completeUser])

  const onReset = useCallback(() => {
    if (prevSettings.current !== null) {
      setEmail(prevSettings.current)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={clsx(styles.container, styles.card)}>
        <Typography className={styles.infoText}>
          {t('SETTINGS.ORDER_CONFIRMATION_SECTION.INFO')}
        </Typography>
        <div className={styles.formFields}>
          {loading ? (
            <CircularProgress size={16} />
          ) : (
            <TextField
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className={styles.emailInput}
              placeholder={t('SETTINGS.ORDER_CONFIRMATION_SECTION.EMAIL_INPUT_PLACEHOLDER')}
            />
          )}
        </div>
      </div>
      <FormActionFooterLine isDirty={isDirty} errors={{}} onReset={onReset} onSubmit={onSubmit} />
    </div>
  )
}
