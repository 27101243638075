import React, { memo, ReactElement } from 'react'
import { ReactComponent as AdminPanelSettingsIcon } from 'assets/icon/designsystem/admin_panel_settings.svg'
import { ReactComponent as CartIcon } from 'assets/icon/designsystem/cart.svg'
import { ReactComponent as RuleFolderIcon } from 'assets/icon/designsystem/rule_folder.svg'
import { ReactComponent as PaymentsIcon } from 'assets/icon/designsystem/payments.svg'
import { ReactComponent as LocalShippingIcon } from 'assets/icon/designsystem/local_shipping.svg'
import styles from './OverviewTablePermissionsIcons.module.scss'
import { Tooltip } from '../tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
import { UserPermissions } from '@obeta/models/lib/schema-models/userV2-subusers'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'

interface PropsOverviewTablePermissionsIcons {
  permissions: UserPermissions
  userActive: boolean
}
export const OverviewTablePermissionsIcons: React.FC<PropsOverviewTablePermissionsIcons> = memo(
  (props) => {
    const { permissions, userActive } = props
    const iconsList: ReactElement[] | null = []
    const { t } = useTranslation()

    if (permissions) {
      if (
        permissions.Global_canSeeUserManagement ||
        permissions.Address_canWrite ||
        permissions.Projects_canManage
      ) {
        iconsList.push(
          <Tooltip
            key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ADMINISTRATION')}
            title={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ADMINISTRATION')}
          >
            <AdminPanelSettingsIcon className={!userActive ? styles.grayedOut : ''} />
          </Tooltip>
        )
      }
      if (
        permissions.Global_canReadPrices !== PricePermissions.NoPrice ||
        permissions.Global_canReadAccountInfo ||
        permissions.Global_showCreditWarning
      ) {
        iconsList.push(
          <Tooltip
            key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.PRICES_AND_ACCOUNTING')}
            title={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.PRICES_AND_ACCOUNTING')}
          >
            <PaymentsIcon className={!userActive ? styles.grayedOut : ''} />
          </Tooltip>
        )
      }
      if (
        permissions.Global_canUseCustomDataSheet ||
        permissions.Offers_canRead ||
        permissions.Orders_canRead
      ) {
        iconsList.push(
          <Tooltip
            key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.OTHER_FEATURES')}
            title={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.OTHER_FEATURES')}
          >
            <RuleFolderIcon className={!userActive ? styles.grayedOut : ''} />
          </Tooltip>
        )
      }
      if (
        permissions.Cart_canChangePayment ||
        permissions.Cart_canWriteAddress ||
        permissions.Cart_canWriteAdditionalText ||
        permissions.Cart_canWriteCommission ||
        permissions.Cart_requiredCommission
      ) {
        iconsList.push(
          <Tooltip
            key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_DETAILS')}
            title={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_DETAILS')}
          >
            <LocalShippingIcon className={!userActive ? styles.grayedOut : ''} />
          </Tooltip>
        )
      }
      if (permissions.Cart_allowedSubmitAction !== 'None') {
        iconsList.push(
          <Tooltip
            key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.CART_ORDERABLE')}
            title={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.CART_ORDERABLE')}
          >
            <CartIcon className={!userActive ? styles.grayedOut : ''} key={'shoppingCartIcon'} />
          </Tooltip>
        )
      }
    }
    return <div className={styles.wrapperIconsList}>{iconsList}</div>
  }
)
