import clsx from 'clsx'
import { IThumbProps } from './types'
import styles from './Thumb.module.scss'
import { useImgProxyUrls } from '@obeta/data/lib/hooks/useImgProxyUrls'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'

export const idAttr = 'data-id'
export const getThumbId = (id: string | number) => {
  return `img-gallery-thumb-${id}`
}

export const Thumb: React.FC<IThumbProps> = (props) => {
  const { className, selected, thumbUrl, label, thumbId, index, size, ...rest } = props

  const { tablet } = useBreakpoints()

  const { imgProxyUrlMobile, imgProxyUrlTablet } = useImgProxyUrls({
    url: thumbUrl,
    mobileWidth: 48,
    tabletWidth: 120,
  })

  let backgroundImageUrl = ''
  const validUrl = ensureValidImgProxyUrl(thumbUrl)
  if (validUrl) {
    if (tablet) {
      backgroundImageUrl = imgProxyUrlTablet
    } else {
      backgroundImageUrl = imgProxyUrlMobile
    }
  } else {
    backgroundImageUrl = thumbUrl
  }

  return (
    <div className={clsx(styles.root, styles[`size${size}`])} {...{ [idAttr]: getThumbId(index) }}>
      <div
        {...rest}
        className={clsx(styles.thumbWrap, { [styles.selectedThumb]: selected }, className)}
      >
        <div
          className={styles.thumb}
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
          }}
        ></div>
        {label}
      </div>
    </div>
  )
}
