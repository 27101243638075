import { FC, useEffect, useRef } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { CustomEditor, EPlugins } from '../reactPageEditor/CustomEditor'
import { useStaticPage } from '@obeta/data/lib/hooks/useStaticPage'
import styles from './staticPage.module.scss'
import { PageIds, ShopRoutes } from '@obeta/utils/lib/variables'
import { useHistory, useLocation, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { useIsChromelessStaticPage } from '@obeta/data/lib/hooks'
import { useObetaResizeObserver } from '@obeta/data/lib/hooks/useObetaResizeObserver'

export const StaticPage: FC<{ page?: PageIds }> = ({ page }) => {
  const apolloClient = useStrapiGql()

  return (
    <ApolloProvider client={apolloClient}>
      <StaticPageView page={page} />
    </ApolloProvider>
  )
}

const pagesWithDomain = [ShopRoutes.AboutUs, ShopRoutes.PrivacyProtection, ShopRoutes.Terms]
const StaticPageView: FC<{ page?: PageIds }> = ({ page }) => {
  const { slug } = useParams()
  const location = useLocation()
  const history = useHistory()
  const isChromelessStaticPage = useIsChromelessStaticPage()
  const pathName = location.pathname as ShopRoutes
  const ref = useRef<HTMLDivElement>(null)

  useObetaResizeObserver(ref.current, () => {
    if (isChromelessStaticPage) {
      //in chromeless mode we are most likley running in an iframe
      // make the parent aware that our size has been updated

      window.parent.postMessage(
        { type: 'set-iframe-height', height: document.body.scrollHeight },
        '*'
      )
    }
  })

  const domain = useAppDomain()
  const pageId = pagesWithDomain.includes(pathName) ? `${page}-${domain}` : page

  const staticPage = useStaticPage(pageId, slug)

  useEffect(() => {
    if (!staticPage.loading && !staticPage.content) {
      history.replace('/404')
    }
  }, [history, staticPage.content, staticPage.loading])

  return (
    <div className={styles['wrapper']} ref={ref}>
      <CustomEditor
        value={staticPage.content}
        pluginsList={[
          EPlugins.RichText,
          EPlugins.Image,
          EPlugins.Video,
          EPlugins.Spacer,
          EPlugins.PDFLayout,
          EPlugins.ProductCardLayoutPlugin,
          EPlugins.RafflePlugin,
          EPlugins.DigitalBillingPlugin,
        ]}
      ></CustomEditor>
    </div>
  )
}
