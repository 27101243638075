import React, { FC } from 'react'
import { RadioButtonLine } from '../form/FormComponents'
import { CartWritePermission } from '@obeta/models/lib/models/Users/UserV2'
import { useTranslation } from 'react-i18next'
import { OrderPermissionGroupOption } from './OrderPermissionGroup'

export const OrderPermissionRadioNotOrderable: FC<OrderPermissionGroupOption> = ({
  allowedSubmitAction,
}) => {
  const { t } = useTranslation()

  return (
    <RadioButtonLine
      value={CartWritePermission.None}
      label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.CART_NOT_ORDERABLE')}
      tooltip={t(
        'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.CART_NOT_ORDERABLE'
      )}
    />
  )
}
