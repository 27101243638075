import React, { PropsWithChildren } from 'react'
import { IResponsiveWrapperProps, ResponsiveWrapper } from './components/ResponsiveWrapper'
import { ObetaHeader } from '../header'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { SignUpShopHeader } from '../header/SignUpHeader'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

interface IResponsiveHeaderProps extends IResponsiveWrapperProps {
  headerRef: React.RefObject<HTMLDivElement>
}

export const ResponsiveHeader: React.FC<PropsWithChildren<IResponsiveHeaderProps>> = (props) => {
  const { backdropClassName, headerRef } = props
  const { location } = useHistory()
  const isSignUpPage =
    location.pathname === ShopRoutes.SignUp || location.pathname === ShopRoutes.ActivateShopAccess

  return (
    <ResponsiveWrapper backdropClassName={backdropClassName}>
      {isSignUpPage ? <SignUpShopHeader ref={headerRef} /> : <ObetaHeader ref={headerRef} />}
    </ResponsiveWrapper>
  )
}
