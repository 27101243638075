import React, { useEffect, useRef } from 'react'
import { Divider, Typography } from '@mui/material'
import { SectionHeader } from '@obeta/components/lib/section/SectionHeader'
import { useTranslation } from 'react-i18next'
import styles from './SearchNoResults.module.scss'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { ObetaCategory } from '@obeta/models/lib/models/Category'
import { CategorySection } from '../startPage/CategorySection'
import { SaleProductSection } from '../startPage/SaleProductSection'
import { Section } from '../startPage/Section'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { ArticleSearchArea } from '@obeta/models/lib/models/Search'
import { DarkButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as SearchIcon } from 'assets/icon/designsystem/search.svg'
import { useFiltersStateContext } from './filters/hooks/useFiltersReducer'
import { trackClick } from '@obeta/utils/lib/tracking'

interface INoResultsProps {
  search: string
}

export const SearchNoResults: React.FC<INoResultsProps> = (props) => {
  const { search } = props
  const { t } = useTranslation()
  const categories = useEntities<ObetaCategory>('categories')
  const { searchParams } = useArticlesSearchProvider()
  const { dispatchFiltersAction } = useFiltersStateContext()
  const didTrackNoResultsImmediatelyAvailable = useRef(false)

  const isImmediatelyAvailableFilterSet =
    searchParams?.articleSearchArea === ArticleSearchArea.IMMEDIATELY_AVAILABLE

  useEffect(() => {
    if (isImmediatelyAvailableFilterSet && !didTrackNoResultsImmediatelyAvailable.current) {
      dispatchFiltersAction({
        type: 'selectSearchArea',
        payload: { searchArea: ArticleSearchArea.ALL },
      })
      didTrackNoResultsImmediatelyAvailable.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImmediatelyAvailableFilterSet])

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <SectionHeader
          marginBottom="md"
          dataAttributes={{ 'data-track-id': 'zeroResultsContainer' }}
        >
          {t('SEARCH.NO_RESULTS.TITLE', { searchTerm: search })}
        </SectionHeader>
        <Divider variant={'fullWidth'} className={styles.devider} />
        <Typography variant="subTitle">
          {t(
            isImmediatelyAvailableFilterSet
              ? 'SEARCH.NO_RESULTS_IMMEDIATELY_AVAILABLE_FILTER_BODY'
              : 'SEARCH.NO_RESULTS.BODY'
          )}
        </Typography>
        {isImmediatelyAvailableFilterSet && (
          <div className={styles.removeImmediatelyAvailableFilter}>
            <DarkButton
              fullWidth={false}
              leftIcon={<SearchIcon />}
              onClick={() => {
                dispatchFiltersAction({
                  type: 'selectSearchArea',
                  payload: { searchArea: ArticleSearchArea.ALL },
                })
                trackClick('no-results-search-full-range')
              }}
            >
              {t('SEARCH.SEARCH_FULL_RANGE')}
            </DarkButton>
          </div>
        )}
      </div>
      {categories.length > 0 && (
        <Section
          className={styles.resetSectionMarginAndPadding}
          title={t('SEARCH.OUR_STOCK_RANGE')}
          content={<CategorySection obetaCategory={categories} />}
        />
      )}
      <Section title={t('START.PRODUCTS')} content={<SaleProductSection />} />
    </div>
  )
}
