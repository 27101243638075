import React from 'react'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import { Typography, TextField, Box, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'

import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks'
import styles from './SignUpStepOne.module.scss'

const SignUpStepOne = ({ control, errors }) => {
  const { t } = useTranslation()
  const { mobile, tablet } = useBreakpoints()
  const theme = useTheme()

  return (
    <div
      className={clsx([
        !mobile && !tablet && styles.formContainer,
        tablet && styles.formContainerTabletWide,
        mobile && styles.formContainerMobile,
      ])}
    >
      <div className={styles.TextField}>
        <Typography variant="body">{t('COMMON.SIGNUP.FIRSTNAME')}</Typography>

        <Controller
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              fullWidth
              error={!!errors.firstName}
              variant="outlined"
              id="firstName"
              name="firstName"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              helperText={
                errors.firstName ? (
                  <Box display={'flex'}>
                    <SvgIcon
                      className={styles.helperTextIcon}
                      component={WarningIcon}
                      fontSize={'small'}
                      htmlColor={theme.palette.primary.main}
                    />
                    <Typography variant={'smallText'} color={theme.palette.primary.main}>
                      {errors.firstName.type === 'maxLength'
                        ? t('COMMON.SIGNUP.ERROR.MAX_LENGTH')
                        : ''}
                      {errors.firstName.type === 'required'
                        ? t('COMMON.SIGNUP.ERROR.FIRSTNAME')
                        : ''}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )
              }
              slotProps={{
                formHelperText: {
                  className: styles.helperTextContainer,
                },
              }}
            />
          )}
          name="firstName"
          defaultValue={''}
          control={control}
          rules={{
            required: true,
            maxLength: 35,
          }}
        />
      </div>
      <div className={styles.TextField}>
        <Typography variant="body">{t('COMMON.SIGNUP.LASTNAME')}</Typography>
        <Controller
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              fullWidth
              variant="outlined"
              id="lastName"
              name="lastName"
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.lastName}
              value={value}
              helperText={
                errors.lastName ? (
                  <Box display={'flex'}>
                    <SvgIcon
                      className={styles.helperTextIcon}
                      component={WarningIcon}
                      fontSize={'small'}
                      htmlColor={theme.palette.primary.main}
                    />
                    <Typography variant={'smallText'} color={theme.palette.primary.main}>
                      {errors.lastName.type === 'maxLength'
                        ? t('COMMON.SIGNUP.ERROR.MAX_LENGTH')
                        : ''}
                      {errors.lastName.type === 'required'
                        ? t('COMMON.SIGNUP.ERROR.FIRSTNAME')
                        : ''}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )
              }
              slotProps={{
                formHelperText: {
                  className: styles.helperTextContainer,
                },
              }}
            />
          )}
          name="lastName"
          control={control}
          defaultValue={''}
          rules={{
            required: true,
            maxLength: 35,
          }}
        />
      </div>
      <div className={styles.TextField}>
        <Typography variant="body">{t('COMMON.SIGNUP.PHONENUMBER')}</Typography>

        <Controller
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              fullWidth
              variant="outlined"
              id="phone"
              name="phone"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={!!errors.phone}
              helperText={
                errors.phone ? (
                  <Box display={'flex'}>
                    <SvgIcon
                      className={styles.helperTextIcon}
                      component={WarningIcon}
                      fontSize={'small'}
                      htmlColor={theme.palette.primary.main}
                    />
                    <Typography variant={'smallText'} color={theme.palette.primary.main}>
                      {t('COMMON.SIGNUP.ERROR.PHONENUMBER')}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )
              }
              slotProps={{
                formHelperText: {
                  className: styles.helperTextContainer,
                },
              }}
            />
          )}
          name="phone"
          control={control}
          defaultValue={''}
          rules={{
            required: true,
            minLength: 6,
            maxLength: 20,
            // Only  +, - , and digits allowed
            pattern: /^[0-9+-\s]+$/,
          }}
        />
      </div>
      <div className={styles.TextField}>
        <Typography variant="body">{t('COMMON.SIGNUP.E-MAIL')}</Typography>

        <Controller
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              fullWidth
              variant="outlined"
              id="email"
              name="email"
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.email}
              value={value}
              helperText={
                errors.email ? (
                  <Box display={'flex'}>
                    <SvgIcon
                      className={styles.helperTextIcon}
                      component={WarningIcon}
                      fontSize={'small'}
                      htmlColor={theme.palette.primary.main}
                    />
                    <Typography variant={'smallText'} color={theme.palette.primary.main}>
                      {errors.email.type === 'required' ? t('COMMON.SIGNUP.ERROR.E-MAIL') : ''}
                      {errors.email.type === 'pattern' ? t('COMMON.SIGNUP.ERROR.E-MAIL') : ''}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )
              }
              slotProps={{
                formHelperText: {
                  className: styles.helperTextContainer,
                },
              }}
            />
          )}
          name="email"
          control={control}
          defaultValue={''}
          rules={{
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: '',
            },
          }}
        />
      </div>
    </div>
  )
}

export default SignUpStepOne
