// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{margin:0;padding:0}html,body,#root,#root>div{height:100%}#oxomi-overlay{top:var(--obeta-safe-area-top-root) !important}.pageWrapper{background-color:var(--background-color);height:100%;width:100%}.oxomi-catalog-overlay-wrapper{margin-top:var(--obeta-safe-area-top-root) !important}", "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAIA,EACE,QAAA,CACA,SAAA,CAGF,0BAIE,WAAA,CAGF,eACE,8CAAA,CAGF,aACE,wCAAA,CACA,WAAA,CACA,UAAA,CAGF,+BACE,qDAAA","sourcesContent":["@import 'libs/assets/theme/coreVariablesV2.scss';\n$mbsc-material-accent: $primaryColor;\n$mbsc-frame-background-light: #fff;\n\n* {\n  margin: 0;\n  padding: 0;\n}\n\nhtml,\nbody,\n#root,\n#root > div {\n  height: 100%;\n}\n\n#oxomi-overlay {\n  top: var(--obeta-safe-area-top-root) !important;\n}\n\n.pageWrapper {\n  background-color: var(--background-color);\n  height: 100%;\n  width: 100%;\n}\n\n.oxomi-catalog-overlay-wrapper {\n  margin-top: var(--obeta-safe-area-top-root) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#e2061b",
	"primaryShade": "rgb(203.4,5.4,24.3)",
	"secondaryColor": "#3f3f3e",
	"secondaryShade": "rgb(56.7,56.7,55.8)",
	"secondaryTint": "rgb(82.404,82.404,81.096)",
	"tertiaryColor": "#fafafa",
	"tertiaryShade": "#e1e1e1",
	"tertiaryTint": "rgb(250.5,250.5,250.5)",
	"backdropHeightRem": "4.7rem",
	"backdropArticlesHeightRem": "6.5rem",
	"red100pct": "#e2061b",
	"red89pct": "#e7362b",
	"red55pct": "#f28e73",
	"red34pct": "#f8bca8",
	"orange": "#f18805",
	"orange89pct": "#f39520",
	"orange55pct": "#f7be76",
	"orange34pct": "#fad6aa",
	"darkGray": "#3f3f3e",
	"darkGray55K": "#929292",
	"darkGray34K": "#bebebe",
	"darkGray21K": "#d8d8d8",
	"lightGray": "#e7e7e7",
	"lightGray8K": "#f0f0f0",
	"lightGray5K": "#f6f6f6",
	"lightGray3K": "#fafafa",
	"green": "#27ae60",
	"black": "#000",
	"black10pct": "rgba(0,0,0,.1019607843)",
	"black15pct": "rgba(0,0,0,.1490196078)",
	"white": "#fff",
	"white50pct": "hsla(0,0%,100%,.5019607843)",
	"modalMarginThreshold": "2.5rem",
	"screenXsMin": "0",
	"screenSmMin": "768px",
	"screenMdMin": "1024px",
	"screenLgMin": "1174px",
	"slideTransitionTimeout": "300",
	"mobileHeaderHeight": "7.25rem",
	"shopMaxWidth": "78.125rem",
	"productStickyBoxHeight": "4.5rem",
	"productStickyBoxHeightMobile": "4.25rem",
	"desktopEtimItemHeight": "2.75rem",
	"listsMaxSize": "5",
	"listsGap": ".5rem"
};
export default ___CSS_LOADER_EXPORT___;
