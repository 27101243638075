import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Typography } from '@mui/material'
import { CellMeasurerCache } from 'react-virtualized'
import { OrderItemsInArrearsInput } from 'graphql-codegen'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { Checkbox } from '@obeta/components/lib/checkbox/Checkbox'
import { InfiniteWindowScroller, TInfiniteLoaderProps } from '@obeta/components/lib/window-scroller'
import styles from './orderItemsInArrearsPage.module.scss'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useHistory, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { NoResults } from '@obeta/components/lib/no-results/NoResults'
import { UnknownOrderItem } from '@obeta/components/lib/product-card'
import { useMeasuredRowRenderer } from '@obeta/data/lib/hooks/useMeasuredRowRenderer'
import { trackClick } from '@obeta/utils/lib/tracking'
import { useEntities, useScopedScrollRestorationContext } from '@obeta/data/lib/hooks'

import {
  MultiSelectionString,
  ShoppingCartForDropdown,
  ShoppingCartV2,
} from '@obeta/models/lib/models'
import { useOrderDetailsInArrearsContext } from '@obeta/data/lib/stores/useOrderDetailsInArrearsContext'
import { SearchField } from '@obeta/components/lib/search-field/SearchField'
import { ListSkeletons } from '@obeta/components/lib/list-skeletons/ListSkeletons'
import { OrderDetailsInArrearsSelectionBar } from '@obeta/components/lib/orders/OrderDetailsInArrearsSelectionBar'
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useOrderItemWithOrderDataSearch } from '@obeta/data/lib/hooks/useOrderItemWithOrderDataSearch'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'
import {
  DEFAULT_ORDER_DETAILS_IN_ARREARS_FILTERS,
  DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS,
  OrderDetailsInArrearsFilters,
  OrderDetailsInArrearsURLSearchParams,
} from '@obeta/models/lib/models/Orders'
import { createOrdersInputByQueryParamsAndURLSearchParams } from '@obeta/utils/lib/order-items-in-arrears-helpers'
import { TextBubble } from '@obeta/components/lib/text-bubble/TextBubble'
import { ReactComponent as ArrowForwardIcon } from '@obeta/assets/icon/designsystem/arrow_forward.svg'
import { OrderItemWrapper } from '@obeta/components/lib/orders/OrderItemWrapper'
import {
  getURLSearchParamsByLocationSearch,
  getURLSearchParamsValue,
  updateURLSearchParams,
} from '@obeta/utils/lib/virtualized-list'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { ShopRoutes } from '@obeta/utils/lib/variables'

const INITIAL_SELECTED_ORDER_ITEMS_IN_ARREARS: MultiSelectionString = {
  selectAll: false,
  include: [],
  exclude: [],
  search: {
    searchTerm: '',
    filter: 'InArrears',
    orderBy: '',
    orderDir: '',
  },
}

const INITIAL_PRODUCTS_COUNT = 20 // Initial order items (products) to display on mount
const ROW_HEIGHT = 200

export const OrderItemsInArrearsPage: FC = () => {
  const cartsV2 = useCartsv2WithPricesAndStock()
  const history = useHistory()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const didInitialSkip = useRef<boolean>(false)

  const params = useParams()

  const { cellMeasureCache, lastScrollY } = useScopedScrollRestorationContext({
    scopeName: 'orderItemsInArreasPage',
    defaults: {
      cellMeasureCache: new CellMeasurerCache({
        defaultHeight: ROW_HEIGHT,
        fixedWidth: true, // measure only the height, improved performance (vertical scrolling)
        minHeight: ROW_HEIGHT, // expected minimum row height
      }),
    },
  })

  const { permissions, isLoggedIn } = useUserDataV2()
  const { t } = useTranslation()
  const { itemState, orderItems, totalItemsCount, searchTerm, setQueryParams, queryParams } =
    useOrderDetailsInArrearsContext()
  const { user } = useUserDataV2()
  const { searchOrderItems, isLoading } = useOrderItemWithOrderDataSearch()

  // Component state
  const [selectedOrderItems, setSelectedOrderItems] = useState<MultiSelectionString>({
    ...INITIAL_SELECTED_ORDER_ITEMS_IN_ARREARS,
  })

  // Create search input if history location search changes
  useDebouncedEffect(
    () => {
      // Create input object for graphql endpoint
      const urlSearchParams =
        getURLSearchParamsByLocationSearch<OrderDetailsInArrearsURLSearchParams>(
          history.location.search
        )

      const input = createOrdersInputByQueryParamsAndURLSearchParams(
        DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS,
        urlSearchParams
      )
      // Reset state
      setQueryParams(DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS)
      // Search order items by input body
      if (orderItems.length === 0 || didInitialSkip.current) {
        searchOrderItems(input)
      }
      didInitialSkip.current = true
    },
    [history.location.search],
    1000
  )

  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      setRvData({ rowCount: orderItems.length, rowTotalCount: totalItemsCount })
    }
  }, [orderItems, totalItemsCount])

  const onGoBack = useCallback(() => {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(isUiTarget('app') ? '/orders' : ShopRoutes.OrdersList)
    }
  }, [history])

  /**
   * Handler to load more order items while endless scrolling.
   * @param queryParams QueryParams
   */
  const onLoadMoreOrders = useCallback(
    (queryParams: QueryParams) => {
      const urlSearchParams =
        getURLSearchParamsByLocationSearch<OrderDetailsInArrearsURLSearchParams>(
          history.location.search
        )
      const input: OrderItemsInArrearsInput = createOrdersInputByQueryParamsAndURLSearchParams(
        queryParams,
        urlSearchParams
      )
      // Search order items by input body
      searchOrderItems(input)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.location.search, orderItems, user?.settings?.defaultStoreId]
  )

  const { desktop, mobile, tabletWide, tablet } = useBreakpoints()
  //let cardSkeletonHeight = '28.313rem'
  if (!mobile) {
    //cardSkeletonHeight = '13.25rem'
  }

  const onSelectAll = () => {
    selectedOrderItems.selectAll ? resetSelectedOrderItems() : selectAllOrderItems()
  }

  const isRowLoaded = useCallback<TInfiniteLoaderProps['isRowLoaded']>(
    ({ index }) => {
      return Boolean(orderItems[index])
    },
    [orderItems]
  )

  const onSearch = useCallback(
    (searchTerm: string) => {
      let urlParams = getURLSearchParamsByLocationSearch<OrderDetailsInArrearsURLSearchParams>(
        history.location.search
      )

      urlParams = {
        ...urlParams,
        searchTerm,
      }
      updateURLSearchParams(urlParams, history, '/order-items-in-arrears')
      resetSelectedOrderItems()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  )

  /**
   * Load more rows (order items) when InfiniteLoader threshold is reached.
   * @param params Order item list (inArrears) start and stop index
   */
  const onLoadMoreRows = useCallback(
    async () => {
      if (orderItems.length < totalItemsCount) {
        const updatedQueryParams = { ...queryParams, offset: orderItems.length.toString() }
        setQueryParams(updatedQueryParams)
        onLoadMoreOrders(updatedQueryParams)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderItems, queryParams, totalItemsCount]
  )

  const [rvData, setRvData] = useState<{
    rowCount: number
    rowTotalCount: number
  }>({
    rowCount: 0,
    rowTotalCount: 0,
  })

  const resetSelectedOrderItems = (): MultiSelectionString => {
    const deselectedOrderItems = {
      selectAll: false,
      include: [],
      exclude: [],
      search: {
        searchTerm: searchTerm,
        filter: 'InArrears',
        orderBy: '',
        orderDir: '',
      },
    }

    setSelectedOrderItems(deselectedOrderItems)
    return deselectedOrderItems
  }

  const selectAllOrderItems = (): MultiSelectionString => {
    trackClick('select-all-order-items-in-arrears')

    const allSelectedOrderItems = {
      selectAll: true,
      include: [],
      exclude: [],
      search: {
        searchTerm: searchTerm,
        filter: 'InArrears',
        orderBy: '',
        orderDir: '',
      },
    }

    setSelectedOrderItems(allSelectedOrderItems)
    return allSelectedOrderItems
  }

  const onOrderItemAmountChanged = (orderItemId: string, amount: number) => {
    trackClick('on-order-item-amount-changed', {
      orderId: params.id,
      orderItemId,
      amount,
    })
    const updatedModifiedOrderItemAmounts = [...modifiedOrderItemAmounts]
    const idx = updatedModifiedOrderItemAmounts.findIndex((el) => el.orderItemId === orderItemId)

    if (idx >= 0) {
      updatedModifiedOrderItemAmounts[idx] = {
        orderItemId: orderItemId,
        amount: amount,
      }
    } else {
      updatedModifiedOrderItemAmounts.push({
        orderItemId: orderItemId,
        amount: amount,
      })
    }
    setModifiedOrderItemAmounts(updatedModifiedOrderItemAmounts)
  }

  const onSelectOrderItem = (orderItemId: string) => {
    selectOrderItem(
      orderItemId,
      // if search term or status filter applied we consider a subset of items to be ALL, hence the following logic
      searchTerm !== '' || itemState !== 'all' ? totalItemsCount : totalItemsCount ?? 0,
      selectedOrderItems,
      setSelectedOrderItems,
      selectAllOrderItems,
      resetSelectedOrderItems
    )
  }

  const shoppingCartsToMoveOrderItemsTo: ShoppingCartForDropdown[] = []
  cartsV2.forEach((cartV2) => {
    const shoppingCartForDropdown: ShoppingCartForDropdown = {
      id: cartV2.id,
      name: cartV2.name,
      articleCount: cartV2.items.length,
      offerId: cartV2.offerId,
      count: cartV2.items.length,
    }
    shoppingCartsToMoveOrderItemsTo.push(shoppingCartForDropdown)
  })

  const [modifiedOrderItemAmounts, setModifiedOrderItemAmounts] = useState<
    { orderItemId: string; amount: number }[]
  >([])

  const { rowRenderer } = useMeasuredRowRenderer({
    cache: cellMeasureCache,
    render: ({ index }) => {
      const orderItem = orderItems[index]

      // Display order name and id if first item in list
      const orderId = orderItem?.orderId
      const isFirstItemInOrder =
        orderItems.find((item) => item.orderId === orderId)?.id === orderItem?.id

      if (!orderItem) {
        return null
      }

      return orderItem?.product === null ? (
        <div className={styles.unknownOrderItemDiv}>
          <UnknownOrderItem
            isLoggedIn={true}
            mobile={mobile}
            orderItem={orderItem}
            tablet={tablet}
            tabletWide={tabletWide}
            user={user}
            selectedStore={undefined}
          />
        </div>
      ) : (
        <div className={styles.item}>
          {isFirstItemInOrder && (
            <div className={styles.order}>
              <div className={styles.orderInfo}>
                <Typography variant="boldText">
                  {`${orderItem.orderName ? orderItem.orderName + ' - ' : ''}${orderId}`}
                </Typography>

                {orderItem.createdAt && orderItem.createdAt.length > 0 && (
                  <TextBubble
                    backgroundColor="white"
                    size={!desktop ? 'large' : 'small'}
                    title={t('ORDERS.INFO.CREATED_AT')}
                    value={new Intl.DateTimeFormat('de-DE', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                    }).format(new Date(orderItem.createdAt))}
                  />
                )}

                {orderItem.commissionText && orderItem.commissionText.length > 0 && (
                  <TextBubble
                    backgroundColor="white"
                    size={!desktop ? 'large' : 'small'}
                    title={t('ORDERS.INFO.COMMISSION')}
                    value={orderItem.commissionText}
                  />
                )}
                {orderItem.offerId && orderItem.offerId.length > 0 && (
                  <TextBubble
                    backgroundColor="white"
                    size={!desktop ? 'large' : 'small'}
                    title={t('ORDERS.INFO.OFFERID')}
                    value={orderItem.offerId}
                  />
                )}
              </div>
              {desktop && (
                <TertiaryButton
                  size={desktop ? 'small' : 'large'}
                  rightIcon={<ArrowForwardIcon />}
                  onClick={() => history.push(`/order-details/${orderId}`)}
                >
                  {t('ORDERS.IN_ARREARS.BUTTON_SHOW_ORDER')}
                </TertiaryButton>
              )}
            </div>
          )}
          <OrderItemWrapper
            carts={carts}
            isLoggedIn={isLoggedIn}
            mobile={mobile}
            orderItem={orderItem}
            productId={orderItem?.product?.sapId ?? ''}
            selected={
              (selectedOrderItems.selectAll &&
                !selectedOrderItems.exclude.includes(orderItem.id)) ||
              selectedOrderItems.include.includes(orderItem.id)
            }
            selectedCart={carts.find((cart) => cart.id === user?.settings?.defaultCartId || '')}
            selectedStore={undefined}
            tablet={tablet} // tabletWide and desktop share the same design, tablet(Small) uses a custom one however
            tabletWide={tabletWide} // tabletWide and desktop share the same design, tablet(Small) uses a custom one however
            url={
              orderItem.product?.images && orderItem.product?.images?.length > 0
                ? orderItem.product?.images[0].url
                : ''
            }
            user={user}
            withCheckbox={true}
            orderWithPrice={true}
            changeProductAmount={(orderItemPosition, amount) => {
              onOrderItemAmountChanged(orderItem.id, amount)
            }}
            onChange={() => {
              onSelectOrderItem(orderItem.id)
            }}
            onAddClicked={(orderItem) => {
              trackClick('on-add-to-cart-template-init', {
                orderId: orderItem.orderId,
                orderItemId: orderItem.id,
              })
            }}
            shippingValue={{
              shippedAmount: orderItem.shippingAmount,
              orderedAmount: orderItem.orderAmount,
              unit: orderItem.unit ?? '',
            }}
            isSapPromotionalProduct={orderItem.isSapPromotionalProduct}
          />
        </div>
      )
    },
  })

  return (
    <div>
      {!isLoading ? (
        <>
          <div className={styles.headCheckoutDesktopVariant3}>
            <TertiaryButton
              leftIcon={<ArrowBackIcon />}
              size={desktop ? 'small' : 'large'}
              onClick={onGoBack}
            >
              {t('ORDERS.IN_ARREARS.BACK')}
            </TertiaryButton>
            <Divider />
            {permissions?.Orders_canRead && (
              <Typography variant="headline3Bold">
                {t('ORDERS.IN_ARREARS.TITLE_INCL_COUNT', { count: totalItemsCount })}
              </Typography>
            )}
            <div className={styles.gap} />
          </div>
          <div className={styles.optionsContentNext}>
            {mobile ? (
              <>
                <SearchField
                  placeholder={t('ORDERS.DETAILS.SEARCH.PLACEHOLDER')}
                  value={getURLSearchParamsValue<
                    OrderDetailsInArrearsFilters,
                    OrderDetailsInArrearsURLSearchParams
                  >(
                    'searchTerm',
                    history.location.search,
                    DEFAULT_ORDER_DETAILS_IN_ARREARS_FILTERS
                  )}
                  onChange={onSearch}
                  onReset={() => {
                    onSearch('')
                    resetSelectedOrderItems()
                  }}
                />
                <div className={styles.optionsContentNextCheckboxAndSelect}>
                  <Checkbox
                    checked={selectedOrderItems.selectAll}
                    indeterminate={
                      (selectedOrderItems.selectAll && selectedOrderItems.exclude.length > 0) ||
                      selectedOrderItems.include.length > 0
                    }
                    label={`${t('COMMON.SELECT_ALL_IN_ARREARS', { count: totalItemsCount })}`}
                    onChange={onSelectAll}
                  />
                </div>
              </>
            ) : (
              <>
                <Checkbox
                  checked={selectedOrderItems.selectAll}
                  indeterminate={
                    (selectedOrderItems.selectAll && selectedOrderItems.exclude.length > 0) ||
                    selectedOrderItems.include.length > 0
                  }
                  label={`${t('COMMON.SELECT_ALL_IN_ARREARS', { count: totalItemsCount })}`}
                  onChange={onSelectAll}
                />
                <SearchField
                  placeholder={t('ORDERS.DETAILS.SEARCH.PLACEHOLDER')}
                  value={getURLSearchParamsValue<
                    OrderDetailsInArrearsFilters,
                    OrderDetailsInArrearsURLSearchParams
                  >(
                    'searchTerm',
                    history.location.search,
                    DEFAULT_ORDER_DETAILS_IN_ARREARS_FILTERS
                  )}
                  onChange={onSearch}
                  onReset={() => {
                    onSearch('')
                  }}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <div>
          <TextSkeleton height="1.25rem" width="4.5rem" />
          <TextSkeleton height="1.75rem" width="15.6875rem" sx={{ marginTop: '1rem' }} />
          <TextSkeleton height="2rem" width="100%" sx={{ marginTop: '0.5rem' }} />
          <TextSkeleton height="1.25rem" width="100%" sx={{ marginTop: '1.5rem' }} />
          {/*}<TextSkeleton height={cardSkeletonHeight} width="100%" sx={{ marginTop: '1rem' }} />
          <TextSkeleton height={cardSkeletonHeight} width="100%" sx={{ marginTop: '0.5rem' }} />
          <TextSkeleton height={cardSkeletonHeight} width="100%" sx={{ marginTop: '0.5rem' }} />
          <TextSkeleton height={cardSkeletonHeight} width="100%" sx={{ marginTop: '0.5rem' }} />{*/}
        </div>
      )}
      {!isLoading && orderItems.length === 0 && (
        <NoResults
          className={styles.noResults}
          searchTerm={
            getURLSearchParamsValue<
              OrderDetailsInArrearsFilters,
              OrderDetailsInArrearsURLSearchParams
            >('searchTerm', history.location.search, DEFAULT_ORDER_DETAILS_IN_ARREARS_FILTERS) ?? ''
          }
        />
      )}
      <div>
        <InfiniteWindowScroller
          infiniteLoader={{
            isRowLoaded,
            loadMoreRows: onLoadMoreRows,
            minimumBatchSize: INITIAL_PRODUCTS_COUNT, // Amount of order items (products) to load at a time
            threshold: mobile || tablet ? 5 : 10, // Load more order items when within the last 5/10 rows
          }}
          list={{
            rowRenderer,
            rowHeight: cellMeasureCache.rowHeight,
            deferredMeasurementCache: cellMeasureCache,
          }}
          rowCount={rvData.rowCount} // Row count for loaded orders in arrears
          rowTotalCount={rvData.rowTotalCount} // Total row count for orders in arrears (including not loaded yet orders)
          onInitialRender={() => {
            window.scrollTo({ top: lastScrollY })
          }}
        />
      </div>
      {isLoading && <ListSkeletons />}
      <OrderDetailsInArrearsSelectionBar
        totalItemsCount={totalItemsCount}
        dropDownCarts={shoppingCartsToMoveOrderItemsTo}
        modifiedOrderItemAmounts={modifiedOrderItemAmounts}
        resetSelectedOrderItems={resetSelectedOrderItems}
        searchTerm={
          getURLSearchParamsValue<
            OrderDetailsInArrearsFilters,
            OrderDetailsInArrearsURLSearchParams
          >('searchTerm', history.location.search, DEFAULT_ORDER_DETAILS_IN_ARREARS_FILTERS) ?? ''
        }
        selectedOrderItems={selectedOrderItems}
      />
    </div>
  )
}

export const selectOrderItem = (
  orderItem: string,
  orderItemCount: number,
  selectedOrderItems: MultiSelectionString,
  setSelectedOrderItems: React.Dispatch<React.SetStateAction<MultiSelectionString>>,
  selectAllOrderItems: () => MultiSelectionString,
  resetSelectedOrderItems: () => MultiSelectionString
) => {
  // Create updated selected order items MultiSelection object for DataDog tracking
  let updatedSelectedOrderItems: MultiSelectionString | undefined = undefined

  // 1) Nur einzelne oder keine Artikel ausgewählt
  if (!selectedOrderItems.selectAll) {
    if (selectedOrderItems.include.includes(orderItem)) {
      // 1a auf INCLUDE list --> entfernen
      updatedSelectedOrderItems = {
        ...selectedOrderItems,
        include: [...selectedOrderItems.include.filter((el) => el !== orderItem)],
      }
    } else {
      // 1b einzigstes element nicht auf include list --> toggle und ALLE auswählen
      if ([...selectedOrderItems.include, orderItem].length === orderItemCount) {
        updatedSelectedOrderItems = selectAllOrderItems()
      } else {
        // 1c nicht auf INCLUDE list --> hinzufügen
        updatedSelectedOrderItems = {
          ...selectedOrderItems,
          include: [...selectedOrderItems.include, orderItem],
        }
      }
    }
  }
  // 2) Alle ausgewählt --> Add to EXCLUDE list
  else if (selectedOrderItems.selectAll && selectedOrderItems.exclude.length === 0) {
    if (orderItemCount === 1) {
      updatedSelectedOrderItems = resetSelectedOrderItems()
    } else {
      updatedSelectedOrderItems = {
        ...selectedOrderItems,
        exclude: [...selectedOrderItems.exclude, orderItem],
      }
    }
  }
  // 3) Alle ausgewählt und exclude Liste existiert
  // 3a) auf exclude list --> entnehmen
  else if (selectedOrderItems.selectAll && selectedOrderItems.exclude.includes(orderItem)) {
    updatedSelectedOrderItems = {
      ...selectedOrderItems,
      exclude: [...selectedOrderItems.exclude.filter((el) => el !== orderItem)],
    }
  }
  // 3b) nicht in exclude liste --> in exclude liste übernehmen
  else if (selectedOrderItems.selectAll && !selectedOrderItems.exclude.includes(orderItem)) {
    if (
      selectedOrderItems.selectAll &&
      [...selectedOrderItems.exclude, orderItem].length === orderItemCount
    ) {
      updatedSelectedOrderItems = resetSelectedOrderItems()
    } else {
      updatedSelectedOrderItems = {
        ...selectedOrderItems,
        exclude: [...selectedOrderItems.exclude, orderItem],
      }
    }
  }

  if (updatedSelectedOrderItems) {
    setSelectedOrderItems(updatedSelectedOrderItems)
  }

  trackClick('select-order-item-in-arrears', {
    request: {
      orderItemId: orderItem,
    },
    response: updatedSelectedOrderItems,
  })
}
export default OrderItemsInArrearsPage
