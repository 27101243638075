import { FC } from 'react'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import {
  CustomButtonIconColor,
  CustomButtonSize,
  TertiaryIconButton,
} from '../custom-button/CustomButton'
import styles from './CardLayoutIconHeader.module.scss'

export type CardLayoutIconActionElement = {
  icon: JSX.Element
  iconColor?: CustomButtonIconColor
  disabled?: boolean
  onClick: () => void
  size?: CustomButtonSize
}

type Props = {
  actions: CardLayoutIconActionElement[]
  additionalElement?: JSX.Element
}

export const CardLayoutIconHeader: FC<Props> = ({ actions, additionalElement }) => {
  const { desktop } = useBreakpoints()
  return (
    <div className={styles.container}>
      {actions.map((action) => (
        <TertiaryIconButton
          disabled={action.disabled}
          icon={action.icon}
          iconColor={action.iconColor ?? 'secondary'}
          onClick={action.onClick}
          size={desktop ? 'small' : 'large'}
        />
      ))}
      {additionalElement}
    </div>
  )
}
