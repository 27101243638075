import { FC, MouseEvent, useRef, useState } from 'react'
import styles from './TemplateListItemHeader.module.scss'
import clsx from 'clsx'
import { SvgIcon, Tooltip, useTheme } from '@mui/material'
import { ReactComponent as LockedIcon } from 'assets/icon/designsystem/locked.svg'
import { ReactComponent as OpenIcon } from 'assets/icon/designsystem/open.svg'
import { useTranslation } from 'react-i18next'
import { CartTemplateContextMenu } from './CartTemplateContextMenu'
import { ReactComponent as DeleteIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { ReactComponent as FavoriteBorderIcon } from '@obeta/assets/icon/designsystem/favourite_border.svg'
import { ReactComponent as FavoriteIcon } from '@obeta/assets/icon/designsystem/favourite.svg'
import { ReactComponent as MoreIcon } from 'assets/icon/designsystem/more_vertical.svg'
import { CartTemplate } from '@obeta/schema'
import { CartTemplateItemInput } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { isSubuser } from '@obeta/utils/lib/isSubuser'
import { buildUserId } from '@obeta/utils/lib/buildUserId'

interface Props {
  cartTemplate: CartTemplate
  isOnlyTemplate: boolean
  onDeleteTemplate: () => void
  onDuplicateTemplate: () => void
  onFavoriteToggled: (isFavorite: boolean) => void
  onRenameTemplate: () => void
  onVisibilityToggled: (isPublic: boolean) => void
  cartTemplatesCount: number
  productsToAdd: CartTemplateItemInput[]
}

export const TemplateListItemHeader: FC<Props> = (props) => {
  const {
    cartTemplate,
    isOnlyTemplate,
    onDeleteTemplate,
    onDuplicateTemplate,
    onFavoriteToggled,
    onRenameTemplate,
    onVisibilityToggled,
    productsToAdd,
  } = props

  const [anchorElContextMenu, setAnchorElContextMenu] = useState<null | HTMLElement>(null)
  const openContextMenu = Boolean(anchorElContextMenu)
  const theme = useTheme()
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const { companyId, userId } = useUserDataV2()
  const listItemHeader = useRef<HTMLDivElement | null>(null)

  const isDefault = cartTemplate.isDefault
  const isFavorite = cartTemplate.isFavourite
  const isShared = cartTemplate.isShared

  // context menu opened
  const handleClickOnMoreIcon = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAnchorElContextMenu(event.currentTarget)
  }

  const switchFavourite = (event) => {
    event.preventDefault()
    onFavoriteToggled(!isFavorite)
  }

  const switchVisibility = (event) => {
    event.preventDefault()
    onVisibilityToggled(!isShared)
  }

  const visibilitySvgIcon = isShared ? (
    <SvgIcon component={OpenIcon} htmlColor={theme.palette.secondary.main} />
  ) : (
    <SvgIcon component={LockedIcon} htmlColor={theme.palette.text.disabled} />
  )
  const fullyQualifiedUserId: string =
    (userId && companyId && buildUserId({ userId, companyId, includeSubuser: true })) ?? ''
  const isOwnerOfCartTemplate = fullyQualifiedUserId === cartTemplate.ownerId
  const isMainUser = (userId && companyId && !isSubuser({ userId, companyId })) ?? false
  const buttonSize = desktop ? 'small' : 'large'

  return (
    <div className={styles.header} ref={listItemHeader}>
      <TertiaryIconButton
        disabled={!isMainUser && !isOwnerOfCartTemplate}
        icon={
          <Tooltip title={t('TEMPLATES.ADD_TO_FAVORITES')}>
            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </Tooltip>
        }
        iconColor={isFavorite ? 'primary' : 'secondary'}
        onClick={switchFavourite}
        size={buttonSize}
      />
      <TertiaryIconButton
        disabled={(!isMainUser && !isOwnerOfCartTemplate) || isDefault}
        icon={
          <Tooltip title={t('TEMPLATES.MAKE_INTO_PUBLIC_TEMPLATE')}>{visibilitySvgIcon}</Tooltip>
        }
        size={buttonSize}
        onClick={switchVisibility}
      />
      <TertiaryIconButton
        disabled={(!isMainUser && !isOwnerOfCartTemplate) || isDefault}
        icon={<DeleteIcon />}
        size={buttonSize}
        onClick={onDeleteTemplate}
      />
      <div className={clsx([desktop && styles.meatballs])}>
        <TertiaryIconButton
          id="context-menu-button"
          aria-controls={openContextMenu ? 'context-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openContextMenu ? 'true' : undefined}
          icon={<MoreIcon />}
          size={buttonSize}
          onClick={handleClickOnMoreIcon}
        />
        <CartTemplateContextMenu
          anchorElement={anchorElContextMenu}
          cartTemplate={cartTemplate}
          isOnlyTemplate={isOnlyTemplate}
          isOpen={openContextMenu}
          onClose={() => setAnchorElContextMenu(null)}
          onDuplicateTemplate={onDuplicateTemplate}
          onRenameTemplate={onRenameTemplate}
          productsToAdd={productsToAdd}
        />
      </div>
    </div>
  )
}
