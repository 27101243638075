import React, { useRef } from 'react'
import styles from './SignUpStepThree.module.scss'
import clsx from 'clsx'
import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { ReactComponent as ChevronLeftIcon } from 'assets/icon/designsystem/chevron_left.svg'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import SignUpStepThreeForm from './SignUpStepThreeForm'
import { useTranslation } from 'react-i18next'
import { useUserRegistrationContext } from '@obeta/data/lib/stores/useUserRegistrationContext'

const SignUpStepThree = ({ control, setValue, handleFinalSubmit, hCaptchaSiteKey }) => {
  const { t } = useTranslation()

  const captchaRef = useRef<HCaptcha>(null)
  const {
    captchaToken,
    captchaTokenError,
    isLoading,
    privacyPolicyAccepted,
    setCaptchaToken,
    termsAccepted,
    uploadedFiles,
  } = useUserRegistrationContext()

  const red100pct = useTheme().palette.primary.main

  const handleNavigateToPrevStep = () => {
    setCaptchaToken('')
    setValue('2')
  }

  return (
    <form className={clsx(styles.container)} onSubmit={handleFinalSubmit}>
      <SignUpStepThreeForm control={control} />
      <div className={styles.marginBottom}>
        <HCaptcha
          sitekey={hCaptchaSiteKey}
          onVerify={setCaptchaToken}
          onExpire={() => setCaptchaToken('')}
          onError={() => setCaptchaToken('')}
          ref={captchaRef}
        />
        {captchaTokenError && (
          <Box display="flex">
            <SvgIcon
              component={WarningIcon}
              fontSize={'small'}
              htmlColor={red100pct}
              className={styles.helperTextIcon}
            />
            <Typography variant={'smallText'} color={red100pct}>
              {captchaTokenError}
            </Typography>
          </Box>
        )}
      </div>
      <div className={styles.requiredText}>
        <Typography variant="smallText">{`* ${t('SIGNUP.REQUIRED_FIELD')}`}</Typography>
      </div>

      <div className={styles.buttonWrapper}>
        <TertiaryButton leftIcon={<ChevronLeftIcon />} onClick={() => handleNavigateToPrevStep()}>
          {t('SIGNUP.BACK')}
        </TertiaryButton>

        <DarkButton
          type="submit"
          rightIcon={<CheckIcon />}
          disabled={
            isLoading ||
            !termsAccepted ||
            !privacyPolicyAccepted ||
            uploadedFiles.length === 0 ||
            captchaToken === ''
          }
          fullWidth
        >
          {t('SIGNUP.SEND')}
        </DarkButton>
      </div>
    </form>
  )
}

export default SignUpStepThree
