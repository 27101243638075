import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks'
import clsx from 'clsx'
import styles from './MaxValueLine.module.scss'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { Checkbox } from '@obeta/components/lib/checkbox/Checkbox'
import { TextField, Tooltip } from '@mui/material'
import formStyles from './form.module.scss'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'

type MaxValueLineProps = {
  active: boolean
  value: number | undefined | null
  setActive: Dispatch<SetStateAction<boolean>>
  setValue: Dispatch<SetStateAction<number | null>>
  alignGap?: boolean
  disabled?: boolean
  tooltipText?: string
  handleMaxValueChange: (value: number | null | undefined) => void
}

export const MaxValueLine: FC<MaxValueLineProps> = ({
  active,
  setActive,
  value,
  setValue,
  alignGap,
  disabled,
  tooltipText,
  handleMaxValueChange,
}) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()

  return (
    <div className={clsx(styles.maxValueLine, alignGap && styles.alignGap)}>
      <Checkbox
        checked={active}
        onChange={(e) =>
          setActive((prev) => {
            if (prev) {
              setValue(null)
              handleMaxValueChange(parseInt(e.target.value))
              return false
            }
            return !prev
          })
        }
        disabled={disabled}
      />
      <div className={styles.inputWithTooltip}>
        <TextField
          className={styles.maxValueInput}
          value={value ?? ''}
          disabled={disabled}
          onChange={(e) => {
            const onlyNums = e.target.value.replace(/[^0-9]/g, '')

            if (onlyNums.length > 0) {
              setValue(parseInt(onlyNums, 10))
              setActive(true)
            } else {
              setValue(null)
              setActive(false)
            }

            handleMaxValueChange(parseInt(e.target.value))
          }}
          placeholder={t(
            'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.ORDERABLE_COMPLETE_RANGE_MAX_VALUE'
          )}
        />
        {desktop && tooltipText && tooltipText?.length > 0 && (
          <Tooltip title={tooltipText}>
            <InfoIcon className={formStyles.tooltipIcon} color={scssVariables.$darkGray} />
          </Tooltip>
        )}
      </div>
    </div>
  )
}
