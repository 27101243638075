import { Pagination } from '@obeta/components/lib/pagination/Pagination'
import { useEffect, useMemo } from 'react'
import { IListProps } from './types'
import styles from './PaginationList.module.scss'
import { HorizontalDummy } from '@obeta/components/lib/product-card/dummies/HorizontalDummy'
import { useLoadMoreProducts } from '@obeta/data/lib/hooks/useLoadMoreProducts'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useSelectedCart } from '@obeta/data/lib/hooks/useSelectedCart'
import { calculatePaginationIndexes } from '@obeta/utils/lib/search/calculatePaginationIndexes'
import { useVoltimumPoints } from '@obeta/data/lib/hooks/useVoltimumPoints'

import { SearchResultsTrackingProvider } from '@obeta/data/lib/hooks/useSearchTrackingContext'
import { useProductImages } from '@obeta/data/lib/hooks/useProductImages'
import { SearchResultItemBaseWrapper } from './SearchResultItemBaseWrapper'
import { useScopedScrollRestorationContext } from '@obeta/data/lib/hooks'
import { SearchPromotionBanner } from './SearchPromotionBanner'

const rowsPerPage = [8, 16, 32]

const PROMOTION_BANNER_INDEX = 1

export const PaginationList: React.FC<IListProps> = (props) => {
  const {
    user,
    selectedStore,
    onItemClick,
    setProductAmount,
    onAddToCart,
    focusFirstSearchResult = true,
  } = props
  const {
    searchParams,
    itemsPerPage,
    changePagination,
    currentPage,
    maxPage,
    isFetching,
    queryId,
    bannerData,
  } = useArticlesSearchProvider()

  const { lastScrollY } = useScopedScrollRestorationContext({
    scopeName: 'searchPaged',
    keyFn: () => searchParams?.id || 'defaultsearch',
  })

  useEffect(() => {
    window.scrollTo({ top: lastScrollY })
    //we explictly only want to restore once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { articles: products } = useLoadMoreProducts({
    searchParams,
  })

  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const selectedCart = useSelectedCart()
  const { isLoggedIn } = useUserDataV2()

  const { startIndex, stopIndex, actualItemsPerPage } = calculatePaginationIndexes({
    currentPage,
    itemsPerPage,
    products,
    isFetching,
  })

  const { sapIds } = useVoltimumPoints(
    products
      .filter((product) => Boolean(product?.sapId))
      .map((product) => ({ sapId: product.sapId, amount: 1 }))
  )

  const visibleProducts = products.slice(startIndex, stopIndex + 1)
  const { images: productImages, isFetchingImages } = useProductImages(
    visibleProducts.filter(Boolean).map((product) => product.sapId),
    isFetching
  )

  const items = useMemo(() => {
    const empty = new Array(actualItemsPerPage).fill(null)
    const items = visibleProducts
    return empty.map((_, idx) => {
      const p = items[idx]

      if (isFetching) {
        return <HorizontalDummy key={idx} />
      }

      // Fixes "Cannot read properties of undefined (reading 'sapId')"
      // unsure about the root cause why p can be undefined here
      if (!p) {
        return null
      }
      const productImageArray = productImages?.find((pi) => p.sapId === pi.sapId)?.images

      return (
        <>
          {idx === PROMOTION_BANNER_INDEX && <SearchPromotionBanner bannerData={bannerData} />}
          <SearchResultItemBaseWrapper
            carts={carts}
            selectedCart={selectedCart}
            onClick={(e) => {
              e.stopPropagation()
              onItemClick(p, queryId, idx, user?.algoliaUserToken)
            }}
            onTitleClick={(e) => {
              e.stopPropagation()
              onItemClick(p, queryId, idx, user?.algoliaUserToken)
            }}
            isLoggedIn={isLoggedIn}
            key={p.sapId}
            product={p}
            selected={false}
            mobile={false}
            tablet={false}
            user={user}
            onAmountChange={(amount) => {
              setProductAmount(p, amount)
            }}
            selectedStore={selectedStore}
            onAddToCart={onAddToCart}
            productId={p.sapId}
            autofocusOnRender={focusFirstSearchResult && idx === startIndex}
            hasVoltimumLogo={sapIds?.includes(p.sapId)}
            isFetchingImages={isFetchingImages}
            url={productImageArray && productImageArray.length > 0 ? productImageArray[0].url : ''}
          />
        </>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    carts,
    isLoggedIn,
    onItemClick,
    actualItemsPerPage,
    products,
    selectedCart,
    selectedStore,
    setProductAmount,
    startIndex,
    stopIndex,
    user,
    onAddToCart,
    isFetching,
    sapIds,
  ])

  return (
    <SearchResultsTrackingProvider products={products}>
      <div className={styles.root}>
        <div className={styles.list}>{items}</div>
        <Pagination
          rowsPerPage={rowsPerPage}
          selectedRowsPerPage={itemsPerPage}
          onRowsPerPageSelected={(p) => {
            changePagination({
              itemsPerPage: p,
            })
          }}
          maxPage={maxPage}
          currentPage={currentPage + 1}
          onPageSelected={(p) => {
            changePagination({ page: p - 1 })
            window.scrollTo(0, 0) //this used to be in the articlesearchprovider, but the displying components should rather control how to alter their scrollposition, not the general provider
          }}
        />
      </div>
    </SearchResultsTrackingProvider>
  )
}
