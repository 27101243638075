import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { CartWritePermission } from '@obeta/models/lib/models/Users/UserV2'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserManagementReadOnly } from '../form/useUserManagementReadOnly'
import { MaxValueLine } from '../form/MaxValueLine'
import { RadioButtonLine } from '../form/FormComponents'
import { OrderPermissionGroupOption } from './OrderPermissionGroup'
import styles from './OrderPermissionRadioOrderable.module.scss'
import { NotifySubForm } from '../form/NotifySubForm'

type Props = {
  maxValueFullRange?: number | null
  setMaxValueFullRange: Dispatch<SetStateAction<number | null | undefined>>
  maxValueFullRangeActive: boolean
  setMaxValueFullRangeActive: Dispatch<SetStateAction<boolean>>
  usersToNotify: string[]
  setUsersToNotify: (value: string[]) => void
  handleMaxValueChange: (value: number | null | undefined) => void
}

export const OrderPermissionRadioOrderable: FC<OrderPermissionGroupOption & Props> = ({
  allowedSubmitAction,
  maxValueFullRange,
  setMaxValueFullRange,
  maxValueFullRangeActive,
  setMaxValueFullRangeActive,
  usersToNotify,
  setUsersToNotify,
  handleMaxValueChange,
}) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const isReadOnlyUser = useUserManagementReadOnly()

  return (
    <>
      <RadioButtonLine
        value={CartWritePermission.Submit}
        label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.CART_ORDERABLE')}
        tooltip={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.CART_ORDERABLE')}
      />

      {allowedSubmitAction === CartWritePermission.Submit && (
        <div className={clsx(styles.activeForm, !desktop && styles.touchWrapper)}>
          <RadioButtonLine
            value={allowedSubmitAction}
            disabled={isReadOnlyUser}
            label={t(
              'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.ORDERABLE_COMPLETE_RANGE'
            )}
            subLine={
              allowedSubmitAction === CartWritePermission.Submit && (
                <div>
                  <MaxValueLine
                    value={maxValueFullRange}
                    setValue={setMaxValueFullRange}
                    active={maxValueFullRangeActive}
                    setActive={setMaxValueFullRangeActive}
                    disabled={isReadOnlyUser}
                    handleMaxValueChange={handleMaxValueChange}
                    tooltipText={t(
                      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.ORDERABLE_COMPLETE_RANGE'
                    )}
                  />
                  {maxValueFullRangeActive && (
                    <NotifySubForm
                      usersToNotify={usersToNotify}
                      setUsersToNotify={setUsersToNotify}
                    />
                  )}
                </div>
              )
            }
          />
        </div>
      )}
    </>
  )
}
