import React, { FC, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { DropdownOption } from '@obeta/models/lib/models/UI'
import { TabContext, TabPanel } from '@mui/lab'
import styles from './signUpPage.module.scss'
import { CompanyType, SubmitUserRegistrationInput } from '@obeta/schema'
import { DarkButton, TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { ProgressBar } from '@obeta/components/lib/progress-bar/ProgressBar'
import { ReactComponent as ArrowRightIcon } from '@obeta/assets/icon/designsystem/keyboard-arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icon/designsystem/keyboard-arrow-left.svg'
import { sanitize } from '@obeta/utils/lib/sanitize'
import SignUpStepOne from '@obeta/components/lib/sign-up/SignUpStepOne'
import SignUpStepThree from '@obeta/components/lib/sign-up/SignUpStepThree'
import SignUpStepTwo from '@obeta/components/lib/sign-up/DesignStepTwo/SignUpStepTwo'
import { Typography } from '@mui/material'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useCompanyDetection } from '@obeta/data/lib/hooks/useCompanyDetection'
import { useForm } from 'react-hook-form'
import { UserRegistrationPending } from '@obeta/components/lib/sign-up/UserRegistrationPending'
import { UserRegistrationSuccess } from '@obeta/components/lib/sign-up/UserRegistrationSuccess'
import { useUserRegistration } from '@obeta/data/lib/hooks/useUserRegistration'
import { useTranslation } from 'react-i18next'
import { useUserRegistrationContext } from '@obeta/data/lib/stores/useUserRegistrationContext'

export interface SignUpPageProps {
  hCaptchaSiteKey: string
}

export const SignUpPage: FC<SignUpPageProps> = ({ hCaptchaSiteKey }) => {
  const { t } = useTranslation()
  const { mobile, tablet } = useBreakpoints()
  const [value, setValue] = useState('1')
  const [amount, setAmount] = useState<number | null>(0)
  const [view, setView] = useState<'form' | 'pending' | 'success'>('form')

  const domain = useCompanyDetection()
  const { uploadedFiles, setCaptchaToken, setUploadedFiles, setIsLoading } =
    useUserRegistrationContext()
  const { submitUserRegistration } = useUserRegistration()

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
      customerCardCount: 0,
      taxNumber: '',
      invoiceEmail: '',
      companyType: 'electrical',
      companyDescription: '',
      electrician: '',
      additionalText: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const [, setVisitedTabs] = useState<Set<string>>(new Set())

  const handleTabChange = (newValue: string) => {
    setVisitedTabs((prev) => new Set(prev).add(newValue))
    setValue(newValue)
  }

  const getTabClass = (tabValue: string) => {
    if (tabValue === value) {
      return styles.activeTab
    } else if (tabValue === '1' && !isAnyFieldEmptyInTab1()) {
      return styles.previousTab
    } else if (tabValue === '2' && !isAnyFieldEmptyInTab2()) {
      return styles.previousTab
    } else if (tabValue === '3') {
      return styles.lastTab
    } else {
      return styles.inactiveTab
    }
  }

  const active = (tabValue: string) => {
    return tabValue === value ? styles.activeLine : styles.inactiveLine
  }

  const onSubmit = () => {
    if (value === '1') {
      setValue('2')
    } else if (value === '2') {
      setValue('3')
    }
  }

  const removeFailedFileUploads = (s3KeysOfFailedFiles: string[]) => {
    if (s3KeysOfFailedFiles) {
      setUploadedFiles(uploadedFiles.filter((file) => !s3KeysOfFailedFiles.includes(file.s3Key)))
    }
  }
  const handleFailedRegistration = (s3KeysOfFailedFiles: string[]) => {
    if (s3KeysOfFailedFiles.length > 0) {
      removeFailedFileUploads(s3KeysOfFailedFiles)
    }
    getEventSubscription().next({
      type: EventType.Alert,
      notificationType: NotificationType.Error,
      id: 'user-registration-failed',
      options: {
        title: t('SIGNUP.NOTIFICATIONS.ATTENTION'),
        message: t('SIGNUP.NOTIFICATIONS.ISSUE_WITH_SENDING_DATA'),
        duration: 8000,
      },
    })
    setIsLoading(false)
    setCaptchaToken('')
    setView('form')
  }
  const handleSuccessfulRegistration = () => {
    setView('success')
  }

  const handleRegistrationResponse = (response) => {
    const { submitUserRegistration } = response.data || {}

    if (submitUserRegistration?.success) {
      handleSuccessfulRegistration()
    } else {
      handleFailedRegistration(submitUserRegistration?.files ?? [])
    }
  }

  const handleFinalSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    setView('pending')
    try {
      const values = getValues()
      const input: SubmitUserRegistrationInput = {
        ...values,
        additionalText: sanitize(values.additionalText),
        attachments: uploadedFiles.map((file) => file.s3Key),
        city: sanitize(values.city),
        companyDescription: sanitize(values.companyDescription),
        companyId: domain,
        companyName: sanitize(values.companyName),
        companyType: values.companyType as CompanyType,
        customerCardCount: amount ?? 0,
        electrician: sanitize(values.electrician),
        email: sanitize(values.email),
        firstName: sanitize(values.firstName),
        houseNumber: sanitize(values.houseNumber),
        invoiceEmail: sanitize(values.invoiceEmail),
        lastName: sanitize(values.lastName),
        phone: sanitize(values.phone),
        street: sanitize(values.street),
        taxNumber: sanitize(values.taxNumber),
        zipCode: sanitize(values.zipCode),
      }
      const response = await submitUserRegistration(input)
      handleRegistrationResponse(response)
    } catch (error) {
      // Handle error
      console.error('error: ', error)
      setIsLoading(false)
      setCaptchaToken('')
      setView('form')
    }
  }

  const handleAmountChange = (newAmount: number) => {
    setAmount(newAmount)
  }

  const Options: DropdownOption[] = [
    { value: 'electrical', label: t('COMMON.SIGNUP.TYPE.ELECTRICAL') },
    { value: 'producing', label: t('COMMON.SIGNUP.TYPE.PRODUCING') },
    { value: 'commerce', label: t('COMMON.SIGNUP.TYPE.COMMERCE') },
    { value: 'research', label: t('COMMON.SIGNUP.TYPE.RESEARCH') },
    { value: 'other', label: t('COMMON.SIGNUP.TYPE.AOB') },
  ]
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const isAnyFieldEmptyInTab1 = useCallback(() => {
    const values = getValues()
    return !values.firstName || !values.lastName || !values.phone || !values.email
  }, [getValues])

  const isAnyFieldEmptyInTab2 = useCallback(() => {
    const values = getValues()
    return (
      !values.companyName ||
      !values.street ||
      !values.houseNumber ||
      !values.zipCode ||
      !values.city ||
      !values.taxNumber ||
      !values.invoiceEmail ||
      !values.companyType
    )
  }, [getValues])

  useEffect(() => {
    if (value === '1') {
      setIsButtonDisabled(isAnyFieldEmptyInTab1() || !isValid)
    } else if (value === '2') {
      setIsButtonDisabled(isAnyFieldEmptyInTab2() || !isValid)
    }
  }, [isValid, value, isAnyFieldEmptyInTab1, isAnyFieldEmptyInTab2])

  if (view === 'pending') {
    return <UserRegistrationPending />
  }

  if (view === 'success') {
    return <UserRegistrationSuccess domain={domain} />
  }
  return (
    <TabContext value={value}>
      <div
        className={clsx([
          !mobile && !tablet && styles.center,
          tablet && styles.centerTabletWide,
          mobile && styles.centerMobile,
        ])}
      >
        <TabPanel className={styles.noPadding} value="1">
          <Typography variant="headline3Bold">{t('COMMON.SIGNUP.STEP1')}</Typography>
        </TabPanel>
        <TabPanel className={styles.noPadding} value="2">
          <Typography variant="headline3Bold">{t('COMMON.SIGNUP.STEP2')}</Typography>
        </TabPanel>
        <TabPanel className={styles.noPadding} value="3">
          <Typography variant="headline3Bold">{t('COMMON.SIGNUP.STEP3')}</Typography>
        </TabPanel>
      </div>
      <div
        className={clsx([
          !mobile && !tablet && styles.barContainer,
          tablet && styles.barContainerTabletWide,
          mobile && styles.barContainerMobile,
          mobile && value === '1' && styles.activeFirst,
          mobile && value === '2' && styles.activeSecond,
          mobile && value === '3' && styles.activeThird,
        ])}
      >
        <ProgressBar
          title={t('COMMON.SIGNUP.PERSONAL_DATA')}
          onChange={() => handleTabChange('1')}
          className={getTabClass('1')}
          active={active('1')}
          isActive={value === '1'}
        />
        <ProgressBar
          title={t('COMMON.SIGNUP.COMPANY')}
          onChange={() => handleTabChange('2')}
          className={getTabClass('2')}
          active={active('2')}
          isActive={value === '2'}
        />
        <ProgressBar
          title={t('COMMON.SIGNUP.SENDING')}
          onChange={() => handleTabChange('3')}
          className={getTabClass('3')}
          active={active('3')}
          isActive={value === '3'}
        />
      </div>

      <div
        className={clsx([
          !mobile && !tablet && styles.center,
          tablet && styles.centerTabletWide,
          mobile && styles.centerMobile,
        ])}
      >
        <TabPanel className={styles.noPadding} value="1">
          <form onSubmit={handleSubmit(onSubmit)}>
            <SignUpStepOne control={control} errors={errors} />
            <div className={styles.right}>
              <Typography className={styles.size}>{t('COMMON.SIGNUP.REQUIRED_FIELD')}</Typography>
              <div className={styles.button}>
                <DarkButton
                  type="submit"
                  rightIcon={<ArrowRightIcon />}
                  disabled={isButtonDisabled}
                  fullWidth
                >
                  {t('COMMON.SIGNUP.NEXT')}
                </DarkButton>
              </div>
            </div>
          </form>
        </TabPanel>

        <TabPanel className={styles.noPadding} value="2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={clsx([
                !mobile && !tablet && styles.center,
                tablet && styles.centerTabletWide,
                mobile && styles.centerMobile,
              ])}
            >
              <SignUpStepTwo
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                amount={amount}
                Options={Options}
                handleAmountChange={handleAmountChange}
                value={value}
              />
            </div>
            <div className={clsx([styles.formContainer, mobile && styles.formContainerMobile])}>
              <div className={styles.left}>
                <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={() => setValue('1')}>
                  {t('COMMON.SIGNUP.BACK')}
                </TertiaryButton>
              </div>
              <div className={styles.right}>
                <Typography className={styles.size}>{t('COMMON.SIGNUP.REQUIRED_FIELD')}</Typography>
                <div className={styles.button}>
                  <DarkButton
                    rightIcon={<ArrowRightIcon />}
                    disabled={isButtonDisabled}
                    fullWidth
                    onClick={() => setValue('3')}
                  >
                    {t('COMMON.SIGNUP.NEXT')}
                  </DarkButton>
                </div>
              </div>
            </div>
          </form>
        </TabPanel>
        <TabPanel className={clsx(styles.noPadding, styles.fullWidth)} value="3">
          <SignUpStepThree
            control={control}
            handleFinalSubmit={handleFinalSubmit}
            hCaptchaSiteKey={hCaptchaSiteKey}
            setValue={setValue}
          />
        </TabPanel>
      </div>
    </TabContext>
  )
}

export default SignUpPage
