import { FC } from 'react'
import { FormSectionProps, FormSection } from '@obeta/components/index'
import styles from './SettingsPage.module.scss'
import clsx from 'clsx'

export const SettingsFormWrapper: FC<FormSectionProps> = ({ title, children, wrapperStyles }) => (
  <FormSection title={title} wrapperStyles={clsx(styles.settingsFormWrapper, wrapperStyles)}>
    {children}
  </FormSection>
)
