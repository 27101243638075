import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { Radio, Typography } from '@mui/material'
import { ShippingGroup } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { useProjectDetailsContext } from '@obeta/data/lib/stores/useProjectDetailsContext'
import { ReactComponent as TruckIcon } from 'assets/icon/designsystem/local_shipping.svg'
import { ReactComponent as LocationIcon } from 'assets/icon/designsystem/location_on.svg'
import { ReactComponent as DoNotDisturbIcon } from 'assets/icon/designsystem/do_disturb_alt.svg'
import formStyles from './ProjectCartPreSelectionForm.module.scss'
import styles from './ProjectCartShippingGroupForm.module.scss'
import { useTranslation } from 'react-i18next'

const ShippingGroupOption = (props: {
  value: ShippingGroup | null
  label: string
  icon: ReactNode
}) => {
  const { value, label, icon } = props
  const { shippingGroup, updateShippingGroup } = useProjectDetailsContext()
  const selected = shippingGroup === value
  return (
    <div
      className={clsx(formStyles.card, styles.shippingGroupOption, selected && styles.selected)}
      onClick={() => updateShippingGroup(value)}
    >
      <Radio checked={selected} />
      {icon}
      <Typography variant="bodyBold">{label}</Typography>
    </div>
  )
}

export const ProjectCartShippingGroupForm: FC = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.shippingGroupOptions}>
      <ShippingGroupOption
        value={null}
        label={t('PROJECTS.DETAILS.SHIPPING_GROUP.NONE')}
        icon={<DoNotDisturbIcon />}
      />
      <ShippingGroupOption
        value={ShippingGroup.Pickup}
        label={t('PROJECTS.DETAILS.SHIPPING_GROUP.PICKUP')}
        icon={<LocationIcon />}
      />
      <ShippingGroupOption
        value={ShippingGroup.Delivery}
        label={t('PROJECTS.DETAILS.SHIPPING_GROUP.DELIVERY')}
        icon={<TruckIcon />}
      />
    </div>
  )
}
