import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from '../select/Select'
import { Pagination as MuiPagination, PaginationItem } from '@mui/material'
import styles from './Pagination.module.scss'

export interface IPaginationProps {
  rowsPerPage?: number[]
  selectedRowsPerPage?: number
  onRowsPerPageSelected?: (rows: number) => void
  currentPage: number
  maxPage: number
  onPageSelected?: (page: number) => void
}

export const Pagination: FC<IPaginationProps> = (props) => {
  const {
    rowsPerPage,
    selectedRowsPerPage,
    onRowsPerPageSelected,
    currentPage,
    onPageSelected,
    maxPage,
  } = props
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {rowsPerPage && onRowsPerPageSelected && selectedRowsPerPage ? (
        <Select
          label={t('SEARCH.PRODUCTS_PER_PAGE')}
          selectedOption={{ id: selectedRowsPerPage, title: selectedRowsPerPage }}
          options={rowsPerPage.map((opt) => {
            return { id: opt, title: opt }
          })}
          onOptionSelected={(opt) => onRowsPerPageSelected(+opt.title)}
          usePopperOverPopover
        />
      ) : (
        <div />
      )}
      <MuiPagination
        page={currentPage}
        count={maxPage}
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{
              ellipsis: styles.ellipsis,
              selected: styles.selected,
              disabled: styles.disabled,
            }}
            className={styles.paginationItem}
          />
        )}
        variant="outlined"
        onChange={(_, value) => {
          const val = Math.min(maxPage, Math.max(value, 1))
          onPageSelected?.(val)
        }}
      ></MuiPagination>
    </div>
  )
}
