import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { GetProjectsQuery, GetProjectsQueryVariables } from '@obeta/schema'

const query = gql`
  query getProjects(
    $limit: Float!
    $offset: Float!
    $searchTerm: String!
    $orderBy: ProjectOrderBy!
    $orderDir: OrderDir!
  ) {
    getProjects(
      limit: $limit
      offset: $offset
      searchTerm: $searchTerm
      orderBy: $orderBy
      orderDir: $orderDir
    ) {
      projects {
        name
        id
        offerId
      }
      message
      totalRecords
    }
  }
`

type SearchProjectsResponse = GetProjectsQuery['getProjects']
type SearchProjects = (options: {
  searchTerm: string
  offset: number
  limit: number
  orderBy: string
  orderDir: string
}) => Promise<SearchProjectsResponse>

export const useProjectsSearch = (): SearchProjects => {
  const client = useApolloClient()
  const searchProjects = useCallback(
    async (variables): Promise<SearchProjectsResponse> => {
      const response = await client.query<GetProjectsQuery, GetProjectsQueryVariables>({
        query,
        variables,
      })
      return response.data.getProjects
    },
    [client]
  )

  return searchProjects
}
