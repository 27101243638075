import { createContext, useContext, useMemo, useState } from 'react'
import { INewsCard } from '@obeta/models/lib/models/News/News'
import { ExpectedNewsFilters, LoadingStatus, useNews } from '../../startPage/hooks/useNews'
import { NewsMeta, useNewsTotalsMeta } from '../hooks/useNewsTotalsMeta'

interface INewsLocalFilters {
  search: string
  category: string
}

type INewsFilters = {
  category?: ExpectedNewsFilters['category']
  or?: ExpectedNewsFilters['or']
}

interface INewsContextData {
  news: INewsCard[]
  updatePagination: () => void
  total: number
  updateFilters: (value: { search: string } | { category: string }) => void
  filters: INewsLocalFilters
  updateSort: (sortOptions: string) => void
  sort: string
  meta: NewsMeta
  pagination: {
    start: number
    limit: number
  }
  isLoaded: boolean
}

export const NewsContext = createContext<INewsContextData | null>(null)

export const NewsContextProvider = ({ children }) => {
  const [sort, setSort] = useState<string>('startDate:desc')
  const [pagination, setPagination] = useState({
    start: 0,
    limit: 20,
  })

  const [filters, setFilters] = useState<INewsLocalFilters>({ search: '', category: '' })

  const transformedFilters = useMemo(() => {
    const res = {} as INewsFilters

    if (filters.category) res.category = { eq: filters.category }

    if (filters.search) {
      res.or = [
        { title: { contains: filters.search } },
        { teaserText: { contains: filters.search } },
      ]
    }

    return res
  }, [filters])

  // We just manage with this flag when we want to update data, to avoid batching, since we keep sort/filters as separate values
  const [shouldRefetch, setShouldRefetch] = useState(1)

  const { news, total, loadingStatus } = useNews<INewsFilters>({
    config: {
      pagination,
      sort,
      shouldRefetch,
      filters: transformedFilters,
    },
  })

  const meta = useNewsTotalsMeta(filters.search, shouldRefetch)

  const updatePagination = () => {
    setPagination({ ...pagination, start: news.length })
    setShouldRefetch((prev) => prev + 1)
  }

  const onResetCurrentPage = () => setPagination({ ...pagination, start: 0 })

  const updateFilters = (filters) => {
    onResetCurrentPage()
    setFilters((prev) => ({ ...prev, ...filters }))
    setShouldRefetch((prev) => prev + 1)
  }

  const updateSort = (value: string) => {
    onResetCurrentPage()
    setSort(value)
    setShouldRefetch((prev) => prev + 1)
  }

  return (
    <NewsContext.Provider
      value={{
        news,
        updatePagination,
        updateFilters,
        total,
        meta,
        pagination,
        updateSort,
        sort,
        filters,
        isLoaded: loadingStatus === LoadingStatus.Loaded,
      }}
    >
      {children}
    </NewsContext.Provider>
  )
}

export const useNewsProvider = () => {
  const searchData = useContext(NewsContext)

  if (!searchData) {
    throw new Error(
      'newsData is not defined. Make sure you call useNewsProvider inside one of NewsContextProvider child'
    )
  }

  return searchData
}
