import { FC, useState } from 'react'
import dayjs from 'dayjs'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useIconSize } from '@obeta/data/lib/hooks/useIconSize'
import { useHistoryBack } from '@obeta/data/lib/hooks/useHistoryBack'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { ReactComponent as TruckIcon } from 'assets/icon/designsystem/local_shipping.svg'
import { ReactComponent as TrashIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import styles from './ProjectDetailsPageHeader.module.scss'
import { RenameField } from '@obeta/components/lib/rename-field/RenameField'
import { useProjectDetailsContext } from '@obeta/data/lib/stores/useProjectDetailsContext'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'
import { useDeleteProject } from '@obeta/data/lib/hooks/projects/useDeleteProject'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopLink } from '@obeta/components/lib/link/ShopLink'
import { ProjectFormVariant } from './ProjectForm'
import { useBreakpoints } from '@obeta/data/lib/hooks'

type Props = {
  variant: ProjectFormVariant
}

export const ProjectDetailsPageHeader: FC<Props> = ({ variant = 'edit' }) => {
  const { t } = useTranslation()
  const iconSize = useIconSize()
  const goBack = useHistoryBack({ fallbackTarget: ShopRoutes.ProjectOverview })
  const { project, mutateProjectName, updateLocalProjectName } = useProjectDetailsContext()
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
  const deleteProject = useDeleteProject()
  const { mobile } = useBreakpoints()
  const history = useHistory()
  const urlOrders = `${ShopRoutes.OrdersList}?${new URLSearchParams({
    projectName: project.name,
    dateFrom: dayjs().subtract(12, 'month').format('YYYY-MM-DD'),
  }).toString()}`

  const onNameChange =
    variant === 'edit'
      ? async (form) => {
          await mutateProjectName(form.name)
        }
      : (form) => updateLocalProjectName(form.name)
  return (
    <>
      <div>
        <div className={styles.actions}>
          <div>
            <TertiaryButton leftIcon={<ArrowBackIcon />} size={iconSize} onClick={goBack}>
              {!mobile && <Typography variant="bodyBold">{t('PROJECTS.DETAILS.BACK')}</Typography>}
            </TertiaryButton>
          </div>
          <div className={styles.projectMetaActions}>
            {variant === 'edit' && (
              <>
                <ShopLink href={urlOrders} style={{ textDecoration: 'none' }}>
                  <TertiaryButton leftIcon={<TruckIcon />} size={iconSize}>
                    {!mobile && (
                      <Typography variant="bodyBold">
                        {t('PROJECTS.DETAILS.SHOW_OFFERS')}
                      </Typography>
                    )}
                  </TertiaryButton>
                </ShopLink>
                <TertiaryButton
                  leftIcon={<TrashIcon />}
                  size={iconSize}
                  onClick={() => setShowDeleteConfirmDialog(true)}
                >
                  {!mobile && (
                    <Typography variant="bodyBold">
                      {t('PROJECTS.DETAILS.DELETE_PROJECT')}
                    </Typography>
                  )}
                </TertiaryButton>
              </>
            )}
          </div>
        </div>
        <LightGrayDivider className={styles.divider} />
        <RenameField
          name={project.name}
          onClose={() => 0}
          onSubmit={onNameChange}
          previewLabel={project.name}
          showEditIcon={true}
        />
      </div>
      <Confirm
        body={t('PROJECTS.DELETE_DIALOG_BODY')}
        heading={t('PROJECTS.DELETE_DIALOG_HEADING')}
        openConfirmDialog={showDeleteConfirmDialog}
        handleCancel={() => setShowDeleteConfirmDialog(false)}
        handleConfirm={async () => {
          setShowDeleteConfirmDialog(false)
          const success = await deleteProject(project.id)
          if (success) {
            history.push(ShopRoutes.ProjectOverview)
          }
        }}
      />
    </>
  )
}
