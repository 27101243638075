import { FC } from 'react'
import { useProject } from '@obeta/data/lib/hooks/projects/useProject'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { ProjectForm } from './ProjectForm'
import { ProjectDetailsLoading } from './ProjectDetailsLoading'

const ProjectDetailsPage: FC = () => {
  const { projectId } = useParams()
  const { project, initialLoading } = useProject(projectId)

  if (initialLoading) return <ProjectDetailsLoading />
  if (!project) return null
  return <ProjectForm project={project} variant="edit" />
}

export default ProjectDetailsPage
