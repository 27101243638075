import { FetchResult, gql, useMutation } from '@apollo/client'
import { uploadFile as uploadFileToS3 } from '@obeta/utils/lib/upload-utils/uploadFile'
import {
  ConfirmUserUploadMutation,
  ConfirmUserUploadRequest,
  CreateUserUploadUrlMutation,
  CreateUserUploadUrlRequest,
  CreateUserUploadUrlResponse,
  Mutation,
  DeleteUserUploadUrlInput,
} from '@obeta/schema'
import { Dispatch, SetStateAction, useState } from 'react'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { TFunction } from 'i18next'
import {
  CameraPermissionMessage,
  checkCameraAuthorization,
  handleCameraPermissionError,
} from '@obeta/utils/lib/camera-authorization'
import { base64ToFile } from '@obeta/utils/lib/upload-utils/base64ToFile'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { useTranslation } from 'react-i18next'
import { useRxDB } from 'rxdb-hooks'

export const CREATE_USER_UPLOAD_URL = gql`
  mutation createUserUploadUrl($input: CreateUserUploadUrlRequest!) {
    createUserUploadUrl(input: $input) {
      s3Key
      params
      uploadUrl
    }
  }
`

export type DeleteUserUploadUrlMutation = {
  __typename?: 'Mutation'
  deleteUserUploadUrl: Mutation['deleteUserUploadUrl']
}

export const DELETE_USER_UPLOAD_URL = gql`
  mutation DeleteUserUploadUrl($input: DeleteUserUploadUrlInput!) {
    deleteUserUploadUrl(input: $input)
  }
`

export const CONFIRM_USER_UPLOAD = gql`
  mutation confirmUserUpload($input: ConfirmUserUploadRequest!) {
    confirmUserUpload(input: $input) {
      url
      responseMessage {
        message
        type
      }
    }
  }
`

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5 MB

const createUploadUrlRequestObject = (file: File): CreateUserUploadUrlRequest => {
  return {
    contentType: file.type,
    fileName: 'avatar.' + file.type,
    type: 'UserPhoto',
  }
}

export const useAvatarUpload = ({
  setAvatarSelectMenuVisible,
}: {
  setAvatarSelectMenuVisible: Dispatch<SetStateAction<boolean>>
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const { t } = useTranslation()
  const db = useRxDB()
  const [uploadError, setUploadError] = useState('')

  const [confirmUploadMutation] = useMutation<ConfirmUserUploadMutation>(CONFIRM_USER_UPLOAD, {
    onCompleted: (data) => {
      return data.confirmUserUpload
    },
    onError: (error) => {
      return error
    },
  })

  const [createUploadUrlMutation] = useMutation<CreateUserUploadUrlMutation>(
    CREATE_USER_UPLOAD_URL,
    {
      onCompleted: (data) => {
        return data.createUserUploadUrl
      },
      onError: (error) => {
        return error
      },
    }
  )

  const [deleteUserUploadUrlMutation] = useMutation<DeleteUserUploadUrlMutation>(
    DELETE_USER_UPLOAD_URL,
    {
      onCompleted: (data) => {
        return data.deleteUserUploadUrl
      },
      onError: (error) => {
        return error
      },
    }
  )

  const updateUserSettings = async (profilePictureS3Path: string) => {
    const user = await db.getLocal<UserV2>('userv2')
    await user?.incrementalModify((user) => ({
      ...user,
      settings: {
        defaultStoreId: user.settings?.defaultStoreId || '',
        defaultCartId: user.settings?.defaultCartId || '',
        name: user.settings?.name || '',
        profilePictureS3Path: profilePictureS3Path || '',
      },
    }))
  }

  const takePicture = async (source: CameraSource, t: TFunction) => {
    const authorizedMessage = await checkCameraAuthorization()

    if (authorizedMessage !== CameraPermissionMessage.GRANTED) {
      setAvatarSelectMenuVisible(false)
      return handleCameraPermissionError(authorizedMessage, t)
    }

    return Camera.getPhoto({
      quality: 80,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source,
      promptLabelCancel: t('BUTTON.ABORT'),
    })
  }

  const changeCustomerImage = async (source: CameraSource) => {
    try {
      const image = await takePicture(source, t)

      if (!image) return

      setIsUploading(true)
      const mime = image.format.startsWith('image/') ? image.format : 'image/' + image.format

      if (image.base64String) {
        const file = base64ToFile(image.base64String, mime, `avatar.${image.format}`)

        if (file.size > MAX_FILE_SIZE) {
          throw new Error(t('CUSTOMER_PROFILE.IMAGE_FILE_SIZE_ERROR_MESSAGE'))
        }

        await changeImageRequest(file)
        setAvatarSelectMenuVisible(false)
      }
    } catch (err) {
      if (err.errorMessage === 'User cancelled photos app') return

      setAvatarSelectMenuVisible(false)
      const errorMessage =
        err instanceof Error && err.message === t('CUSTOMER_PROFILE.IMAGE_FILE_SIZE_ERROR_MESSAGE')
          ? t('CUSTOMER_PROFILE.IMAGE_FILE_SIZE_ERROR_MESSAGE')
          : t('CUSTOMER_PROFILE.IMAGE_UPLOAD_GENERAL_ERROR')

      setUploadError(errorMessage)
      handleError(err)
      trackCustom('change-customer-image-on-customer-card-error')
    } finally {
      setIsUploading(false)
    }
  }

  const changeImageRequest = async (file: File) => {
    const createUploadUrlRequest = createUploadUrlRequestObject(file)

    try {
      const createUploadUrlResponse = await createUploadUrl(createUploadUrlRequest)
      const uploadUrlData = createUploadUrlResponse.data

      if (!uploadUrlData) throw new Error('Error on creating aws url.')

      const uploadFileResponse = await uploadFile(file, uploadUrlData.createUserUploadUrl)

      if (uploadFileResponse?.status !== 201) {
        return setUploadError('Error on uploading file.')
      }

      const confirmUploadResponse = await confirmUpload({
        s3Key: uploadUrlData.createUserUploadUrl.s3Key,
      })

      const confirmUploadData = confirmUploadResponse?.data?.confirmUserUpload
      if (!confirmUploadData || confirmUploadData.responseMessage.type === 'Error') {
        throw new Error(confirmUploadData?.responseMessage?.message)
      }

      await updateUserSettings(confirmUploadData.url)
    } catch (error) {
      setUploadError(t('CUSTOMER_PROFILE.IMAGE_UPLOAD_FAILED'))
      handleError(error)
    }
  }

  const deleteUserUploadUrl = async (input: DeleteUserUploadUrlInput): Promise<void> => {
    try {
      await deleteUserUploadUrlMutation({ variables: { input } })
      await updateUserSettings('')
    } catch (error) {
      setUploadError(t('CUSTOMER_PROFILE.IMAGE_REMOVAL_FAILED'))
      handleError(error)
    }
  }

  const confirmUpload = async (
    input: ConfirmUserUploadRequest
  ): Promise<FetchResult<ConfirmUserUploadMutation>> => {
    return await confirmUploadMutation({ variables: { input } })
  }

  const createUploadUrl = async (
    input: CreateUserUploadUrlRequest
  ): Promise<FetchResult<CreateUserUploadUrlMutation>> => {
    return await createUploadUrlMutation({ variables: { input } })
  }

  const uploadFile = async (
    file: File,
    createUploadUrlResponse: CreateUserUploadUrlResponse
  ): Promise<Response | undefined> => {
    return uploadFileToS3(file, createUploadUrlResponse)
  }

  return {
    isUploading,
    changeCustomerImage,
    uploadError,
    setUploadError,
    deleteUserUploadUrl,
  }
}
