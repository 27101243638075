import { SvgIcon, Typography } from '@mui/material'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { Counter } from '@obeta/components/lib/counter/Counter'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CantBePackagedIcon } from '@obeta/assets/icon/cant_be_packaged.svg'
import { ReactComponent as VoltimumIcon } from '@obeta/assets/icon/voltimum.svg'
import { ReactComponent as ContentCutIcon } from '@obeta/assets/icon/content_cut.svg'
import { ReactComponent as XpressLogo } from '@obeta/assets/icon/xpress_logo_rotweiss_3.svg'
import { ReactComponent as LocalShippingIcon } from '@obeta/assets/icon/local_shipping.svg'
import { ReactComponent as RestoreIcon } from '@obeta/assets/icon/restore.svg'
import { ReactComponent as DescriptionIcon } from '@obeta/assets/icon/description.svg'
import { IArticle } from './types'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { ReactComponent as TecSelectIcon } from '@obeta/assets/icon/tec_select.svg'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { AddItemsToCartButtonWithCarts } from '@obeta/components/lib/add-items-to-cart-button/AddItemsToCartButton'
import { ArticleStickyHeader } from './ArticleStickyHeader'
import { useWarehouse } from '@obeta/data/lib/hooks/useWarehouse'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { PricesAuthenticateView } from '@obeta/components/lib/prices-authenticate-view/PricesAuthenticateView'
import { PricePermissions, UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { PopoverMobileCounter } from '@obeta/components/lib/counter/MobileCounter'
import { PricesNoPermission } from './PricesNoPermission'
import { useProductCard } from '@obeta/data/lib/hooks/useProductCard'
import {
  ILabelWithIconProps,
  LabelWithIcon,
} from '@obeta/components/lib/label-with-icon/LabelWithIcon'
import { productHasTecSelect } from '@obeta/utils/lib/productHasTecSelect'
import styles from './ArticlePrice.module.scss'
import { IProductIds, useCurrentProductIds } from '@obeta/data/lib/hooks/useCurrentProductIds'
import { DropdownTemplates } from './DropdownTemplates'
import {
  IProductPrices,
  useProductPrices,
  useProductTotalPrice,
} from '@obeta/data/lib/hooks/useProductPrices'
import { StockInfo } from '@obeta/components/lib/stock-info/StockInfo'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import { useLocation } from '@obeta/data/lib/hooks/useHistoryApi'
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useVoltimumPoints } from '@obeta/data/lib/hooks/useVoltimumPoints'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { IdsTransferProductButton } from '@obeta/components/lib/ids/IdsTransferProductButton'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'

interface IPrice {
  variant: 'headline2Bold' | 'headline4Bold'
  value: string
  description: string
  loading: boolean
}

const Price: React.FC<IPrice> = (props) => {
  if (props.loading) {
    return <TextSkeleton width={140} height={38} />
  }

  return (
    <div className={styles.price}>
      <Typography color="secondary.main" variant={props.variant}>
        {props.value}
      </Typography>
      <Typography className={styles.priceDescription} variant="body">
        {props.description}
      </Typography>
    </div>
  )
}

interface IActionsProps {
  isNotSendable: boolean
  isCutProduct: boolean
}

const Labels: React.FC<IActionsProps> = (props) => {
  const { isNotSendable, isCutProduct } = props
  const { t } = useTranslation()

  const labels: Array<ILabelWithIconProps> = []
  if (isNotSendable) {
    labels.push({ icon: <CantBePackagedIcon />, label: t<string>('ARTICLE_DETAIL.NOT_SENDABLE') })
  }

  if (isCutProduct) {
    labels.push({
      /** This icon has different size than other icons (24x24). SvgIcon will make it 16x16 */
      icon: <SvgIcon component={ContentCutIcon} />,
      label: t<string>('ARTICLE_DETAIL.CUT_PRODUCT'),
    })
  }

  if (!labels.length) {
    return null
  }

  return (
    <div className={styles.labels}>
      {labels.map((labelObj) => {
        return <LabelWithIcon key={labelObj.label} {...labelObj} />
      })}
    </div>
  )
}

interface IPricesBlockProps {
  amount: number
  prices: IProductPrices
}

const PricesBlock: React.FC<IPricesBlockProps> = (props) => {
  const { amount, prices: pricesData } = props
  const { t } = useTranslation()
  const { prices, loading: loadingPrices } = pricesData
  const currency = prices?.currency
  const metalPrice = prices?.metalNeAddition || 0
  const withMetal = metalPrice > 0
  const { total, subTotal, totalMetalAdition, totalStrikeThrough } = useProductTotalPrice(prices, {
    amount,
  })

  return (
    <>
      <div className={styles.priceBlock}>
        {prices && Boolean(prices?.strikeThroughPrice) && (
          <Typography className={styles.oldPrice} variant="xSmallTextLineThrough">
            {normalizePrice(totalStrikeThrough, currency)}
          </Typography>
        )}
        <Price
          loading={loadingPrices}
          variant="headline2Bold"
          value={normalizePrice(total, currency)}
          description={t<string>(
            withMetal ? 'ARTICLE_DETAIL.TOTAL_PRICE_INCLUDING_METAL' : 'ARTICLE_DETAIL.TOTAL_PRICE'
          )}
        />
      </div>
      {withMetal && (
        <div className={styles.priceDetails}>
          <Price
            loading={loadingPrices}
            variant="headline4Bold"
            value={normalizePrice(subTotal, currency)}
            description={t<string>('ARTICLE_DETAIL.TOTAL_PRICE')}
          />
          <LightGrayDivider orientation="vertical" />
          <Price
            loading={loadingPrices}
            variant="headline4Bold"
            value={normalizePrice(totalMetalAdition, currency)}
            description={t<string>('SHOPPING_CART.DETAILS.METAL_SURCHARGE')}
          />
        </div>
      )}
    </>
  )
}

/**
 * @param props.withCounter - allows to hide counter.
 *  TODO: create component that will encapsulate productAmount state
 *  (this will allow to remove state if don't need counter)
 */
interface IArticlePriceProps {
  article: IArticle | undefined
  selectedStore: StoreV2 | undefined
  withDropdownTemplates?: boolean
  withAddItemsToCartButton?: boolean
  withCounter?: boolean
  withStickyHeader?: boolean
}

interface IMainProps extends IArticlePriceProps {
  user: UserV2 | undefined
  prices: IProductPrices
  productIds: IProductIds
  isLoggedIn: boolean
}

const pricesAvailable = (permissions: PricePermissions | undefined) => {
  if (!permissions) {
    return false
  }

  return permissions === PricePermissions.PurchasePrice
}

const Main: React.FC<IMainProps> = (props) => {
  const {
    article,
    selectedStore,
    user,
    prices,
    withDropdownTemplates = true,
    withAddItemsToCartButton = true,
    withCounter = true,
    withStickyHeader = true,
    productIds,
    isLoggedIn,
  } = props

  const [productAmount, setProductAmount] = useState(article?.minimumAmount || 0)
  const location = useLocation()
  const { mobile, tabletAll } = useBreakpoints()

  const { setAmountRoundUpNotification } = useProductCard()

  const [initialAmount] = useState(() => {
    const searchParams = new URLSearchParams(location.search)
    const amount = searchParams.get('amount')
    if (!amount) {
      return article?.minimumAmount || 0
    }

    return parseFloat(amount)
  })

  const { stocks, stockAvailabilityEstimate } = useWarehouse(
    productIds,
    ['main', selectedStore?.id],
    isLoggedIn
  )
  const [showMobileCounter, setShowMobileCounter] = useState(false)
  const mobileCounterAvailable = Boolean(mobile || tabletAll)

  const cartButtonRef = useRef<HTMLDivElement>(null)
  const withIdsTransferBackButton = useIsIdsTransferBackPossible()

  const stocksMap = useStocksMap({
    isOnlineCurrentNotAvailable: Boolean(article?.isCurrentlyNotAvailable),
    stocks,
    user: user || null,
  })

  const newStock = article && (
    <StockInfo
      direction={'vertical'}
      productType={article.type}
      productAmount={productAmount}
      storeAddress={selectedStore?.address?.name1 || ''}
      stockAvailabilityEstimate={stockAvailabilityEstimate}
      stocks={stocksMap}
    />
  )

  return (
    <>
      {withStickyHeader && (
        <ArticleStickyHeader
          mobile={mobile}
          productAmount={productAmount}
          isLoggedIn={isLoggedIn}
          buttonRef={cartButtonRef}
        />
      )}
      <div className={styles.header}>
        {article && <div className={styles.statusGroup}>{newStock}</div>}
      </div>
      <LightGrayDivider />
      {pricesAvailable(user?.permissions?.Global_canReadPrices) ? (
        <PricesBlock amount={productAmount} prices={prices} />
      ) : (
        <PricesNoPermission
          listPricePermission={
            user?.permissions?.Global_canReadPrices === PricePermissions.ListPrice
          }
        />
      )}
      {withCounter && (
        <Counter
          stretchHorizontal={true}
          amount={productAmount}
          minimumAmount={article?.minimumAmount || 0}
          initialAmount={initialAmount}
          variant={'big'}
          readonly={mobileCounterAvailable}
          disabled={false}
          onInputValidation={(valid, amountToUse, amount) => {
            if (!valid && article) {
              setAmountRoundUpNotification(article?.sapId, amountToUse, amount, article?.unit)
            }
          }}
          changeProductAmount={setProductAmount}
          onTextFieldClicked={() => {
            setShowMobileCounter(true)
          }}
          dataAttributes={{ 'data-track-id': 'addToBasketQuantity' }}
        />
      )}
      {mobileCounterAvailable && (
        <PopoverMobileCounter
          open={showMobileCounter}
          onClose={function () {
            setShowMobileCounter(false)
          }}
          initialAmount={productAmount}
          unit={article?.unit ?? ''}
          minimumAmount={article?.minimumAmount || 0}
          maxAcceptableAmount={9999}
          onAccept={function (value: number): void {
            setProductAmount(value)
            setShowMobileCounter(false)
          }}
        />
      )}
      {(withAddItemsToCartButton || withIdsTransferBackButton) && (
        <div className={styles.submitGroup}>
          {withAddItemsToCartButton && (
            <AddItemsToCartButtonWithCarts
              ref={cartButtonRef}
              size="large"
              mobile={mobile}
              product={article}
              productAmount={productAmount}
              algoliaUserToken={user?.algoliaUserToken}
              dataAttributes={{
                'data-track-id': 'addToCartPDP',
                'data-product-id': productIds?.sapIds?.[0] ?? '',
              }}
            />
          )}
          {withIdsTransferBackButton && (
            <IdsTransferProductButton
              sapId={productIds?.sapIds?.[0] ?? ''}
              amount={productAmount}
              variant="secondary"
            />
          )}
        </div>
      )}
      {withDropdownTemplates && (
        <DropdownTemplates
          mobile={mobile}
          withTitle={true}
          productAmount={productAmount}
          templatesType={DropdownTemplatesType.ADD_ARTICLES}
        />
      )}

      <Labels
        isNotSendable={article?.isSendable === false}
        isCutProduct={article?.isCutProduct === true}
      />
    </>
  )
}

interface IArticlePriceBaseProps extends IArticlePriceProps {
  user: UserV2 | null
  isLoggedIn: boolean
  productIds: IProductIds
  className?: string
}

export const ArticlePriceBase: React.FC<IArticlePriceBaseProps> = (props) => {
  const { article, user, isLoggedIn, className, productIds } = props
  const productPricesData = useProductPrices(article?.sapId, isLoggedIn)
  const { prices } = productPricesData

  const { sapIds } = useVoltimumPoints([{ sapId: productIds?.sapIds?.[0] ?? '', amount: 1 }])
  const showVoltimum = sapIds.length === 1
  return (
    <div className={clsx(styles.root, className)}>
      {isLoggedIn && user ? (
        <Main {...props} user={user} prices={productPricesData} isLoggedIn={isLoggedIn} />
      ) : (
        <PricesAuthenticateView />
      )}
      <LightGrayDivider orientation="horizontal" />
      {showVoltimum && (
        <div className={styles.logos}>
          <span className={styles.voltimumIcon}>
            <VoltimumIcon />
          </span>
        </div>
      )}
      <div className={styles.deliveryActions}>
        {productHasTecSelect(prices) && <TecSelectIcon />}
        {[
          { icon: XpressLogo, title: 'Xpress Filiale' },
          { icon: LocalShippingIcon, title: 'Sofort lieferbar' },
          { icon: RestoreIcon, title: 'Kostenlos 30-Tage-Rückgabe' },
          { icon: DescriptionIcon, title: 'Kauf auf Rechnung' },
        ].map((action) => {
          const Icon = action.icon
          return (
            <div key={action.title} className={styles.wrapper}>
              <Icon />
              <Typography variant="smallText" className={styles.deliveryActionButton}>
                {action.title}
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const ArticlePrice: React.FC<IArticlePriceProps> = (props) => {
  const { isLoggedIn, user } = useUserDataV2()
  const productIds = useCurrentProductIds()

  return (
    <ArticlePriceBase
      className={styles.rootMargins}
      isLoggedIn={isLoggedIn}
      user={user}
      productIds={productIds}
      {...props}
    />
  )
}
