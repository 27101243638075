import { FC } from 'react'
import checkoutSuccessStyles from '../shoppingcart/CheckoutOrderSuccess.module.scss'
import orderPendingStyles from '../shoppingcart/CheckoutOrderPending.module.scss'
import styles from './UserRegistrationDialog.module.scss'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export const UserRegistrationPending: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.root)} id={styles.root}>
      <div className={orderPendingStyles.animationMainContainer}>
        <div className={checkoutSuccessStyles.circleLoader}></div>
      </div>
      <div className={styles.headlines}>
        <Typography id="modal-heading" variant={'headline3Bold'}>
          {t('SIGNUP.DATA_CHECKING')}
        </Typography>
      </div>
    </div>
  )
}
