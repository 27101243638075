import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  LocalNotificationDescriptor,
  LocalNotifications,
  LocalNotificationSchema,
} from '@capacitor/local-notifications'
import { useRxDB } from 'rxdb-hooks'
import { addMarkForNotification, removeMarkForNotification } from '../actions'

import { NotificationEnum } from '@obeta/models/lib/models/GraphQL/globalTypes'
import {
  buffer,
  combineLatest,
  concat,
  concatMap,
  defer,
  filter,
  map,
  mergeAll,
  skipUntil,
} from 'rxjs'
import { useDbUtils } from './useDbUtils'
import { Message, MessageStatus } from '@obeta/models/lib/models/Message'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { PluginListenerHandle } from '@capacitor/core'
import { MangoQuerySortDirection, RxDocument } from 'rxdb'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { FeedType } from '@obeta/utils/lib/pubSub'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { useHistory } from './useHistoryApi'
import { useUserDataV2 } from './useUserDataV2'

let nextId = 0

interface MessagePointer {
  notificationId: number
  messageId?: string
  isGroupSummary?: boolean
  summaryType?: 'cart' | 'cart_notified'
  event?: NotificationEnum
}

export const useUpdateCartsMessages = () => {
  const { userId } = useUserDataV2()
  const dispatch = useDispatch()
  const db = useRxDB()
  const { push } = useHistory()
  const { getCollectionSync } = useDbUtils()

  useEffect(() => {
    if (isPlatform('android')) {
      const createChannels = async () => {
        await LocalNotifications.createChannel({
          id: 'cart',
          name: 'Auftragsbenachrichtigungen',
          importance: 3,
          description: 'Zeigt Benachrichtigungen zu abgeschickten Warenkörben',
        })
        await LocalNotifications.createChannel({
          id: 'cart_notified',
          name: 'Gemeldete Warenkörbe',
          importance: 3,
          description: 'Zeigt Benachrichtigungen zu gemeldeten Warenkörben',
        })
      }
      createChannels()
    }

    let handle: PluginListenerHandle | undefined
    const register = async () => {
      handle = await LocalNotifications.addListener(
        'localNotificationActionPerformed',
        (action) => {
          if (action.notification.extra.event === NotificationEnum.CartNotified) {
            push('/carts/' + action.notification.extra.cartId)
          } else if (action.notification.extra.event === NotificationEnum.OrderCreated) {
            push('/orders')
          } else if (
            [NotificationEnum.OrderFailed, NotificationEnum.OrderIdMissingInResponse].indexOf(
              action.notification.extra.event
            ) !== -1
          ) {
            push('/message-center')
          } else {
            push('/message-center')
          }
        }
      )
    }
    register()

    return () => {
      handle?.remove()
    }
  }, [push])

  useEffect(() => {
    if (!userId) {
      return
    }

    const cart$ = db.cartsv2.find().$.pipe(
      filter((cart) => cart.length > 0),
      map((cart: RxDocument<ShoppingCartV2>[]) => {
        return cart.map((doc) => {
          return doc.toJSON()
        })
      })
    )

    const newMessages$ = db.message.find({
      selector: {
        status: {
          $eq: 'new',
        },
      },
      sort: [{ updatedAtNumber: 'desc' as MangoQuerySortDirection }],
    }).$

    const sub = combineLatest([
      cart$,
      concat([
        newMessages$.pipe(buffer(cart$), mergeAll()),
        newMessages$.pipe(skipUntil(cart$)),
      ]).pipe(mergeAll()),
    ])
      .pipe(
        concatMap(([carts, messages]: [RxDocument<ShoppingCartV2>[], RxDocument<Message>[]]) => {
          // if isActive is false it means, that the sync was active and has finished now
          return defer(async () => {
            const idsToStore: MessagePointer[] = []
            const msgToSchedule: LocalNotificationSchema[] = []

            const timeDoc = await db.message.getLocal<{ highestInsertTime: string }>(
              'highestInsertTime'
            )
            const currentHighestInsertTime = timeDoc?.get('highestInsertTime') || ''
            let nextHighestInsertTime = currentHighestInsertTime
            const doc = await db.message.getLocal<{ items: MessagePointer[] }>('notificationIds')
            let currentNotifications: MessagePointer[] = []
            if (doc) {
              currentNotifications = doc.get('items')
            }

            messages.forEach((message: Message) => {
              const existingPointer = currentNotifications.find(
                (currNot) => currNot.messageId === message.id
              )
              nextHighestInsertTime =
                message.createdAt > nextHighestInsertTime
                  ? message.createdAt
                  : nextHighestInsertTime
              const existingId = existingPointer?.notificationId

              if (existingId) {
                idsToStore.push({
                  messageId: message.id,
                  notificationId: existingId,
                  event: message.event,
                })
                return
              }

              if (message.createdAt < currentHighestInsertTime) {
                return
              }

              nextId++

              let cart
              if (message.data.cartId) {
                cart = carts.find((sc) => sc.id === message.data.cartId)
              }

              if (
                message.event === NotificationEnum.OrderCreated &&
                message.status === MessageStatus.new
              ) {
                idsToStore.push({
                  messageId: message.id,
                  notificationId: existingId || nextId,
                  event: message.event,
                })
                msgToSchedule.push({
                  extra: {
                    cartId: message.data.cartId,
                    event: message.event,
                    isGroupSummary: false,
                    messageId: message.id,
                    orderId: message.data.orderId,
                  },
                  id: existingId || nextId,
                  title: 'Auftrag ausgelöst',
                  body: `Ihr Auftrag für den Warenkorb “${cart?.name}” wurde erfolgreich ausgelöst`,
                  group: 'cart',
                  threadIdentifier: 'cart',
                  channelId: 'cart',
                })
                // remove marked cart
                dispatch(removeMarkForNotification(message.data.cartId))
              } else if (
                message.event === NotificationEnum.CartNotified &&
                message.status === MessageStatus.new
              ) {
                // mark cart
                dispatch(addMarkForNotification(message.data.cartId))
                idsToStore.push({
                  messageId: message.id,
                  notificationId: existingId || nextId,
                  event: message.event,
                })
                msgToSchedule.push({
                  extra: {
                    cartId: message.data.cartId,
                    event: message.event,
                    isGroupSummary: false,
                    messageId: message.id,
                  },
                  id: existingId || nextId,
                  title: 'Warenkorb gemeldet',
                  body: `Der Warenkorb “${cart?.name}” kann bestellt werden`,
                  group: 'cart_notified',
                  threadIdentifier: 'cart_notified',
                  channelId: 'cart_notified',
                })
              } else if (
                [NotificationEnum.OrderFailed, NotificationEnum.OrderIdMissingInResponse].indexOf(
                  message.event
                ) !== -1 &&
                message.status === MessageStatus.new
              ) {
                idsToStore.push({
                  messageId: message.id,
                  notificationId: existingId || nextId,
                  event: message.event,
                })
                msgToSchedule.push({
                  extra: {
                    cartId: message.data.cartId,
                    event: message.event,
                    isGroupSummary: false,
                    messageId: message.id,
                  },
                  id: existingId || nextId,
                  title: 'Auftrag konnte nicht ausgelöst werden',
                  body: `Der Warenkorb “${cart?.name}” konnte nicht bestellt werden`,
                  group: 'cart',
                  threadIdentifier: 'cart',
                  channelId: 'cart',
                })
              }
            })

            // show group summary
            if (nextHighestInsertTime > currentHighestInsertTime && idsToStore.length > 0) {
              let countCartSent = 0
              let countCartSendFailed = 0
              let cartNotified = 0
              idsToStore.forEach((notification) => {
                if (notification.event === NotificationEnum.OrderCreated) {
                  countCartSent++
                } else if (
                  notification.event &&
                  [NotificationEnum.OrderFailed, NotificationEnum.OrderIdMissingInResponse].indexOf(
                    notification.event
                  ) !== -1
                ) {
                  countCartSendFailed++
                } else if (notification.event === NotificationEnum.CartNotified) {
                  cartNotified++
                }
              })
              if (countCartSent + countCartSendFailed > 1) {
                const existingPointer = currentNotifications.find(
                  (currNot) => currNot.summaryType === 'cart'
                )
                const existingId = existingPointer?.notificationId
                const text: string[] = []
                if (countCartSent === 1) {
                  text.push(`1 Auftrag ausgelöst`)
                } else if (countCartSent > 1) {
                  text.push(`${countCartSent} Aufträge ausgelöst`)
                }
                if (countCartSendFailed === 1) {
                  text.push(`1 Auftrag fehlgeschlagen`)
                } else if (countCartSent > 1) {
                  text.push(`${countCartSendFailed} Aufträge fehlgeschlagen`)
                }

                let newId
                if (!existingId) {
                  newId = nextId++
                }
                idsToStore.push({
                  isGroupSummary: true,
                  notificationId: existingId || newId,
                  summaryType: 'cart',
                })

                msgToSchedule.push({
                  extra: {
                    isGroupSummary: true,
                  },
                  id: existingId || newId,
                  title: text.join(', '),
                  body: text.join(', '),
                  group: 'cart',
                  threadIdentifier: 'cart',
                  channelId: 'cart',
                  groupSummary: true,

                  summaryText: text.join(', '),
                })
              }
              if (cartNotified > 1) {
                const existingPointer = currentNotifications.find(
                  (currNot) => currNot.summaryType === 'cart_notified'
                )
                const existingId = existingPointer?.notificationId
                let newId
                if (!existingId) {
                  newId = nextId++
                }
                idsToStore.push({
                  isGroupSummary: true,
                  notificationId: existingId || newId,
                  summaryType: 'cart_notified',
                })

                const text = `${cartNotified} ${
                  cartNotified === 1 ? 'Warenkorb' : 'Warenkörbe'
                } gemeldet`
                msgToSchedule.push({
                  extra: {
                    isGroupSummary: true,
                  },
                  id: existingId || newId,
                  title: text,
                  body: text,
                  group: 'cart_notified',
                  threadIdentifier: 'cart_notified',
                  channelId: 'cart_notified',
                  groupSummary: true,
                  summaryText: text,
                })
              }
            }

            const toDelete: LocalNotificationDescriptor[] = []
            currentNotifications.forEach((toD) => {
              // if current notifications are read by the user
              // we remove them from the drawer/homescreen
              const hit = idsToStore.find((mp) => mp.messageId === toD.messageId)
              if (toD.isGroupSummary || !hit) {
                toDelete.push({
                  id: toD.notificationId,
                })
              }
            })
            if (!isPlatform('web')) {
              if (toDelete.length > 0) {
                await LocalNotifications.cancel({
                  notifications: toDelete,
                })
              }
              // TODO: As a temporary solution, it was decided to comment out this logic in order not to annoy the users
              //  https://coditorei.atlassian.net/jira/software/c/projects/COD/boards/14?assignee=640ee38fb05b4e3e7da83ae6&selectedIssue=COD-17277
              // This logic will be improved
              /*   if (msgToSchedule.length > 0) {
                  await LocalNotifications.schedule({
                    notifications: msgToSchedule,
                  })
                }*/
            }
            await db.message.upsertLocal('notificationIds', {
              items: idsToStore,
            })

            await db.message.upsertLocal('highestInsertTime', {
              highestInsertTime: nextHighestInsertTime,
            })
          })
        })
      )
      .subscribe(() => {
        //
      })

    const unsubs: Array<() => void> = [
      () => {
        sub.unsubscribe()
      },
    ]

    if (!isPlatform('web')) {
      let firebaseListener
      const runFirebaseListener = async () => {
        firebaseListener = await FirebaseMessaging.addListener(
          'notificationReceived',
          async (notificationData) => {
            const notification = notificationData.notification
            const payload = notification.data as { feedType: FeedType }
            if (payload.feedType) {
              switch (payload.feedType) {
                case FeedType.Cart: {
                  trackCustom('cartsv2-resync-firebase-notification')
                  getCollectionSync('cartsv2')?.reSync()
                  return
                }
                case FeedType.CartTemplate:
                  getCollectionSync('carttemplates')?.reSync()
                  return
              }
            } else {
              // trigger message sync
              const sync = getCollectionSync('message')
              sync?.reSync()
            }
            // delete cart if cart sent event
          }
        )
      }

      runFirebaseListener()

      unsubs.push(() => {
        firebaseListener?.remove()
      })
    }

    return () => {
      unsubs.forEach((unsub) => {
        unsub()
      })
    }
  }, [userId, db, dispatch, getCollectionSync])
}
