import React, { useContext, useEffect, useRef } from 'react'
import { Paper } from '@mui/material'
import { useProduct } from './ProductProvider'
import styles from './ArticleStickyHeader.module.scss'
import { AddItemsToCartButtonWithCarts } from '@obeta/components/lib/add-items-to-cart-button/AddItemsToCartButton'
import { DropdownTemplates } from './DropdownTemplates'
import { ProductImage } from '@obeta/components/lib/product-card/layouts/ProductImage'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { IArticle } from './types'
import clsx from 'clsx'
import { useMobileScrollHandler } from '@obeta/data/lib/hooks/useMobileScrollHandler'
import { useStickyElementStyles } from '@obeta/components/lib/page-elements/PageElements'
import { useProductPrices, useProductTotalPrice } from '@obeta/data/lib/hooks/useProductPrices'
import { ArticleDetailsElementsById } from '@obeta/utils/lib/variables'
import { ProductOxomiImage } from '@obeta/components/lib/product-images/ProductOxomiImage'
import { BaseTitle } from '@obeta/components/lib/product-card/Title'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { ArticleStickyHeaderStrikeThroughPrice } from './ArticleStickyHeaderStrikeThroughPrice'
import { ArticleStickyHeaderPrice } from './ArticleStickyHeaderPrice'
import { hasReadPricePermission } from '@obeta/utils/lib/hasReadPricePermission'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { ImgProxyImage } from '@obeta/components/lib/img-proxy-image/ImgProxyImage'
import { ArticleStickyHeaderContext } from './wrappers/ArticleStickyHeaderProvider'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'

interface IArticleStickyHeader extends WithBreakpoints<'mobile'> {
  productAmount: number
  isLoggedIn: boolean
  forceShow?: boolean //this is only used in the associated stories, any better idea?
  buttonRef?: React.RefObject<HTMLDivElement>
}

const Product: React.FC<{ product: IArticle; mobile?: boolean }> = (props) => {
  const { product, mobile } = props
  const validUrl = ensureValidImgProxyUrl(product?.images?.[0]?.url)
  const imageElement = validUrl ? (
    <ImgProxyImage
      variant="product"
      className={styles.productImage}
      url={validUrl}
      title={product.title}
      widthInPx={product?.images?.[0]?.width}
    />
  ) : (
    <ProductOxomiImage
      key="product-image"
      src={product.imageData.images[0]?.large}
      alt={product.title}
      supplierImage={product.supplierImageData?.large}
      supplierId={product.supplierId}
      oxomiId={product.oxomiId}
      className={styles.productImage}
    />
  )

  const titleElement = (
    <BaseTitle key="product-title" lines={2} variant={mobile ? 'bodyBold' : 'boldText'}>
      {product.title}
    </BaseTitle>
  )

  return (
    <div className={styles.product}>
      <ProductImage size="48">{imageElement}</ProductImage>
      {titleElement}
    </div>
  )
}

export const ArticleStickyHeader: React.FC<IArticleStickyHeader> = (props) => {
  const { productAmount, mobile, isLoggedIn, forceShow = false } = props
  const product = useProduct()
  const { prices, loading: loadingPrices } = useProductPrices(product?.sapId, isLoggedIn)
  const { total, totalStrikeThrough } = useProductTotalPrice(prices, {
    amount: productAmount,
  })

  const { user } = useUserDataV2()
  const userCanReadPrices = hasReadPricePermission(user)

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const { updateStickyHeaderPosition } = useContext(ArticleStickyHeaderContext)

  let heightToScroll = 0

  if (props.buttonRef?.current) {
    heightToScroll =
      props.buttonRef.current.offsetTop +
      (props.buttonRef.current.offsetParent
        ? parseInt(getComputedStyle(props.buttonRef.current.offsetParent).paddingTop, 10)
        : 0)
  }

  const show = !useMobileScrollHandler({
    targetSectionHeight: heightToScroll,
    isEnabled: true,
  })

  const updateArticleSticky = ({ topDistance }) => {
    if (!mobile) {
      const articleStickyHeaderHeight = wrapperRef?.current?.clientHeight || 0
      updateStickyHeaderPosition(articleStickyHeaderHeight + topDistance)
    }
  }

  const cssStyles = useStickyElementStyles({ getSizesCallback: updateArticleSticky })

  useEffect(() => {
    updateArticleSticky({ topDistance: wrapperRef?.current?.offsetTop || 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, cssStyles, show])

  if (!show && !forceShow) {
    return null
  }

  const currency = prices?.currency

  const common = (
    <>
      <div className={styles.prices}>
        <ArticleStickyHeaderStrikeThroughPrice
          price={totalStrikeThrough ? normalizePrice(totalStrikeThrough, currency) : null}
          userCanReadPrices={userCanReadPrices}
        />
        <ArticleStickyHeaderPrice
          price={normalizePrice(total, currency)}
          userCanReadPrices={userCanReadPrices}
          mobile={mobile}
          loadingPrices={loadingPrices}
        />
      </div>
      <div className={styles.actions}>
        <AddItemsToCartButtonWithCarts
          product={product}
          mobile={mobile}
          productAmount={productAmount}
          algoliaUserToken={user?.algoliaUserToken}
          size="large"
        />
        <DropdownTemplates
          mobile={mobile}
          withTitle={!mobile}
          withEndIcon={false}
          productAmount={productAmount}
          templatesType={DropdownTemplatesType.ADD_ARTICLES}
        />
      </div>
    </>
  )

  return (
    <Paper
      id={ArticleDetailsElementsById.StickyBox}
      style={cssStyles}
      ref={wrapperRef}
      className={clsx(styles.root, { [styles.mobile]: mobile })}
    >
      <div className={styles.wrapper}>
        {product && <Product product={product} mobile={mobile} />}
        {common}
      </div>
    </Paper>
  )
}
