import { ProjectDetailsProject } from '@obeta/models/lib/schema-models/project'
import { FC } from 'react'
import { ProjectFormProvider } from '@obeta/data/lib/stores/useProjectFormContext'
import { ProjectDetailsProvider } from '@obeta/data/lib/stores/useProjectDetailsContext'
import { ProjectDetailsPageHeader } from './ProjectDetailsPageHeader'
import { ProjectCartPreSelectionForm } from './ProjectCartPreSelectionForm'
import styles from './ProjectForm.module.scss'

export type ProjectFormVariant = 'create' | 'edit'

type Props = {
  project: ProjectDetailsProject
  variant: ProjectFormVariant
}

export const ProjectForm: FC<Props> = ({ project, variant }) => (
  <ProjectFormProvider>
    <ProjectDetailsProvider initialProject={project}>
      <div className={styles.container}>
        <ProjectDetailsPageHeader variant={variant} />
        <ProjectCartPreSelectionForm variant={variant} />
      </div>
    </ProjectDetailsProvider>
  </ProjectFormProvider>
)
