import { ComponentType, FC, ReactNode } from 'react'
import { FormControlLabel, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { ShippingGroup } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { PaymentMethod } from '@obeta/schema'
import { ReactComponent as InvoiceIcon } from 'assets/icon/designsystem/description.svg'
import { ReactComponent as CreditCartIcon } from 'assets/icon/designsystem/payment.svg'
import { ReactComponent as CashIcon } from 'assets/icon/designsystem/payments.svg'
import { ReactComponent as PaypalIcon } from 'assets/icon/designsystem/paypal.svg'
import { ReactComponent as PaypalDisabledIcon } from 'assets/icon/designsystem/paypal-disabel.svg'
import { ReactComponent as MastercardIcon } from 'assets/icon/designsystem/Mastercard.svg'
import { ReactComponent as MastercardDisabledIcon } from 'assets/icon/designsystem/Mastercard-disabel.svg'
import { ReactComponent as VisaIcon } from 'assets/icon/designsystem/Visa.svg'
import { ReactComponent as VisaDisabledIcon } from 'assets/icon/designsystem/Visa-disabeld.svg'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import style from './ShoppingCartPaymentMethods.module.scss'
import { SimpleInfoBlock } from '../notifications/SimpleInfoBlock'

type ShoppingCartPaymentMethodsProps = {
  selectedPaymentMethod: PaymentMethod
  control: Control
  activeMethods: PaymentMethod[]
  shippingGroup: ShippingGroup
  updatePaymentMethod: (paymentMethod: PaymentMethod) => void
  userIsCashCustomer: boolean
}

type ShoppingCartPaymentOptionProps = {
  isSelected?: boolean
  disabled?: boolean
  label: string
  icon: ReactNode
  onClick: () => void
  value: PaymentMethod
}

const ShoppingCartPaymentOption: FC<ShoppingCartPaymentOptionProps> = ({
  label,
  icon,
  isSelected,
  onClick,
  disabled,
  value,
}) => {
  return (
    <div
      aria-label={label}
      onClick={onClick}
      className={clsx(style.option, isSelected && style.selectedOption, disabled && style.disabled)}
    >
      {value !== 'CreditCard' ? (
        <div className={style.optionIconContainer}>
          <div
            className={clsx(
              style.optionIcon,
              disabled && style.disabled,
              value === 'PayPal' && style.colored
            )}
          >
            {value === 'PayPal' && disabled ? <PaypalDisabledIcon /> : icon}
          </div>
        </div>
      ) : (
        <div className={style.creditCartContainer}>
          <div className={style.optionIconContainer}>
            <div className={clsx(style.optionIcon, disabled && style.disabled, style.colored)}>
              {disabled ? <VisaDisabledIcon /> : <VisaIcon />}
            </div>
          </div>
          <div className={style.optionIconContainer}>
            <div className={clsx(style.optionIcon, disabled && style.disabled, style.colored)}>
              {disabled ? <MastercardDisabledIcon /> : <MastercardIcon />}
            </div>
          </div>
        </div>
      )}
      <Typography className={clsx(style.optionText, disabled && style.disabled)}>
        {label}
      </Typography>
    </div>
  )
}

const PAYMENT_METHODS: Array<{
  translationKey: string
  value: PaymentMethod
  IconComponent: ComponentType
}> = [
  { translationKey: 'INVOICE', value: 'Invoice', IconComponent: InvoiceIcon },
  { translationKey: 'CASH', value: 'Cash', IconComponent: CashIcon },
  { translationKey: 'CREDIT_CARD', value: 'CreditCard', IconComponent: CreditCartIcon },
  { translationKey: 'PAYPAL', value: 'PayPal', IconComponent: PaypalIcon },
]

export const ShoppingCartPaymentMethods: FC<ShoppingCartPaymentMethodsProps> = ({
  selectedPaymentMethod,
  control,
  activeMethods = [],
  shippingGroup,
  updatePaymentMethod,
  userIsCashCustomer,
}) => {
  const { t } = useTranslation()

  function getLabel(translationKey: string, value: PaymentMethod) {
    if (value !== 'Cash') return t(`PAYMENT.${translationKey}`)
    return shippingGroup === ShippingGroup.Delivery
      ? t('PAYMENT.CASH_DELIVERY')
      : t('PAYMENT.CASH_PICKUP')
  }

  return (
    <div className={style.layout}>
      <Controller
        control={control}
        defaultValue={selectedPaymentMethod}
        name="paymentOption"
        render={({ field: { value, onChange } }) => {
          return (
            <div className={style.container}>
              {PAYMENT_METHODS.filter((method) => {
                if (method.value === 'PayPal') {
                  return true
                }
                if (userIsCashCustomer && method.value !== 'Invoice') {
                  return method
                } else if (!userIsCashCustomer && method.value !== 'Cash') {
                  return method
                } else {
                  return null
                }
              }).map((method) => {
                const disabled = !activeMethods.includes(method.value)
                return (
                  <FormControlLabel
                    key={method.value}
                    label=""
                    value={method.value}
                    disabled={disabled}
                    control={
                      <ShoppingCartPaymentOption
                        value={method.value}
                        label={getLabel(method.translationKey, method.value)}
                        icon={<method.IconComponent />}
                        isSelected={!disabled && value === method.value}
                        onClick={() => {
                          if (!disabled) {
                            onChange(method.value)
                            updatePaymentMethod(method.value)
                          }
                        }}
                        disabled={disabled}
                      />
                    }
                  />
                )
              })}
            </div>
          )
        }}
      />
      <Grid data-testid="delivery-information">
        <SimpleInfoBlock
          containerClassname={style.infoBlockDiscount}
          className={style.infoBlockDiscount}
          body={t('PAYMENT.INFO_TEXT_DISCOUNT')}
          Icon={InfoIcon}
          variant="light"
        />
      </Grid>
    </div>
  )
}
