import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import clsx from 'clsx'

// Components
import { TemplateFilterBar } from './TemplateFilterBar'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useCartTemplatesListContext } from '@obeta/data/lib/stores/useCartTemplatesListContext'
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'

// Icons
import { ReactComponent as FilterAltIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'

// Models
import { CartTemplate } from '@obeta/schema'
import { Tab, SortBy as ESortBy } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { DropdownOption } from '@obeta/models/lib/models/UI'

// Styles
import styles from './TemplateActionsBar.module.scss'

// UI
import { Dropdown2 } from '../dropdown2/Dropdown2'
import { MobileRadioGroupPopover } from '../mobile-radio-group-popover/MobileRadioGroupPopover'
import { SearchField } from '../search-field/SearchField'
import { SecondaryIconButton } from '../custom-button/CustomButton'

interface Props {
  activeCartTemplatesFilter: Tab
  sortBy: ESortBy
  templates: CartTemplate[]
  onSearch: (input: string) => void
  onSortByChange: (value: ESortBy) => void
  onToggleActiveCartTemplatesFilter: (ActiveCartTemplatesFilter) => void
}

export const TemplateActionsBar: React.FC<Props> = (props) => {
  const {
    activeCartTemplatesFilter,
    sortBy,
    templates,
    onSearch,
    onSortByChange,
    onToggleActiveCartTemplatesFilter,
  } = props

  const { mobile, tablet } = useBreakpoints()
  const { searchTerm, setSearchTerm } = useCartTemplatesListContext()
  const { t } = useTranslation()

  // Note: This order is, despite not being in alphabetical order, set by the design team
  const sortByOptions: DropdownOption[] = [
    { value: ESortBy.LAST_UPDATED, label: t('TEMPLATES.LAST_UPDATED') },
    { value: ESortBy.ALPHABETICAL, label: 'A-Z' },
  ]

  // Component state
  const [showFilter, setShowFilter] = useState<boolean>(false)

  // Delay search for 200ms after last key input
  useDebouncedEffect(
    () => {
      onSearch(searchTerm)
    },
    [searchTerm],
    200
  )

  /**
   * Handler to change sort by.
   * @param values SortBy enum
   */
  const onSortByChangeCmp = useCallback((value: ESortBy) => {
    setShowFilter(false)
    onSortByChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box className={clsx([!mobile && styles.wrapper, mobile && styles.wrapperMobile])}>
        <Box
          className={clsx([
            styles.searchWrapper,
            mobile && styles.mobile,
            mobile && styles.searchAndSortWrapperMobile,
          ])}
        >
          <SearchField
            placeholder={t('TEMPLATES.TEMPLATE_NAME')}
            value={searchTerm}
            onChange={setSearchTerm}
            onReset={() => setSearchTerm('')}
          />
          {mobile && (
            <div className={styles.filterIcon}>
              <SecondaryIconButton
                icon={<FilterAltIcon />}
                onClick={() => setShowFilter(!showFilter)}
                size="large"
              />
            </div>
          )}
        </Box>
        {!mobile && (
          <Dropdown2
            outerLabel={tablet ? t('SEARCH.SORT_BY.SHORT') : t('SEARCH.SORT_BY.TITLE')}
            options={sortByOptions}
            width="11.5rem"
            value={sortBy}
            onChange={(value) => onSortByChangeCmp(value as ESortBy)}
          />
        )}
      </Box>
      <TemplateFilterBar
        activeCartTemplatesFilter={activeCartTemplatesFilter}
        templates={templates}
        onToggleActiveCartTemplatesFilter={onToggleActiveCartTemplatesFilter}
        searchTerm={searchTerm}
      />
      {mobile && (
        <MobileRadioGroupPopover
          label={t('SEARCH.SORT_BY.TITLE')}
          open={showFilter}
          options={sortByOptions}
          value={sortBy}
          onClose={() => setShowFilter(false)}
          onSubmit={(value) => onSortByChangeCmp(value as ESortBy)}
        />
      )}
    </>
  )
}
