import { useState } from 'react'
import { FetchResult, gql, useMutation } from '@apollo/client'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { uploadFile as uploadFileToS3 } from '@obeta/utils/lib/upload-utils/uploadFile'
import {
  ConfirmUserUploadRequest,
  ConfirmUserUploadResponse,
  CreateUserUploadUrlRequest,
  CreateUserUploadUrlResponse,
  Mutation,
  UserUploadType,
} from 'graphql-codegen'
import { useFormContext } from 'react-hook-form'
import { IFirmData } from '@obeta/models/lib/models/Settings/Settings'

type ConfirmUserUploadMutation = {
  __typename?: 'Mutation'
  confirmUserUpload: ConfirmUserUploadResponse
}

type CreateUserUploadMutation = {
  __typename?: 'Mutation'
  createUserUploadUrl: CreateUserUploadUrlResponse
}

const CREATE_USER_UPLOAD_URL = gql`
  mutation createUserUploadUrl($input: CreateUserUploadUrlRequest!) {
    createUserUploadUrl(input: $input) {
      s3Key
      params
      uploadUrl
    }
  }
`

const CONFIRM_USER_UPLOAD = gql`
  mutation confirmUserUpload($input: ConfirmUserUploadRequest!) {
    confirmUserUpload(input: $input) {
      url
      responseMessage {
        message
        type
      }
    }
  }
`

export type DeleteUserUploadUrlMutation = {
  __typename?: 'Mutation'
  deleteUserUploadUrl: Mutation['deleteUserUploadUrl']
}

export const DELETE_USER_UPLOAD_URL = gql`
  mutation ($input: DeleteUserUploadUrlInput!) {
    deleteUserUploadUrl(input: $input)
  }
`

const createUploadUrlRequestObject = ({ type }: File) => {
  return {
    contentType: type,
    fileName: 'avatar.' + type,
    type: 'CompanyLogo' as UserUploadType,
  }
}

export const useLogoUpload = () => {
  const [fileIsUploading, setFileIsUploading] = useState<boolean>(false)
  const { watch } = useFormContext<IFirmData>()
  const companyLogo = watch('companyLogo')

  const [confirmUploadMutation] = useMutation<ConfirmUserUploadMutation>(CONFIRM_USER_UPLOAD, {
    onCompleted: (data) => {
      return data.confirmUserUpload
    },
    onError: (error) => {
      return error
    },
  })

  const [createUploadUrlMutation] = useMutation<CreateUserUploadMutation>(CREATE_USER_UPLOAD_URL, {
    onCompleted: (data) => {
      return data.createUserUploadUrl
    },
    onError: (error) => {
      return error
    },
  })

  const [deleteUserUploadUrlMutation] = useMutation<DeleteUserUploadUrlMutation>(
    DELETE_USER_UPLOAD_URL,
    {
      onCompleted: (data) => {
        return data.deleteUserUploadUrl
      },
      onError: (error) => {
        return error
      },
    }
  )

  const confirmUpload = async (
    input: ConfirmUserUploadRequest
  ): Promise<FetchResult<ConfirmUserUploadMutation>> => {
    return await confirmUploadMutation({ variables: { input } })
  }

  const createUploadUrl = async (
    input: CreateUserUploadUrlRequest
  ): Promise<FetchResult<CreateUserUploadMutation>> => {
    return await createUploadUrlMutation({ variables: { input } })
  }

  const uploadFile = async (
    file: File,
    createUploadUrlResponse: CreateUserUploadUrlResponse
  ): Promise<Response | undefined> => {
    return uploadFileToS3(file, createUploadUrlResponse)
  }

  const onLogoSubmit = async () => {
    if (!companyLogo || typeof companyLogo === 'string') return

    const fileNameObj = { fileName: companyLogo.name }
    try {
      setFileIsUploading(true)

      // Create upload url
      const createUploadUrlRequest = createUploadUrlRequestObject(companyLogo)
      const createUploadUrlResponse = await createUploadUrl(createUploadUrlRequest)

      // Upload file to AWS
      if (createUploadUrlResponse.data) {
        const uploadFileResponse = await uploadFile(
          companyLogo,
          createUploadUrlResponse.data.createUserUploadUrl
        )

        // Confirm uploaded file
        if (uploadFileResponse?.status === 201) {
          const confirmUploadResponse = await confirmUpload({
            s3Key: createUploadUrlResponse.data.createUserUploadUrl.s3Key,
          })
          const responseMessage = confirmUploadResponse.data?.confirmUserUpload?.responseMessage

          if (responseMessage?.type === 'Success') {
            return true
          } else {
            console.error('Error confirming the uploaded company logo.', responseMessage?.message)
            trackCustom('company-logo-upload-error-confirm-upload', fileNameObj)
          }
        } else {
          console.error('Error uploading the company logo. Response:', uploadFileResponse)
          trackCustom('company-logo-upload-error-aws-file-upload', fileNameObj)
        }
      } else {
        console.error('Error creating AWS URL for the company logo.')
        trackCustom('company-logo-upload-error-aws-create-url', fileNameObj)
      }
    } catch (error) {
      console.error('Error during company logo upload:', error)
      trackCustom('company-logo-upload-error', {
        error,
        ...fileNameObj,
      })
    } finally {
      setFileIsUploading(false)
    }
  }

  const onLogoDelete = async (logoUrl: string) => {
    return deleteUserUploadUrlMutation({ variables: { input: { s3Url: logoUrl } } })
  }

  return {
    fileIsUploading,
    onLogoSubmit,
    onLogoDelete,
  }
}
