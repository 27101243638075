import React from 'react'
import { Navigation } from './Navigation/Navigation'
import { SelectionBarContainer } from '@obeta/components/lib/selection-bar/SelectionBarContainer'
import { useCustomerMainManager } from '../customer-main/CustomerMainManagerProvider'
import { TABS_ID } from '@obeta/data/lib/hooks/useBarcodeScanner'
import styles from './MainNavbar.module.scss'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import SignUpFooter from '@obeta/layouts/lib/startPage/SignUpFooter'

export const MainNavbar = ({ children }) => {
  const { isCustomerCardOpen } = useCustomerMainManager()
  const { location } = useHistory()
  const isSignUpPage =
    location.pathname === ShopRoutes.SignUp || location.pathname === ShopRoutes.ActivateShopAccess

  /* To enhance the user experience when showing the customer card in landscape mode, we avoid rendering the main content during this transition. */
  if (isCustomerCardOpen) return null

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.appMainContent} id={TABS_ID}>
        {children}
        <SelectionBarContainer className={styles.selectionBarContainer} />
        {isSignUpPage ? <SignUpFooter /> : <Navigation />}
      </div>
    </div>
  )
}
