import { useBreakpoints } from './useBreakpoints'

const ICON_SIZE_SMALL = 'small' as const
const ICON_SIZE_LARGE = 'large' as const

type IconSize = typeof ICON_SIZE_SMALL | typeof ICON_SIZE_LARGE

export const useIconSize = (): IconSize => {
  const { desktop } = useBreakpoints()
  return desktop ? ICON_SIZE_SMALL : ICON_SIZE_LARGE
}
