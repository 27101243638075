import { MultiSelectionString, ShoppingCartForDropdown } from '@obeta/models/lib/models'
import { PopoverOrigin } from '@mui/material'
import Grid from '@mui/material/Grid2'
import clsx from 'clsx'
import styles from './OrderDetailsSelectionBar.module.scss'
import { DropdownTemplates } from '../dropdown-templates/DropdownTemplates'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'

// Actions
import { addOrderItemsInArrearsToCartGraphQL } from '@obeta/data/lib/actions'

// Assets
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/designsystem/add_shopping_cart.svg'

// Components
import { DropdownCartsBase } from '../dropdown-carts/DropdownCarts'
import { PrimaryButton } from '../custom-button/CustomButton'
import { SplitButton } from '../split-button/SplitButton'
import { SelectionBar } from '../selection-bar/SelectionBar'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useDispatch } from 'react-redux'
import { useSelectShoppingCart } from '@obeta/data/lib/hooks'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import React, { useRef } from 'react'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import { useOrderDetailsContext } from '@obeta/data/lib/stores/useOrderDetailsContext'

interface Props {
  dropDownCarts: ShoppingCartForDropdown[]
  modifiedOrderItemAmounts: { orderItemId: string; amount: number }[]
  resetSelectedOrderItems: () => void
  selectedOrderItems: MultiSelectionString
  searchTerm: string
  totalItemsCount: number
}
export const OrderDetailsInArrearsSelectionBar: React.FC<Props> = ({
  dropDownCarts,
  modifiedOrderItemAmounts,
  resetSelectedOrderItems,
  searchTerm,
  selectedOrderItems,
  totalItemsCount,
}) => {
  const { desktop, mobile, tabletAll } = useBreakpoints()
  const cartsV2 = useCartsv2WithPricesAndStock()
  const dispatch = useDispatch()
  const splitButtonRef = useRef<HTMLDivElement>(null)
  const popoverState = usePopoverState(splitButtonRef)
  const onSetActiveCart = useSelectShoppingCart()
  const userV2 = useUserV2()

  const defaultCartId = userV2?.settings?.defaultCartId || ''
  const defaultCart = cartsV2.find((cart) => cart.id === defaultCartId)
  const cartName = defaultCart ? defaultCart.name : '-'

  const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
  const transformOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }

  const { itemState } = useOrderDetailsContext()
  const getSelectedOrderItemCount = () => {
    // SELECT ALL W/ OR W/OUT EXCLUDE
    if (selectedOrderItems.selectAll) {
      if (searchTerm !== '') {
        if (selectedOrderItems.exclude.length) {
          return totalItemsCount - selectedOrderItems.exclude.length
        }
        return totalItemsCount
      } else {
        if (selectedOrderItems.exclude.length) {
          return totalItemsCount - selectedOrderItems.exclude.length
        }
        return totalItemsCount
      }
    }
    // INCLUDE ONLY
    if (selectedOrderItems.include) {
      return selectedOrderItems.include.length
    }
  }
  function handleAddToCart(cartId: string) {
    addMultipleOrderItemsToCart(cartId)
  }

  const selectedOrderItemCount = getSelectedOrderItemCount()
  const addMultipleOrderItemsToCart = (cartId: string) => {
    // Note, addOrderItemsInArrearsToCartGraphQLEffect handles the user-facing notification rendering regarding this action
    dispatch(
      addOrderItemsInArrearsToCartGraphQL(
        {
          cartId: cartId,
          include: selectedOrderItems.include,
          exclude: selectedOrderItems.exclude,
          amount: modifiedOrderItemAmounts,
          search: {
            searchTerm,
            orderBy: '',
            orderDir: '',
            filter: itemState === 'all' ? '' : itemState,
          },
        },
        selectedOrderItemCount ?? 0
      )
    )
    onSetActiveCart(cartId)
    //deselect all order items
    resetSelectedOrderItems()
  }
  return (
    <SelectionBar portalKey="order-details-selection-bar">
      <Grid className={styles.selectionBar} container>
        <Grid className={styles.selectionBarItem} size={{ xs: 6 }}>
          <DropdownTemplates
            anchorOrigin={anchorOrigin}
            desktop={desktop}
            disabled={(selectedOrderItemCount as number) <= 0}
            mobile={mobile}
            order={{
              id: '', // Note: Irrelevant for this use case, however allows us to reuse existing model
              multiSelection: {
                selectAll: selectedOrderItems.selectAll,
                include: selectedOrderItems.include,
                exclude: selectedOrderItems.exclude,
                search: selectedOrderItems.search,
              },
              modifiedOrderItemAmounts,
              selectedItemCount: (selectedOrderItemCount as number) ?? 0,
            }}
            productsToAdd={[
              {
                productId: '',
                amount: 0,
              },
            ]}
            selectedArticleCount={selectedOrderItemCount}
            templatesType={DropdownTemplatesType.ADD_ORDER_ITEMS_IN_ARREARS}
            transformOrigin={transformOrigin}
          />
        </Grid>
        <Grid className={clsx(styles.selectionBarItem, styles.selectionBarCart)} size={{ xs: 6 }}>
          {mobile && (
            <PrimaryButton
              disabled={!selectedOrderItemCount}
              leftIcon={<AddShoppingCartIcon />}
              onClick={() => {
                handleAddToCart(defaultCartId)
              }}
            />
          )}
          {(tabletAll || desktop) && (
            <>
              {/* TODO: update to animated button if necessary. See COD-13452  */}
              {cartsV2.length > 1 ? (
                <SplitButton
                  disabled={!selectedOrderItemCount}
                  loading={false}
                  size={mobile ? 'large' : 'small'}
                  title={cartName}
                  onArrowDownClicked={popoverState.handleClick}
                  onClick={() => handleAddToCart(defaultCartId)}
                />
              ) : (
                <PrimaryButton
                  disabled={!selectedOrderItemCount}
                  leftIcon={<AddShoppingCartIcon />}
                  size={mobile ? 'large' : 'small'}
                  onClick={() => handleAddToCart(defaultCartId)}
                >
                  {cartName}
                </PrimaryButton>
              )}
              <DropdownCartsBase
                carts={dropDownCarts}
                mobile={mobile}
                onOptionSelected={(cart) => {
                  handleAddToCart(cart.id)
                }}
                dropdown={{
                  anchorEl: mobile ? null : popoverState.anchorEl,
                  onClose: popoverState.onClose,
                  open: popoverState.open,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </SelectionBar>
  )
}
