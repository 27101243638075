import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { noop } from '@obeta/utils/lib/noop'
import { useProjectOverviewUrlHandling } from '../hooks/projects/useProjectOverviewUrlHandling'
import { useProjectsSearch } from '../hooks/projects/useProjectsSearch'
import { GetProjectsQuery } from '@obeta/schema'
import { pageToOffset } from '@obeta/utils/lib/pagination'

type OverviewPageProject = GetProjectsQuery['getProjects']['projects'][number]

type ProjectOverviewContextValue = {
  searchTerm: string
  setSearchTerm: (value: string) => void
  page: number
  setPage: (value: number) => void
  projects: OverviewPageProject[]
  maxPage: number
  count: number
  refetchProjects: () => Promise<boolean>
  initialLoading: boolean
}

const defaultValue: ProjectOverviewContextValue = {
  searchTerm: '',
  setSearchTerm: noop,
  page: 1,
  setPage: noop,
  projects: [],
  maxPage: 0,
  count: 0,
  refetchProjects: () => Promise.resolve(false),
  initialLoading: true,
}

const ProjectOverviewContext = createContext<ProjectOverviewContextValue>(defaultValue)

const PROJECT_OVERVIEW_LIMIT = 20
const PROJECT_OVERVIEW_ORDER_BY = 'name'
const PROJECT_OVERVIEW_ORDER_DIR = 'DESC'

export const ProjectOverviewProvider = ({ children }) => {
  const [projects, setProjects] = useState<Array<OverviewPageProject>>([])
  const [maxPage, setMaxPage] = useState(0)
  const [count, setCount] = useState(0)
  const [initialLoading, setInitialLoading] = useState(true)

  const { searchTerm, setSearchTerm, page, setPage } = useProjectOverviewUrlHandling()
  const searchProjects = useProjectsSearch()

  const fetchProjects = useCallback(async () => {
    const result = await searchProjects({
      searchTerm,
      offset: pageToOffset(page, PROJECT_OVERVIEW_LIMIT),
      limit: PROJECT_OVERVIEW_LIMIT,
      orderBy: PROJECT_OVERVIEW_ORDER_BY,
      orderDir: PROJECT_OVERVIEW_ORDER_DIR,
    })
    const projects = result.projects
    const count = result.totalRecords
    const maxPage = Math.ceil(count / PROJECT_OVERVIEW_LIMIT)

    setProjects(projects)
    setCount(count)
    setMaxPage(maxPage)
    setInitialLoading(false)
    return projects.length > 0
  }, [page, searchProjects, searchTerm])

  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  return (
    <ProjectOverviewContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        page,
        setPage,
        projects,
        maxPage,
        count,
        initialLoading,
        refetchProjects: fetchProjects,
      }}
    >
      {children}
    </ProjectOverviewContext.Provider>
  )
}

export const useProjectOverviewContext = () => {
  return useContext(ProjectOverviewContext)
}
