import { HorizontalDummy } from '@obeta/components/lib/product-card/dummies/HorizontalDummy'
import { EntityChange, useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useMeasuredRowRenderer } from '@obeta/data/lib/hooks/useMeasuredRowRenderer'
import { useLoadMoreProducts } from '@obeta/data/lib/hooks/useLoadMoreProducts'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { useCallback, FC } from 'react'
import { InfiniteWindowScroller, TInfiniteLoaderProps } from '@obeta/components/lib/window-scroller'
import { CellMeasurerCache } from 'react-virtualized'
import { IListProps } from './types'
import styles from './VirtualizedList.module.scss'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useSelectedCart } from '@obeta/data/lib/hooks/useSelectedCart'
import { useVoltimumPoints } from '@obeta/data/lib/hooks/useVoltimumPoints'
import { SearchResultsTrackingProvider } from '@obeta/data/lib/hooks/useSearchTrackingContext'
import { useProductImages } from '@obeta/data/lib/hooks/useProductImages'
import { SearchResultItemBaseWrapper } from './SearchResultItemBaseWrapper'
import { useScopedScrollRestorationContext } from '@obeta/data/lib/hooks'
import { SearchPromotionBanner } from './SearchPromotionBanner'

const MAX_ITEMS_TO_DISPLAY = 1000

const PROMOTION_BANNER_INDEX = 1

export const VirtualizedList: FC<IListProps & WithBreakpoints<'mobile' | 'tabletAll'>> = (
  props
) => {
  const { selectedStore, user, mobile, tabletAll, onItemClick, setProductAmount, onAddToCart } =
    props
  const { searchParams, queryId, bannerData } = useArticlesSearchProvider()
  const { isLoggedIn } = useUserDataV2()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const selectedCart = useSelectedCart()

  const elementsPerRow = 1

  const { lastScrollY, cellMeasureCache } = useScopedScrollRestorationContext({
    scopeName: 'searchVirtual',
    keyFn: () => searchParams?.id || 'defaultsearch',
    defaults: {
      cellMeasureCache: new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 300,
        minHeight: 100,
      }),
    },
  })

  const articleDataChange = useCallback<EntityChange<ProductAggregate>>(
    (oldData, data) => {
      data.forEach((product: ProductAggregate, index: number) => {
        const cacheIndex = Math.floor(index / elementsPerRow)

        // element was not loaded before
        if (index > oldData.length - 1) {
          cellMeasureCache.clear(cacheIndex, 0)
        }
        // element lazy loaded prices or stock
        else if (
          (!oldData[index]?.prices && product?.prices) ||
          (!oldData[index]?.stock && product?.stock)
        ) {
          cellMeasureCache.clear(cacheIndex, 0)
        }
      })
    },
    [cellMeasureCache, elementsPerRow]
  )

  const {
    articles: products,
    rowCount,
    loadMoreItems: loadMoreRows,
    page,
    isFetching,
  } = useLoadMoreProducts({ searchParams, elementsPerRow, articleChange: articleDataChange })

  const { sapIds } = useVoltimumPoints(
    products
      .filter((product) => Boolean(product && product.sapId))
      .map((product) => ({ sapId: product.sapId, amount: 1 }))
  )

  const { images: productImages, isFetchingImages } = useProductImages(
    products.filter(Boolean).map((product) => product.sapId),
    isFetching
  )
  const { rowRenderer } = useMeasuredRowRenderer({
    cache: cellMeasureCache,
    render: ({ index }) => {
      const product = products[index]

      if (!product) {
        return <HorizontalDummy />
      }

      const productImageArray = productImages?.find((pi) => product.sapId === pi.sapId)?.images
      // TODO: cache oxomi image

      const url = productImageArray && productImageArray.length > 0 ? productImageArray[0].url : ''
      return product ? (
        <>
          {index === PROMOTION_BANNER_INDEX && <SearchPromotionBanner bannerData={bannerData} />}
          <SearchResultItemBaseWrapper
            carts={carts}
            selectedCart={selectedCart}
            onClick={(e) => {
              e.stopPropagation()
              onItemClick(product, queryId, index, user?.algoliaUserToken)
            }}
            onTitleClick={(e) => {
              e.stopPropagation()
              onItemClick(product, queryId, index, user?.algoliaUserToken)
            }}
            onAmountChange={(amount) => {
              setProductAmount(product, amount)
            }}
            isLoggedIn={isLoggedIn}
            selected={false}
            mobile={mobile}
            tablet={tabletAll}
            product={product}
            selectedStore={selectedStore}
            user={user}
            isFetchingImages={isFetchingImages}
            onAddToCart={onAddToCart}
            productId={product.sapId}
            hasVoltimumLogo={sapIds?.includes(product.sapId)}
            url={url}
          />
        </>
      ) : (
        <HorizontalDummy />
      )
    },
    wrapperClassName: styles.row,
  })

  const isRowLoaded = useCallback<TInfiniteLoaderProps['isRowLoaded']>(
    ({ index }) => Boolean(products[index]),
    [products]
  )

  if (!products.length) {
    return null
  }

  return (
    <SearchResultsTrackingProvider virtualizedPage={page} products={products}>
      <div className={styles.root}>
        <InfiniteWindowScroller
          rowTotalCount={Math.min(MAX_ITEMS_TO_DISPLAY, rowCount)}
          rowCount={products.length}
          infiniteLoader={{ isRowLoaded, loadMoreRows, minimumBatchSize: 16, threshold: 10 }}
          list={{
            rowRenderer,
            rowHeight: cellMeasureCache.rowHeight,
            deferredMeasurementCache: cellMeasureCache,
          }}
          onInitialRender={() => {
            window.scrollTo({ top: lastScrollY })
          }}
        />
      </div>
    </SearchResultsTrackingProvider>
  )
}
