import { WrappedContentRoutes } from '../common/components/main-navbar/Content/Content'
import { PageIds, ShopRoutes } from '@obeta/utils/lib/variables'
import { Navigate, Route, Routes } from 'react-router'
import {
  CartTemplateList,
  CartImportPage,
  MessageCenter,
  RentArticlesPage,
  AllTeasersPage,
  NotFoundPage,
  OrderList,
  OfferList,
} from '../pages/index'
import { PromotionPage } from '@obeta/layouts/lib/promotionPage/promotionPage'
import { ShoppingCartCheckoutPage } from '@obeta/layouts/lib/shoppingCartCheckoutPage/ShoppingCartCheckoutPage'
import { TeaserDetailsPage } from '@obeta/layouts/lib/teaserDetailsPage/TeaserDetailsPage'
import { NewsDetailPage } from '@obeta/layouts/lib/newsDetailsPage/NewsDetailsPage'
import OrderDetailsPage from '@obeta/layouts/lib/orderDetailsPage/orderDetailsPage'
import { ShoppingCartDetailsPage } from '@obeta/layouts/lib/shoppingCartDetailsPage/ShoppingCartDetailsPage'
import { ShoppingCartListPage } from '@obeta/layouts/lib/shoppingCartListPage/ShoppingCartListPage'
import { ArticleDetailPage } from '@obeta/layouts/lib/articleDetailsPage/ArticleDetailsPage'
import { StartPageContainer as StartPage } from '@obeta/layouts/lib/startPage/StartPageContainer'
import { NewsPage } from '@obeta/layouts/lib/newsPage/NewsPage'
import { SearchPage } from '@obeta/layouts/lib/searchPage/SearchPage'
import CartTemplatesDetailsPage from '@obeta/layouts/lib/cartTemplateDetailsPage/cartTemplateDetailsPage'
import { StaticPage, StaticPage as StaticContent } from '@obeta/layouts/lib/staticPage/staticPage'
import { CreateOrderPage } from '@obeta/layouts/lib/createOrderPage/CreateOrderPage'
import { SuppliersOverviewPage } from '@obeta/layouts/lib/suppliersOverviewPage/SuppliersOverviewPage'
import { LocationPage } from '@obeta/layouts/lib/locationPage/LocationPage'
import OfferDetailsPage from '@obeta/layouts/lib/offerDetailsPage/offerDetailsPage'
import { useActiveCartId } from '@obeta/data/lib/hooks/useActiveCartId'
import { TecworldPage } from '@obeta/layouts/lib/tecworldPage/TecworldPage'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { MyAccountingPage } from '@obeta/layouts/lib/openPositionsPage/MyAccountingPage'
import { SettingsPage } from '@obeta/layouts/lib/settingsPage/SettingsPage'
import { OxomiStoryPage } from '@obeta/layouts/lib/oxomiStoryPage/OxomiStoryPage'
import { useUpdateCartsMessages } from '@obeta/data/lib/hooks/useUpdateCartsMessages'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { UserManagementPage } from '@obeta/layouts/lib/userManagementPage/UserManagementPage'
import { UserOverviewPage } from '@obeta/layouts/lib/userOverviewPage/UserOverviewPage'
import SignUpPage from '@obeta/layouts/lib/signUpPage/signUpPage'
import ShopAccessActivationPage from '@obeta/layouts/lib/shopAccessActivationPage/shopAccessActivationPage'
import OrderItemsInArrearsPage from '@obeta/layouts/lib/orderItemsInArrearsPage/orderItemsInArrearsPage'
import ProjectOverviewPage from '@obeta/layouts/lib/projectOverviewPage/ProjectOverviewPage'
import ProjectDetailsPage from '@obeta/layouts/lib/projectDetailsPage/ProjectDetailsPage'

export const AppRoutes = () => {
  const { isLoggedIn, singleCartMode, permissions } = useUserDataV2()
  const user = useUserDataV2()
  const activeCartId = useActiveCartId()
  useUpdateCartsMessages()

  const canSeeOffers = isLoggedIn && !!permissions?.Offers_canRead
  const canSeeOrders = !!permissions?.Orders_canRead

  const isNewCompanySettings = useFeatureToggle('UseNewCompanySettings')

  return (
    <WrappedContentRoutes>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/start" element={<StartPage />} />
        <Route path="/cart-template-list" element={<CartTemplateList />} />
        <Route path="/cart-template-details/:templateId" element={<CartTemplatesDetailsPage />} />
        <Route path={`${ShopRoutes.News}`} element={<NewsPage />} />
        {/*DEPRECATED: we use StaticContent for now instead */}
        <Route path={`${ShopRoutes.NewsDetailPage}/:newsId`} element={<NewsDetailPage />} />
        <Route path={`${ShopRoutes.ActivateShopAccess}`} element={<ShopAccessActivationPage />} />
        <Route
          path={`${ShopRoutes.SignUp}`}
          element={<SignUpPage hCaptchaSiteKey={process.env.REACT_APP_HCAPTCHA_SITE_KEY ?? ''} />}
        />
        <Route path={`${ShopRoutes.SuppliersOverviewPage}`} element={<SuppliersOverviewPage />} />
        <Route path={`${ShopRoutes.Advertising}`} element={<AllTeasersPage />} />
        <Route path="/rent-articles" element={<RentArticlesPage />} />
        <Route path={`${ShopRoutes.TeaserDetailPage}/:teaserId`} element={<TeaserDetailsPage />} />
        <Route path={ShopRoutes.OrdersList} element={<OrderList />} />
        <Route
          path={ShopRoutes.OrderItemsInArrears}
          element={canSeeOrders ? <OrderItemsInArrearsPage /> : <Navigate to={'/'} />}
        />
        <Route
          path="/orders/:orderId"
          element={canSeeOrders ? <OrderDetailsPage /> : <Navigate to={'/'} />}
        />
        <Route
          path="/order-details/:id"
          element={canSeeOrders ? <OrderDetailsPage /> : <Navigate to={'/'} />}
        />
        <Route path="/offers" element={<OfferList />} />
        <Route
          path="/offers/:offerId"
          element={canSeeOffers ? <OfferDetailsPage /> : <Navigate to={'/'} />}
        />
        <Route
          path="/offer-details/:id"
          element={canSeeOffers ? <OfferDetailsPage /> : <Navigate to={'/'} />}
        />
        <Route path="/stores*" element={<LocationPage />} />
        <Route path={`${ShopRoutes.ArticleDetailPage}/:id`} element={<ArticleDetailPage />} />
        <Route path="/articles/:id" element={<ArticleDetailPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/carts/import" element={<CartImportPage />} />
        <Route
          path="/carts"
          element={
            singleCartMode ? <Navigate to={`/carts/${activeCartId}`} /> : <ShoppingCartListPage />
          }
        />
        <Route path="/carts/:cartId" element={<ShoppingCartDetailsPage />} />
        <Route
          path="/shopping-cart-list"
          element={
            singleCartMode ? <Navigate to={`/carts/${activeCartId}`} /> : <ShoppingCartListPage />
          }
        />
        <Route path="/shopping-cart-details/:cartId" element={<ShoppingCartDetailsPage />} />
        <Route path="/message-center" element={<MessageCenter />} />
        <Route path="/promotion/:id" element={<PromotionPage />} />
        <Route path="/shopping-cart-checkout/:cartId" element={<ShoppingCartCheckoutPage />} />
        <Route
          path={ShopRoutes.PrivacyProtection}
          element={<StaticPage page={PageIds.Privacy} />}
        />
        <Route path={ShopRoutes.Terms} element={<StaticPage page={PageIds.Terms} />} />
        <Route path={ShopRoutes.AboutUs} element={<StaticPage page={PageIds.About} />} />
        <Route path={`${ShopRoutes.StaticContent}/:slug`} element={<StaticContent />} />
        <Route path={`${ShopRoutes.CreateOrderPage}/:cartId`} element={<CreateOrderPage />} />
        {user.permissions?.Global_canReadAccountInfo && (
          <Route path={ShopRoutes.MyAccounting} element={<MyAccountingPage />} />
        )}
        {isNewCompanySettings && <Route path={ShopRoutes.Settings} element={<SettingsPage />} />}
        <Route path={`${ShopRoutes.Tecworld}/:id`} element={<TecworldPage />} />
        <Route path={`${ShopRoutes.OxomiStory}/:id`} element={<OxomiStoryPage />} />
        <Route path={ShopRoutes.UserOverview} element={<UserOverviewPage />} />
        <Route path={ShopRoutes.ProjectOverview} element={<ProjectOverviewPage />} />
        <Route path={`${ShopRoutes.ProjectDetails}/:projectId`} element={<ProjectDetailsPage />} />
        <Route
          path={`${ShopRoutes.UserManagement}/:customerNumber`}
          element={<UserManagementPage />}
        />
        <Route path={`*`} element={<NotFoundPage />} />
      </Routes>
    </WrappedContentRoutes>
  )
}
