import { gql, useApolloClient } from '@apollo/client'
import { ProjectDetailsProject } from '@obeta/models/lib/schema-models/project'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { responseMessageToNotification } from '@obeta/utils/lib/responseMessageToNotification'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { mapProjectToInput } from './project-hook-utils'
import { CreateProjectMutation, CreateProjectMutationVariables } from 'graphql-codegen'
import { useHistory } from '../useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'

const CREATE_PROJECT = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      message
      type
      modelId
    }
  }
`

export const useCreateProject = (): ((project: ProjectDetailsProject) => Promise<boolean>) => {
  const client = useApolloClient()
  const { t } = useTranslation()
  const history = useHistory()

  return useCallback(
    async (project: ProjectDetailsProject) => {
      let success = false
      const { id, ...mappedProject } = mapProjectToInput(project)

      try {
        const response = await client.mutate<CreateProjectMutation, CreateProjectMutationVariables>(
          {
            mutation: CREATE_PROJECT,
            variables: { input: mappedProject },
          }
        )
        if (response.data?.createProject) {
          if (response.data?.createProject.type === 'Success') success = true
          responseMessageToNotification(response.data.createProject)
          const { modelId } = response.data.createProject
          if (modelId.length > 0) history.replace(`${ShopRoutes.ProjectDetails}/${modelId}`)
        }
      } catch (error) {
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.Error,
          id: 'project-creation-went-wrong',
          options: {
            message: t('PROJECT.DETAILS.MUTATION_WENT_WRONG'),
          },
        })
      }
      return success
    },
    [client, history, t]
  )
}
