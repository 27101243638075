import { useApolloClient, gql } from '@apollo/client'
import { useCallback } from 'react'
import { Mutation, MutationUpdateProjectArgs } from '@obeta/schema'
import { ProjectDetailsProject } from '@obeta/models/lib/schema-models/project'
import { responseMessageToNotification } from '@obeta/utils/lib/responseMessageToNotification'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'
import { mapProjectToInput } from './project-hook-utils'

type UpdateProjectMutation = {
  __typename?: 'Mutation'
  updateProject: Mutation['updateProject']
}

const MUTATE_PROJECT = gql`
  mutation updateProject($input: ProjectInput!) {
    updateProject(input: $input) {
      message
      type
    }
  }
`

export const useMutateProject = (): ((project: ProjectDetailsProject) => Promise<boolean>) => {
  const client = useApolloClient()
  const { t } = useTranslation()

  return useCallback(
    async (project: ProjectDetailsProject) => {
      let success = false
      const mappedProject = mapProjectToInput(project)
      try {
        const response = await client.mutate<UpdateProjectMutation, MutationUpdateProjectArgs>({
          mutation: MUTATE_PROJECT,
          variables: { input: mappedProject },
        })
        if (response.data?.updateProject) {
          if (response.data?.updateProject.type === 'Success') success = true
          responseMessageToNotification(response.data.updateProject)
        }
      } catch (error) {
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.Error,
          id: 'project-mutation-went-wrong',
          options: {
            message: t('PROJECTS.DETAILS.MUTATION_WENT_WRONG'),
          },
        })
      }
      return success
    },
    [client, t]
  )
}
