import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { gqlUserToUpdate } from '../queries/userV2Subusers'
import {
  GetUserToUpdateQuery,
  GetUserToUpdateQueryVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserInputV2,
} from '@obeta/schema'
import { gqlUpdateUser } from './useUserUpdate'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'

type UserToUpdate = Omit<GetUserToUpdateQuery['getUser'], '__typename'>

type UseUserToUpdate = () => {
  getUserToUpdate: () => Promise<UserToUpdate>
  updateUserComplete: (value: UserInputV2, onSuccess: () => void) => void
}

export const useUserToUpdate: UseUserToUpdate = () => {
  const apolloClient = useApolloClient()

  const getUserToUpdate = useCallback(async () => {
    const response = await apolloClient.query<GetUserToUpdateQuery, GetUserToUpdateQueryVariables>({
      query: gqlUserToUpdate,
    })
    return response.data.getUser
  }, [apolloClient])

  const updateUserComplete = useCallback(
    async (user: UserInputV2, onSuccess: () => void) => {
      const response = await apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: gqlUpdateUser,
        variables: { input: user },
      })

      if (!response.data) return

      const [result] = response.data.updateUserV2
      if (result && result.type === 'Success') {
        onSuccess()
        getEventSubscription().next({
          type: EventType.Alert,
          notificationType: NotificationType.Confirm,
          id: 'order-confirmation-email-success',
          options: {
            message: result.message,
            id: 'order-confirmation-email-success',
            duration: 8000,
          },
        })
      }
    },
    [apolloClient]
  )

  return {
    getUserToUpdate,
    updateUserComplete,
  }
}
