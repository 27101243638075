import { useMemo } from 'react'
import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { useEntities } from './useEntities'

export const useDefaultUserAddress = () => {
  const userAddresses = useEntities<UserAddressV2>('useraddressesv2')
  return useMemo(() => {
    return userAddresses.find((address) => address.isDefault)
  }, [userAddresses])
}
