import React, { useState } from 'react'
import clsx from 'clsx'
import { ShoppingCartOrderDeclarations } from '@obeta/components/lib/shoppingcart/ShoppingCartOrderDeclarations'
import { useProjectDetailsContext } from '@obeta/data/lib/stores/useProjectDetailsContext'
import formStyles from './ProjectCartPreSelectionForm.module.scss'
import { useProjectFormContext } from '@obeta/data/lib/stores/useProjectFormContext'
import { ShoppingCartCheckoutPickupInformation } from '@obeta/components/lib/shoppingcart/ShoppingCartCheckoutPickupInformation'
import { ShippingGroup } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { noop } from '@obeta/utils/lib/noop'
import { useEntities } from '@obeta/data/lib/hooks'
import { ShoppingCartV2, StoreV2 } from '@obeta/models/lib/models'
import { ShippingType } from '@obeta/schema'
import { defaultShippingOptionsDelivery, defaultShippingOptionsPickup } from './constants'
import styles from './ProjectCartOrderDeclarations.module.scss'
import { ShoppingCartCheckoutDeliveryInformation } from '@obeta/components/lib/shoppingcart/ShoppingCartCheckoutDeliveryInformation'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { DeliveryAddressV2 } from '@obeta/models/lib/models/ShoppingCart/DeliveryAddressV2'
import { useAddressCRUDActions } from '@obeta/data/lib/hooks/user/useAddressCRUDActions'

const isShippingType = (value?: string): value is ShippingType =>
  [
    'Cargo',
    'DefaultParcel',
    'DefaultPickup',
    'ExpressPickup',
    'FastParcel',
    'PickupRenzbox',
  ].includes(value ?? '')

export const ProjectCartOrderDeclarations = () => {
  const {
    project,
    shippingGroup,
    updateShippingType,
    updateShippingDataPickup,
    updateStoreId,
    updateDeliveryAddress,
    updateAddressId,
  } = useProjectDetailsContext()
  const { form } = useProjectFormContext()
  const {
    control,
    trigger,
    formState: { errors },
  } = form

  // TODO check if this states are needed or can be derived from project
  const [checkoutStoreSearchBox, setCheckoutStoreSearchBox] = useState(false)
  const [showAddressManagementModal, setShowAddressManagementModal] = useState(false)

  const { createUserAddress, updateUserAddress, deleteUserAddress } = useAddressCRUDActions()
  const stores = useEntities<StoreV2>('storesv2')
  const userAddresses = useEntities<UserAddressV2>('useraddressesv2')
  const userV2 = useUserV2()

  const shippingOptions =
    shippingGroup === ShippingGroup.Pickup
      ? defaultShippingOptionsPickup
      : defaultShippingOptionsDelivery

  return (
    <div className={clsx(styles.container, formStyles.card)}>
      {shippingGroup === ShippingGroup.Pickup &&
        project.shippingData &&
        isShippingType(project.shippingData?.shippingType) && (
          <ShoppingCartCheckoutPickupInformation
            variant="project"
            stores={stores}
            selectedStoreId={project.shippingData?.storeId ?? ''}
            savedShippingType={project.shippingData?.shippingType}
            selectedShippingType={project.shippingData?.shippingType ?? ''}
            checkoutStoreSearchBox={checkoutStoreSearchBox}
            partialShippingDataPickup={project.shippingData}
            shippingOptions={shippingOptions}
            selectStoreId={updateStoreId}
            updateShippingType={updateShippingType}
            setPartialShippingDataPickup={updateShippingDataPickup}
            setCheckoutStoreSearchBox={setCheckoutStoreSearchBox}
            xpressPickupEnabled
            preFilterCartItems={noop}
            setValuesForMemoryBox={noop}
            updateShippingDate={noop}
            cartContainsSpecialItems={false}
            selectedShippingDate=""
            outOfStockMessage=""
            cartId=""
          />
        )}
      {shippingGroup === ShippingGroup.Delivery &&
        project.shippingData &&
        project.shippingData.deliveryAddress &&
        isShippingType(project.shippingData?.shippingType) && (
          <ShoppingCartCheckoutDeliveryInformation
            variant="project"
            selectedDeliveryAddressId={project.shippingData.addressId ?? ''}
            selectedShippingType={project.shippingData?.shippingType ?? ''}
            selectedDeliveryAddress={project.shippingData.deliveryAddress as DeliveryAddressV2}
            userCanWriteCartAddress={userV2?.permissions?.Cart_canWriteAddress ?? false}
            userCanWriteAddresses={userV2?.permissions?.Address_canWrite ?? false}
            showAddressManagmentModal={showAddressManagementModal}
            setShowAddressManagmentModal={setShowAddressManagementModal}
            onSelectUserAddressId={updateAddressId}
            updateShippingAddress={updateDeliveryAddress}
            toggleEditingStateOfDeliveryAddress={noop}
            shippingOptionsForDelivery={shippingOptions}
            userAddresses={userAddresses}
            updateShippingType={updateShippingType}
            createUserAddress={(address, customName) =>
              createUserAddress(address, customName, updateAddressId)
            }
            deleteUserAddress={deleteUserAddress}
            updateUserAddress={(addressId, address, customName) =>
              updateUserAddress(addressId, address, customName, updateAddressId)
            }
            updateShippingDate={noop}
            setValuesForMemoryBox={noop}
            preFilterCartItems={noop}
            cartContainsNonSendableItems={false}
            cartContainsSpecialItems={false}
            isDeactivated={false}
            selectedShippingDate=""
            outOfStockMessage=""
            cart={{} as ShoppingCartV2}
            cartId=""
          />
        )}
      <ShoppingCartOrderDeclarations
        control={control}
        errors={errors}
        phone={project.phone}
        additionalText={project.remark}
        commission={project?.commission}
        phoneIsRequired={false}
        trigger={trigger}
        userHasToWriteCommission={false}
        isDeactivated={false}
        userCanWriteAdditionalText
        userCanWriteCommission
      />
    </div>
  )
}
