import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { RadioGroup } from '@mui/material'
import { CartWritePermission } from '@obeta/models/lib/models/Users/UserV2'
import { ReactComponent as CartIcon } from 'assets/icon/designsystem/cart.svg'
import { CollapseComponent } from '../../searchPage/filters/CollapseComponent'
import { CollapsibleContentContainer } from '../form/CollapsibleContentContainer'
import { OrderPermissionRadioNotOrderable } from './OrderPermissionRadioNotOrderable'
import { OrderPermissionRadioNotify } from './OrderPermissionRadioNotify'
import { OrderPermissionRadioOrderable } from './OrderPermissionRadioOrderable'
import styles from './OrderPermissionGroup.module.scss'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'

export type OrderPermissionGroupOption = {
  allowedSubmitAction: CartWritePermission
}

type Props = OrderPermissionGroupOption & {
  setAllowedSubmitAction: Dispatch<SetStateAction<CartWritePermission>>
  usersToNotify: string[]
  setUsersToNotify: (value: string[]) => void
  maxValueFullRange?: number | null
  setMaxValueFullRange: Dispatch<SetStateAction<number | null | undefined>>
  maxValueFullRangeActive: boolean
  setMaxValueFullRangeActive: Dispatch<SetStateAction<boolean>>
  currentUser?: UserSubuser
  updateUser?: (value: UserSubuser) => void
  handleMaxValueChange: (value: number | null | undefined) => void
}

export const OrderPermissionGroup: FC<Props> = ({
  allowedSubmitAction,
  setAllowedSubmitAction,
  usersToNotify,
  setUsersToNotify,
  maxValueFullRange,
  setMaxValueFullRange,
  maxValueFullRangeActive,
  setMaxValueFullRangeActive,
  currentUser,
  updateUser,
  handleMaxValueChange,
}) => {
  const { t } = useTranslation()
  return (
    <CollapseComponent
      boxClassName={styles.titleWithoutCheckbox}
      header={
        <div className={styles.titleWithTooltip}>
          {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSIONS')}
        </div>
      }
      ClassificationIcon={CartIcon}
    >
      <CollapsibleContentContainer noIndentation>
        <RadioGroup
          className={styles.radioContainer}
          value={allowedSubmitAction}
          onChange={(event) => {
            setAllowedSubmitAction(event.target.value as CartWritePermission)
            setMaxValueFullRange(currentUser?.permissions.Cart_maxCartValue)
            setMaxValueFullRangeActive(
              typeof currentUser?.permissions.Cart_maxCartValue === 'number'
            )
          }}
        >
          <OrderPermissionRadioNotOrderable allowedSubmitAction={allowedSubmitAction} />
          <OrderPermissionRadioNotify
            allowedSubmitAction={allowedSubmitAction}
            usersToNotify={usersToNotify}
            setUsersToNotify={setUsersToNotify}
          />
          <OrderPermissionRadioOrderable
            allowedSubmitAction={allowedSubmitAction}
            maxValueFullRange={maxValueFullRange}
            setMaxValueFullRange={setMaxValueFullRange}
            maxValueFullRangeActive={maxValueFullRangeActive}
            setMaxValueFullRangeActive={setMaxValueFullRangeActive}
            usersToNotify={usersToNotify}
            setUsersToNotify={setUsersToNotify}
            handleMaxValueChange={handleMaxValueChange}
          />
        </RadioGroup>
      </CollapsibleContentContainer>
    </CollapseComponent>
  )
}
