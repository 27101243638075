import { Card, SvgIcon, Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ShoppingCartSummary } from '@obeta/components/lib/shoppingcart/ShoppingCartSummary'
import styles from './ShoppingCartCheckout.module.scss'
import {
  CartVerificationErrorCode,
  OrderButtonActionType,
  partialCartInfo,
  ShoppingCartContextEnum,
  ShoppingCartDetailsState,
  ShoppingCartFilterEnum,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { OfferV2 } from '@obeta/models/lib/models/Offer/OfferV2'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { ShoppingCartCheckoutHeader } from '@obeta/components/lib/shoppingcart/ShoppingCartCheckoutHeader'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ShoppingCartOrderDeclarations } from '@obeta/components/lib/shoppingcart/ShoppingCartOrderDeclarations'
import { ShoppingCartCheckoutBilling } from '@obeta/components/lib/shoppingcart/ShoppingCartCheckoutBilling'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { ShoppingCartCheckoutDeliveryInformation } from '@obeta/components/lib/shoppingcart/ShoppingCartCheckoutDeliveryInformation'
import { ShoppingCartCheckoutPickupInformation } from '@obeta/components/lib/shoppingcart/ShoppingCartCheckoutPickupInformation'
import { GenericControlStickyBox } from '@obeta/components/lib/shoppingcart/ShoppingCartControlStickyBox'
import { useDispatch } from 'react-redux'
import {
  CartsActionTypes,
  getShoppingCartPrices,
  updateCartGraphQL,
  verifyCartGraphQL,
  VerifyCartGraphQLResultAction,
} from '@obeta/data/lib/actions/cart-actions'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import { useLoadShippingOptions } from '@obeta/data/lib/hooks/useLoadShippingOptions'
import { IsPaymentMethodPermittedProvider } from '@obeta/data/lib/hooks/useIsPaymentMethodPermittedProvider'

import {
  ShippingGroup,
  ShippingOptions,
} from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { ShippingType } from '@obeta/schema'
import { DeliveryAddressV2 } from '@obeta/models/lib/models/ShoppingCart/DeliveryAddressV2'
import {
  CartWritePermission,
  PricePermissions,
  UserAddressV2,
  UserType,
} from '@obeta/models/lib/models/Users/UserV2'
import { CartVerification } from '@obeta/components/lib/orders/CartVerification'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { OrderFailureReason } from '@obeta/models/lib/models/Notification/Notification'
import { useHistory, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import {
  createUserAddressGraphQL,
  CreateUserAddressGraphQLResultAction,
  deleteUserAddressGraphQL,
  updateUserAddressGraphQL,
  UpdateUserAddressGraphQLResultAction,
  UserActionTypes,
} from '@obeta/data/lib/actions/user-actions'
import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'
import { useForm } from 'react-hook-form'
import { TelephoneRequiredPopUp } from '@obeta/components/lib/shoppingcart/TelephoneRequiredPopUp'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useCompanyBaseData } from '@obeta/data/lib/hooks/useCompanyBaseData'
import { useCheckIsCashCustomer } from '@obeta/data/lib/hooks/useCheckIsCashCustomer'
import {
  PartialShippingDataDelivery,
  PartialShippingDataPickup,
} from '@obeta/models/lib/models/ShoppingCart/ShippingData'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import dayjs from 'dayjs'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useOnCartUpdateAndVerifySuccess } from '@obeta/data/lib/hooks/shoppingcart-checkout/useOnCartUpdateAndVerifySuccess'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useVoltimumPoints } from '@obeta/data/lib/hooks/useVoltimumPoints'
import { usePvProducts } from '@obeta/data/lib/hooks/usePvProducts'
import {
  displayCreditLimitExceeded,
  displayOrderBlockIsActivated,
  displayPickupBlockIsActivated,
  displayShippingBlockIsActivated,
} from '@obeta/utils/lib/displayOrderFailureNotifications'
import { trackView } from '@obeta/utils/lib/tracking'
import { ShopDomainToCompany } from '@obeta/utils/lib/domains'
import { useEntityMetaData } from '@obeta/data/lib/hooks/useEntityMetaData'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import {
  isDefaultPaymentMethod,
  PaymentMethodWithDefaults,
  usePaymentHandler,
} from '@obeta/data/lib/hooks/usePaymentHandler'
import { PaymentMethod } from '@obeta/schema'
import { clearOrderProcessInformation } from '@obeta/utils/lib/order-process-information'
import { useCreateOrderRedirect } from '@obeta/data/lib/hooks/create-order/useCreateOrderRedirect'
import clsx from 'clsx'
import { useIdsCartTransfer } from '@obeta/data/lib/hooks/ids/useIdsCartTransfer'
import { ShopRoutes } from '@obeta/utils/lib/variables'

export const ShoppingCartCheckoutPage: React.FC = () => {
  useAuthenticatedRoute()
  const [partialShippingDataDelivery, setPartialShippingDataDelivery] =
    useState<PartialShippingDataDelivery>({})
  const [partialShippingDataPickup, setPartialShippingDataPickup] =
    useState<PartialShippingDataPickup>({})
  const [showAddressManagementModal, setShowAddressManagementModal] = useState(false)
  const [showTelephoneRequiredModal, setShowTelephoneRequiredModal] = useState(false)
  const [checkoutStoreSearchBox, setCheckoutStoreSearchBox] = useState(false)
  const [cartVerificationErrorCode, setCartVerificationErrorCode] = useState(
    CartVerificationErrorCode.Unset
  )
  const [cartVerificationActionType, setCartVerificationActionType] = useState(
    OrderButtonActionType.Submit
  )
  const [outOfStockMessage, setOutOfStockMessage] = useState<string | undefined>(undefined)

  const domain = useAppDomain()

  useEffect(() => {
    trackView('shoppingcartcheckout')
  }, [])

  const [memoryBox, setMemoryBox] = useState<partialCartInfo>({})
  const [showModalUnsavedChangesInCart, setShowModalUnsavedChangesInCart] = useState<boolean>(false)

  const userData = useUserDataV2()
  const userAddresses = useEntities<UserAddressV2>('useraddressesv2')
  const mainAddress = userAddresses.find((address) => address.isDefault)

  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressV2 | null>(
    mainAddress?.address ?? null
  )
  const params = useParams<{ cartId: string; pspRedirect?: string }>()
  const isErrorRedirectFromPaymentProvider = params.pspRedirect?.includes('error')
  const shoppingCarts = useCartsv2WithPricesAndStock()
  const selectedCartId = params.cartId
  const cart = shoppingCarts.find((sc) => sc.id === selectedCartId)
  const companyData = useCompanyBaseData(ShopDomainToCompany[domain])

  const offers = useEntities<OfferV2>('offersv2')
  const stores = useEntities<StoreV2>('storesv2')
  const { t } = useTranslation()
  const theme = useTheme()
  const { desktop } = useBreakpoints()

  const userV2 = useUserV2()
  const { isCashCustomer, isLoadingCashCustomerCheck } = useCheckIsCashCustomer()
  const [context, setContext] = useState<ShoppingCartContextEnum>(ShoppingCartContextEnum.UNSET)
  const history = useHistory()

  const [selectedStoreId, setSelectedStoreId] = useState(
    cart?.shippingData.storeId ?? userV2?.settings?.defaultStoreId ?? ''
  )
  const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] = useState(
    mainAddress?.addressId ?? ''
  )
  const [deliveryAddressIsBeingEdited, setDeliveryAddressIsBeingEdited] = useState<boolean>(false)
  const [orderSubmitted, setOrderSubmitted] = useState<boolean>(false)
  const [activePaymentMethods, setActivePaymentMethods] = useState<PaymentMethod[]>([])
  const [cartContainsNonPickupableItems, setCartContainsNonPickupableItems] =
    useState<boolean>(false)
  const setValuesForMemoryBox = (param: partialCartInfo) => {
    setMemoryBox((prevState) => {
      let nestedShippingData = {}
      if (Object.prototype.hasOwnProperty.call(param, 'shippingData')) {
        const shippingData = { ...prevState.shippingData, ...param.shippingData }
        nestedShippingData = { ...prevState, shippingData: shippingData }
      }
      return { ...prevState, ...param, ...nestedShippingData }
    })
  }

  // this effect should only be executed in the beginning when the store is not loaded yet (because neither cart nor user settings have been loaded yet)
  useEffect(() => {
    if (userV2?.settings?.defaultStoreId && selectedStoreId === '') {
      setSelectedStoreId(userV2?.settings?.defaultStoreId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userV2?.settings])

  useEffect(() => {
    const subscription = getEventSubscription().subscribe((event) => {
      if (event.type !== EventType.Data) return

      // redirect user to create-order page for multi-user / -tabs usage
      if (
        cart &&
        (event.notificationType === NotificationType.OrderFailure ||
          event.notificationType === NotificationType.OrderSuccess) &&
        'cartId' in event.options &&
        cart.id === event.options.cartId
      ) {
        history.push(`${ShopRoutes.CreateOrderPage}/${cart.id}`)
      }
    })
    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const dispatch = useDispatch()
  const waitForResultAction = useActionNotification(CartsActionTypes.VerifyCartGraphQLResult)
  const waitForCreateUserAddressResultAction = useActionNotification(
    UserActionTypes.CreateUserAddressGraphQLResult
  )
  const waitForUpdateCartResultAction = useActionNotification(
    CartsActionTypes.UpdateCartGraphQLResult
  )
  const waitForUpdateUserAddressResultAction = useActionNotification(
    UserActionTypes.UpdateUserAddressGraphQLResult
  )
  const creditLimitExceeded = useRef(false)
  const orderBlockIsActivated = useRef(false)
  const shippingBlockIsActivated = useRef(false)
  const maxValueExceeded = useRef(false)
  const deliveryOptionNotPossible = useRef(false)
  const prevCreditLimitExceeded = useRef(false)
  useEffect(() => {
    prevCreditLimitExceeded.current = creditLimitExceeded.current
  })

  //TODO: The value for shippingGroup must ultimately come from the corresponding instance of ShippingData. Apply once the ShippingData model is ready and delivers the necessary info.

  const prevCartId = useRef(selectedCartId)
  const didInitialGetPrices = useRef(false)

  const createOrderRedirect = useCreateOrderRedirect()

  const shippingOptionsForDelivery: ShippingOptions[] = useLoadShippingOptions(
    {
      cartId: selectedCartId,
      shippingGroup: ShippingGroup.Delivery,
    },
    context,
    '',
    deliveryAddress
  )

  const shippingOptionsForPickUp: ShippingOptions[] = useLoadShippingOptions(
    {
      cartId: selectedCartId,
      shippingGroup: ShippingGroup.Pickup,
    },
    context,
    selectedStoreId,
    ''
  )

  const toggleEditingStateOfDeliveryAddress = (bool) => {
    setDeliveryAddressIsBeingEdited(bool)
  }
  const isShippingTypeDelivery = (shippingType: ShippingType | string) => {
    if (shippingType === '') {
      return false
    }

    return (
      shippingType === 'DefaultParcel' || shippingType === 'FastParcel' || shippingType === 'Cargo'
    )
  }

  // clear order process information on mount, to ensure that user has no old information stored
  useEffect(() => {
    clearOrderProcessInformation()
  }, [])

  useEffect(() => {
    if (prevCartId.current !== selectedCartId) {
      prevCartId.current = selectedCartId
      didInitialGetPrices.current = false
    }
  }, [selectedCartId])

  useEffect(() => {
    return () => {
      displayCreditLimitExceeded({ cartId: selectedCartId, removeNotification: true })
      displayOrderBlockIsActivated({ cartId: selectedCartId, removeNotification: true })
    }
  }, [selectedCartId])

  const runWaitForResultAction = () => {
    waitForResultAction((action: VerifyCartGraphQLResultAction) => {
      const exceeded = action.results.find((error) => {
        return error.errorCode === CartVerificationErrorCode.CreditLimitExceeded
      })
      const orderBlock = action.results.find((error) => {
        return error.errorCode === CartVerificationErrorCode.OrderingBlocked
      })
      const shippingBlock = action.results.find((error) => {
        return error.errorCode === CartVerificationErrorCode.ShippingBlocked
      })
      const maxValueExceededError = action.results.find((error) => {
        return error.errorCode === CartVerificationErrorCode.UserCartMaxValueExceeded
      })

      // handle verify results with type 'Notice'
      const noticeResults = action.results.filter((result) => result.errorType === 'Notice')
      if (noticeResults.length > 0) {
        const outOfStockResult = noticeResults.find(
          (result) =>
            result.errorCode === CartVerificationErrorCode.CartWithWarehouseArticlesOutOfStock
        )
        if (outOfStockResult) {
          setOutOfStockMessage(outOfStockResult.message)
        } else {
          setOutOfStockMessage(undefined)
        }
      } else {
        setOutOfStockMessage(undefined)
      }

      deliveryOptionNotPossible.current = action.results.some(
        ({ errorCode }) => errorCode === CartVerificationErrorCode.DeliveryOptionNotPossible
      )
      creditLimitExceeded.current = exceeded !== undefined
      orderBlockIsActivated.current = orderBlock !== undefined
      shippingBlockIsActivated.current = shippingBlock !== undefined
      maxValueExceeded.current = maxValueExceededError !== undefined

      if (creditLimitExceeded.current) {
        displayCreditLimitExceeded({ cartId: selectedCartId })
      } else if (!creditLimitExceeded.current && prevCreditLimitExceeded.current) {
        displayCreditLimitExceeded({ cartId: selectedCartId, removeNotification: true })
      } else if (orderBlockIsActivated.current) {
        displayOrderBlockIsActivated({ cartId: selectedCartId })
      } else if (shippingBlockIsActivated.current) {
        displayShippingBlockIsActivated(selectedCartId)
      }
    })
  }

  useEffect(() => {
    if (context === ShoppingCartContextEnum.CHECKOUT_DELIVERY) {
      if (deliveryOptionNotPossible.current) {
        setCartVerificationErrorCode(CartVerificationErrorCode.DeliveryOptionNotPossible)
        deliveryOptionNotPossible.current = false
      }
    }
    if (cart && context === ShoppingCartContextEnum.CHECKOUT_PICKUP) {
      if (cart?.items.find((article) => article.product.type === ProductType.oversize)) {
        setCartContainsNonPickupableItems(true)
        displayPickupBlockIsActivated(cart.id)
      }
    }
    runWaitForResultAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, cart])

  // TODO: consolidate refs and useEffect, since its almost identical with code in ShoppingCartDetailstPage
  // check credit limit, orderBlock, shippingBlock - violates DRY
  const verifyCart = (withPolling = true) => {
    const pollInterval = 5 * 1000 * 60
    dispatch(verifyCartGraphQL(selectedCartId, withPolling ? pollInterval : undefined))
    runWaitForResultAction()
  }

  // Bestellangaben
  const {
    control,
    getValues,
    setValue,
    trigger,
    watch,
    // https://react-hook-form.com/api/useform/formstate - 'Rules' part
    // read all formState values to subscribe to changes
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
  })

  const commissionText: string = watch('commission') ?? ''
  const phoneText: string = watch('phone') ?? ''
  const additionalText: string = watch('additionalText') ?? ''

  const paymentMethod: PaymentMethodWithDefaults = watch('paymentOption')

  const { voltimumPoints, isLoadingVoltimumPoints } = useVoltimumPoints(
    cart?.items.map((item) => ({ sapId: item.sapId, amount: item.amount })) ?? []
  )
  const hasPvProductsInCart = usePvProducts(cart?.items ?? [])
  const shoppingCartPrices = cart?.shoppingCartPrices
  const hasSubmitPermission =
    userV2?.permissions?.Cart_allowedSubmitAction === CartWritePermission.Submit
  const hasMaxCartValueConfigured = typeof userV2?.permissions?.Cart_maxCartValue === 'number'
  const maxValueWithinLimitClientSide =
    shoppingCartPrices?.netSum !== undefined &&
    (hasMaxCartValueConfigured
      ? (userV2?.permissions.Cart_maxCartValue as number) >= shoppingCartPrices?.netSum
      : true)

  const userCanSubmitCart =
    hasSubmitPermission && maxValueWithinLimitClientSide && !maxValueExceeded.current
  const limitExceededWithoutNotificationMail =
    maxValueExceeded.current &&
    (!userV2?.notificationEmail || userV2.notificationEmail.length === 0)

  const transferIdsCartAndSubmit = useIdsCartTransfer({
    withRedirect: true,
    updateCartBefore: true,
    cleanupCart: true,
    userCanSubmitCart,
    commission: getValues('commission'),
    phone: getValues('phone'),
    remark: getValues('additionalText'),
  })

  useEffect(() => {
    if (
      userV2?.permissions?.Cart_allowedSubmitAction === CartWritePermission.Submit &&
      userCanSubmitCart
    ) {
      setCartVerificationActionType(OrderButtonActionType.Submit)
    } else {
      setCartVerificationActionType(OrderButtonActionType.Notify)
    }
  }, [userCanSubmitCart, userV2?.permissions])

  useEffect(() => {
    if (!didInitialGetPrices.current) {
      dispatch(getShoppingCartPrices(selectedCartId, verifyCart))
      didInitialGetPrices.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, selectedCartId, dispatch])

  const [selectedShippingType, setSelectedShippingType] = useState('')
  const [selectedShippingDate, setSelectedShippingDate] = useState('')

  const updatePaymentMethodInCart = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (cart) {
        cart.paymentMethod = paymentMethod
        dispatch(updateCartGraphQL(cart))
        waitForUpdateCartResultAction(() => {
          dispatch(verifyCartGraphQL(selectedCartId))
          runWaitForResultAction()
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, dispatch, selectedCartId, waitForUpdateCartResultAction]
  )

  const cartShippingData = cart?.shippingData
  const updateShippingData = (shippingType: ShippingType, deliveryAddress?: DeliveryAddressV2) => {
    if (!cartShippingData) {
      return
    }
    cart.shippingData.shippingType = shippingType
    if (deliveryAddress) {
      cart.shippingData.deliveryAddress = deliveryAddress
    }
    // if delivery -> write write function params into partial data and cart
    if (isShippingTypeDelivery(shippingType)) {
      setPartialShippingDataDelivery({
        ...partialShippingDataDelivery,
        shippingType: shippingType,
        deliveryAddress: deliveryAddress ?? partialShippingDataDelivery.deliveryAddress,
      })
      cart.shippingData.storeId = ''
    } else if (
      !isShippingTypeDelivery(shippingType) &&
      partialShippingDataPickup.shippingType !== shippingType
    ) {
      setPartialShippingDataPickup({ ...partialShippingDataPickup, shippingType: shippingType })
      if (partialShippingDataPickup?.shippingDate) {
        cart.shippingData.shippingDate = partialShippingDataPickup.shippingDate
      }
    }

    // pickup && keine store id gesetzt?
    if (
      !isShippingTypeDelivery(cart.shippingData.shippingType) &&
      cart.shippingData.storeId === ''
    ) {
      cart.shippingData.storeId = selectedStoreId
    }
    if (shippingType === 'ExpressPickup') {
      cart.shippingData.shippingDate =
        shippingOptionsForPickUp.find(
          (shippingOption) => shippingOption.shippingType === 'ExpressPickup'
        )?.shippingDates[0].time ?? ''
    }

    if (!isErrorRedirectFromPaymentProvider) {
      // set storeId empty, to ensure that the backend is able to update the delivery shipping type correctly.
      // this is only necessary for delivery, not pickup!
      dispatch(updateCartGraphQL(cart))
    }

    // Update cart prices on cart update
    waitForUpdateCartResultAction(() => {
      dispatch(getShoppingCartPrices(selectedCartId))
      dispatch(verifyCartGraphQL(selectedCartId))
    })
  }

  /** Appears to expect the following format: YYYY-MM-DDTHH:mm:ssZ */
  const updateShippingDate = (date: string) => {
    if (!cartShippingData) {
      return
    }
    cart.shippingData.shippingDate = date
    if (context === ShoppingCartContextEnum.CHECKOUT_DELIVERY) {
      setPartialShippingDataDelivery({ ...partialShippingDataDelivery, shippingDate: date })
    } else {
      setPartialShippingDataPickup({ ...partialShippingDataPickup, shippingDate: date })
    }
    setSelectedShippingDate(date)
    setValuesForMemoryBox({ shippingData: { shippingDate: date } })
    if (!isErrorRedirectFromPaymentProvider) {
      dispatch(updateCartGraphQL(cart))
    }
  }

  useEffect(() => {
    if (isShippingTypeDelivery(selectedShippingType)) {
      const shippingOption = shippingOptionsForDelivery.find(
        (shippingOption) => shippingOption.shippingType === selectedShippingType
      )
      const availableShippingDates = shippingOption?.shippingDates
      // Check if selectedShippingDate is part of the available shippingDates provided by backend via shippingOptionsForDelivery

      const formattedSelectedShippingDate = dayjs(selectedShippingDate).format('YYYY-MM-DD')
      if (
        availableShippingDates &&
        availableShippingDates.length > 0 &&
        !availableShippingDates?.find(
          (shippingDate) => shippingDate.date === formattedSelectedShippingDate
        )
      ) {
        // Set to first available shippingDate, if current one is no longer available
        const formattedFirstAvailableShippingDate = dayjs(availableShippingDates[0].date).format(
          'YYYY-MM-DDTHH:mm:ssZ'
        )
        if (isErrorRedirectFromPaymentProvider) {
          updateShippingDate(cartShippingData?.shippingDate ?? formattedFirstAvailableShippingDate)
        } else {
          updateShippingDate(formattedFirstAvailableShippingDate)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShippingDate, selectedShippingType, shippingOptionsForDelivery])

  const updateShippingDateInCart = () => {
    if (cart) {
      if (memoryBox.shippingData) {
        if (memoryBox.shippingData.shippingDate) {
          cart.shippingData.shippingDate = memoryBox.shippingData.shippingDate
        } else {
          cart.shippingData.shippingDate = ''
        }

        if (isShippingTypeDelivery(cart.shippingData.shippingType)) {
          setPartialShippingDataDelivery({
            ...partialShippingDataDelivery,
            shippingDate: cart.shippingData.shippingDate,
          })
        } else {
          setPartialShippingDataPickup({
            ...partialShippingDataPickup,
            shippingDate: cart.shippingData.shippingDate,
          })
        }
      }
    }
  }

  const onChangeContextSetDelivery = () => {
    // 1.) check partialShippingDataDelivery (local state) for shippingType - set only after tab or option change
    if (partialShippingDataDelivery?.shippingType) {
      if (!partialShippingDataDelivery.deliveryAddress) {
        const deliveryAddressViaShippingOptions =
          shippingOptionsForDelivery.find(
            (shippingOption) => partialShippingDataDelivery.shippingType && shippingOption.isEnabled
          )?.deliveryAddress ?? cartShippingData?.deliveryAddress
        setPartialShippingDataDelivery({
          ...partialShippingDataDelivery,
          deliveryAddress: deliveryAddressViaShippingOptions,
        })
      }
      updateShippingData(
        partialShippingDataDelivery.shippingType,
        partialShippingDataDelivery.deliveryAddress
      )
      return
    }
    // 2. check cart for shippingType of type delivery - required when checkout page first rendered {
    const shippingOptionAsSavedOnCart = shippingOptionsForDelivery.find(
      (shippingOption) =>
        shippingOption.shippingType === cartShippingData?.shippingType && shippingOption.isEnabled
    )

    if (shippingOptionAsSavedOnCart) {
      updateShippingData(
        shippingOptionAsSavedOnCart.shippingType,
        shippingOptionAsSavedOnCart.deliveryAddress
      )
      if (!partialShippingDataDelivery.deliveryAddress) {
        setPartialShippingDataDelivery({
          ...partialShippingDataDelivery,
          shippingType: shippingOptionAsSavedOnCart.shippingType,
          deliveryAddress: shippingOptionAsSavedOnCart.deliveryAddress,
        })
      }
      return
    }
    // 3. set shippingType via defaults in shippingOptions - required when delivery tab first rendered and no delivery type saved on cart
    const defaultShippingOption = shippingOptionsForDelivery.find(
      (shippingOption) => shippingOption.isDefault && shippingOption.isEnabled
    )
    const deliveryTypeViaShippingOptions = defaultShippingOption?.shippingType ?? 'Cargo'

    if (cart) {
      const deliveryAddressViaShippingOptions: DeliveryAddressV2 =
        defaultShippingOption?.deliveryAddress ?? cart.shippingData.deliveryAddress

      setPartialShippingDataDelivery({
        ...partialShippingDataDelivery,
        shippingType: deliveryTypeViaShippingOptions,
        deliveryAddress: deliveryAddressViaShippingOptions,
      })
      cart.shippingData.storeId = ''
      updateShippingData(deliveryTypeViaShippingOptions, deliveryAddressViaShippingOptions)
    }
  }

  const onChangeContextSetPickUp = () => {
    if (cart) {
      // 1.) check partialShippingDataDelivery (local state) for shippingType - set only after tab or option change
      if (partialShippingDataPickup.shippingType) {
        updateShippingData(partialShippingDataPickup.shippingType)
        return
      }

      // 2. check cart for shippingType of type delivery - required when checkout page first rendered

      const shippingTypeAsSavedOnCart = shippingOptionsForPickUp.find(
        (shippingOption) =>
          shippingOption.shippingType === cart.shippingData.shippingType && shippingOption.isEnabled
      )
      if (shippingTypeAsSavedOnCart) {
        updateShippingData(shippingTypeAsSavedOnCart.shippingType)
        setPartialShippingDataPickup({
          ...partialShippingDataPickup,
          shippingType: shippingTypeAsSavedOnCart.shippingType,
        })
        return
      }
      // 3. set shippingType via defaults in shippingOptions - required when delivery tab first rendered and no delivery type saved on cart
      const pickupTypeViaShippingOptions =
        shippingOptionsForPickUp.find(
          (shippingOption) => shippingOption.isDefault && shippingOption.isEnabled
        )?.shippingType ?? 'DefaultPickup'
      setPartialShippingDataPickup({
        ...partialShippingDataPickup,
        shippingType: pickupTypeViaShippingOptions,
      })
      updateShippingData(pickupTypeViaShippingOptions)
    }
  }

  const setCheckoutContext = (context: ShoppingCartContextEnum) => {
    setContext(context)
    if (context === ShoppingCartContextEnum.CHECKOUT_DELIVERY) {
      onChangeContextSetDelivery()
      if (partialShippingDataDelivery?.shippingDate) {
        updateShippingDate(partialShippingDataDelivery.shippingDate)
      }
    } else {
      onChangeContextSetPickUp()
      if (partialShippingDataPickup?.shippingDate) {
        updateShippingDate(partialShippingDataPickup.shippingDate)
      }
    }
  }

  const setInitialShippingDate = () => {
    if (cart) {
      if (context === ShoppingCartContextEnum.CHECKOUT_DELIVERY) {
        cart.shippingData.shippingDate =
          shippingOptionsForDelivery.find(
            (shippingOption) => shippingOption.isDefault && shippingOption.isEnabled
          )?.shippingDates[0].date ?? ''
      }
      if (context === ShoppingCartContextEnum.CHECKOUT_PICKUP) {
        cart.shippingData.shippingDate =
          shippingOptionsForPickUp.find(
            (shippingOption) => shippingOption.isDefault && shippingOption.isEnabled
          )?.shippingDates[0].date ?? ''
      }
      dispatch(updateCartGraphQL(cart))
    }
  }

  // initialize shippingType and shippingDate
  useEffect(() => {
    if (context === ShoppingCartContextEnum.CHECKOUT_DELIVERY) {
      onChangeContextSetDelivery()
    } else {
      onChangeContextSetPickUp()
    }
    if (cartShippingData?.shippingDate === 'undefined') {
      setInitialShippingDate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectStoreId = (storeId: string) => {
    if (cart) {
      cart.shippingData.storeId = storeId
      setPartialShippingDataPickup({ ...partialShippingDataPickup, storeId: storeId })
      dispatch(updateCartGraphQL(cart))
      waitForUpdateCartResultAction(() => {
        if (cartShippingData?.storeId) {
          setSelectedStoreId(cartShippingData?.storeId)
        }
        verifyCart(false)
      })
    }
  }

  const updateDeliveryAddress = (
    deliveryAddress: DeliveryAddressV2,
    addressId?: string | undefined
  ) => {
    if (cart) {
      cart.shippingData.deliveryAddress = deliveryAddress
      // Note that userAddresses all have addressIds, while addresses edited via ShoppingCartShippingForm don't
      if (addressId !== undefined) {
        cart.shippingData.addressId = addressId
        setSelectedDeliveryAddressId(addressId)
      }
      setPartialShippingDataDelivery({
        ...partialShippingDataDelivery,
        addressId: addressId ?? '',
        deliveryAddress: deliveryAddress,
      })
      setDeliveryAddress(deliveryAddress)
      if (!isErrorRedirectFromPaymentProvider) {
        dispatch(updateCartGraphQL(cart))
        waitForUpdateCartResultAction(() => {
          verifyCart(false)
        })
      }
    }
  }

  //only run once, when the cart was loaded the first time
  const storeIdDataLoaded = useRef(false)
  useEffect(() => {
    // don't update storeId when is set to '' because of the delivery update call
    if (cartShippingData?.storeId === '') {
      return
    }
    if (cartShippingData?.storeId && !storeIdDataLoaded.current) {
      setSelectedStoreId(cartShippingData?.storeId)
      storeIdDataLoaded.current = true
    }
  }, [cartShippingData?.storeId])

  useEffect(() => {
    if (cartShippingData?.addressId) {
      setSelectedDeliveryAddressId(cartShippingData?.addressId)
    }
  }, [cartShippingData?.addressId])

  useEffect(() => {
    if (cartShippingData?.deliveryAddress) {
      setDeliveryAddress(cartShippingData?.deliveryAddress)
    }
  }, [cartShippingData?.deliveryAddress])

  useEffect(() => {
    if (mainAddress?.address && cart) {
      if (isErrorRedirectFromPaymentProvider) {
        updateDeliveryAddress(cartShippingData?.deliveryAddress ?? mainAddress?.address)
      } else {
        updateDeliveryAddress(mainAddress?.address)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainAddress?.address])

  //update delivery address to trigger useLoadShippingOptions
  useEffect(() => {
    if (cart && cartShippingData?.shippingType) {
      setSelectedShippingType(cart?.shippingData?.shippingType)
    }
    const selectedShippingOption = [
      ...shippingOptionsForDelivery,
      ...shippingOptionsForPickUp,
    ].find((option) => option.shippingType === cart?.shippingData?.shippingType)
    if (selectedShippingOption) {
      setActivePaymentMethods(selectedShippingOption.paymentMethods)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartShippingData?.shippingType, shippingOptionsForDelivery, shippingOptionsForPickUp])

  const enabledShippingOptionsForDelivery = shippingOptionsForDelivery.filter(
    (shippingOption) => shippingOption.isEnabled
  )

  const metaData = useEntityMetaData('cartsv2')
  const isUpdating = metaData.isUpdating

  const setOrderDeclarationsInCart = () => {
    if (cart) {
      cart.commission = getValues('commission')
      cart.phone = getValues('phone')
      cart.remark = getValues('additionalText')
    }
  }

  const onCartUpdateAndVerifySuccess = useOnCartUpdateAndVerifySuccess({
    cart,
    setOrderSubmitted,
    cartVerificationActionType,
    setCartVerificationErrorCode,
    setOrderDeclarationsInCart,
    selectedCartId,
  })

  const notifyShoppingCart = () => {
    if (onCartUpdateAndVerifySuccess) {
      onCartUpdateAndVerifySuccess(() =>
        createOrderRedirect({
          cart,
          commission: commissionText,
          phone: phoneText,
          remark: additionalText,
          isApprovalRequest: true,
        })
      )
    }
  }

  const submitShoppingCart = () => {
    if (orderSubmitted) {
      return
    }

    if (phoneIsRequired) {
      if (getValues('phone') === '') {
        setOrderSubmitted(false)
        setShowTelephoneRequiredModal(true)
        return
      }
    }

    if (onCartUpdateAndVerifySuccess) {
      if (!isDefaultPaymentMethod(paymentMethod)) {
        const cartId = cart?.id ?? ''
        onCartUpdateAndVerifySuccess(() =>
          createOrderRedirect({
            cart,
            commission: commissionText,
            phone: phoneText,
            remark: additionalText,
            preventRedirect: true,
            onCartUpdate: () => createPaymentTransaction(cartId, paymentMethod),
          })
        )
      } else {
        onCartUpdateAndVerifySuccess(() =>
          createOrderRedirect({
            cart,
            commission: commissionText,
            phone: phoneText,
            remark: additionalText,
          })
        )
      }
    }
  }

  const { createPaymentTransaction } = usePaymentHandler({
    cartId: cart?.id,
    paymentMethod: cart?.paymentMethod,
  })

  const emptyUser = !userV2 || Object.keys(userV2).length === 0

  if (shoppingCarts.length === 0 || stores.length === 0 || emptyUser) {
    return null
  }

  const handleConfirmSaveChanges = () => {
    if (cart) {
      setOrderDeclarationsInCart()
      //handles the existing bug where sometimes no shippingDate is set in the data received from backend
      //TODO: once the above bug has been addressed properly, the following can be refactored in a much shorter way as in cart.shippingData = Object.assign({}, cart.shippingData, memoryBox.shippingData)
      updateShippingDateInCart()

      dispatch(updateCartGraphQL(cart))
      //close the modal
      setShowModalUnsavedChangesInCart(false)
      history.push('/shopping-cart-details/' + selectedCartId)
    }
  }

  const handleCancelSaveChanges = () => {
    setShowModalUnsavedChangesInCart(false)
    history.push('/shopping-cart-details/' + selectedCartId)
  }

  // handles which tab is opened on first render - delivery or pickup
  if (
    context === ShoppingCartContextEnum.UNSET &&
    shippingOptionsForDelivery.length > 0 &&
    shippingOptionsForPickUp.length > 0
  ) {
    setContext(
      !isShippingTypeDelivery(cartShippingData?.shippingType ?? '') ||
        enabledShippingOptionsForDelivery.length === 0
        ? ShoppingCartContextEnum.CHECKOUT_PICKUP
        : ShoppingCartContextEnum.CHECKOUT_DELIVERY
    )
  }

  //could apply only for subusers:
  const noPermissionGranted =
    userV2?.permissions?.Cart_allowedSubmitAction === CartWritePermission.None &&
    userV2.type === UserType.sub

  // Render notification to show user reason that they cannot order due to missing permission
  if (noPermissionGranted) {
    getEventSubscription().next({
      type: EventType.Toast,
      notificationType: NotificationType.OrderFailure,
      id: `${selectedCartId}-${NotificationType.OrderFailure}-${OrderFailureReason.NoPermissionGranted}`,
      options: {
        reason: OrderFailureReason.NoPermissionGranted,
      },
    })
  }

  const preFilterCartItems = (filter: ShoppingCartFilterEnum) => {
    const data: ShoppingCartDetailsState = {
      context: ShoppingCartContextEnum.DETAILS_EDITING,
      filter: filter,
    }
    if (cart) {
      history.push(`/shopping-cart-details/${cart.id}`, data)
    }
  }

  const updateCartIsCompleteDelivery = (checked: boolean) => {
    if (cart) {
      cart.shippingData.isCompleteDelivery = checked
      dispatch(updateCartGraphQL(cart))
    }
  }

  const updateCartOffer = (offerId: string) => {
    if (cart) {
      cart.offerId = offerId
      dispatch(updateCartGraphQL(cart))
    }
  }

  const createUserAddress = (address: AddressV2, customName: string) => {
    dispatch(createUserAddressGraphQL(address, customName))
    waitForCreateUserAddressResultAction((action: CreateUserAddressGraphQLResultAction) => {
      if (action.success && action.addressId) {
        setSelectedDeliveryAddressId(action.addressId)
      }
    })
  }

  const deleteUserAddress = (addressId: string) => {
    dispatch(deleteUserAddressGraphQL(addressId))
  }

  const updateUserAddress = (addressId: string, address: AddressV2, customName: string) => {
    dispatch(updateUserAddressGraphQL(addressId, address, customName))
    waitForUpdateUserAddressResultAction((action: UpdateUserAddressGraphQLResultAction) => {
      if (action.success) {
        setSelectedDeliveryAddressId(addressId)
      }
    })
  }

  const onSelectUserAddressId = (addressId: string) => {
    const selectedDeliveryAddress = userAddresses.find(
      (userAddress) => userAddress.addressId === addressId
    )
    if (selectedDeliveryAddress) {
      updateDeliveryAddress(selectedDeliveryAddress.address, selectedDeliveryAddress.addressId)
    }
  }

  const nonXpressItems = cart?.items.filter(
    (shoppingCartItem) =>
      // no stock data provided
      !shoppingCartItem.product.stock ||
      // insufficient stock data provided
      shoppingCartItem.product.stock.length === 0 ||
      // only online stock data provided
      (shoppingCartItem.product.stock.length === 1 &&
        shoppingCartItem.product.stock[0].warehouseId === '10') ||
      // item only partially available in store
      shoppingCartItem.product.stock?.find(
        (stockObject) =>
          stockObject.warehouseId !== '10' && stockObject.amount < shoppingCartItem.amount
      )
  )

  const nonSendableItems = cart?.items.filter((article) => !article.product.isSendable)
  const specialItems = cart?.items.filter(
    (article) =>
      article.product.type === ProductType.special ||
      article.product.type === ProductType.custom ||
      article.product.type === ProductType.oversize
  )

  const cartContainsSpecialItems = specialItems ? specialItems.length > 0 : false
  const cartContainsNonSendableItems = nonSendableItems ? nonSendableItems?.length > 0 : false

  const resetCartVerificationErrorCode = () => {
    setCartVerificationErrorCode(CartVerificationErrorCode.Unset)
  }
  const handleCutOffTimeExceeded = () => {
    if (!cart) {
      return
    }
    updateShippingData(cart.shippingData.shippingType, cart.shippingData.deliveryAddress)

    // Attempt to submit the cart only once the shipping date has been set to the next available date
    waitForUpdateCartResultAction(() => {
      submitShoppingCart()
    })
  }

  /** phone number is (only) required for Xpress pick up or pick up from Renzbox/Abholbox */
  const phoneIsRequired =
    context === ShoppingCartContextEnum.CHECKOUT_PICKUP &&
    (cartShippingData?.shippingType === 'PickupRenzbox' ||
      cartShippingData?.shippingType === 'ExpressPickup')

  const handleClickBack = () => {
    if (!isDirty) {
      history.push('/shopping-cart-details/' + selectedCartId)
      return
    }
    setShowModalUnsavedChangesInCart(true)
  }

  const saveTelephoneNumber = (phone) => {
    setValue('phone', phone)
    if (cart) {
      cart.phone = phone
      dispatch(updateCartGraphQL(cart))
    }
  }

  const isCommissionFieldValid =
    (!userV2?.permissions?.Cart_requiredCommission ||
      (userV2?.permissions?.Cart_requiredCommission && commissionText.length > 0)) &&
    errors?.commission === undefined

  const submit = () => {
    submitShoppingCart()
  }
  const hasMandatoryDeliveryAddressData =
    context === ShoppingCartContextEnum.CHECKOUT_PICKUP ||
    Boolean(
      cart?.shippingData?.deliveryAddress?.name1?.length &&
        cart?.shippingData?.deliveryAddress?.street?.length &&
        cart?.shippingData?.deliveryAddress?.zipCode?.length &&
        cart?.shippingData?.deliveryAddress?.city?.length
    )

  return (
    <IsPaymentMethodPermittedProvider>
      <>
        <div className={styles.background} />
        <div className={clsx(styles.shoppingCartCheckout)}>
          <div className={styles.content}>
            <header className={styles.header}>
              <div className={styles.back}>
                <TertiaryButton
                  size={desktop ? 'small' : 'large'}
                  leftIcon={<SvgIcon component={ArrowBackIcon} />}
                  onClick={handleClickBack}
                >
                  {t('SHOPPING_CART.NAVIGATION.BACK')}
                </TertiaryButton>
              </div>
              <LightGrayDivider className={styles.deliveryDivider} />
              <Typography variant={'h3'} color={theme.palette.secondary.main}>
                {t('SHOPPING_CART.CHECKOUT.ORDER_DETAILS')}
              </Typography>
            </header>
            <Grid
              container
              direction={'row'}
              columnSpacing={1.875}
              className={styles.gridHoldingDetailsAndSummary}
            >
              <Grid
                container
                direction={'column'}
                className={styles.form}
                rowSpacing={{ xs: 0.75, lg: 1.5 }}
                size={{ xs: 12, lg: 8 }}
              >
                <ShoppingCartCheckoutHeader
                  context={context}
                  setCheckoutContext={setCheckoutContext}
                  isDeactivated={deliveryAddressIsBeingEdited}
                />
                <Grid>
                  <Card
                    className={styles.informationContainer}
                    {...(context === ShoppingCartContextEnum.CHECKOUT_PICKUP && {
                      'data-testid': 'checkout-pickup-box',
                    })}
                    {...(context === ShoppingCartContextEnum.CHECKOUT_DELIVERY && {
                      'data-testid': 'checkout-delivery-box',
                    })}
                  >
                    {context === ShoppingCartContextEnum.CHECKOUT_PICKUP && cart && (
                      <ShoppingCartCheckoutPickupInformation
                        variant="cart"
                        preFilterCartItems={preFilterCartItems}
                        selectedStoreId={selectedStoreId}
                        stores={stores}
                        xpressPickupEnabled={nonXpressItems ? nonXpressItems.length === 0 : true}
                        checkoutStoreSearchBox={checkoutStoreSearchBox}
                        savedShippingType={cart.shippingData.shippingType}
                        partialShippingDataPickup={partialShippingDataPickup}
                        selectStoreId={selectStoreId}
                        setCheckoutStoreSearchBox={setCheckoutStoreSearchBox}
                        setValuesForMemoryBox={setValuesForMemoryBox}
                        shippingOptions={shippingOptionsForPickUp}
                        selectedShippingType={selectedShippingType}
                        selectedShippingDate={selectedShippingDate}
                        updateShippingDate={updateShippingDate}
                        updateShippingType={updateShippingData}
                        setPartialShippingDataPickup={setPartialShippingDataPickup}
                        cartContainsSpecialItems={cartContainsSpecialItems}
                        outOfStockMessage={outOfStockMessage}
                        cartId={selectedCartId}
                      />
                    )}
                    {context === ShoppingCartContextEnum.CHECKOUT_DELIVERY &&
                      deliveryAddress &&
                      cart && (
                        <ShoppingCartCheckoutDeliveryInformation
                          variant="cart"
                          selectedDeliveryAddressId={selectedDeliveryAddressId}
                          selectedDeliveryAddress={deliveryAddress}
                          selectedShippingType={selectedShippingType}
                          selectedShippingDate={selectedShippingDate}
                          createUserAddress={createUserAddress}
                          deleteUserAddress={deleteUserAddress}
                          updateUserAddress={updateUserAddress}
                          onSelectUserAddressId={onSelectUserAddressId}
                          cartContainsSpecialItems={cartContainsSpecialItems}
                          showAddressManagmentModal={showAddressManagementModal}
                          setShowAddressManagmentModal={setShowAddressManagementModal}
                          cartContainsNonSendableItems={cartContainsNonSendableItems}
                          preFilterCartItems={preFilterCartItems}
                          shippingOptionsForDelivery={shippingOptionsForDelivery}
                          updateShippingAddress={updateDeliveryAddress}
                          userAddresses={userAddresses}
                          cart={cart}
                          setValuesForMemoryBox={setValuesForMemoryBox}
                          updateShippingDate={updateShippingDate}
                          updateShippingType={updateShippingData}
                          userCanWriteAddresses={userV2?.permissions?.Address_canWrite}
                          userCanWriteCartAddress={userV2?.permissions?.Cart_canWriteAddress}
                          toggleEditingStateOfDeliveryAddress={toggleEditingStateOfDeliveryAddress}
                          isDeactivated={deliveryAddressIsBeingEdited}
                          outOfStockMessage={outOfStockMessage}
                          cartId={selectedCartId}
                        />
                      )}

                    <ShoppingCartOrderDeclarations
                      control={control}
                      errors={errors}
                      phone={cart?.phone ?? ''}
                      additionalText={cart?.remark ?? ''}
                      commission={cart?.commission ?? ''}
                      phoneIsRequired={phoneIsRequired}
                      trigger={trigger}
                      userCanWriteCommission={userV2?.permissions?.Cart_canWriteCommission}
                      userHasToWriteCommission={userV2?.permissions?.Cart_requiredCommission}
                      userCanWriteAdditionalText={userV2?.permissions?.Cart_canWriteAdditionalText}
                      isDeactivated={deliveryAddressIsBeingEdited}
                    />
                    {!isLoadingCashCustomerCheck && cart && (
                      <ShoppingCartCheckoutBilling
                        control={control}
                        orderSubmitted={orderSubmitted}
                        selectedPaymentMethod={cart.paymentMethod}
                        activePaymentMethods={activePaymentMethods}
                        userIsCashCustomer={isCashCustomer}
                        isDeactivated={deliveryAddressIsBeingEdited}
                        updatePaymentMethodInCart={updatePaymentMethodInCart}
                        hidePaymentMethods={!userCanSubmitCart}
                        shippingGroup={
                          context === ShoppingCartContextEnum.CHECKOUT_DELIVERY
                            ? ShippingGroup.Delivery
                            : ShippingGroup.Pickup
                        }
                      />
                    )}
                  </Card>
                </Grid>
              </Grid>
              <Grid
                columnSpacing={2}
                className={styles.gridHoldingSummary}
                size={{ xs: 12, lg: 4 }}
              >
                <ShoppingCartSummary
                  shoppingCartPrices={shoppingCartPrices}
                  offers={offers}
                  context={context}
                  shippingData={cartShippingData}
                  updateCartOffer={updateCartOffer}
                  updateCartIsCompleteDelivery={updateCartIsCompleteDelivery}
                  selectedCartId={selectedCartId}
                  user={userV2}
                  loadingPrices={isUpdating || !shoppingCartPrices}
                  checkBoxIsCompleteDelivery={cartShippingData?.isCompleteDelivery ?? false}
                  cartOfferId={cart?.offerId ?? ''}
                  notifyShoppingCart={notifyShoppingCart}
                  submitShoppingCart={submit}
                  orderButtonEnabled={
                    hasMandatoryDeliveryAddressData &&
                    !deliveryAddressIsBeingEdited &&
                    !orderBlockIsActivated.current &&
                    !shippingBlockIsActivated.current &&
                    !cartContainsNonPickupableItems &&
                    // Subusers can notify even if credit limit is exceeded
                    (!creditLimitExceeded.current ||
                      (creditLimitExceeded.current &&
                        userV2?.permissions?.Cart_allowedSubmitAction ===
                          CartWritePermission.Notify)) &&
                    !noPermissionGranted &&
                    !orderSubmitted &&
                    isCommissionFieldValid &&
                    !limitExceededWithoutNotificationMail
                  }
                  userMainAddress={mainAddress}
                  activeCart={cart}
                  userData={userData}
                  companyData={companyData}
                  isDeactivated={deliveryAddressIsBeingEdited}
                  deliveryDisabled={
                    enabledShippingOptionsForDelivery.length === 0 &&
                    context === ShoppingCartContextEnum.CHECKOUT_DELIVERY
                  }
                  specialOrCustomProductsInCart={cartContainsSpecialItems}
                  voltimumPoints={voltimumPoints}
                  isLoadingVoltimumPoints={isLoadingVoltimumPoints}
                  hasPvProductsInCart={hasPvProductsInCart}
                  isEmptyCart={cart?.items ? cart.items.length === 0 : true}
                  transferIdsCartAndSubmit={() => transferIdsCartAndSubmit(cart)}
                  promotionId={cart?.promotionId}
                  userCanSubmitCart={userCanSubmitCart}
                />
              </Grid>
            </Grid>
            {shoppingCartPrices && (
              <GenericControlStickyBox
                cartId={selectedCartId}
                context={context}
                orderButtonEnabled={
                  hasMandatoryDeliveryAddressData &&
                  !deliveryAddressIsBeingEdited &&
                  !orderBlockIsActivated.current &&
                  !shippingBlockIsActivated.current &&
                  !cartContainsNonPickupableItems &&
                  // Subusers can notify even if credit limit is exceeded
                  (!creditLimitExceeded.current ||
                    (creditLimitExceeded.current &&
                      userV2?.permissions?.Cart_allowedSubmitAction ===
                        CartWritePermission.Notify)) &&
                  !noPermissionGranted &&
                  !orderSubmitted &&
                  isCommissionFieldValid &&
                  !limitExceededWithoutNotificationMail
                }
                shoppingCartPrices={shoppingCartPrices}
                notifyShoppingCart={notifyShoppingCart}
                submitShoppingCart={submit}
                userCanSubmitCart={userCanSubmitCart}
                userCanReadPrices={
                  userV2?.permissions?.Global_canReadPrices === PricePermissions.PurchasePrice
                }
                isDeactivated={deliveryAddressIsBeingEdited}
                deliveryDisabled={
                  enabledShippingOptionsForDelivery.length === 0 &&
                  context === ShoppingCartContextEnum.CHECKOUT_DELIVERY
                }
                transferIdsCartAndSubmit={() => transferIdsCartAndSubmit(cart)}
              />
            )}
            <CartVerification
              errorCode={cartVerificationErrorCode}
              handleCloseByUnsettingErrorCode={resetCartVerificationErrorCode}
              handleCutOffTimeExceeded={handleCutOffTimeExceeded}
              submitCart={submitShoppingCart}
              nameOfPickupOption={
                cartVerificationErrorCode === CartVerificationErrorCode.PickupOptionNotPossible
                  ? stores.find((store) => store.id === selectedStoreId)?.address.name1
                  : undefined
              }
            />
          </div>
          {showTelephoneRequiredModal && (
            <TelephoneRequiredPopUp
              isOpen={showTelephoneRequiredModal}
              setShowTelephoneRequiredModal={setShowTelephoneRequiredModal}
              saveTelephoneNumber={saveTelephoneNumber}
            />
          )}
          <Confirm
            heading={t('SHOPPING_CART.CHECKOUT.UNSAVED_CHANGES')}
            body={t('SHOPPING_CART.CHECKOUT.SAVE_CHANGES')}
            handleConfirm={handleConfirmSaveChanges}
            handleCancel={handleCancelSaveChanges}
            openConfirmDialog={showModalUnsavedChangesInCart}
            changesInCart={true}
          />
        </div>
      </>
    </IsPaymentMethodPermittedProvider>
  )
}
