import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyCardContainer } from '@obeta/components/lib/empty-card/EmptyCardContainer'
import { EmptyCard } from '@obeta/components/lib/empty-card/EmptyCard'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'

export const ProjectOverviewNoPermission: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <EmptyCardContainer>
      <EmptyCard
        title={t('PROJECTS.NO_PERMISSION')}
        subtitle={t('PROJECTS.NO_PERMISSION_DESCRIPTION')}
        customAction={{
          title: t('PROJECTS.NO_PERMISSION_LOCATIONS'),
          onClick: () => history.push(ShopRoutes.Stores),
        }}
        showContact
      />
    </EmptyCardContainer>
  )
}
