import React from 'react'
import { TFunction } from 'i18next'
import { belowMdQuery, useMediaQuery } from '@obeta/data/lib/hooks'
import { useTranslation } from 'react-i18next'
import { ReactComponent as HomeIcon } from 'assets/icon/designsystem/home.svg'
import { ReactComponent as TemplatesIcon } from 'assets/icon/designsystem/templates.svg'
import { ReactComponent as OrdersIcon } from 'assets/icon/designsystem/local_shipping.svg'
import { ReactComponent as ProjectsIcon } from 'assets/icon/designsystem/offers.svg'
import { ReactComponent as CustomerCardIcon } from 'assets/icon/designsystem/customer_card.svg'
import { NavLink } from './NavLink'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useCustomerMainManager } from '../../customer-main/CustomerMainManagerProvider'
import styles from './Navigation.module.scss'

const getNavLinks = (t: TFunction, isBelowMd: boolean) => [
  {
    path: '/start',
    title: t('MAIN.START'),
    icon: <HomeIcon />,
  },
  {
    path: ShopRoutes.TemplateList,
    title: t('MAIN.TEMPLATES'),
    icon: <TemplatesIcon />,
  },
  {
    path: ShopRoutes.OrdersList,
    title: t('MAIN.ORDERS'),
    icon: <OrdersIcon />,
  },
  {
    path: ShopRoutes.Offers,
    title: t('MAIN.PROJECTS'),
    icon: <ProjectsIcon />,
  },
  {
    path: '/profile',
    title: t(isBelowMd ? 'CUSTOMER_PROFILE.CUSTOMER_CARD_SHORT' : 'CUSTOMER_PROFILE.CUSTOMER_CARD'),
    icon: <CustomerCardIcon />,
  },
]

export const Navigation = () => {
  const isBelowMd = useMediaQuery(belowMdQuery)
  const { t } = useTranslation()
  const { customerMainCardVisibilityHandler } = useCustomerMainManager()

  const links = getNavLinks(t, isBelowMd)

  return (
    <div className={styles.navigationWrapper} id="navigation">
      <div className={styles.navigation}>
        {links.map((navItem) => {
          return (
            <NavLink
              navItem={navItem}
              key={navItem.path}
              customClickHandler={
                navItem.path === '/profile'
                  ? () => customerMainCardVisibilityHandler(true)
                  : undefined
              }
            />
          )
        })}
      </div>
    </div>
  )
}
