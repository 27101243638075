import React, { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Box, FormControlLabel, FormGroup, Tooltip, Typography, useTheme } from '@mui/material'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { Checkbox } from '@obeta/components/lib/checkbox/Checkbox'
import clsx from 'clsx'
import { UserPermission, UserPermissionElement } from '../permission/UserPermission'
import { CollapsibleContentContainer } from './CollapsibleContentContainer'
import styles from './form.module.scss'
import { BaseOption, Select } from '@obeta/components/lib/select/Select'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { useCreateUserContext } from '../../userOverviewPage/create-user/useCreateUserContext'
import { UserPermissions } from '@obeta/models/lib/schema-models/userV2-subusers'

type UserPermissionRelation = {
  dependencies: UserPermissionElement[]
  target: UserPermissionElement
}
type UserPermissionRelations = Array<UserPermissionRelation>
type CollapsibleContentProps = Omit<ComponentProps<typeof UserPermission>, 'setAllPermissions'> & {
  permissionsList: UserPermissionElement[][]
  onChange?: (value: boolean) => void
  disabled?: boolean
  relations?: UserPermissionRelations
}

const hasSufficientDependencies = (
  userPermissions: UserPermissions,
  permission: UserPermissionElement,
  relations?: UserPermissionRelations
) => {
  // no relations => no dependencies to check for
  if (!relations) return true

  const relation = relations.find(
    (relation) => relation.target.translationKey === permission.translationKey
  )
  // permission not included in relation targets => nothing to check for
  if (!relation) return true

  // every dependency satisfied?
  return relation.dependencies.every((dependency) => userPermissions[dependency.translationKey])
}

export const CollapsibleCheckboxGroup: FC<CollapsibleContentProps> = (props) => {
  const { permissionsList, user, updateUser, forceMinWidth, onChange, disabled, relations } = props
  const { t } = useTranslation()
  const { mobile, desktop } = useBreakpoints()
  const theme = useTheme()
  const { inCreateUserDialog } = useCreateUserContext()
  if (!user) {
    return null
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    if (onChange) {
      onChange(checked)
    }

    if (!checked) {
      const realationWithMatchingDependency: UserPermissionRelation[] = []
      relations?.forEach((relation) => {
        const permission = relation.dependencies.find(
          (dependency) => dependency.translationKey === name
        )
        if (permission) {
          realationWithMatchingDependency.push(relation)
        }
      })
      realationWithMatchingDependency.forEach((relation) => {
        user.permissions[relation.target.translationKey] = false
      })
    }

    const updatedUser = {
      ...user,
      permissions: {
        ...user.permissions,
        [name]: name === 'Global_canReadPrices' ? (checked ? 'PurchasePrice' : 'NoPrice') : checked,
      },
    }
    updateUser(updatedUser)
  }

  const handleSelect = (option: BaseOption) => {
    if (onChange) {
      onChange(option.id !== 'NoPrice')
    }
    const updatedUser = {
      ...user,
      permissions: {
        ...user.permissions,
        Global_canReadPrices: option.id as string,
      },
    }
    updateUser(updatedUser)
  }

  const formControlLabelOrSelectBox = (
    isPricePermission: boolean,
    permission: UserPermissionElement
  ) => {
    return (
      <div key={permission.translationKey}>
        <FormControlLabel
          sx={{ marginLeft: '0' }}
          className={isPricePermission ? styles.formControlLabelVariant : styles.formControlLabel}
          control={
            <Checkbox
              checked={
                user.permissions[permission.translationKey] &&
                user.permissions[permission.translationKey] !== 'NoPrice'
              }
              onChange={handleChange}
              name={permission.translationKey}
              className={styles.formControlCheckbox}
              disabled={
                disabled || !hasSufficientDependencies(user.permissions, permission, relations)
              }
            />
          }
          label={
            !isPricePermission && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant={'bodyBold'}>
                  {t(
                    `USER_MANAGEMENT.USER_MANAGEMENT_PAGE.PERMISSION_LABELS.${permission.translationKey}`
                  )}
                </Typography>
                {permission.tooltipTranslationKey?.length > 0 && desktop && (
                  <div style={{ display: 'flex' }}>
                    <Tooltip
                      title={t(permission.tooltipTranslationKey)}
                      className={clsx(styles.selectTooltip)}
                    >
                      <InfoIcon className={styles.tooltipIcon} color={scssVariables.$darkGray} />
                    </Tooltip>
                  </div>
                )}
              </div>
            )
          }
        />
        {isPricePermission && (
          <div style={{ display: 'flex' }}>
            <Select
              paperClassname={styles.selectRoot}
              disabled={disabled}
              paperSx={{
                maxWidth: '13rem',
                minWidth: '13rem',
                '.MuiSelect-outlined': {
                  width: '100%',
                },
                'justify-content': 'space-between',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.grayVariant.light,
                },
              }}
              rootClassName={
                desktop ? styles.selectBoxPricePermissionDesktop : styles.selectBoxPricePermission
              }
              selectedOption={{
                title: t(
                  `USER_MANAGEMENT.USER_MANAGEMENT_PAGE.PERMISSION_LABELS.PRICES_RIGHTS.${
                    user.permissions[permission.translationKey] || 'NoPrice'
                  }`
                ),
                id: user.permissions[permission.translationKey] || 'NoPrice',
              }}
              options={Object.values(PricePermissions).map((p) => ({
                id: p,
                title: t(
                  `USER_MANAGEMENT.USER_MANAGEMENT_PAGE.PERMISSION_LABELS.PRICES_RIGHTS.${p}`
                ),
              }))}
              onOptionSelected={(option) => handleSelect(option)}
            />

            {permission.tooltipTranslationKey?.length > 0 && desktop && (
              <Tooltip
                title={t(permission.tooltipTranslationKey)}
                className={clsx(
                  desktop
                    ? styles.selectBoxPricePermissionDesktop
                    : styles.selectBoxPricePermission,
                  styles.selectTooltip
                )}
              >
                <InfoIcon className={styles.tooltipIcon} color={scssVariables.$darkGray} />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <CollapsibleContentContainer>
      <FormGroup>
        {mobile ? (
          permissionsList.map((sublist) => {
            return sublist.map((permission) => {
              const isPricePermission = permission.translationKey === 'Global_canReadPrices'
              return formControlLabelOrSelectBox(isPricePermission, permission)
            })
          })
        ) : (
          <Box className={styles.radioGroupStyles}>
            {permissionsList.map((sublist, listIndx) => {
              return (
                <Box
                  key={sublist[0].translationKey}
                  className={clsx(
                    styles.checkBoxesAsColumn,
                    inCreateUserDialog && styles.checkBoxesAsColumnSize,
                    {
                      [styles.columnMinSize]: forceMinWidth && listIndx % 2 !== 0,
                    }
                  )}
                >
                  {sublist.map((permission) => {
                    const isPricePermission = permission.translationKey === 'Global_canReadPrices'
                    return formControlLabelOrSelectBox(isPricePermission, permission)
                  })}
                </Box>
              )
            })}
          </Box>
        )}
      </FormGroup>
    </CollapsibleContentContainer>
  )
}
