import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

// Models
import { OrderItemStateType, OrdersFacetV2 } from '@obeta/schema'

// Stores
import { useOrderListContext } from '@obeta/data/lib/stores/useOrderListContext'
import styles from './OrderListFilters.module.scss'
import { CheckboxWithAmountItem } from '../checbox-radio-item/CheckboxRadioItem'
import {
  getOrderListFilterToggleState,
  sortFacetByCustomOrder,
  toggleOrderListFilter,
} from '@obeta/utils/lib/orders-helpers'
import { getTranslationString } from '@obeta/utils/lib/translation-helpers'
import {
  FACETS_ITEM_STATES_ORDER,
  ORDER_ITEM_STATE_TYPE_TRANSLATIONS,
} from '@obeta/models/lib/models/Orders/OrderListViewModels'

export const OrderListFiltersItemState: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { facets, isLoading, resetOrders, setIsLoading } = useOrderListContext()
  const initiateRefresh = useCallback(
    (itemStateValue) => {
      // Reset order list
      resetOrders()
      setIsLoading(true)
      toggleOrderListFilter(history, 'itemStates', itemStateValue)

      return true
    },
    [history, resetOrders, setIsLoading]
  )

  return (
    <div className={styles.content}>
      {sortFacetByCustomOrder(facets?.itemStates as OrdersFacetV2[], FACETS_ITEM_STATES_ORDER).map(
        (itemState) => {
          const itemStateValue = itemState.search as OrderItemStateType
          const addition = itemState.isAddition ? '+' : ''
          const disabled = itemState.count === 0
          const rightAdornment: JSX.Element = (
            <Typography
              variant="bodyBold"
              className={clsx(styles.checkboxWithAmount, disabled && styles.disabled)}
            >{`(${addition}${itemState.count})`}</Typography>
          )
          return (
            <CheckboxWithAmountItem
              disabled={disabled}
              rightAdornment={rightAdornment}
              onChange={() => !isLoading && initiateRefresh(itemStateValue)}
              key={itemStateValue}
              id={itemStateValue}
              label={t(
                getTranslationString<OrderItemStateType>(
                  ORDER_ITEM_STATE_TYPE_TRANSLATIONS,
                  itemStateValue
                )
              )}
              value={itemStateValue}
              checked={getOrderListFilterToggleState(
                history.location.search,
                'itemStates',
                itemStateValue
              )}
              datatestid="itemStates"
            />
          )
        }
      )}
    </div>
  )
}
