import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUserAddressesV2 } from '@obeta/data/lib/hooks/useUserAddressesV2'
import styles from './GreetingSection.module.scss'

const getGreetingKey = () => {
  const currentHour = new Date().getHours()

  if (currentHour >= 0 && currentHour < 10) {
    return 'GREETING.MORNING'
  } else if (currentHour >= 10 && currentHour < 18) {
    return 'GREETING.DAY'
  } else {
    return 'GREETING.EVENING'
  }
}

export const GreetingSection = () => {
  const { t } = useTranslation()
  const greetingKey = getGreetingKey()
  const { mainAddress } = useUserAddressesV2()

  return (
    <div className={styles.greetingWrapper}>
      <Typography className={styles.greetingTitle} variant={'headline3Bold'}>
        {t(`CUSTOMER_PROFILE.${greetingKey}`, { userName: mainAddress?.address.name1 })}
      </Typography>
    </div>
  )
}
