import React from 'react'
import OfferListPage from '@obeta/layouts/lib/offerListPage/offerListPage'
import { useTranslation } from 'react-i18next'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { PleaseLoginScreen } from '../../common/components/please-login-screen/PleaseLoginScreen'

export const OfferList: React.FC = () => {
  const { t } = useTranslation()
  const { isLoggedIn } = useUserDataV2()

  if (isLoggedIn) return <OfferListPage />

  return (
    <PleaseLoginScreen
      title={t('OFFER_OVERVIEW.GUEST_TEXT_TITLE')}
      body={t('OFFER_OVERVIEW.GUEST_TEXT_DESCRIPTION')}
    />
  )
}
