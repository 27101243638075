import React, { FC, useCallback, useState } from 'react'
import styles from './PermissionsTabHeader.module.scss'
import { Typography, Box, Divider, SvgIcon } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ReactComponent as PersonIcon } from 'assets/icon/designsystem/person.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useUserActiveState, useUserDelete } from '@obeta/data/lib/hooks/useUserUpdate'
import { UserType } from '@obeta/models/lib/models/Users/UserV2'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'
import { useUserManagementReadOnly } from '../form/useUserManagementReadOnly'
import { buildUserIdByUser } from '@obeta/utils/lib/buildUserId'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import clsx from 'clsx'
import { settingsNameToName } from '@obeta/utils/lib/settingsNameToName'

export const PermissionsTabHeader: FC<{ currentUser: UserSubuser; isActiveUser?: boolean }> = (
  props
) => {
  const { t } = useTranslation()
  const { desktop, mobile } = useBreakpoints()
  const history = useHistory()
  const { currentUser, isActiveUser } = props
  const { dispatchUserUpdate, userActive } = useUserActiveState(currentUser.isActive)
  const dispatchUserDelete = useUserDelete()
  const [showUserDeleteConfirmation, setShowUserDeleteConfirmation] = useState(false)
  const isReadOnlyUser = useUserManagementReadOnly()
  const customerId = buildUserIdByUser(currentUser, true)
  const user = useUserDataV2()

  const removeId = useCallback(() => {
    return settingsNameToName(currentUser.settings.name)
  }, [currentUser])

  const toggleStatus = async (status: boolean) => {
    await dispatchUserUpdate({
      isActive: status,
      subUserId: customerId,
      email: currentUser.email,
      name: settingsNameToName(currentUser.settings.name),
      notificationEmail: currentUser.notificationEmail,
      permissions: currentUser.permissions,
      orderConfirmationEmail: currentUser.orderConfirmationEmail,
    })
  }
  const handleDeleteUser = async () => {
    await dispatchUserDelete(customerId)
    history.push('/user-overview')
  }
  const handleCancelDeleteUser = () => {
    setShowUserDeleteConfirmation(false)
  }

  const canSeeUserManagement = user?.permissions?.Global_canSeeUserManagement
  return (
    <div className={styles.tabHeader}>
      <div className={styles.wrapperGoBackButton}>
        {!isReadOnlyUser && (
          <TertiaryButton
            size={desktop ? 'small' : 'large'}
            leftIcon={<SvgIcon component={ArrowBackIcon} />}
            onClick={() => history.push('/user-overview')}
          >
            {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.BACK_TO_USER_OVERVIEW')}
          </TertiaryButton>
        )}
      </div>

      <Typography
        className={clsx(canSeeUserManagement && styles.marBottomOneRem)}
        variant="h3"
        color="text.primary"
      >
        {t(
          !canSeeUserManagement
            ? 'USER_MANAGEMENT.MY_PROFILE'
            : 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.USER_MANAGEMENT'
        )}
      </Typography>

      <Box className={styles.wrapperButtonsAndDataHeader}>
        <Box>
          <Typography variant={'boldText'} className={styles.marginRightHalfRem}>
            {currentUser.customerNumber}
          </Typography>
          <Typography variant={'subTitle'}>{removeId()}</Typography>
        </Box>
        {currentUser.type === UserType.sub && !isActiveUser && (
          <Box className={styles.headerButtons}>
            <TertiaryButton
              leftIcon={<PersonIcon />}
              onClick={() => toggleStatus(!userActive)}
              size={desktop ? 'small' : 'large'}
            >
              {mobile
                ? ''
                : userActive
                ? t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.INACTIVATE_USER')
                : t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACTIVATE_USER')}
            </TertiaryButton>
            <TertiaryButton
              leftIcon={<DeleteIcon />}
              onClick={() => {
                setShowUserDeleteConfirmation(true)
              }}
              size={desktop ? 'small' : 'large'}
            >
              {mobile ? '' : t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.DELETE_USER')}
            </TertiaryButton>
          </Box>
        )}
      </Box>
      <Divider variant="fullWidth" />
      {showUserDeleteConfirmation && (
        <Confirm
          heading={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.DELETE_SPECIFIC_USER', {
            customerNumber: currentUser.customerNumber,
          })}
          body={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.DELETE_USER_WARNING')}
          handleConfirm={handleDeleteUser}
          handleCancel={handleCancelDeleteUser}
          openConfirmDialog={true}
        />
      )}
    </div>
  )
}
