import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import { Collapse } from '@obeta/components/lib/collapse/Collapse'
import { Divider, Typography } from '@mui/material'
import { ToggleMore } from '@obeta/components/lib/toggle-more/ToggleMore'
import styles from './ShowMoreContentCollapse.module.scss'

type ShowMoreContentCollapseProps = {
  children: React.ReactNode
  headerTitle: string
  headerSubtitle: string
}

export const ShowMoreContentCollapse = ({
  children,
  headerTitle,
  headerSubtitle,
}: ShowMoreContentCollapseProps) => {
  const [isExpanded, setExpanded] = useState(false)
  const headerRef = useRef<HTMLDivElement | null>(null)

  const scrollToHeader = () => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <>
      <Collapse
        iconLeft={false}
        withIcon={false}
        skipOnToggleFirstRender={true}
        initialExpanded={false}
        header={
          <div className={styles.header} ref={headerRef}>
            <div className={styles.sectionTitleWrapper}>
              <Typography variant="headline4Bold">{headerTitle}</Typography>
            </div>
            <div>
              <Typography className={styles.text} variant="body1">
                {headerSubtitle}
              </Typography>
            </div>
          </div>
        }
        isExpanded={isExpanded}
      >
        {children}
      </Collapse>

      <div className={styles.toggler}>
        <Divider className={clsx(styles.divider)} />
        <ToggleMore
          canToggle={true}
          showMore={isExpanded}
          toggleMore={() => {
            if (isExpanded) scrollToHeader()
            setExpanded(!isExpanded)
          }}
        />
      </div>
    </>
  )
}
