import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'
import styles from './ProjectDetailsLoading.module.scss'

export const ProjectDetailsLoading = () => (
  <div className={styles.container}>
    <TextSkeleton height="1.25rem" />
    <TextSkeleton height="2rem" />
    <TextSkeleton height="12.5rem" />
    <TextSkeleton height="12.5rem" />
    <TextSkeleton height="12.5rem" />
  </div>
)
