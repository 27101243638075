import { OrderItemsInArrearsInput } from '@obeta/schema'
import {
  DEFAULT_ORDER_DETAILS_IN_ARREARS_FILTERS,
  DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS,
  OrderDetailsInArrearsURLSearchParams as OrderItemsInArrearsURLSearchParams,
} from '@obeta/models/lib/models/Orders'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'
import { getURLSearchParamsByLocationSearch } from './virtualized-list'

/**
 * Create OrdersInput by query params and url search params for GraphQL.
 * @param queryParams Query params
 * @param urlSearchParams Url search params
 * @returns OrdersInput
 */
export const createOrdersInputByQueryParamsAndURLSearchParams = (
  queryParams: QueryParams,
  urlSearchParams: OrderItemsInArrearsURLSearchParams
): OrderItemsInArrearsInput => {
  return {
    limit: queryParams.limit ?? DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS.limit,
    offset: queryParams.offset ?? DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS.offset,
    searchTerm: urlSearchParams.searchTerm ?? DEFAULT_ORDER_DETAILS_IN_ARREARS_FILTERS.searchTerm,
  }
}

/**
 * Get order list search term value by search params
 * @param search Location search
 * @returns Search term value
 */
export const getOrderDetailsInArrearsSearchTermValue = (search: string): string => {
  const params = getURLSearchParamsByLocationSearch<OrderItemsInArrearsURLSearchParams>(search)
  return params.searchTerm ?? ''
}
