import React, { useState } from 'react'
import clsx from 'clsx'
import { GuardedImageTile } from '@obeta/components/lib/tiles'
import profilePicture from 'assets/img/Customer_Account/customerCardAvatar.svg'
import { IconButton, Typography } from '@mui/material'
import { ImagePickerPopover } from '@obeta/components/lib/image-picker-popover/ImagePickerPopover'
import { ReactComponent as EditIcon } from 'assets/icon/designsystem/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { Modal } from '@obeta/components/lib/modal/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import { Alert } from '@obeta/components/lib/alert-and-confirm/Alert'
import { useAvatarUpload } from '../../../../../hooks/useAvatarUpload'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useImgProxyUrls } from '@obeta/data/lib/hooks/useImgProxyUrls'
import { belowMdQuery, useMediaQuery } from '@obeta/data/lib/hooks'
import { useTranslation } from 'react-i18next'
import { getCustomerCardId } from '../info-section/InfoSection'
import styles from './LogoSection.module.scss'

export const LogoSection = () => {
  const { t } = useTranslation()
  const [isAvatarSelectMenuVisible, setAvatarSelectMenuVisible] = useState(false)
  const { isUploading, changeCustomerImage, uploadError, setUploadError, deleteUserUploadUrl } =
    useAvatarUpload({
      setAvatarSelectMenuVisible,
    })
  const isBelowMd = useMediaQuery(belowMdQuery)

  const { user: activeUser } = useUserDataV2()

  const customerCardActivatedId = getCustomerCardId(activeUser)

  const isAvatarPresent = activeUser?.settings?.profilePictureS3Path

  const { imgProxyUrlMobile, imgProxyUrlTablet } = useImgProxyUrls({ url: isAvatarPresent || '' })

  const deleteUserPhotoHandler = async () => {
    if (activeUser?.settings?.profilePictureS3Path) {
      await deleteUserUploadUrl({ s3Url: activeUser.settings?.profilePictureS3Path })
    }
  }

  const getAvatarUrl = () => {
    if (isAvatarPresent) return isBelowMd ? imgProxyUrlMobile : imgProxyUrlTablet
    return ''
  }

  return (
    <>
      <div className={styles.logoSection}>
        <div
          className={styles.imageOuter}
          onClick={!isAvatarPresent ? () => setAvatarSelectMenuVisible(true) : undefined}
        >
          <div className={styles.image}>
            <GuardedImageTile
              className={clsx(styles.imageContent, { [styles.dummyImage]: !isAvatarPresent })}
              imageUrl={isAvatarPresent ? getAvatarUrl() : profilePicture}
            />
          </div>
          {customerCardActivatedId && <div className={styles.noScreenshotIndicator}></div>}
          {isAvatarPresent && (
            <>
              <IconButton
                className={styles.editUserPhotoButton}
                onClick={() => setAvatarSelectMenuVisible(true)}
              >
                <EditIcon />
              </IconButton>
              <IconButton className={styles.deleteUserPhotoButton} onClick={deleteUserPhotoHandler}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <ImagePickerPopover
        isOpen={isAvatarSelectMenuVisible}
        onClose={() => setAvatarSelectMenuVisible(false)}
        takePictureCallback={changeCustomerImage}
      />
      <Modal fitContent open={isUploading}>
        <div className={styles.loadingContent}>
          <CircularProgress className={styles.progressSpin} />
          <Typography variant="bodyBold">{t('CUSTOMER_PROFILE.IMAGE_UPLOAD_LOADING')}</Typography>
        </div>
      </Modal>
      <Alert
        heading={uploadError || ''}
        handleConfirm={() => setUploadError('')}
        openAlertDialog={!!uploadError}
      />
    </>
  )
}
