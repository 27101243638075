import React, { FC } from 'react'
import checkoutSuccessStyles from '../shoppingcart/CheckoutOrderSuccess.module.scss'
import orderPendingStyles from '../shoppingcart/CheckoutOrderPending.module.scss'
import styles from './UserRegistrationDialog.module.scss'
import { DarkButton } from '../custom-button/CustomButton'
import { Typography } from '@mui/material'
import { ShopNameByDomain } from '@obeta/utils/lib/shopInfoByDomain'
import { useCompanyDetection } from '@obeta/data/lib/hooks/useCompanyDetection'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'
import { useTranslation } from 'react-i18next'

const DEPARTMENT_E_MAIL = 'neukunden@obeta.de'
const DEPARTMENT_TELEPHONE = '+49 30 720 94 - 550'
export const ShopAccessActivationSuccess: FC = () => {
  const { redirectToStartPage } = useRedirect()
  const { t } = useTranslation()

  const domain = useCompanyDetection()
  const getButtonText = () => {
    return t('SIGNUP.SUCCESS_MODAL.EXPLORE_DOMAIN', { domain: ShopNameByDomain[domain] })
  }

  return (
    <div className={styles.root}>
      <div className={orderPendingStyles.animationMainContainer}>
        <div className={checkoutSuccessStyles.circleLoader}>
          <div className={checkoutSuccessStyles.checkMark} />
        </div>
      </div>
      <div className={styles.textBlock}>
        <div className={styles.headlines}>
          <Typography id="modal-heading" variant={'headline2Bold'}>
            {t('SIGNUP.SUCCESS_MODAL.CONGRATS')}
          </Typography>
        </div>
        <div className={styles.body}>
          <Typography id="modal-description" variant={'body'}>
            {t('SIGNUP.SUCCESS_MODAL.NEW_CUSTOMER_DATA_FORWARDED_EMAIL_COMING')}
          </Typography>
          <div className={styles.contactInformationRow}>
            <Typography variant={'bodyBold'} color={'error'}>
              M
            </Typography>
            <Typography variant={'body'}>{DEPARTMENT_E_MAIL}</Typography>
          </div>
          <div className={styles.contactInformationRow}>
            <Typography variant={'bodyBold'} color={'error'}>
              T
            </Typography>
            <Typography variant={'body'}>{DEPARTMENT_TELEPHONE}</Typography>
          </div>
        </div>
      </div>
      <DarkButton type="submit" fullWidth={false} onClick={redirectToStartPage}>
        {getButtonText()}
      </DarkButton>
    </div>
  )
}
