import { FC, ReactNode } from 'react'
import { Divider, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { Tooltip } from '../tooltip/Tooltip'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import styles from './FormSection.module.scss'

export type FormSectionProps = {
  title: string | ReactNode
  children: ReactNode
  wrapperStyles?: string
  infoPlaceholder?: string
}

export const FormSection: FC<FormSectionProps> = ({
  title,
  children,
  wrapperStyles,
  infoPlaceholder,
}) => {
  const { desktop } = useBreakpoints()

  return (
    <div className={clsx(styles.wrapper, wrapperStyles)}>
      <div className={styles.sectionTitleWrapper}>
        {infoPlaceholder && !desktop ? (
          <Tooltip title={infoPlaceholder} placement="bottom" activateOnClick={true}>
            <Typography variant="bodyBold" className={styles.underlineText}>
              {title}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="bodyBold">{title}</Typography>
        )}
        {infoPlaceholder && desktop && (
          <Tooltip title={infoPlaceholder}>
            <InfoIcon className={styles.tooltipIcon} color={scssVariables.$darkGray} />
          </Tooltip>
        )}
      </div>
      <Divider className={styles.divider} />
      {children}
    </div>
  )
}
