import { composeReactRefs } from '@obeta/utils/lib/composeReactRefs'
import {
  useVirtualizedListAnimationState,
  VirtualizedListAnimationContext,
  virtualizedListAnimationOffsetTopVar,
} from '@obeta/utils/lib/virtualized-list-animation'
import { FC, LegacyRef, RefObject, useRef } from 'react'
import {
  AutoSizer,
  IndexRange,
  InfiniteLoader,
  InfiniteLoaderProps,
  List,
  ListProps,
  WindowScroller,
} from 'react-virtualized'

export type TInfiniteLoaderProps = Pick<
  InfiniteLoaderProps,
  'isRowLoaded' | 'loadMoreRows' | 'minimumBatchSize' | 'threshold'
>

export interface IInfiniteWindowScrollerProps {
  list: Pick<ListProps, 'rowRenderer' | 'deferredMeasurementCache' | 'rowHeight'>
  infiniteLoader: TInfiniteLoaderProps
  rowCount: number // current count of loaded items (ex. due to a pagination, 100 out of 1000)
  rowTotalCount: number // count of items, overall available (ex. items in a DB)
  onRowsRendered?: () => void
  onInitialRender?: () => void
  listReference?: LegacyRef<List>
  preventScrollRestoration?: boolean
}

interface IWindowScrollerListProps
  extends Pick<
    ListProps,
    'rowRenderer' | 'deferredMeasurementCache' | 'rowHeight' | 'onRowsRendered'
  > {
  rowCount: number
  listRef?: LegacyRef<List>
  onRowsRendered?: (params: IndexRange) => void
  listReference?: LegacyRef<List>
  preventScrollRestoration?: boolean
}

const WindowScrollerList: FC<IWindowScrollerListProps> = (props) => {
  const { rowCount, listRef, listReference, preventScrollRestoration, ...listProps } = props
  const lref = useRef<List>(null)

  let forcedHeight = 0
  const cache = listProps.deferredMeasurementCache

  if (cache) {
    new Array(rowCount).fill(1).forEach((val, idx) => {
      forcedHeight += cache.getHeight(idx, 0)
    })
  }

  const listWrapperRef = useRef<HTMLDivElement>(null)

  const listAnimation = useVirtualizedListAnimationState({
    onHeightChange(offset) {
      const element = listWrapperRef.current
      if (!element) return

      if (offset === null) {
        element.style.removeProperty(virtualizedListAnimationOffsetTopVar)
      } else {
        element.style.setProperty(virtualizedListAnimationOffsetTopVar, `${offset}px`)
      }
    },
  })

  return (
    <WindowScroller scrollElement={window}>
      {({
        height,
        onChildScroll,
        scrollTop,
        registerChild: windowScrollerRegisterChild,
        isScrolling,
      }) => {
        return (
          <AutoSizer disableHeight>
            {({ width }) => {
              return (
                <div
                  ref={composeReactRefs<HTMLDivElement>(
                    listWrapperRef,
                    windowScrollerRegisterChild
                  )}
                >
                  <VirtualizedListAnimationContext.Provider value={listAnimation.context}>
                    <List
                      {...listProps}
                      style={{ minHeight: forcedHeight }} //make sure that we at least have the height of all elements that we have cached for scroll restoration immidiatley, else there can be a race condition that scrolls before all elements are renderd and therefore the list beeing to small
                      rowCount={rowCount}
                      ref={
                        listRef
                          ? composeReactRefs(
                              lref,
                              listRef as RefObject<List>,
                              listReference as RefObject<List>
                            )
                          : lref
                      }
                      width={width}
                      height={height || 0}
                      isScrolling={isScrolling}
                      scrollTop={scrollTop}
                      onScroll={onChildScroll}
                      autoHeight // auto height should be always used with WindowScroller
                    />
                  </VirtualizedListAnimationContext.Provider>
                </div>
              )
            }}
          </AutoSizer>
        )
      }}
    </WindowScroller>
  )
}

export const InfiniteWindowScroller: FC<IInfiniteWindowScrollerProps> = (props) => {
  const {
    list,
    infiniteLoader,
    rowTotalCount,
    rowCount,
    onRowsRendered,
    onInitialRender,
    listReference,
    preventScrollRestoration,
  } = props

  const initallyRendered = useRef(false)

  return (
    <InfiniteLoader {...infiniteLoader} rowCount={rowTotalCount}>
      {({ onRowsRendered: onRowsRenderedInternal, registerChild: infiniteLoaderRegisterChild }) => {
        return (
          <WindowScrollerList
            listRef={infiniteLoaderRegisterChild}
            listReference={listReference}
            preventScrollRestoration={preventScrollRestoration}
            onRowsRendered={(indexRange) => {
              if (!initallyRendered.current && onInitialRender) {
                initallyRendered.current = true
                onInitialRender()
              }
              if (onRowsRendered) onRowsRendered()
              onRowsRenderedInternal(indexRange)
            }}
            rowCount={rowCount}
            {...list}
          />
        )
      }}
    </InfiniteLoader>
  )
}
