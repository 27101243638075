import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DarkButton, TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import styles from './SettingsPage.module.scss'

type FormActionFooterLineProps = {
  isDirty?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
  onReset: () => void
  onSubmit: () => void
  errors: Record<string, unknown>
}

export const FormActionFooterLine: FC<FormActionFooterLineProps> = ({
  isDirty,
  isLoading,
  onReset,
  errors,
  isSubmitting,
  onSubmit,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.formActionFooter}>
      <TertiaryButton disabled={!isDirty || isLoading} onClick={onReset}>
        {t('BUTTON.ABORT')}
      </TertiaryButton>
      <DarkButton
        type="submit"
        disabled={!isDirty || isLoading || !!Object.keys(errors).length || isSubmitting}
        onClick={onSubmit}
      >
        {t('BUTTON.SAVE_DATA')}
      </DarkButton>
    </div>
  )
}

export const FormActionFooter = ({ onSubmit, onReset }) => {
  const {
    formState: { isDirty, isLoading, errors, isSubmitting },
  } = useFormContext()

  return (
    <FormActionFooterLine
      isDirty={isDirty}
      isLoading={isLoading}
      onReset={onReset}
      errors={errors}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    />
  )
}
