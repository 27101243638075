import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'
import { FormField, IFormField } from './FormField'

const ZIP_CODE_PATTERN = /^\d{5}$/i

type ValRules = IFormField['rules']

export const CompanySection = () => {
  const { t } = useTranslation()

  const zipCodeRules: ValRules = {
    pattern: {
      value: ZIP_CODE_PATTERN,
      message: t('ADDRESSES.ERROR.ZIP_CODE_INVALID'),
    },
  }

  return (
    <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 1.5 }}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormField name="companyName" label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.COMPANY_NAME')} />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormField
          name="contactPerson"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.CONTACT_PERSON')}
          placeholder={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.CONTACT_PERSON_PLACEHOLDER')}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormField name="streetAndNumber" label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.ADDRESS')} />
      </Grid>
      <Grid size={{ xs: 4, sm: 2 }}>
        <FormField
          name="zipCode"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.ZIP_CODE')}
          rules={zipCodeRules}
        />
      </Grid>
      <Grid size={{ xs: 8, sm: 4 }} paddingLeft="0.5rem !important">
        <FormField name="city" label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.CITY')} />
      </Grid>
    </Grid>
  )
}
