import { Dispatch, FC, Fragment, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, MenuList, PopoverOrigin, Typography, useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BasePopover, TertiaryButton } from '@obeta/components/index'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { usePopoverState } from '@obeta/data/lib/hooks'
import { ReactComponent as ArrowBack } from 'assets/icon/designsystem/arrow_back.svg'
import { ReactComponent as ArrowDown } from 'assets/icon/designsystem/arrow_down.svg'
import { ReactComponent as ArrowUp } from 'assets/icon/designsystem/arrow_up.svg'
import { menuItems } from './constants'
import { MenuButton } from './MenuButton'
import { IHeader } from './Header'
import { IMenuItem, TSettingsView } from './types'
import styles from './Header.module.scss'
// Import Swiper styles
import '@obeta/assets/theme/swiper.scss'

export const NavMenu: FC<IHeader> = ({ setActiveView, activeView }) => {
  const theme = useTheme()
  const popoverState = usePopoverState()
  const { open, handleClick } = popoverState

  const handleItemClick = (e: React.MouseEvent<HTMLElement>, item: IMenuItem) => {
    const { title, options } = item
    if (options?.length) {
      handleClick(e)
    } else {
      setActiveView(title as TSettingsView)
    }
  }

  const renderMenuItem = (menuItem: IMenuItem) => {
    const { title, options } = menuItem
    const arrowColor =
      activeView === title ? theme.palette.redVariant.red100pct : theme.palette.grayVariant.dark
    const rightAdornment = open ? <ArrowUp color={arrowColor} /> : <ArrowDown color={arrowColor} />

    return (
      <Fragment key={title}>
        <MenuButton
          title={title}
          active={activeView === title}
          rightAdornment={options?.length ? rightAdornment : null}
          onClick={(e) => handleItemClick(e, menuItem)}
          disabled={menuItem.disabled}
        />
        {options?.length && open && (
          <Submenu popoverState={popoverState} options={options} setActiveView={setActiveView} />
        )}
      </Fragment>
    )
  }

  return (
    <div className={styles.navMenu}>
      <Swiper
        direction="horizontal"
        slidesPerView="auto"
        resizeObserver={true}
        className={styles.menuItems}
      >
        {menuItems.map((item) => (
          <SwiperSlide key={item.title} className={styles.menuButton}>
            {renderMenuItem(item)}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

interface ISubmenu {
  options: IMenuItem[]
  popoverState: {
    open: boolean
    onClose: () => void
    handleClick: (event: React.MouseEvent<HTMLElement>) => void
    anchorEl: Element | null
  }
  setActiveView: Dispatch<SetStateAction<TSettingsView>>
}

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}

const Submenu: FC<ISubmenu> = ({ options, popoverState, setActiveView }) => {
  const { anchorEl, open, onClose } = popoverState
  const { mobile } = useBreakpoints()
  const { t } = useTranslation()

  const paperClasses = mobile ? styles.submenuPopoverMobile : undefined
  const paperDesktopStyles = { style: { width: anchorEl?.clientWidth ?? 'auto' } }

  return (
    <BasePopover
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      mobile={mobile}
      classes={{ paper: paperClasses }}
      open={open}
      onClose={onClose}
      PaperProps={mobile ? undefined : paperDesktopStyles}
    >
      {mobile && (
        <div className={styles.submenuBack}>
          <TertiaryButton leftIcon={<ArrowBack />} onClick={onClose}>
            {t('SETTINGS.BACK_BUTTON')}
          </TertiaryButton>
        </div>
      )}
      <MenuList>
        {options.map(({ title }) => (
          <MenuItem
            className={styles.submenuItem}
            key={title}
            value={title}
            onClick={() => {
              setActiveView(title as TSettingsView)
              onClose()
            }}
          >
            <Typography>{title}</Typography>
          </MenuItem>
        ))}
      </MenuList>
    </BasePopover>
  )
}
