import { createContext, useContext } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'

type ProjectFormValue = {
  form: UseFormReturn
}

const ProjectFormContext = createContext<ProjectFormValue>({} as ProjectFormValue)

export const ProjectFormProvider = ({ children }) => {
  const form = useForm({
    mode: 'onChange',
  })
  return <ProjectFormContext.Provider value={{ form }}>{children}</ProjectFormContext.Provider>
}

export const useProjectFormContext = () => {
  return useContext(ProjectFormContext)
}
