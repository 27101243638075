import { useCallback } from 'react'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { useHistory } from './useHistoryApi'

/**
 * Returns a function that navigates back in the history if the user has navigated within the shop.
 * Otherwise, it redirects to the specified fallback target.
 *
 * @param fallbackTarget - The pathname to navigate to when there is no shop history.
 * @param fallbackTargetApp - The pathname to navigate to within the app.
 * @returns A function that handles the navigation logic.
 */
export const useHistoryBack = ({
  fallbackTarget,
  fallbackTargetApp,
}: {
  fallbackTarget: string
  fallbackTargetApp?: string
}): (() => void) => {
  const history = useHistory()
  return useCallback(() => {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(isUiTarget('app') ? fallbackTargetApp ?? fallbackTarget : fallbackTarget)
    }
  }, [fallbackTarget, fallbackTargetApp, history])
}
