export enum ESettingsSection {
  DATA_FOR_PRINT = 'Daten für individuelles Datenblatt',
  FIRM_DATA = 'Firmendaten',
  PRICE_DATA = 'Preisinformationen',
  DELIVERY_ADDRESSESS = 'Lieferadressen',
  COMMISSION = 'Kommission',
  ORDER_NOTIFICATION = 'Bestellbenachrichtigung',
}

export type TSettingsView = ESettingsSection

export interface IMenuItem {
  title: ESettingsSection
  options?: IMenuItem[]
  disabled?: boolean
}
