import { gql, useApolloClient } from '@apollo/client'
import { useCallback, useRef, useState } from 'react'
import {
  OrderDetailsInput,
  SearchOrderDetailsQuery,
  SearchOrderDetailsQueryVariables,
} from 'graphql-codegen'
import { useOrderDetailsContext } from '../stores/useOrderDetailsContext'
import { useWarehouseContext } from '../stores/useWarehouseContext'

const SEARCH_QUERY = gql`
  query searchOrderDetails($input: OrderDetailsInput!, $warehouseIds: [String!]!) {
    searchOrderDetails(input: $input) {
      success
      errorCode
      errorMessage
      totalItemsCount
      items {
        articleDescription
        createdAt
        dehaId
        deliverySlipId
        id
        isSapPromotionalProduct
        lastUpdated
        metalAddition
        netPrice
        obetaId
        orderAmount
        orderId
        supplierFilterName
        product {
          sapId
          articleDescription
          dehaId
          isCurrentlyNotAvailable
          isTopseller
          isSendable
          isCutProduct
          minimumAmount
          oxomiId
          priceDimension
          imageData {
            sapId
            images {
              large
            }
          }
          images {
            url
          }
          oxomiId
          supplierId
          prices {
            currency
            listPrice
            netPrice
            strikeThroughPrice
            tecSelect
          }
          stock(warehouseIds: $warehouseIds) {
            amount
            sapId
            unit
            warehouseId
          }
          stockAvailabilityEstimate {
            sapId
            minValue
            maxValue
            error
            unit
          }
          supplierId
          supplierImageData {
            large
            sapId
          }
          title
          type
          unit
        }
        sapId
        shippingAmount
        shippingValue
        shippingValueState
        state {
          type
          count
        }
        supplierArticleId
        supplierArticleId2
        supplierId
        supplierImageData {
          sapId
          large
        }
        supplierName
        totalPrice
        unit
      }
    }
  }
`
export const SEARCH_ORDER_ITEMS_LIMIT = '20'

export const INITIAL_ORDER_ITEMS_SEARCH_INPUT: OrderDetailsInput = {
  orderId: '',
  limit: SEARCH_ORDER_ITEMS_LIMIT,
  offset: '0',
  searchTerm: '',
  filter: [],
}

export const useOrderItemsSearch = () => {
  const { orderItems, setOrderItems } = useOrderDetailsContext()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const lastLoadedOrderId = useRef<string>('')
  const [searchOrderItemsInput, setSearchOrderItemsInput] = useState<OrderDetailsInput>(
    INITIAL_ORDER_ITEMS_SEARCH_INPUT
  )
  const { warehouseId } = useWarehouseContext()
  const client = useApolloClient()

  const searchOrderItems = useCallback(
    async (input: OrderDetailsInput, defaultStoreId) => {
      if (lastLoadedOrderId.current !== input.orderId) {
        setOrderItems([])
        lastLoadedOrderId.current = input.orderId
      }
      const warehouseIds = [defaultStoreId, warehouseId].filter(Boolean) as string[]

      const response = await client.query<
        SearchOrderDetailsQuery,
        SearchOrderDetailsQueryVariables
      >({
        query: SEARCH_QUERY,
        variables: {
          input: {
            orderId: input.orderId,
            limit: input.limit,
            offset: input.offset,
            searchTerm: input.searchTerm,
            filter: input.filter,
          },
          warehouseIds: warehouseIds,
        },
        fetchPolicy: 'no-cache',
      })
      const orderItemsResponse = response.data?.searchOrderDetails.items

      if (orderItems) {
        if (input.offset === '0') {
          // Replace offer items on search change
          setOrderItems([...orderItemsResponse])
        } else if (orderItemsResponse.length > 0) {
          // Add offers on scroll
          setOrderItems([...orderItems, ...orderItemsResponse])
        }
      }
      setIsLoading(false)
    },
    [client, orderItems, setOrderItems, warehouseId]
  )

  return {
    isLoading,
    searchOrderItemsInput,
    searchOrderItems,
    setIsLoading,
    setSearchOrderItemsInput,
  }
}
