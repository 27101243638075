import { TFunction } from 'react-i18next'
import { IFetchedPrices } from '@obeta/data/lib/hooks/useProductPrices'
import { EtimData, ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { withUnit } from '@obeta/utils/lib/withUnit'
import { ShopDomains, ShopFullDomains } from '@obeta/utils/lib/domains'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { IStaticTableSheetProps } from '../components/StaticTableSheet/StaticTableSheet'
import { DatasheetConfig } from '@obeta/models/lib/models/PrintService/PrintInput'
import { DatsheetPrintOptions } from '@obeta/models/lib/models/Datasheet/IndividualDatasheet'
import {
  PRICE_INFO,
  shouldShowIndividualPrice,
  getIndividualPricesInfo,
  getStandartPricesInfo,
} from './priceInfoConfig'

export type PdfArticleDataSheetProps = {
  product: ProductAggregate
  domain: ShopDomains
  prices?: IFetchedPrices
  datasheetConfig?: DatasheetConfig
}

export const getPricesInfo = (
  t: TFunction,
  product: ProductAggregate,
  prices: IFetchedPrices,
  datasheetConfig?: DatasheetConfig
) => {
  const priceInfoList =
    datasheetConfig?.type === DatsheetPrintOptions.INDIVIDUAL
      ? PRICE_INFO.filter((info) => {
          return shouldShowIndividualPrice(info, datasheetConfig)
        })
      : PRICE_INFO

  if (datasheetConfig?.type === DatsheetPrintOptions.INDIVIDUAL)
    return getIndividualPricesInfo(t, product, prices, priceInfoList, datasheetConfig)

  return getStandartPricesInfo(t, product, prices, priceInfoList)
}

export const getPackagingInfo = (t: TFunction, product: ProductAggregate) => {
  return [
    {
      type: t('ARTICLE_DETAIL.MINIMUM_AMOUNT'),
      value: withUnit(product?.minimumAmount, product?.unit),
    },
    {
      type: t('ARTICLE_DETAIL.PACKAGING_UNIT'),
      value: withUnit(product?.packagingUnit, product?.unit),
    },
  ]
}

export const getMetaInfo = (t: TFunction, product: ProductAggregate) => {
  return [
    {
      type: t('ARTICLE_DETAIL.ARTICLE_NUMBER'),
      value: product.dehaId,
    },
    {
      type: t('ARTICLE_DETAIL.SUPPLIER_ORDER_NUMBER'),
      value: product.supplierArticleId,
    },
    {
      type: t('ARTICLE_DETAIL.SUPPLIER_ARTICLE_NUMBER'),
      value: product.supplierArticleId2,
    },
    {
      type: t('ARTICLE_DETAIL.EAN'),
      value: product.eans[0],
    },
  ]
}

export const getCatalogYearAndPageInfo = (
  catalogInfo: PdfArticleDataSheetProps['product']['catalogInfo']
) => {
  if (!catalogInfo) return ''

  const { year1, year2, page } = catalogInfo

  return `${year1}/${year2}  (Seite ${page})`
}

export const getArticleLink = (domain: ShopDomains, product: ProductAggregate) => {
  const env = process.env.NODE_ENV || 'production'
  const url = ShopFullDomains[env]?.[domain] || 'http://localhost:4200'

  return `${url}${ShopRoutes.ArticleDetailPage}/${product.sapId}`
}

export const formatDomainName = (domain: ShopDomains) =>
  domain.charAt(0).toUpperCase() + domain.slice(1)

export const getTechInfoValue = (etimDataItem: EtimData, t: TFunction) => {
  if (etimDataItem.value1 === 'true') return t('COMMON.YES')

  if (etimDataItem.value1 === 'false') return t('COMMON.NO')

  if (etimDataItem.unit && etimDataItem.value1 !== '-')
    return `${etimDataItem.value1} ${etimDataItem.unit}`

  return etimDataItem.value1
}

export const getTechInfo = (product: ProductAggregate, t: TFunction) => {
  return product.etimData.reduce((acc, etimDataItem) => {
    if (etimDataItem.value1 === '-') return acc

    return [...acc, { type: etimDataItem.featureText, value: getTechInfoValue(etimDataItem, t) }]
  }, [] as IStaticTableSheetProps['data'])
}
