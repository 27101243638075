export function offsetLimitToPage(offset: number, limit: number): number {
  if (!Number.isFinite(offset) || !Number.isFinite(limit) || offset < 0 || limit < 1) {
    return 0
  }
  return Math.floor(offset / limit) + 1
}

export function pageToOffset(page: number, limit: number): number {
  if (!Number.isFinite(page) || !Number.isFinite(limit) || page < 1 || limit < 1) {
    return 0
  }
  return (page - 1) * limit
}
