import React from 'react'
import CartTemplatesListPage from '@obeta/layouts/lib/cartTemplatesListPage/cartTemplatesListPage'
import { PleaseLoginScreen } from '../../common/components/please-login-screen/PleaseLoginScreen'
import { useTranslation } from 'react-i18next'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

export const CartTemplateList: React.FC = () => {
  const { t } = useTranslation()
  const { isLoggedIn } = useUserDataV2()

  if (isLoggedIn) return <CartTemplatesListPage />

  return (
    <PleaseLoginScreen
      title={t('TEMPLATE_OVERVIEW.GUEST_TEXT_TITLE')}
      body={t('TEMPLATE_OVERVIEW.GUEST_TEXT_DESCRIPTION')}
    />
  )
}
