import React, { FC } from 'react'
import { Autocomplete, TextField, Typography } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { useUserOverviewTableData } from '@obeta/data/lib/hooks/user-overview/useUserOverviewTableData'
import styles from '../order-permission/OrderPermissionCartNotification.module.scss'
import { useUserManagementReadOnly } from './useUserManagementReadOnly'

type UserOption = {
  value: string
  label: string
  email: string
}

type Props = {
  usersToNotify: string[]
  setUsersToNotify: (value: string[]) => void
}

const getUserOption = (user): UserOption => {
  if (user.email === undefined) {
    return {
      value: user,
      label: user,
      email: user,
    }
  } else
    return {
      value: user.emailData.email,
      label: user.emailData.email,
      email: user.emailData.email,
    }
}

export const NotifyAutoComplete: FC<Props> = ({ usersToNotify, setUsersToNotify }) => {
  const { t } = useTranslation()
  const { sortedItems } = useUserOverviewTableData()
  const isReadOnlyUser = useUserManagementReadOnly()

  return (
    <Autocomplete
      freeSolo
      disabled={isReadOnlyUser}
      onBlur={(event) => {
        setUsersToNotify([(event.target as HTMLInputElement).value])
      }}
      clearIcon={null}
      inputValue={usersToNotify[0] ?? ''}
      onInputChange={(event, value, reason) => {
        setUsersToNotify([value])
      }}
      className={clsx(styles.autoComplete, styles.notificationInput)}
      options={[...new Map(sortedItems.map((item) => [item.email, item])).values()]
        .filter((item) => !usersToNotify.find((userEmail) => userEmail === item.email))
        .filter((item) => item.emailData.isConfirmed)
        .map(getUserOption)}
      renderOption={(props, option: { email: string; label: string; value: string }) => (
        <li {...props}>
          <Typography>{option.email}</Typography>
        </li>
      )}
      getOptionLabel={(option: { value: string; label: string }) => option.label}
      onChange={(event, value: UserOption) => {
        if (!value) {
          setUsersToNotify([])
          return
        }
        const chosenUser = sortedItems.find((item) => item.customerNumber === value.value)
        if (chosenUser) {
          setUsersToNotify([chosenUser.email])
        }
      }}
      renderInput={(params) => {
        const inputValue = usersToNotify[0] ?? ''
        return (
          <TextField
            {...params}
            placeholder={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.USER_AUTOSELECT')}
            className={styles.autoCompleteTextField}
            sx={{
              '& .MuiInputBase-root': {
                paddingRight: '0.5rem !important',
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: inputValue ? (
                <CloseIcon
                  onClick={() => setUsersToNotify([''])}
                  style={{ height: '1rem', width: '1rem', cursor: 'pointer' }}
                />
              ) : null,
            }}
          />
        )
      }}
      filterOptions={(options, { inputValue }) => {
        return options.filter(
          (option: UserOption) =>
            option.email.toLowerCase().includes(inputValue) ||
            option.label.toLowerCase().includes(inputValue)
        )
      }}
      autoHighlight={false}
      sx={{
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: 'transparent',
        },
        '& .MuiAutocomplete-option:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
    />
  )
}
