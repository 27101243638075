import { CustomerCompanyDataModel } from 'graphql-codegen'

export const checkForCustomData = (companyInfo: CustomerCompanyDataModel) => {
  return (
    companyInfo.companyLogo ||
    companyInfo.companyName ||
    companyInfo.contactPerson ||
    companyInfo.fax ||
    companyInfo.mail ||
    (companyInfo.openingHours && companyInfo.openingHours.length > 0) ||
    companyInfo.phone ||
    companyInfo.streetAndNumber ||
    companyInfo.website ||
    (companyInfo.zipCode && companyInfo.city)
  )
}
