import { useCallback } from 'react'
import { UserPermissions } from '@obeta/models/lib/schema-models/userV2-subusers'
import {
  CART_PERMISSION_STATUS_PROJECT_CARTS,
  CartPermissionStatus,
} from '@obeta/utils/lib/user-management/cart-status'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'
import { isValidEmail } from '@obeta/utils/lib/isValidEmail'
import { CartWritePermission } from '@obeta/models/lib/models/Users/UserV2'

export const useUserOrderPermissionsErrorCount = () => {
  const { t } = useTranslation()

  const getUserOrderPermissionsErrorCount = useCallback(
    (
      notificationEmail: string | null | undefined,
      projectCartIds: string[],
      cartPermissionStatus: CartPermissionStatus,
      allowedSubmitAction: CartWritePermission,
      permissions: UserPermissions,
      maxValueFullRangeActive,
      maxValueFullRange
    ) => {
      let errorCount = 0

      // "Projektwarenkorb" is set, but no option was selected
      if (
        projectCartIds.length === 0 &&
        cartPermissionStatus === CART_PERMISSION_STATUS_PROJECT_CARTS
      ) {
        errorCount++
        getEventSubscription().next({
          type: EventType.Alert,
          notificationType: NotificationType.Error,
          id: 'missing-project-cart',
          options: {
            message: t('USER_MANAGEMENT.PROJECT_CART_MISSING_ERROR'),
            id: 'missing-project-cart',
            duration: 8000,
          },
        })
      }

      // Checkbox on "Max. Warenkorbwert" is set, but no value was entered
      if (
        maxValueFullRangeActive &&
        (maxValueFullRange === undefined || maxValueFullRange === null)
      ) {
        errorCount++
        setTimeout(
          () =>
            getEventSubscription().next({
              type: EventType.Alert,
              notificationType: NotificationType.Error,
              id: 'missing-max-value-full-range-input',
              options: {
                message: t('USER_MANAGEMENT.MAX_CART_VALUE_MISSING_ERROR'),
                id: 'missing-max-value-full-range-input',
                duration: 8000,
              },
            }),
          0
        )
      }

      const isValidNotificationEmail = notificationEmail && isValidEmail(notificationEmail)
      const isValidSubmitEmail = !notificationEmail || isValidEmail(notificationEmail)

      const isValidEmailAction =
        allowedSubmitAction === CartWritePermission.None ||
        (allowedSubmitAction === CartWritePermission.Notify && isValidNotificationEmail) ||
        (allowedSubmitAction === CartWritePermission.Submit && isValidSubmitEmail)

      // invalid or missing notificationMail value
      if (!isValidEmailAction) {
        errorCount++
        setTimeout(
          () =>
            getEventSubscription().next({
              type: EventType.Alert,
              notificationType: NotificationType.Error,
              id: 'invalid-notification-email',
              options: {
                message: t('USER_MANAGEMENT.INVALID_NOTIFICATION_EMAIL_ERROR'),
                id: 'invalid-notification-email',
                duration: 8000,
              },
            }),
          0
        )
      }

      return errorCount
    },
    [t]
  )

  return getUserOrderPermissionsErrorCount
}
